<template>
    <div v-if="youTubePublishingEnabled">
        <v-divider class="mb-2" />
        <v-row no-gutters>
            <v-col
                cols="12"
                xl="1"
                lg="2"
                md="2"
                sm="4"
                class="pt-2"
            >
                <v-img
                    src="/images/yt_logo_rgb_light.png"
                    max-width="131"
                    class="mr-4 mt-sm-2 mt-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="3"
                lg="3"
                md="4"
                sm="4"
            >
                <v-text-field
                    v-model="newAccount.name"
                    label="Account name"
                />
            </v-col>
            <v-col
                cols="12"
                xl="8"
                lg="7"
                md="6"
                sm="2"
            >
                <div class="d-flex">
                    <v-btn
                        :disabled="!accountNameValid"
                        color="primary"
                        class="ml-sm-4 mt-sm-3 mt-0"
                        @click="grantAccess()"
                    >
                        Grant Access
                    </v-btn>
                    <div class="py-2 px-4">
                        <a
                            style="color: #666666"
                            href="https://www.youtube.com/t/terms"
                            target="_blank"
                        >YouTube Terms
                            of Service</a><br>
                        <a
                            style="color: #666666"
                            href="https://policies.google.com/privacy"
                            target="_blank"
                        >Google
                            Privacy Policy</a>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row
            v-for="account in youTubeAccounts"
            :key="`yt-account-${account.id}`"
            no-gutters
        >
            <v-col
                cols="12"
                xl="1"
                lg="2"
                md="2"
                sm="4"
                class="pt-2"
            >
                <v-img
                    src="/images/yt_logo_rgb_light.png"
                    max-width="131"
                    class="mr-4 mt-sm-2 mt-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="3"
                lg="3"
                md="4"
                sm="4"
            >
                <v-text-field
                    v-model="account.name"
                    label="Account name"
                    readonly
                />
            </v-col>
            <v-col
                cols="12"
                xl="8"
                lg="7"
                md="6"
                sm="4"
            >
                <div class="d-flex">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn
                                text
                                icon
                                class="ml-2 mt-sm-3"
                                color="error"
                                @click="askRemoveAccess(account)"
                                v-on="on"
                            >
                                <v-icon>clear</v-icon>
                            </v-btn>
                        </template>
                        <span>Remove</span>
                    </v-tooltip>
                    <div class="py-2 px-4">
                        To revoke permissions given to the Quadia Platform, please go to your Google security settings page
                        <a
                            href="https://security.google.com/settings/security/permissions"
                            target="_blank"
                        >https://security.google.com/settings/security/permissions</a>.
                    </div>
                </div>
            </v-col>
        </v-row>
        <confirm-modal
            v-model="deleteModal"
            title="Remove YouTube account"
            cancel-button="Cancel"
            confirm-button="Remove"
            @confirm="removeAccount"
        >
            <p>
                Would you like to remove this YouTube access token?<br>
                This company will no longer be able to publish to this YouTube account.
            </p>
            <v-divider class="my-4" />
            <p><strong>Important:</strong></p>
            <p>
                This will remove the YouTube access tokens from our system only. <br>
                To revoke the permissions given to Quadia Platform, please go to your Google security settings page at
                <a
                    href="https://security.google.com/settings/security/permissions"
                    target="_blank"
                >https://security.google.com/settings/security/permissions</a>.
            </p>
        </confirm-modal>
    </div>
</template>

<script>
    import ConfirmModal from '@/components/controls/modals/confirm-modal';
    import {getYouTubeAuthorizationUrl} from '@/services/youtube';
    import {removePublishingAccount} from '@/services/publishing';

    export default {
        name: 'YoutubeAccounts',
        components: {ConfirmModal},
        props: ['company', 'accounts'],
        data() {
            return {
                newAccount: {
                    name: '',
                    id: '',
                },
                youTubeAccounts: this.accounts.filter(acc => acc.meta.accountType === 'youtube'),
                accountToRemove: null,
                deleteModal: false,
            };
        },
        computed: {
            accountNameValid() {
                return !!this.newAccount.name &&
                    this.accounts.filter(account => account.name === this.newAccount.name).length === 0;
            },
            youTubePublishingEnabled() {
                return process.env.VUE_APP_ENABLE_YOUTUBE_PUBLISHING === 'true';
            }
        },
        methods: {
            askRemoveAccess(account) {
                this.accountToRemove = account;
                this.deleteModal = true;
            },
            removeAccount() {
                removePublishingAccount(this.accountToRemove.id).then(() => {
                    this.youTubeAccounts = this.youTubeAccounts.filter(acc => acc.id !== this.accountToRemove.id);
                    this.accountToRemove = null;
                });
            },
            grantAccess() {
                getYouTubeAuthorizationUrl(this.company.id, this.newAccount.name)
                    .then(url => location.href = url);
            }
        }
    };

</script>

<style scoped>

</style>