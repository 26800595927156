<template>
    <div>
        <div class="d-flex">
            <div class="component-title">
                <span>
                    <slot
                        name="title"
                        :title="title"
                    >{{ title }}</slot>
                </span>
            </div>
            <div
                v-if="canEdit"
                class="component-action"
            >
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn
                            icon
                            small
                            class="my-0 py-0 ml-2"
                            color="primary"
                            v-on="on"
                            @click="addItem"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                    <span>Add Chapter</span>
                </v-tooltip>
            </div>
        </div>
        <div
            v-for="item in internalItems"
            :key="`chapter-${item._id}`"
            class="chapter-list-item d-flex"
        >
            <div class="chapter-time-offset">
                <time-index-input
                    v-model="item.offset"
                    class="mt-1 mr-2"
                    :read-only="!canEdit"
                    :max="video.duration / 1000"
                    @input="sortSaveItem(item)"
                />
            </div>
            <div class="chapter-title">
                <input
                    v-model="item.value"
                    type="text"
                    class="chapter-input mt-1"
                    :disabled="!canEdit"
                    @input="saveItem(item)"
                >
            </div>
            <div
                v-if="canEdit"
                class="chapter-actions"
            >
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn
                            small
                            icon
                            v-on="on"
                            @click="deleteItem(item)"
                        >
                            <v-icon color="error">
                                delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Delete</span>
                </v-tooltip>
            </div>
        </div>
        <confirm-modal
            v-model="deleteModal"
            title="Delete Chapter"
            confirm-button="Delete Chapter"
            cancel-button="cancel"
            @confirm="confirmDelete"
        >
            <p>Would you like to delete the following Chapter?</p>
            <div
                v-if="selectedChapter"
                class="chapter-list-item d-flex"
            >
                <div class="chapter-time-offset">
                    {{ getReadableTimeOffset(selectedChapter) }}
                </div>
                <div class="chapter-title">
                    {{ selectedChapter.value }}
                </div>
            </div>
        </confirm-modal>
    </div>
</template>

<script>
    import {debounce} from 'lodash';
    import {$http, videoApiUrl} from '../../../../services/http';
    import Chapter from '../../../../models/chapters/Chapter';
    import TimeIndexInput from './TimeIndexInput';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';
    import ConfirmModal from '../../modals/confirm-modal';
    import TimeHelper from '../../../../time-helper';

    export default {
        name: 'Chapters',
        components: {ConfirmModal, TimeIndexInput},
        props: {
            title: {
                type: String,
                default: ''
            },
            video: {
                type: Object,
            },
        },
        data() {
            return {
                internalItems: [],
                deleteModal: false,
                selectedChapter: null
            };
        },
        computed: {
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            getApiRoot() {
                return videoApiUrl(`api/videos/${this.video.id}/chapters`);
            },
            addItem() {
                const smallPreviewId = document.querySelector('[id^="preview-player-' + this.video.id + '"]').id;
                window.QuadiaPlayer.players[smallPreviewId].get('time').then(time => {
                    this.createItem({offset: time, value: 'New Chapter'});
                }).catch(() => {
                    this.createItem({value: 'New Chapter'});
                });
            },
            createItem(item) {
                const newItem = new Chapter(item) || {};
                const items = this.sortItems([...this.internalItems, newItem]);
                this.internalItems = items;
                $http.post(this.getApiRoot(), newItem).then(() => {
                    this.$emit('input');
                });
            },
            sortItems(items) {
                return items.sort((a, b) => a.offset < b.offset ? -1 : 1);
            },
            deleteItem(item) {
                this.selectedChapter = item;
                this.$nextTick(() => {
                    this.deleteModal = true;
                });
            },
            confirmDelete() {
                this.internalItems = this.internalItems.filter(item => item._id !== this.selectedChapter._id);
                $http.delete(`${this.getApiRoot()}/${this.selectedChapter._id}`)
                    .then(() => {
                        this.getItems();
                        this.$emit('input');
                    });
            },
            getReadableTimeOffset(chapter) {
                if (!chapter) {
                    return 0;
                }
                return TimeHelper.fromMsToHisU(chapter.offset * 1000);
            },
            getItems() {
                return $http.get(this.getApiRoot()).then(response => {
                    this.internalItems = this.sortItems(response.data.map(item => new Chapter(item)));
                });
            },
            sortSaveItem: debounce(function (item) {
                this.internalItems = this.sortItems(this.internalItems);
                this.saveItem(item);
            }, 400),
            saveItem: debounce(function (item) {
                return $http.put(`${this.getApiRoot()}/${item._id}`, item).then(() => {
                    this.$emit('input');
                });
            }, 1000),
        },
        mounted() {
            this.getItems();
        }
    };
</script>

<style scoped>
    .component-title {
        flex: 1 1 auto;
        color: gray;
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    .chapter-list-item {
        flex-direction: row;
    }

    .chapter-time-offset {
        flex: 0 0 7rem;
        padding-top: 4px;
    }

    .chapter-title {
        flex: 1 1 0
    }

    .chapter-actions {
        flex: 0 0 auto;
        justify-content: end;
    }

    .chapter-input {
        border: 1px solid #BBBBBB;
        border-radius: 3px;
        padding: 0 3px;
        display: block;
        width: 100%;
    }
</style>
<style lang="scss">
    .chapter-time-offset {
        .numeric-input {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }
</style>
