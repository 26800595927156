import {sortBy} from 'lodash';

const presentationGetters = {
    videoId: state => state.video.id,
    activeElement: state => state.presentation.items.find(element => element.active),
    isActiveElement: (state, getters) => (element) => element._id === getters.activeElement?._id,
    elements: (state) => state.presentation.items,
    openElements: state => {
        const res = [];
        state.presentation.items.forEach((item, index) => {
            res.push(index);
        });
        return res;
    },
    currentSlide: state => {
        if (state.presentation.items.length === 0) {
            return null;
        }

        const items = sortBy(state.presentation.items, 'start');
        let oldItem = null;
        for (let i=0; i < items.length; i++) {
            if (items[i].start <= state.currentPlayerPosition) {
                oldItem = items[i];
            } else {
                return oldItem;
            }
        }

        return oldItem;
    }
};

export default presentationGetters;