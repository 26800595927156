import Vue from 'vue';

export function toastError(message, icon) {
    Vue.prototype.$toasted.error(message, {icon: icon || 'error'});
}

export function toastSuccess(message, icon) {
    Vue.prototype.$toasted.success(message, {icon: icon || 'done'});
}

export function toastInfo(message, icon) {
    Vue.prototype.$toasted.info(message, {icon: icon || 'info'});
}

export function toast(message, icon) {
    Vue.prototype.$toasted.show(message, (icon ? {icon: icon} : null));
}

export function handleUnprocessable(error)
{
    const errors = error.response.data;
    for (let key in errors) {
        let errorMessage = Array.isArray( errors[key]) ?  errors[key].join('<br />') :  errors[key].message || errors[key];
        if(typeof errorMessage !== 'string'){
            errorMessage = '';
            try {
                for (let prop in errors[key]) {
                    if (Object.prototype.hasOwnProperty.call(errors[key], prop)) {
                        for (let i = 0; i < errors[key][prop].length; ++i) {
                            errorMessage = errorMessage + `${prop}: ${errors[key][prop][i]} <br/>`;
                        }
                    }
                }
            }catch (e) {
                errorMessage = JSON.stringify(errors[key]);
            }
        }
        toastError(errorMessage, 'warning');
    }
}

export function handleServerErrors(error)
{
    if (error.response.data.message) {
        toastError('An unexpected error occurred. Please try again. <br />' + error.response.data.message);
    }

    if (error.response.data.error) {
        toastError('An unexpected error occurred. Please try again. <br />' + error.response.data.error.message);
    }

}

export function handleBadRequest() {
    toastError('We couldn\'t process your request. Please try again later.');
}

export function handleAuthenticationRequired(e) {
    if( e.response.data.error ) {
        toastError(e.response.data.error);
        return;
    }
    toastError('Login is required to have access to this feature.');
}

export function handleUnauthorized() {
    toastError('You are not authorized to access this feature..');
}

export function handleNotFound(e) {
    if (e.response.data.error) {
        toastError(e.response.data.error.message);
    }

    if (e.response.message) {
        toastError(e.response.data.message);
    }
}

export function handleTooManyAttempts() {
    toastError('You have reached your request limit. Please try again in a minute.');
}

export function handleTooEarly(e) {
    if (new RegExp(/^(http)(.+\/)api\/videos\/([a-zA-Z-0-9]+)\/copy$/gi).test(e.response.config.url)) {
        toastError('We’re unable to copy the video, because it’s not encoded yet. Please wait until the video is encoded before trying again.');
        return;
    }
    toastError(e.response.data.error);
}