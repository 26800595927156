import BaseAction from './BaseAction';

class OpenLinkAction extends BaseAction {
    constructor(data) {
        super(data);
        this.link = data.link;
        this.target = data.target;
    }
}

export default OpenLinkAction;