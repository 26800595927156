<template>
    <div class="col-md-12">
        <v-card class="no-search-result">
            No items match your search!
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'NoSearchResult',
        methods: {
        },
    };
</script>

<style scoped>
.no-search-result {
    border-radius: 5px;
    line-height: 4rem;
    font-size: 1rem;
    padding-left: 20px;
}
</style>
