import { getActiveElement, hasField } from './helpers';
import {
    actionFieldRelations,
    actions,
    areas, disabledActions,
    fontFamilies,
    formats,
    linkTargets,
    positions,
    shapes, startActionDisabledFields,
    startActions,
    typeFieldRelations
} from './options';

const interactiveGetters = {
    videoId: state => state.video.id,
    elements: state => state.elements,
    activeElement: state => getActiveElement(state),
    isActiveElement: (state, getters) => (element) => element._id === getters.activeElement?._id,
    isLoading: state => state.loading,
    options: () => {
        return {
            startActions,
            actions,
            areas,
            shapes,
            positions,
            formats,
            fontFamilies,
            linkTargets,
            typeFieldRelations,
            actionFieldRelations,
            startActionDisabledFields,
        };
    },
    elementFormatName: () => value => formats.find(element => element.value === value)?.name,
    elementCanHaveField: () => (element, field) => hasField(typeFieldRelations, element.area.type, field)
        && !hasField(startActionDisabledFields, element.startAction, field),
    elementCanHaveActionField: () => (element, field) => hasField(actionFieldRelations, element.action.type, field),
    elementCanHaveAction: () => (element, action) => !hasField(disabledActions, element.area.type, action),
    elementCanHaveAnyFormattingField: () => (element) => {
        return hasField(typeFieldRelations, element.area.type, 'border') ||
            hasField(typeFieldRelations, element.area.type, 'border-hover') ||
            hasField(typeFieldRelations, element.area.type, 'background-color') ||
            hasField(typeFieldRelations, element.area.type, 'background-color-hover') ||
            hasField(typeFieldRelations, element.area.type, 'text-content') ||
            hasField(typeFieldRelations, element.area.type, 'font') ||
            hasField(typeFieldRelations, element.area.type, 'font-size') ||
            hasField(typeFieldRelations, element.area.type, 'font-color') ||
            hasField(typeFieldRelations, element.area.type, 'background-image') ||
            hasField(typeFieldRelations, element.area.type, 'background-image-hover');
    },
    defaultBackgroundUrl: () => element => element.style.default.background.url,
    hoverBackgroundUrl: () => element => element.style.hover.background.url,
    canUndo: (state) => state.undoStack.hasItems(),
    canRedo: (state) => state.redoStack.hasItems(),
    getComponent: () => element => 'interactive-' + element.area.type,
    isVisualElement: () => element => element.area.type !== 'playeraction',
};

export default interactiveGetters;