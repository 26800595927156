import vuetify from './plugins/vuetify';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VeeValidate from 'vee-validate';
import './_scss/main.scss';
import Toasted from 'vue-toasted';
import VueSafeHTML from 'vue-safe-html';

Vue.config.productionTip = true;

Vue.use(VueSafeHTML);
Vue.use(VeeValidate);
Vue.use(Toasted, {
  position: 'bottom-center',
  keepOnHover: true,
  duration: 5000,
  className: 'v-alert v-sheet',
  closeOnSwipe: true,
});

import { Plugin } from 'vue-fragment';
Vue.use(Plugin);

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fetchNewVersion } from './services/versioning';
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

setInterval(fetchNewVersion, 120000);

new Vue({
  render: h => h(App),
  router,
  vuetify,
  Toasted
}).$mount('#app');
