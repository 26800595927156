import InteractiveElement from '../InteractiveElement';
import {v4 as uuidv4} from 'uuid';

class PlayeractionFactory {
    make({name, start}) {
        return new InteractiveElement({
            name,
            _id: uuidv4(),
            start,
            end: start,
            startAction: 'pause',
            action: {
                type: 'pause',
            },
            area: {
                type: 'playeraction',
            },
        });
    }
}

export default new PlayeractionFactory();