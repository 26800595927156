<template>
    <v-autocomplete
        ref="librarySelector"
        v-model="library"
        class="drop-selector"
        solo
        outlined
        dense
        flat
        label="Library"
        :items="libraries"
        item-text="name"
        item-value="id"
        return-object
        clearable
        :menu-props="elementMenuProps"
    >
        <template v-slot:selection="data">
            <v-sheet
                class="selection-content"
                color="transparent"
            >
                <span class="library-sheet-content">
                    <span class="library-company-name">{{ getLibraryCompanyName(data.item) }}</span>
                    <span class="library-name">{{ data.item.name }}</span>
                </span>
            </v-sheet>
        </template>
        <template v-slot:item="{item}">
            <v-list-item-content>
                <v-list-item-subtitle>
                    {{ getLibraryCompanyName(item) }}
                </v-list-item-subtitle>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { $http, videoApiUrl } from '../../../services/http';

    export default {
        name: 'FtpActionsLibrarySelect',
        props:{
            value:{
                type: Object
            }
        },
        data(){
            return{
                elementMenuProps: undefined,
                libraries: [],
                library: this.value

            };
        },
        computed:{
            ...mapGetters('libraries', ['getLibraryCompanyName']),
        },
        methods:{
            async getLibraries() {
                const libs = await $http.get(videoApiUrl('api/libraries/for-upload'));
                this.libraries = libs.data;
            },
            setElementMenuProps() {
                if (this.$refs.librarySelector) {
                    this.$refs.librarySelector.blur();
                    const element = this.$refs.librarySelector.$el;
                    const style = getComputedStyle(element);
                    const dropDownWidth = style.width.replace('px', '');
                    this.elementMenuProps = {'max-width': dropDownWidth, contentClass: 'should-wrap'};
                }
            }
        },
        watch: {
            library(value) {
                this.$emit('input', value);
            },
        },
        async mounted() {
            await this.getLibraries();
            this.setElementMenuProps();
        },
    };
</script>