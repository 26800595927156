<template>
    <div>
        <slot
            name="filter-top"
            :clearSelected="clearSelected"
            :listLengthLimit="listLengthLimit"
        />
        <v-list
            dense
            flat
        >
            <v-list-item v-if="items.length === 0">
                <v-list-item-title>No results found</v-list-item-title>
            </v-list-item>
            <v-list-item-group
                v-model="selected"
                multiple
                @change="onSelectionChange"
                @click.native.stop="() => {}"
            >
                <v-list-item
                    v-for="item in items.slice(0,listLengthLimit)"
                    :key="item.id"
                    :value="item.id"
                >
                    <template v-slot:default="{ active, toggle }">
                        <v-list-item-action>
                            <v-checkbox
                                :input-value="active"
                                :true-value="item.id"
                                @click.stop="toggle"
                            />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-subtitle v-if="item.subtitle">
                                {{ item.subtitle }}
                            </v-list-item-subtitle>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: 'ListHeadFilter',
        props: ['items', 'columnId', 'selectCheck'],
        data() {
            return {
                selected: this.selectedElements(),
                listLengthLimit: 5
            };
        },
        methods: {
            onSelectionChange() {
                this.$emit('filterChanged',  {column: this.columnId, items: this.selected.map(item => item)});
            },
            rowClicked(index, event){
                event.stopPropagation();
                this.items[index].selected = !this.items[index].selected;
                this.onSelectionChange();
            },
            clearSelected() {
                this.selected = [];
                this.onSelectionChange();
            },
            selectedElements(){
                var selectedElems = [];
                Object.keys(this.selectCheck).forEach(element => {
                    if (element == this.columnId){
                        selectedElems.push(this.selectCheck[element]);
                    }
                });
                return selectedElems[0];
            }
        },
    };
</script>

<style scoped>
    .v-list-item__action {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 10px !important;
    }

    .v-list-item__title {
        text-align: left;
    }
    .v-list-item__subtitle {
        text-align: left;
    }
    .v-text-field{
        padding: 0 16px !important;
    }
</style>
