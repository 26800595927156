export default {
    methods: {
        mapLibrary() {
            return {
                name: this.library.name,
                meta: '[]',
                company_id: this.library.company,
                footer_text: this.library.footer_text,
                whitelisted_domains: this.library.whitelisted_domains,
            };
        },
    }
};
