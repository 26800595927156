import {v4 as uuidv4} from 'uuid';

class Chapter {
    constructor(data) {
        this._id = data._id || uuidv4();
        this.offset = data.offset || 0;
        this.value = data.value || '';
    }
}

export default Chapter;