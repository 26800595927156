<template>
    <span>{{ duration }}</span>
</template>

<script>
    import TimeHelper from '@/time-helper';

    export default {
        name: 'DurationSpan',
        props: ['video'],
        computed: {
            duration() {
                return TimeHelper.fromMsToHis(this.video.duration);
            },
        },
    };
</script>
