<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>Embed & Share</v-expansion-panel-header>
        <v-expansion-panel-content>
            <visibility-editor
                :video="video"
                @onVisibilityUpdated="visibilityUpdated"
            />
            <player-template-editor
                v-if="enablePlayerTemplates"
                :video="video"
            />
            <v-simple-table dense
                            class="property-sheet"
            >
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td style="vertical-align: middle"
                                class="pb-1"
                            >
                                Embed Code
                            </td>
                            <td class="pt-1 pb-1">
                                <embed-code :video="video" />
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align: middle"
                                class="pt-1 pb-1"
                            >
                                Viewing page
                            </td>
                            <td class="pt-1 pb-1">
                                <url-presenter :url="viewingUrl" />
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align: middle"
                                class="pt-1 pb-1"
                            >
                                <v-badge x-small
                                         color="error"
                                         content="new"
                                >
                                    Comments
                                </v-badge>
                            </td>
                            <td class="pt-1 pb-1">
                                <url-presenter :url="commentsUrl" />
                            </td>
                        </tr>
                        <publish-vimeo
                            v-if="enableSocialMediaIntegration"
                            :key="`publish-vimeo-${videoChangeKey}`"
                            :video="video"
                            :log="publishing.vimeo"
                            :video-company-id="videoCompanyId"
                            @popup-status="onPublishPopupStatus"
                        />
                        <publish-youtube
                            v-if="youTubePublishingEnabled"
                            :key="`publish-youtube-${videoChangeKey}`"
                            :video="video"
                            :log="publishing.youtube"
                            :video-company-id="videoCompanyId"
                            @popup-status="onPublishPopupStatus"
                        />
                    </tbody>
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import EmbedCode from '../../controls/embed-code';
    import PublishVimeo from '../publishing/publish-vimeo';
    import Gate from '../../../services/gate';
    import VisibilityEditor from './editor/VisibilityEditor';
    import store from '../../../store/index';
    import PlayerTemplateEditor from './editor/PlayerTemplateEditor';
    import PublishYoutube from '@/components/controls/publishing/publish-youtube';
    import { getPublishingInfo } from '@/services/publishing';
    import { $http, videoApiUrl } from '../../../services/http';
    import {simpleHash} from '@/services/url-hashing';
    import UrlPresenter from '@/components/controls/video-metadata/url/url-presenter.vue';

    export default {
        components: {
            UrlPresenter,
            PublishYoutube,
            PlayerTemplateEditor,
            PublishVimeo,
            EmbedCode,
            VisibilityEditor,
        },
        name: 'MetadataPublish',
        props: ['videos', 'video'],
        data() {
            return {
                publishingLoading: false,
                publishing: {
                    vimeo: [],
                    youtube: [],
                },
                updateCount: 0,
                publishingPopupOpen: false,
                videoCompanyId: null,
            };
        },
        computed: {
            viewingUrl() {
                return (
                    location.protocol +
                    '//' +
                    location.host +
                    '/view/' +
                    encodeURI(this.video.id)
                );
            },
            libraryId() {
                return this.video ? this.video.library : undefined;
            },
            enableSocialMediaIntegration() {
                return (
                    !this.publishingLoading &&
                    process.env.VUE_APP_ENABLE_SOCIAL_MEDIA_INTEGRATION &&
                    Gate.allowsPublishing(store.getters['auth/user'], this.videoCompanyId)
                );
            },
            youTubePublishingEnabled() {
                return (
                    this.enableSocialMediaIntegration &&
                    process.env.VUE_APP_ENABLE_YOUTUBE_PUBLISHING === 'true'
                );
            },
            enablePlayerTemplates() {
                return Gate.allows(
                    store.getters['auth/user'],
                    'manageVideo',
                    this.libraryId
                );
            },
            videoChangeKey() {
                return this.hashCode(
                    this.video.title +
                        this.video.description +
                        this.video.tags.join('') +
                        this.updateCount
                );
            },
            commentsUrl() {
                return (
                    location.protocol +
                    '//' +
                    location.host +
                    '/comments/' +
                    encodeURI(`${this.video.id}/${simpleHash(this.video.id)}`)
                );
            }
        },
        methods: {
            visibilityUpdated() {
                this.videos[0].visibility.type = this.video.visibility.type;
            },
            getPublishingData(shouldShowLoading) {
                if (this.video) {
                    this.publishingLoading =
                        typeof shouldShowLoading !== 'undefined' ? shouldShowLoading : true;
                    getPublishingInfo(this.video.id).then((result) => {
                        this.publishing.vimeo = result.vimeo;
                        this.publishing.youtube = result.youtube;
                        this.publishingLoading = false;
                        ++this.updateCount;
                    });
                }
            },
            onPublishPopupStatus(status) {
                this.publishingPopupOpen = status;
            },
            hashCode(s) {
                let hash = 0;
                for (let i = 0; i < s.length; i++) {
                    const char = s.charCodeAt(i);
                    hash = (hash << 5) - hash + char;
                    hash = hash & hash; // Convert to 32bit integer
                }
                return hash;
            },

            getVideoCompany() {
                return $http
                    .get(videoApiUrl('api/companies/by-library/' + this.video.library))
                    .then((response) => {
                        this.videoCompanyId = response.data.company_id;
                    });
            },
        },
        mounted() {
            this.$options.readInterval = setInterval(() => {
                if (!this.publishingPopupOpen) {
                    this.getPublishingData(false);
                }
            }, 120000);
            this.videoCompanyId = null;
            this.getVideoCompany();
            this.getPublishingData();
        },
        beforeDestroy() {
            clearInterval(this.$options.readInterval);
        },
    };
</script>

<style scoped>
</style>
