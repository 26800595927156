<template>
    <v-row class="col-md-12 no-gutters pt-0 pb-0 list-container list-loader">
        <v-progress-linear indeterminate />
    </v-row>
</template>

<script>
    export default {
        name: 'LoadingBar',
    };
</script>
