<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn icon
                       color="primary"
                       :disabled="!hasSlides"
                       v-on="on"
                       @click="reorder"
                >
                    <v-icon>mdi-sort-alphabetical-ascending</v-icon>
                </v-btn>
            </template>
            <span>
                Reorders slides based on their name.<br>Time indexes won't be changed, just<br>the slides moved around.
            </span>
        </v-tooltip>
        <confirm-presentation-reorder ref="confirm" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import ConfirmPresentationReorder from '@/components/controls/modals/confirm-presentation-reorder';

    export default {
        name: 'PresentationEditorReorder',
        components: {ConfirmPresentationReorder},
        computed: {
            ...mapGetters('presentation', ['elements']),
            hasSlides() {
                return this.elements.length > 0;
            },
        },
        methods:{
            ...mapActions('presentation', ['reorderSlides']),
            async reorder() {
                const confirmation = await this.$refs.confirm.open().then();
                if (!confirmation) {
                    return;
                }

                await this.reorderSlides();
            }
        }
    };
</script>

<style scoped>

</style>