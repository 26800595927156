<template>
    <div class="presentation-settings">
        <div class="mb-2">
            Default view
        </div>
        <v-btn
            v-for="option in options"
            :key="option"
            class="setting-button px-0 ma-1"
            elevation="0"
            @click="setInternalValue(option)"
        >
            <span :class="getButtonClass(option)" />
        </v-btn>
    </div>
</template>

<script>
    import {
        PRESENTATION_POSITION_SINGLE,
        PRESENTATION_POSITION_SBS,
        PRESENTATION_POSITION_PIP
    } from '@/store/presentation/options';

    export default {
        name: 'PresentationViewSettings',
        props: ['value'],
        data() {
            return {
                internalValue: this.value,
                options: [
                    PRESENTATION_POSITION_PIP,
                    PRESENTATION_POSITION_SBS,
                    PRESENTATION_POSITION_SINGLE
                ]
            };
        },
        methods: {
            getButtonClass(option) {
                const classes = {};
                classes[option] = true;
                if (this.internalValue === option) {
                    classes['active'] = true;
                }
                return classes;
            },
            setInternalValue(option) {
                this.internalValue = option;
                this.$nextTick(() => {
                    this.$emit('input', this.internalValue);
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.presentation-settings {

  .setting-button {
    min-width: 48px;

    span {
      display: block;
      width: 42px;
      height: 30px;
      margin: 2px;
      background-color: rgba(0, 0, 0, 0.3);
      position: relative;

      &.PIP {
        &:before {
          content: "";
          display: block;
          width: 40%;
          height: 40%;
          background-color: #fff;
          right: 3px;
          top: 3px;
          position: absolute;
        }
      }

      &.SBS {
        &:before, &:after {
          content: "";
          display: inline-block;
          width: 15px;
          height: 24px;
          margin: 3px;
          background-color: #fff;
        }

        &:after {
          margin-left: 0;
        }
      }

      &.active {
        background-color: #000;
      }
    }
  }
}
</style>