import InteractiveTextFormat from '../format/InteractiveTextFormat';
import InteractiveOrigin from '../format/primitives/InteractiveOrigin';
import InteractiveDimensions from '../format/primitives/InteractiveDimensions';
import InteractiveFont from '../format/primitives/InteractiveFont';

class TextFormatFactory {
    make(data) {
        return new InteractiveTextFormat({
            origin: new InteractiveOrigin(data.origin),
            dimensions: new InteractiveDimensions(data.dimensions),
            'default': {
                font: new InteractiveFont({
                    family: 'arial, helvetica, sans-serif',
                    size: 24,
                    color: 'rgba(255,255,255,0.8)',
                }),
            },
            hover: {
                font: new InteractiveFont({
                    family: 'arial, helvetica, sans-serif',
                    size: 24,
                    color: 'rgba(255,255,255, 1)',
                }),
            }
        });
    }
}

export default new TextFormatFactory();