<template>
    <div>
        <v-simple-table
            dense
            class="property-sheet file-listing-table"
        >
            <template v-slot:default>
                <tbody>
                    <tr>
                        <td class="quitclaim-title">
                            Quitclaim(s):
                        </td>
                        <td
                            v-if="canEdit"
                            class="text-right"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        icon
                                        color="primary"
                                        x-small
                                        @click="openQuitclaimModal(null)"
                                        v-on="on"
                                    >
                                        <v-icon small>
                                            add
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Upload quitclaim</span>
                            </v-tooltip>
                        </td>
                    </tr>
                    <tr class="ql--item">
                        <td colspan="2">
                            <v-list
                                dense
                                flat
                                class="tiny-actions py-0"
                            >
                                <v-list-item-group>
                                    <v-list-item
                                        v-for="quitclaim in quitclaims"
                                        :key="quitclaim.id"
                                        class="pa-0"
                                    >
                                        <v-list-item-content
                                            class="pa-0"
                                            @click="openQuitclaimModal(quitclaim.id)"
                                        >
                                            {{ quitclaim.name }}
                                        </v-list-item-content>
                                        <v-list-item-action
                                            v-if="canEdit"
                                            class="my-0 mr-n1"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{on}">
                                                    <v-btn
                                                        icon
                                                        x-small
                                                        :disabled="deleteInProgress && quitclaimForDelete.id === quitclaim.id"
                                                        v-on="on"
                                                        @click.prevent="deleteSelected(quitclaim)"
                                                    >
                                                        <v-icon
                                                            small
                                                            color="error"
                                                        >
                                                            delete
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete</span>
                                            </v-tooltip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <delete-quitclaim
            v-if="canEdit"
            v-model="quitclaimDeleteModal"
            :quitclaim="quitclaimForDelete"
            @confirm="confirmDelete"
        />
        <quitclaim-modal
            v-if="quitclaimModal"
            v-model="quitclaimModal"
            :quitclaim="selectectedQuitclaim"
            :read-only="!canEdit"
            @upload="uploadSelectedFile"
            @update="updateQuitclaim"
        />
    </div>
</template>

<script>
    import {$http, videoApiUrl} from '../../services/http';
    import DeleteQuitclaim from './modals/delete-quitclaim';
    import Gate from '../../services/gate';
    import store from '../../store/index';
    import QuitclaimModal from './modals/quitclaim-modal';
    import {toastSuccess} from '../../services/responseErrors';

    export default {
        name: 'QuitclaimFiles',
        props: ['video'],
        components: {
            QuitclaimModal,
            DeleteQuitclaim
        },
        data() {
            return {
                deleteInProgress: false,
                quitclaims: [],
                quitclaimDeleteModal: false,
                quitclaimModal: false,
                quitclaimForDelete: {
                    name: '',
                    id: '',
                },
                isDestroying: false,
                selectedQuitclaimId: null,
                selectectedQuitclaim: {
                    id: null,
                    personName: null,
                    copyrightEndAt: null,
                    selectedFileName: '',
                    url: '',
                }
            };
        },
        computed: {
            canEdit: function () {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            openQuitclaimModal(quitclaimId) {
                if (quitclaimId) {
                    $http.get(videoApiUrl(`api/videos/${this.video.id}/quitclaims/${quitclaimId}`)).then((data) => {
                        this.selectectedQuitclaim.id = data.data.id;
                        this.selectectedQuitclaim.personName = data.data.person_name;
                        this.selectectedQuitclaim.copyrightEndAt = data.data.copyright_end_at;
                        this.selectectedQuitclaim.selectedFileName = data.data.name;
                        this.selectectedQuitclaim.url = data.data.url;
                        this.quitclaimModal = true;
                    });
                } else {
                    this.selectectedQuitclaim = {};
                    this.quitclaimModal = true;
                }
            },
            listFiles() {
                if (this.isDestroying) {
                    return;
                }
                $http.get(videoApiUrl(`api/videos/${this.video.id}/quitclaims`)).then((list) => {
                    this.quitclaims = list.data.quitclaims;
                });
            },
            deleteSelected(quitclaim) {
                this.quitclaimForDelete = quitclaim;
                this.quitclaimDeleteModal = true;
            },
            confirmDelete(quitclaim) {
                this.deleteInProgress = true;
                $http.delete(videoApiUrl(`api/videos/${this.video.id}/quitclaims/${quitclaim.id}`)).then(() => {
                    this.deleteInProgress = false;
                    this.listFiles();
                });
            },
            uploadSelectedFile(formData) {
                $http.post(videoApiUrl(`api/videos/${this.video.id}/quitclaims`), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(() => {
                    this.quitclaimModal = false;
                    this.$emit('quitclaimModified');
                    this.listFiles();
                });
            },
            updateQuitclaim(data) {
                let formData = {
                    person_name: data.personName,
                    copyright_end_at: data.copyrightEndAt
                };
                $http.put(videoApiUrl(`api/videos/${this.video.id}/quitclaims/${data.id}`), formData).then(() => {
                    this.quitclaimModal = false;
                    toastSuccess('Quitclaim updated!');
                    this.$emit('quitclaimModified');
                });
            },
        },
        mounted() {
            setTimeout(this.listFiles, 500);
        },
        beforeDestroy() {
            this.isDestroying = true;
        }
    };
</script>

<style scoped>
    .v-list-item {
        min-height: 1.8rem;
    }

    .v-list-item:hover {
        background-color: rgba(0, 0, 0, .1);
    }

</style>

<style lang="scss">
    .file-listing-table {
        margin-top: 0px !important;

        tr.ql--item {
            td {
                .ql--file-name {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;

                    text-decoration: none;
                    color: gray;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .privacy .quitclaim-title {
        font-weight: 500;
        vertical-align: middle !important;
    }
</style>
