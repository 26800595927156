<template>
    <fragment>
        <v-row v-show="canPaginate">
            <v-col class="col-md-12">
                <v-toolbar
                    flat
                    color="transparent"
                >
                    <v-spacer />
                    <v-btn
                        icon
                        :disabled="!canMoveBackwards"
                        title="First"
                        :height="wh"
                        :width="wh"
                        @click="selectPage(1)"
                    >
                        <v-icon>mdi-arrow-collapse-left</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        :disabled="!canMoveBackwards"
                        title="Previous"
                        :height="wh"
                        :width="wh"
                        @click="selectPage(value.currentPage-1)"
                    >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-btn
                        v-for="i in extent"
                        :key="i"
                        icon
                        :class="{'v-btn--active': i === value.currentPage}"
                        :outlined="i === value.currentPage"
                        :color="i === value.currentPage ? 'primary' : ''"
                        :height="wh"
                        :width="wh"
                        @click="selectPage(i)"
                    >
                        {{ i }}
                    </v-btn>
                    <v-btn
                        icon
                        :disabled="!canMoveForward"
                        title="Next"
                        :height="wh"
                        :width="wh"
                        @click="selectPage(parseInt(value.currentPage)+1)"
                    >
                        <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        class="button"
                        :disabled="!canMoveForward"
                        title="Last"
                        :height="wh"
                        :width="wh"
                        @click="selectPage(totalPages)"
                    >
                        <v-icon>mdi-arrow-collapse-right</v-icon>
                    </v-btn>
                    <v-spacer />
                </v-toolbar>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    export default {
        name: 'XPaginator',
        props: {
            value: {
                type: Object,
                default: () => ({
                    range: 2,
                    currentPage: 1,
                    itemPerPage: 12,
                    total: 0
                })
            }
        },
        data(){
            return {
                wh: 36,
            };
        },
        computed: {
            totalPages() {
                return Math.ceil(this.value.total / this.value.itemPerPage);
            },

            rangeStart() {
                let result = this.value.currentPage - this.value.range;
                return result < 1 ? 1 : result;
            },

            rangeEnd() {
                let result = parseInt(this.value.currentPage) + this.value.range;
                return result > this.totalPages ? this.totalPages : result;
            },

            extent() {
                let result = [];
                for (let i = this.rangeStart; i <= this.rangeEnd ; i++) {
                    result.push(i);
                }

                return result;
            },

            canMoveBackwards() {
                return this.value.currentPage > 1;
            },

            canMoveForward() {
                return this.value.currentPage < this.totalPages;
            },

            canPaginate() {
                return this.totalPages > 1;
            }
        },
        methods: {
            selectPage (page) {
                if (page !== this.value.currentPage ) {
                    this.$emit('input', {...this.value, currentPage: page});
                }
            }
        },
    };
</script>

<style scoped>

</style>