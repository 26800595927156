<template>
    <v-row>
        <v-col>
            <v-expansion-panels accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Default tags
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="large-text">
                        <div v-if="hasTags">
                            <p>These tags will automatically be assigned to all new uploads.</p>
                            <v-chip v-for="tag in value"
                                    :key="tag"
                                    class="mr-2"
                            >
                                {{ tag }}
                            </v-chip>
                        </div>
                        <div v-else
                             class="text-center no-data"
                        >
                            There are no default tags for this library.
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'DefaultTagsListing',
        props: {
            value: {
                type: Array,
                default: () => [],
            }
        },
        computed: {
            hasTags() {
                return this.value.length > 0;
            }
        }
    };
</script>

<style scoped>
    .no-data {
        color: rgba(0, 0, 0, 0.38);
    }

    .large-text {
        font-size: 0.875rem;
    }
</style>