import moment from 'moment-timezone';

export default {
  formatYmdHi(moment) {
    return moment.format('YYYY-MM-DD HH:mm');
  },
  formatYmd(moment) {
    return moment.format('YYYY-MM-DD');
  },
  fromUnixToClientsTz(time) {
    var ClientTimeZone = moment.tz.guess();
    return moment(time * 1000).tz(ClientTimeZone);
  },
  fromMsToHi(time) {
    return moment.utc(time).format('HH:mm');
  },
  fromMsToHis(time) {
    return moment.utc(time).format('HH:mm:ss');
  },
  fromMsToHisU(time) {
    return moment.utc(time).format('HH:mm:ss.S');
  },
  hoursToSeconds(time, format) {
    return moment(time, format).diff(moment('00:00:00', 'HH:mm:ss'));
  },
  secondsToHHmmssS(seconds) {
    return moment.utc(seconds * 1000).format('HH:mm:ss.S');
  }
};
