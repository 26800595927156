import InteractiveOrigin from '../format/primitives/InteractiveOrigin';
import InteractiveDimensions from '../format/primitives/InteractiveDimensions';
import InteractiveImageFormat from '../format/InteractiveImageFormat';

class ImageFormatFactory {
    make(data) {
        return new InteractiveImageFormat({
            origin: new InteractiveOrigin(data.origin),
            dimensions: new InteractiveDimensions(data.dimensions),
            'default': {
                background: {
                    path: null
                }
            },
            hover: {
                background: {
                    path: null
                }
            }
        });
    }
}

export default new ImageFormatFactory();