<template>
    <v-container v-if="!pageLoading"
                 fluid
    >
        <v-row justify-center
               align-center
               no-gutters
        >
            <v-col cols="12"
                   md="12"
            >
                <div class="content">
                    <v-col md="6">
                        <h1 class="ml-4 mr-4 page-header">
                            <span v-if="!library.id">Add</span>
                            <span v-if="library.id">Edit</span>&nbsp;library
                        </h1>
                        <validation-observer ref="obs"
                                             v-slot="{ invalid, validated, validate }"
                        >
                            <v-form class="ml-4 mr-4"
                                    @submit.prevent="submitlibrary"
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ errors }"
                                                             name="LibraryName"
                                                             rules="required"
                                        >
                                            <v-text-field v-model="library.name"
                                                          label="Library name"
                                                          :error-messages="errors"
                                                          prepend-icon="mdi-bank"
                                                          @keyup="validate"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col v-if="showCompany"
                                           cols="12"
                                           class="pt-0 pb-0"
                                    >
                                        <validation-provider v-slot="{ errors }"
                                                             name="Company"
                                                             rules="required"
                                        >
                                            <v-autocomplete v-model="library.company"
                                                            label="Company"
                                                            prepend-icon="mdi-office-building"
                                                            :items="companies"
                                                            :loading="companiesLoading"
                                                            item-text="company_name"
                                                            item-value="id"
                                                            :search-input.sync="search"
                                                            :error-messages="errors"
                                                            @keyup="validate"
                                                            @change="validate"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <domain-whitelist-editor v-model="library.whitelisted_domains"
                                                         @input="validate"
                                />

                                <webhooks-listing :value="library.webhooks" />

                                <default-tags-listing :value="library.defaultTags" />

                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-left">
                                            <v-btn type="submit"
                                                   color="accent"
                                                   class="mr-4"
                                                   :disabled="invalid || !validated"
                                            >
                                                <span v-if="!library.id">Add library</span>
                                                <span v-if="library.id">Save changes</span>&nbsp;
                                            </v-btn>
                                            <router-link :to="{name: 'libraries'}"
                                                         style="text-decoration: none"
                                            >
                                                <v-btn color="error"
                                                       text
                                                >
                                                    Cancel
                                                </v-btn>
                                            </router-link>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </validation-observer>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                    >
                        <library-landing-page-settings :value="library" />
                    </v-col>
                    <v-divider />
                    <v-col v-if="library.id"
                           md="12"
                    >
                        <assigned-library-users :library-id="library.id" />
                    </v-col>
                    <v-col v-if="library.id"
                           md="12"
                           class="player-templates-wrapper"
                    >
                        <h1 class="ml-4 mr-4 page-header">
                            Player templates
                        </h1>
                        <library-player-template-list :library-id="library.id" />
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {$http, getLimitedQuickSearchUri, videoApiUrl} from '@/services/http';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { debounce } from 'lodash';
    import { toastSuccess } from '@/services/responseErrors';
    import { mapState } from 'vuex';
    import DomainWhitelistEditor from '@/components/controls/domain-whitelist-editor.vue';
    import WebhooksListing from '@/components/controls/webhooks-listing';
    import DefaultTagsListing from '@/components/controls/default-tags-listing.vue';
    import AssignedLibraryUsers from '@/components/pages/library/AssignedLibraryUsers.vue';
    import LibraryPlayerTemplateList from '@/components/pages/library/LibraryPlayerTemplateList.vue';
    import LibraryLandingPageSettings from '@/components/pages/library/LibraryLandingPageSettings.vue';
    import LibraryMixin from '@/components/pages/library/LibraryMixin';
    
    export default {
        name: 'AddEditLibrary',
        mixins: [
            LibraryMixin,
        ],
        components: {
            LibraryLandingPageSettings,
            LibraryPlayerTemplateList,
            AssignedLibraryUsers,
            DefaultTagsListing,
            WebhooksListing,
            ValidationProvider,
            ValidationObserver,
            DomainWhitelistEditor
        },
        data() {
            const library = {
                id: '',
                name: '',
                company: '',
                whitelisted_domains: '',
            };

            if (this.$route.params.id) {
                library.id = this.$route.params.id;
            }

            return {
                library,
                companies: [],
                companiesLoading: false,
                pageLoading: false,
                search: '',
            };
        },
        computed: {
            ...mapState('auth', { authUser: 'user' }),
            showCompany() {
                return this.authUser.role !== 'company-admin';
            }
        },
        watch: {
            search: debounce(function(val) {
                if (!this.pageLoading) {
                    this.loadCompanies(val);
                }
            }, 200)
        },
        methods: {
            getlibrary() {
                this.pageLoading = true;
                $http
                    .get(videoApiUrl('api/libraries/' + this.library.id))
                    .then(response => {
                        this.library = {
                            ...this.library,
                            ...response.data,
                            company: response.data.company_id
                        };
                        setTimeout(() => {
                            this.pageLoading = false;
                        }, 250);
                    })
                    .catch(() => {
                        this.$router.push({ name: 'libraries' });
                        this.pageLoading = false;
                    });
            },
            submitlibrary() {
                const method = this.library.id ? $http.put : $http.post;
                const library = this.mapLibrary();

                method(videoApiUrl('api/libraries/' + this.library.id), library).then(() => {
                    toastSuccess(`library ${this.library.id ? 'updated' : 'saved'}`);
                    if (!this.library.id) {
                        this.$router.push('/libraries');
                    }
                });
            },
            loadCompanies(q) {
                return $http
                    .get(videoApiUrl(getLimitedQuickSearchUri('api/companies', 1000, q)+'&sorting[column]=company_name'))
                    .then(data => {
                        this.companies = data.data.data;
                        this.companiesLoading = false;
                    })
                    .catch(() => {
                        this.companiesLoading = false;
                    });
            },
        },
        created() {
            this.search = '';
            if (this.showCompany) {
                this.loadCompanies();
            }
            if (this.library.id) {
                this.getlibrary();
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
