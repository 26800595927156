import get from 'lodash/get';

class InteractiveFont {
    constructor(data) {
        this.family = get(data, 'family', 'arial, helvetica, sans-serif');
        this.size = get(data, 'size', ''); // empty = inherit
        this.color = get(data, 'color', ''); // empty = inherit
    }
}

export default InteractiveFont;