<template>
    <img
        class="listing-image"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        :style="{
            'background-image': `url(${image})`,
        }"
        :alt="item.title"
        :title="item.title"
        @error="loadPlaceholder"
    >
</template>

<script>
    export default {
        name: 'Thumbnail',
        props: ['item'],
        methods: {
            loadPlaceholder(e) {
                e.target.src = '/images/thumbnail-placeholder.jpg';
            },
        },
        computed: {
            image() {
                return this.item.thumbnail
                    ? this.item.thumbnail
                    : '/images/thumbnail-placeholder.jpg';
            },
        },
    };
</script>
