<template>
    <div class="color-editor">
        <color-picker
            ref="colorPicker"
            :value="color"
            @input="colorChanged"
            @validate="colorValidated"
        />
    </div>
</template>
<script>
    import ColorPicker from '../../../primitives/color-picker';

    export default {
        name: 'Coloreditor',
        components: {
            ColorPicker
        },
        props: ['value'],
        data() {
            return {
                color: this.value
            };
        },
        methods: {
            colorChanged(value) {
                this.color = value;
                this.$emit('input', this.color);
            },
            colorValidated(value) {
                this.$emit('validate', value);
            },
            validate() {
                return this.$refs.colorPicker.validate();
            }
        }
    };
</script>
<style scoped>
    .color-editor {
        display: inline-block;
        margin: 0 auto;
    }
</style>