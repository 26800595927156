<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-chip
                color="white"
                large
                label
                class="logged-in-user"
                v-on="on"
            >
                <span class="mr-4">
                    {{ userName }}
                </span>
                <v-avatar
                    color="primary"
                    size="32px"
                    item
                >
                    <v-img
                        v-if="picture"
                        :src="picture"
                        :alt="userName"
                    />
                    <span
                        v-if="!picture"
                        class="white--text initials"
                    >{{ initials }}</span>
                </v-avatar>
            </v-chip>
        </template>
        <v-list>
            <v-list-item @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import {mapState} from 'vuex';
    import {$auth} from '@/services/auth';
    export default {
        name: 'UserMenu',
        computed: {
            ...mapState('auth', {
                userName: state => state.user.name,
                picture: state => state.user.picture
            }),
            initials() {
                const userNameArray = this.userName.split(' ');
                let result = '';
                if (userNameArray.length > 1) {
                    result = userNameArray[0].substring(0,1) + userNameArray[1].substring(0,1);
                } else {
                    result = userNameArray[0].substring(0,1);
                }
                return result.toUpperCase();
            }
        },
        methods: {
            logout() {
                $auth.logout();
            }
        }
    };
</script>

<style scoped>
    .initials {
        font-size: .8rem;
    }
    .v-chip.logged-in-user {
        margin-right: 8px;
    }
</style>
