<template>
    <div>
        <div class="usage-stats-listing-container">
            <v-row
                class="pt-0 pb-0"
                style="margin: 0;"
            >
                <div
                    class="col-md-12 no-gutters pt-0 pb-0 sticky-table-header"
                    :class="{'pl-0': !!companyId}"
                >
                    <div class="d-flex usage-stats-listing-header listing-header-container">
                        <div
                            v-if="!companyId"
                            class="flex-grow-1 header-name-column pa-0 pt-2 pb-2"
                        >
                            <v-btn
                                text
                                block
                                class="header-button"
                            >
                                Company Name
                            </v-btn>
                        </div>

                        <div class="flex-grow-1 header-name-column pa-0 pt-2 pb-2">
                            <v-btn
                                text
                                block
                                class="header-button"
                            >
                                Users
                            </v-btn>
                        </div>

                        <div class="flex-grow-1 header-name-column pa-0 pt-2 pb-2">
                            <v-btn
                                text
                                block
                                class="header-button"
                            >
                                Libraries
                            </v-btn>
                        </div>

                        <div class="flex-grow-1 header-name-column pa-0 pt-2 pb-2">
                            <v-btn
                                text
                                block
                                class="header-button"
                            >
                                Videos
                            </v-btn>
                        </div>

                        <div class="flex-grow-1 header-name-column pa-0 pt-2 pb-2">
                            <v-btn
                                text
                                block
                                class="header-button"
                            >
                                Used storage
                            </v-btn>
                        </div>
                    </div>
                </div>

                <v-col
                    v-for="(stat, index) in Object.values(stats)"
                    :key="'usage-stats-listing-' + index"
                    cols="12"
                    class="usage-stats-listing-item pb-0 pt-0"
                    :class="{'pl-0': !!companyId}"
                >
                    <v-card class="stat-item">
                        <div
                            v-if="!companyId"
                            class="flex-grow-1 header-name-column pt-2 pb-2 pl-4 pr-4"
                        >
                            {{ stat.company_name }}
                        </div>
                        <div class="flex-grow-1 header-name-column pt-2 pb-2 pl-4 pr-4">
                            {{ stat.nr_of_users }}
                        </div>
                        <div class="flex-grow-1 header-name-column pt-2 pb-2 pl-4 pr-4">
                            {{ stat.nr_of_libraries }}
                        </div>
                        <div class="flex-grow-1 header-name-column pt-2 pb-2 pl-4 pr-4">
                            {{ stat.nr_of_videos }}
                        </div>
                        <div class="flex-grow-1 header-name-column pt-2 pb-2 pl-4 pr-4">
                            {{ stat.used_storage }}
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {$http, videoApiUrl} from '../../services/http';

    export default {
        name: 'CompaniesUsageStats',
        props: {
            companyId:{
                default: ''
            }
        },
        components: {},
        data() {
            return {
                stats: {},
                callsInProgress: 0
            };
        },
        methods: {
            async getStats() {
                this.loading = true;
                let response = await $http.get(videoApiUrl('api/usage-stats/' + this.companyId));
                if (!Array.isArray(response.data)) {
                    await this._processCompanyInfo(response.data);
                    return;
                }
                await Promise.all(response.data.map(async (item) => {
                    await this._processCompanyInfo(item);
                }));
            },

            async _processCompanyInfo(companyInfo){
                const companyId = companyInfo.company_id ? companyInfo.company_id : this.companyId;
                this.stats = {
                    ...this.stats,
                    [companyId]: {
                        'company_id': companyId,
                        'company_name': companyInfo.company_name ? companyInfo.company_name : '',
                        'nr_of_users': companyInfo.nr_of_users,
                        'nr_of_libraries': companyInfo.nr_of_libraries,
                        'nr_of_videos': 0,
                        'used_storage': 0,
                    }
                };
                if (companyInfo.libraries.length <= 0) {
                    return;
                }
                let response = await $http.get(videoApiUrl('api/stats/libraries'), {params: {libraries: companyInfo.libraries}});
                this.stats = {
                    ...this.stats,
                    [companyId]: {
                        ...this.stats[companyId],
                        nr_of_videos: response.data.nr_of_videos,
                        used_storage: response.data.size_of_videos,
                    },
                };
            }
        },
        async mounted() {
            await this.getStats();
            this.$emit('loaded', Object.values(this.stats));
        }
    };
</script>

<style scoped lang="scss">
    .listing-header-container{
        button{
            pointer-events: none;
        }
    }
</style>
