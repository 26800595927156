<template>
    <tr class="editable">
        <td class="middle-">
            Title:
        </td>
        <td>
            <div v-if="multipleVideos">
                <div
                    v-for="videoItem in videos"
                    :key="'video-title-meta-' + videoItem.id"
                >
                    {{ videoItem.title }}
                </div>
            </div>
            <div
                v-else
                class="editable-meta video-title"
            >
                <span
                    v-if="video"
                    v-safe-html="video.title ? brokenTitle : 'Title'"
                    class="editable-content"
                    @dblclick="openEditor()"
                />
                <metadata-field-editor
                    v-if="!multipleVideos && video && canEdit"
                    :key="'title-' + video.id"
                    ref="editableTitle"
                    v-model="video.title"
                    name="title"
                    type="inline"
                    :meta="{counter: 200, pattern: /^([a-zA-Z0-9 \-_\.])+$/gi, minLength: 2}"
                    :required="true"
                    @input="videoTitleUpdated"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import updateMeta from '../../../../services/metadata';
    import MetadataFieldEditor from '../editor/MetadataFieldEditor';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';

    export default {
        name: 'MetaTitleField',
        components: {MetadataFieldEditor},
        props: ['videos', 'multipleVideos', 'video'],
        computed: {
            brokenTitle() {
                return this.video.title ? this.video.title.replace(/_/g, '_<span></span>') : '';
            },
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            videoTitleUpdated(value) {
                updateMeta(this.video.id, {
                    'name': value
                }).then(() => {
                    this.$emit('input', value);
                });
            },
            openEditor() {
                if(this.canEdit) {
                    this.$refs.editableTitle.openEditor();
                }
            }
        },
    };
</script>

<style scoped>
.video-title .editable-content {
    padding-top: 20px
}
</style>
