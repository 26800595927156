<template>
    <confirm-modal
        v-model="isOpen"
        title="Would you like to cancel this upload?"
        confirm-button="Yes, cancel it"
        cancel-button="No, I've changed my mind"
        @confirm="confirm"
        @cancel="cancel"
    >
        <div>This will cancel the following upload:</div>
        <v-list>
            <v-list-item>
                <v-list-item-title>
                    {{ uploadItemName }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
        <p>You'll lose any progress on it. Are you sure?</p>
    </confirm-modal>
</template>

<script>
    import ConfirmModal from '@/components/controls/modals/confirm-modal';

    export default {
        name: 'ConfirmCancelUpload',
        components: {ConfirmModal},
        props: {
            uploadItem: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                isOpen: false,
                resolve: null,
            };
        },
        computed: {
            uploadItemName() {
                return this.uploadItem?.name || '';
            }
        },
        methods: {
            open() {
                this.isOpen = true;
                return new Promise(resolve => {
                    this.resolve = resolve;
                });
            },
            confirm() {
                this.resolve(true);
                this.isOpen = false;
            },
            cancel() {
                this.resolve(false);
                this.isOpen = false;
            }
        }
    };
</script>

<style scoped>

</style>