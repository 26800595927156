<template>
    <div class="comment-form px-3 pt-4 mx-n3 no-error-color">
        <validation-observer ref="validator"
                             v-slot="{ invalid, validated, validate }"
        >
            <v-form ref="commentForm"
                    @submit.prevent="send"
            >
                <validation-provider v-if="!isLoggedIn"
                                     v-slot="{errors}"
                                     name="Name"
                                     rules="required|min:2"
                >
                    <v-text-field v-model="commenterUserName"
                                  label="Name"
                                  dense
                                  outlined
                                  :error-messages="errors"
                                  background-color="#fff"
                                  :disabled="formLoading"
                                  @keyup="validate"
                    />
                </validation-provider>
                <validation-provider v-if="!isLoggedIn"
                                     v-slot="{errors}"
                                     name="E-mail"
                                     rules="required|email"
                >
                    <v-text-field v-model="commenterUserEmail"
                                  type="email"
                                  label="E-mail address"
                                  dense
                                  outlined
                                  :error-messages="errors"
                                  background-color="#fff"
                                  :disabled="formLoading"
                                  @keyup="validate"
                    />
                </validation-provider>
                <div class="grey--text tiny--text mb-2 mt-n1">
                    <span class="warning--text">
                        <v-icon color="warning"
                                x-small
                        >
                            warning
                        </v-icon>
                        Please don't input sensitive information in this form.
                        Chat messages are visible to the public.
                    </span>
                    Shift+Enter adds line breaks. Enter: sends message.
                </div>
                <validation-provider v-slot="{errors}"
                                     name="Message"
                                     rules="required"
                >
                    <v-textarea v-model="message"
                                :disabled="formLoading"
                                :error-messages="errors"
                                class="mb-2"
                                auto-grow
                                background-color="#fff"
                                clearable
                                dense
                                label="Message"
                                no-resize
                                outlined
                                rows="2"
                                hide-details
                                @keyup="validate"
                                @keydown="processKeys"
                    >
                        <template v-slot:append-outer>
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn type="submit"
                                           icon
                                           color="primary"
                                           :disabled="formLoading || !validated || invalid"
                                           v-on="on"
                                    >
                                        <v-icon>send</v-icon>
                                    </v-btn>
                                </template>
                                <span>Send</span>
                            </v-tooltip>
                        </template>
                    </v-textarea>
                </validation-provider>
            </v-form>
        </validation-observer>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import {$http, videoApiUrl} from '@/services/http';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';

    export default {
        name: 'CommentForm',
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        data() {
            return {
                commenterUserName: '',
                commenterUserEmail: '',
                message: '',
                formLoading: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLoggedIn']),
            ...mapState('auth', ['user']),
            ...mapState('comments', ['video']),
            ...mapGetters('comments', ['activeElement']),
        },
        methods: {
            ...mapActions('comments', ['sendComment']),
            async send() {
                console.log('sending');
                if (!this.isLoggedIn) {
                    window.localStorage.setItem('CommenterUserName', this.commenterUserName);
                    window.localStorage.setItem('CommenterUserEmail', this.commenterUserEmail);
                }
                await this.sendComment({
                    videoId: this.video.id,
                    comment: {
                        id: 111,
                        message: this.message,
                        name: this.commenterUserName,
                        email: this.commenterUserEmail,
                        timecode: this.activeElement,
                        video_id: this.video.id,
                    }
                });
                this.message = '';
            },
            async processKeys(e) {
                if (e.shiftKey === false && e.keyCode === 13) {
                    e.preventDefault();
                    if (await this.$refs.validator.validate()) {
                        await this.send();
                    }
                    return false;
                }
            }
        },
        async mounted() {
            try {
                this.formLoading = true;
                if (this.isLoggedIn) {
                    const profileData = await $http.get(videoApiUrl('api/profile'))
                        .then(response => response.data);
                    this.commenterUserName = profileData.name;
                    this.commenterUserEmail = profileData.email;
                } else {
                    this.commenterUserName = window.localStorage.getItem('CommenterUserName') ?? '';
                    this.commenterUserEmail = window.localStorage.getItem('CommenterUserEmail') ?? '';
                }
            } finally {
                this.formLoading = false;
            }
        }
    };
</script>

<style scoped lang="scss">
    .comment-form {
        border-bottom: 1px solid #ccc;
        background-color: rgba(0,0,0,0.05);
    }

    .v-input, .v-textarea {
      input, textarea {
            padding: 0;
      }
      font-size: 14px;
    }

</style>

<style lang="scss">

.v-application {
  .no-error-color {
    .error--text {
      color: #313131 !important;
      caret-color: #313131 !important;
    }
  }

  .comment-form {
    .v-input__slot {
      min-height: 25px !important;
    }

    .v-text-field__slot {
      .v-label {
        font-size: 14px !important;
        top: 6px;
      }
    }

    .tiny--text {
      font-size: 12px;
    }
  }
}

</style>