<template>
    <div :key="updateCount">
        <v-btn
            x-small
            color="primary"
            class="mb-2"
            @click.stop="triggerUpload"
        >
            Browse
        </v-btn>
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <v-btn
                    v-if="canDelete"
                    x-small
                    icon
                    color="error"
                    class="ml-4 mb-2"
                    v-on="on"
                    @click="confirmDelete"
                >
                    <v-icon>delete</v-icon>
                </v-btn>
            </template>
            <span>Remove</span>
        </v-tooltip>
        <input
            ref="fileSelector"
            type="file"
            class="image-picker"
            accept="image/jpeg, image/png, .jpg, .png"
            @input="fileSelected"
        >
        <confirm-modal
            v-model="deleteModal"
            title="Remove image?"
            confirm-button="Remove"
            cancel-button="Cancel"
            @confirm="fileCleared"
        >
            Would you like to remove this image?
        </confirm-modal>
    </div>
</template>

<script>
    import ConfirmModal from '../../modals/confirm-modal';

    export default {
        name: 'Imagepicker',
        components: {ConfirmModal},
        props: ['value'],
        data() {
            return {
                updateCount: 0,
                deleteModal: false,
            };
        },
        computed: {
            canDelete() {
                if (!this.value && !this.selectedFile) {
                    return false;
                }
                return true;
            }
        },
        methods: {
            fileSelected(e) {
                const input = e.target;
                if (input.files && input.files[0]) {
                    this.$emit('input', input.files[0]);
                    this.$nextTick(() => {
                        ++this.updateCount;
                    });
                }
            },
            confirmDelete() {
                this.deleteModal = true;
            },
            fileCleared() {
                this.$emit('input', null);
                ++this.updateCount;
            },
            triggerUpload() {
                this.$refs.fileSelector.click();
            }
        }

    };
</script>

<style scoped>
    .image-picker {
        visibility: hidden;
    }
</style>