<template>
    <list-head-filter-popup
        :button-class="{
            'is-filtered': isFilteredBy
        }"
        :flat="flat"
    >
        <list-head-filter
            :column-id="columnId"
            :items="filterItems"
            :select-check="value"
            @filterChanged="filterChanged"
        >
            <template v-slot:filter-top="clearSelected">
                <slot
                    name="filter-top"
                    :clearSelected="clearSelected"
                />
            </template>
        </list-head-filter>
    </list-head-filter-popup>
</template>

<script>
    import ListHeadFilterPopup from '@/components/primitives/list-head-filter-popup';
    import ListHeadFilter from '@/components/primitives/list-head-filter';
    export default {
        name: 'PrivacyFilter',
        components: {ListHeadFilter, ListHeadFilterPopup},
        props: {
            value: {
                type: Object,
                default: () => {
                }
            },
            columnId: {
                type: String,
                default: ''
            },
            flat: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                filterItems: [
                    {id: 'private', name: 'Private'},
                    {id: 'public', name: 'Public'},
                    {id: 'scheduled', name: 'Scheduled'},
                ],
            };
        },
        computed: {
            isFilteredBy() {
                return this.value[this.columnId] && this.value[this.columnId].length;
            }
        },
        methods: {
            filterChanged(items) {
                this.$emit('input', {...this.value, [items.column]: items.items});
            },
        }
    };
</script>

<style scoped>

</style>