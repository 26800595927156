import {$http, videoApiUrl} from '@/services/http';

const tags = {
    namespaced: true,
    state() {
        return {
            loading: false,
            uniqueTags: [],
        };
    },
    mutations: {
        setLoading: (state, payload) => state.loading = payload,
        setUniqueTags: (state, payload) => state.uniqueTags = payload,
    },
    getters: {
        tags: state => state.uniqueTags,
    },
    actions: {
        getTags({commit}, videoId) {
            commit('setUniqueTags', []);
            commit('setLoading', true);
            return $http.get(videoApiUrl(`api/videos/${videoId}/company-tags`)).then(r => {
                const {data} = r;
                if (data.length < 1) {
                    commit('setUniqueTags', []);
                    return;
                }
                commit('setUniqueTags', r.data[0].uniqueTags);
            }).catch(() => {
                commit('setUniqueTags', []);
            }).finally(() => {
                commit('setLoading', false);
            });
        }
    }
};

export default tags;