<template>
    <div
        class="list-control-item"
        @click.stop="onSelectionChangeList"
    >
        <v-card
            class="elevation-0"
            :class="{' ma-1': gridMode, 'grid-mode': gridMode, 'list-mode': !gridMode, 'd-flex': !gridMode}"
        >
            <div
                v-if="!gridMode"
                class="toggle-column flex-grow-0 pa-2"
            >
                <v-checkbox
                    :input-value="selected"
                    @click.stop="onSelectionChange"
                />
            </div>
            <div :class="{'image-column':!gridMode, 'pa-2':!gridMode}">
                <img
                    class="listing-image"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    :style="{'background-image': 'url(' + (item.thumbnail ? item.thumbnail : '/images/thumbnail-placeholder.jpg') + ')'}"
                    :alt="item.title"
                    :title="item.title"
                    @error="loadPlaceholder"
                >
            </div>
            <v-card-title v-if="gridMode">
                <v-checkbox
                    :input-value="selected"
                    @click.stop="onSelectionChange"
                />
                <span>{{ item.title ? item.title : 'Title' }}</span>
            </v-card-title>
            <v-divider v-if="gridMode" />
            <v-card-text
                v-if="gridMode"
                class="d-flex"
            >
                <div>
                    <strong>Privacy: </strong> {{ item.privacy ? item.privacy : 'N/A' }}
                </div>
                <div class="text-right">
                    <strong>Added: </strong>{{ item.publish_date ? simpleDate : 'N/A' }}
                </div>
            </v-card-text>
            <v-divider v-if="gridMode" />
            <v-card-actions v-if="gridMode">
                <v-btn
                    small
                    color="accent"
                    elevation="0"
                    outlined
                    @click.stop="details"
                >
                    <v-icon
                        outlined
                        class="mr-2"
                    >
                        mdi-eye
                    </v-icon>
                    <span>Details</span>
                </v-btn>
            </v-card-actions>
            <div
                v-if="!gridMode"
                class="flex-grow-1 title-column pt-2 pb-2 pl-4 pr-4"
            >
                <span>{{ item.title ? item.title : 'Title' }}</span>
            </div>
            <div
                v-if="!gridMode"
                class="flex-grow-1 short-text-column pt-2 pb-2 pl-4 pr-4"
            >
                {{ item.visibility.type ? visibilityTypeText : 'Privacy' }}
            </div>
            <div
                v-if="!gridMode"
                class="d-flex flex-column flex-grow-1 short-text-column publish-date-column justify-center pt-2 pb-2 pl-4 pr-4"
            >
                <span>{{ item.publish_date ? simpleDate : 'Publish date' }}</span>
                <span style="text-overflow: ellipsis; overflow:hidden">{{ item.uploader || 'N/A' }}</span>
            </div>
            <div
                v-if="!gridMode"
                class="flex-grow-1 short-text-column d-flex pt-2 pb-2 pl-4 pr-2"
            >
                <div class="flex-grow-1 flex-shrink-1 with-hover-text">
                    {{ libraryName }}
                </div>
                <div class="spacer" />
                <div class="flex-grow-0 flex-shrink-0 hover-controls">
                    <div class="readonly">
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn
                                    icon
                                    :href="viewingUrl"
                                    class="mr-1"
                                    target="_blank"
                                    @click.stop=""
                                    v-on="on"
                                >
                                    <v-icon>open_in_new</v-icon>
                                </v-btn>
                            </template>
                            <span>Viewing page</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn
                                    icon
                                    class="mr-1"
                                    @click.stop="download"
                                    v-on="on"
                                >
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Download</span>
                        </v-tooltip>
                    </div>
                    <div
                        v-if="canEdit"
                        class="editable"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn
                                    icon
                                    class="mr-2"
                                    @click.stop="deleteItem"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    import TimeHelper from '../../time-helper';
    import VisibilityHelper from '../../visibility-helper';
    import {mapState, mapGetters} from 'vuex';
    import {toast} from '../../services/responseErrors';
    import Gate from '../../services/gate';
    import downloadVideo from '../../services/download';

    export default {
        name: 'ListControlItem',
        props: ['item', 'selected'],
        computed: {
            ...mapState('layout', ['gridMode']),
            ...mapGetters('auth', ['user']),
            ...mapState('libraries', ['libraries']),
            simpleDate() {
                return TimeHelper.formatYmd(TimeHelper.fromUnixToClientsTz(this.item.publish_date));
            },
            visibilityTypeText() {
                return VisibilityHelper.getOption(this.item.visibility.type);
            },
            canEdit() {
                return Gate.allows(this.user, 'manageVideo', this.item.library);
            },
            viewingUrl() {
                return location.protocol + '//' + location.host + '/view/' + encodeURI(this.item.id);
            },
            libraryName() {
                if (!this.item) {
                    return '';
                }

                let oldCompany = null;
                let hasMoreCompanies = false;

                this.libraries.forEach(library => {
                    if (!library.company) {
                        return false;
                    }

                    if (oldCompany !== library.company.company_name) {
                        if (oldCompany != null) {
                            hasMoreCompanies = true;
                        }
                        oldCompany = library.company.company_name;
                    }
                });

                const library = this.libraries.find(library => library.id === this.item.library);

                if (library) {
                    return (hasMoreCompanies ? library.company.company_name + ' / ' : '') + library.name;
                }

                return 'n/a';
            },
        },
        methods: {
            onSelectionChange() {
                this.$emit('selection-change', {
                    item: this.item,
                    selected: !this.selected
                });
            },
            onSelectionChangeList() {
                this.$emit('selection-change-list', {
                    item: this.item,
                    selected: !this.selected
                });
            },
            details() {
                toast('Details button clicked');
            },
            loadPlaceholder(e) {
                e.target.src = '/images/thumbnail-placeholder.jpg';
            },
            download() {
                downloadVideo(this.item);
            },
            deleteItem() {
                this.$emit('delete-request', this.item);
            }
        },
    };
</script>

<style scoped>
    .list-control-item .v-card__title {
        display: flex;
        align-items: flex-start;
    }

    .list-control-item .v-card__title > div {
        flex: 0 0 3rem;
    }

    .list-control-item .v-card__title > span {
        flex: 1 1 0%;
    }

    .hover-controls {
        display: none;
        border-radius: 5px;
    }

    .spacer {
        flex-grow: 0 !important;
        flex-shrink: 1;
        flex-basis: 1rem;
        display: none;
    }

    .list-control-item:hover .hover-controls {
        display: block;
    }

    .list-control-item:hover .spacer {
        display: block;
    }

    .list-control-item.selected .hover-controls {
        background-color: #ededed;
        transition: all .3s ease-in-out;
    }

    .editable, .readonly {
        display: inline-block;
    }

    .hover-controls .v-btn:hover .v-icon {
        color: black;
    }

    .publish-date-column{
        line-height: 1.1rem !important;
    }

    .with-hover-text {
        flex-shrink: 2;
        flex-basis: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
