<template>
    <div
        class="element-content"
        :style="elementStyle"
        @mouseover="onMouseOver"
        @mouseout="onMouseOut"
    >
        <div
            :key="element.area.text"
            class="element-text"
            :style="elementStyle"
            contenteditable="true"
            @keydown="onKeyDown"
            @blur="saveText"
        >
            {{ element.area.text }}
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'InteractiveText',
        data() {
            return {
                mouseOver: false,
            };
        },
        props: ['element'],
        computed: {
            ...mapGetters('interactive', ['activeElement']),
            elementStyle() {
                if (!this.element) {
                    return;
                }

                const styleState = this.mouseOver ? this.element.style.hover : this.element.style.default;

                const style = {
                    'font-family': (styleState.font.family || 'arial, helvetica, sans-serif') + '!important',
                    'font-size': (styleState.font.size || '8') + 'px',
                    color: styleState.font.color,
                    position: 'relative',
                };

                return style;
            },
        },
        methods: {
            onMouseOver() {
                this.mouseOver = true;
            },
            onMouseOut() {
                this.mouseOver = false;
            },
            onKeyDown(e) {
                if (e.ctrlKey || e.metaKey) {
                    if ([66, 98, 73, 105, 85, 117].indexOf(e.keyCode) > -1) {
                        e.preventDefault();
                        return false;
                    }
                }

                if (e.keyCode === 13) {
                    e.preventDefault();
                    e.currentTarget.blur();
                    return false;
                }

                if (e.keyCode === 27) {
                    e.preventDefault();
                    e.currentTarget.innerHTML = this.element.area.text;
                    return false;
                }

                const width = parseFloat(window.getComputedStyle(e.target.parentNode.parentNode).width);
                const height = parseFloat(window.getComputedStyle(e.target).height);
                this.$emit('shouldResize', {width, height});

                return true;
            },
            saveText(e) {
                this.$store.dispatch('interactive/executeSingleCommit',
                                     {
                                         name: 'setAreaText',
                                         payload: e.currentTarget.innerText
                                     });
            }
        }
    };
</script>

<style scoped>

</style>