const main = {
    namespaced: true,
    state: {
        searchContent: '',
        pagination: {
            itemPerPage: 12,
            currentPage: 1,
            total: 0,
            range: 2
        },
        headerState: {
            sorting: {
                targetId: 'publish_date',
                direction: 'desc'
            },
            filters: {}
        }
    },
    mutations: {
        setSearch(state, value) {
            state.searchContent = value;
        },
        setHeaderState(state, value) {
            state.headerState = { ... value };
        },
        setPagination(state, value)
        {
            for (let key in value) {
                state.pagination[key] = value[key] ;
            }
        }
    }
};

export default main;
