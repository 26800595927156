<template>
    <div class="background-upload">
        <div v-if="!internalLoading">
            <div v-if="!hasSelectedImage">
                <img v-if="hasOriginal"
                     :src="originalValue"
                     class="selected-image mb-4"
                >
                <div v-else
                     class="mb-4"
                >
                    Please select a background image
                </div>
            </div>
            <div v-else
                 class="mb-4"
            >
                <img :src="selectedImageDisplay"
                     class="selected-image mb-4"
                ><br>
                Please click Save changes to upload the background image
            </div>
        </div>
        <div v-else>
            <v-progress-circular
                class="mb-4"
                indeterminate
                size="36"
                color="primary"
            />
        </div>
        <input v-if="uploadReady"
               ref="fileSelector"
               type="file"
               accept=".jpg, .jpeg, .png, image/jpeg, image/png"
               label="Select background image"
               class="file-input"
               @input="onSelectFile"
        >
        <v-btn class="mr-4"
               color="primary"
               small
               outlined
               @click="onSelectFileClick"
        >
            <span v-if="hasOriginal">Replace background image</span>
            <span v-else>Select background image</span>
        </v-btn>
        <v-btn v-if="hasSelectedImage"
               class="mr-4"
               small
               outlined
               @click="resetImage"
        >
            Reset background image
        </v-btn>
        <v-btn v-if="hasOriginal"
               color="secondary"
               small
               outlined
               @click="removeImage"
        >
            Remove background image
        </v-btn>
        <confirm-modal
            v-model="confirmRemove"
            title="Remove viewing page background image?"
            confirm-button="Yes, remove it"
            cancel-button="Cancel"
            @confirm="onConfirmRemove"
        >
            This will remove the viewing page background image for this library. Would you like to do this?
        </confirm-modal>
    </div>
</template>
<script>
    import { deleteViewingPageBackground } from '@/services/library';
    import ConfirmModal from '@/components/controls/modals/confirm-modal.vue';

    export default {
        name: 'BackgroundUpload',
        components: {ConfirmModal},
        props: {
            value: {
                type: String,
                default: () => null
            },
            libraryId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                originalValue: this.value,
                internalValue: null,
                internalLoading: this.loading,
                uploadReady: true,
                selectedImageDisplay: null,
                confirmRemove: false,
            };
        },
        computed: {
            hasOriginal() {
                return !!this.originalValue
                    && typeof this.originalValue === 'string';
            },
            hasSelectedImage() {
                return !!this.internalValue;
            },
        },
        methods: {
            onSelectFile() {
                const input = this.$refs.fileSelector;
                const files = input.files;
                if (files && files[0]) {
                    const reader = new FileReader();
                    reader.onload = e => this.selectedImageDisplay = e.target.result;
                    reader.readAsDataURL(files[0]);
                    this.internalValue = files[0];
                    this.$emit('input', this.internalValue);
                }
            },
            onSelectFileClick() {
                this.$refs.fileSelector.click();
            },
            resetImage() {
                this.uploadReady = false;
                this.selectedImageDisplay = null;
                this.internalValue = null;
                this.uploadReady = true;
                this.$emit('input', this.originalValue);
            },
            removeImage() {
                this.confirmRemove = true;
            },
            async onConfirmRemove() {
                this.internalLoading = true;
                this.originalValue = null;
                this.resetImage();
                await deleteViewingPageBackground(this.libraryId);
                this.internalLoading = false;
                this.$emit('input', null);
            }
        }
    };
</script>

<style scoped>
input[type="file"] {
    display: none;
}

.selected-image {
    max-width: 200px;
    max-height: 200px;
}
</style>