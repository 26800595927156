import { $http, getStatQueryRequest } from '@/services/http';

class StatisticsRepository {
    constructor(videos, dateRange) {
        this.initMap(videos);
        this.videoIds = Object.keys(this.map);
        this.daterange = dateRange;
    }

    async fetchAll() {
        await Promise.all([
            this.getImpressions(),
            this.getStarts(),
            this.getAverageWatchTime(),
            this.getTotalWatchTime(),
        ]);

        return this.map;
    }

    initMap(videos) {
        this.map = {};
        videos.forEach((video) => {
            this.map[video.id] = {
                starts: null,
                impressions: null,
                avg_view_time: null,
                viewed_ratio: null,
                total_watch_time: null,
            };
        });
    }

    getImpressions() {
        return $http.get(getStatQueryRequest('api/videos/impressions', this.videoIds, this.daterange))
            .then(response => {
                response.data.forEach((item) => {
                    this.map[item.key].impressions = item.doc_count ?? 0;
                });
            });
    }

    getStarts() {
        return $http.get(getStatQueryRequest('api/videos/views', this.videoIds, this.daterange)).then(response => {
            response.data.forEach((item) => {
                this.map[item.key].starts = item.doc_count ?? 0;
            });
        });
    }

    getAverageWatchTime() {
        return $http.get(getStatQueryRequest('api/videos/average-view-time', this.videoIds, this.daterange))
            .then(response => {
                response.data.forEach((item) => {
                    this.map[item.key].avg_view_time = item.time ?? 0;
                });
            });
    }

    getTotalWatchTime() {
        return $http.get(getStatQueryRequest('api/videos/total-watch-time', this.videoIds, this.daterange))
            .then((response) => {
                response.data.forEach((item) => {
                    this.map[item.key].total_watch_time = item.total_time ?? 0;
                });
            });
    }
}

export default StatisticsRepository; 