<template>
    <list-head-filter-popup :button-class="{'is-filtered': isFilteredBy}">
        <list-head-filter
            :column-id="columnId"
            :items="filteredCompanies"
            :select-check="value"
            @filterQuickSearch="setSearchText"
            @filterChanged="filterChange"
        >
            <template v-slot:filter-top="{listLengthLimit, clearSelected}">
                <div v-if="companies.length > listLengthLimit">
                    <v-text-field
                        v-model="searchText"
                        height="29"
                        flat
                        solo
                        background-color="grey lighten-1"
                        hide-details
                        :append-icon="searchText ? undefined : 'search'"
                        label="Quick search"
                        clearable
                    />
                    <div
                        v-if="!searchText"
                        class="mt-4"
                    >
                        Please narrow your search
                    </div>
                </div>
                <slot
                    name="filter-top"
                    :clearSelected="clearSelected"
                />
            </template>
        </list-head-filter>
    </list-head-filter-popup>
</template>

<script>
    import {$http, videoApiUrl} from '@/services/http';
    import ListHeadFilterPopup from '@/components/primitives/list-head-filter-popup';
    import ListHeadFilter from '@/components/primitives/list-head-filter';

    export default {
        name: 'UserCompanyFilter',
        components: {ListHeadFilter, ListHeadFilterPopup},
        props: {
            value: {
                type: Object,
                default: () => {
                }
            },
            columnId: {
                type: String
            }
        },
        data() {
            return {
                companies: [],
                searchText: '',
            };
        },
        computed: {
            filteredCompanies() {
                return this.searchText
                    ? this.companies.filter(c => c.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
                    : this.companies;
            },
            isFilteredBy() {
                return  Object.prototype.hasOwnProperty.call(this.value, this.columnId) && this.value[this.columnId].length > 0;

            }
        },
        methods: {
            setSearchText(s) {
                this.searchText = s;
            },

            filterChange(items) {
                this.$emit('input', {...this.value, [items.column]: items.items});
            },

            getCompanies() {
                $http.get(videoApiUrl('api/companies/?pagination[pageSize]=100000&pagination[current]=1')).then(res => {
                    this.companies = res.data.data.map((item) => {
                        return {
                            id: item.id,
                            name: item.company_name
                        };
                    });
                });
            },
        },
        mounted() {
            this.getCompanies();
        }
    };
</script>