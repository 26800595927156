<template>
    <div class="ma-6">
        <v-card elevation="0">
            <v-progress-linear
                v-if="!errorState"
                indeterminate
            />
            <v-card-text>
                <div v-if="!errorState">
                    Retrieving authorization from YouTube, please wait...
                </div>
                <div
                    v-else
                    class="error--text"
                >
                    {{ errorText }}
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {getYouTubeTokens} from '@/services/youtube';

    export default {
        name: 'YoutubeAuthorize',
        data() {
            return {
                errorState: false,
                errorText: ''
            };
        },
        mounted() {
            if (this.$route.query.error) {
                this.errorState = true;
                this.errorText = 'Unable to get authorization from YouTube.';
                return;
            }

            const {code, state} = this.$route.query;
            getYouTubeTokens(state, code)
                .then(entityId => this.$router.push({name: 'editCompany', params: {id: entityId}}))
                .catch(e => {
                    this.errorState = true;
                    this.errorText = e;
                });
        }
    };
</script>

<style scoped>

</style>