import BaseArea from './BaseArea';

class InteractiveTextArea extends BaseArea {
    constructor(data) {
        super(data);
        this.text = data.text;
    }
}

export default InteractiveTextArea;
