<template>
    <div
        ref="externalPlayerControls"
        class="external-player-controls"
    >
        <div class="player-buttons">
            <v-btn
                v-if="!playing"
                fab
                small
                color="primary"
                @click="play"
            >
                <v-icon>play_arrow</v-icon>
            </v-btn>
            <v-btn
                v-else
                fab
                small
                color="primary"
                @click="pause"
            >
                <v-icon>pause</v-icon>
            </v-btn>
            <v-btn
                fab
                small
                class="ml-4"
                @click="toggleMute"
            >
                <v-icon v-if="muted">
                    volume_off
                </v-icon>
                <v-icon v-else>
                    volume_up
                </v-icon>
            </v-btn>
            <v-btn
                fab
                small
                class="ml-4"
                @click="backward"
            >
                <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-btn
                fab
                small
                class="ml-1"
                @click="forward"
            >
                <v-icon>arrow_forward</v-icon>
            </v-btn>
        </div>
        <div class="spacer" />
        <div class="player-timer">
            {{ currentFormattedTime }} / {{ videoFormattedDuration }}
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import TimeHelper from '../../../time-helper';

    export default {
        name: 'InteractiveExternalPlayerControls',
        data() {
            return {
                currentTime: 0,
                playing: false,
                muted: true,
            };
        },
        computed: {
            ...mapState('interactive', ['player', 'video']),
            currentFormattedTime() {
                return TimeHelper.fromMsToHisU((this.currentTime || 0) * 1000);
            },
            videoFormattedDuration() {
                return TimeHelper.fromMsToHisU(this.video.duration);
            }
        },
        methods: {
            play() {
                this.player.$emit('play');
                this.playing = true;
            },

            pause() {
                this.player.$emit('pause');
                this.playing = false;
            },

            toggleMute() {
                this.muted = !this.muted;
                if (!this.muted) {
                    this.player.$emit('updateConfig', {volume: false, mute: false});
                    this.player.$emit('updateConfig', {volume: 75, mute: false});
                    return;
                }
                this.player.$emit('updateConfig', {mute: this.muted});
            },
            forward(){
                let newTime = this.currentTime + 0.1;
                if (newTime * 1000 < this.video.duration) {
                    this.$store.commit('interactive/setCurrentPlayerTime', newTime);
                    this.player.$emit('seek', newTime);
                }
            },
            backward(){
                let newTime = this.currentTime - 0.1;
                if (newTime > 0) {
                    this.$store.commit('interactive/setCurrentPlayerTime', newTime);
                    this.player.$emit('seek', newTime);
                }
            },
            keyUp(e) {
                if (e.target !== document.body) {
                    return;
                }
                if (e.code === 'ArrowRight') {
                    this.forward();
                } else if (e.code === 'ArrowLeft') {
                    this.backward();
                }
            }
        },
        mounted() {
            this.player.$on('timeChange', (time) => {
                this.currentTime = time;
                this.$store.commit('interactive/setCurrentPlayerTime', time);
            });
            this.player.$on('play', () => {
                this.playing = true;
            });
            this.player.$on('pause', () => {
                this.playing = false;
            });
            this.player.$on('timeChange', (time) => {
                if (time * 1000 >= this.video.duration) {
                    this.playing = false;
                }
            });
            this.player.$emit('updateConfig', {mute: this.muted});
            document.addEventListener('keyup', this.keyUp);
        },
        beforeDestroy() {
            document.removeEventListener('keyup', this.keyUp);
        }
    };
</script>

<style scoped>
    .external-player-controls {
        display: flex;
        flex-direction: row;
    }

    .player-buttons, .player-timer {
        flex: 0 0 auto;
    }

    .spacer {
        flex: 1 1 0;
    }
</style>
