<template>
    <div
        id="replace-video"
        style="width: 100%"
    >
        <v-row>
            <v-col class="py-0">
                <div>
                    <v-col
                        sm="12"
                        class="pa-0"
                    >
                        <v-card
                            elevation="0"
                            color="transparent"
                            class="drop-vcard pa-0"
                        >
                            <v-tooltip bottom
                                       :disabled="!fileIsUploading"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs"
                                         class="ml-m7 replace-wrapper"
                                         v-on="on"
                                    >
                                        <file-upload
                                            ref="upload"
                                            v-model="files"
                                            :thread="3"
                                            accept="video/mov,video/quicktime,video/mp4,video/x-m4v,video/*"
                                            :multiple="false"
                                            :chunk-enabled="true"
                                            :chunk="chunk"
                                            drop="#replace-video .drop-area"
                                            :drop-directory="true"
                                            class="replace-file-upload"
                                            :disabled="fileIsUploading"
                                            @input-filter="inputFilter"
                                            @input="$refs.upload.active = true"
                                        >
                                            <v-btn
                                                small
                                                class="button-minwidth"
                                                color="primary"
                                                :disabled="fileIsUploading"
                                            >
                                                Replace file
                                            </v-btn>
                                        </file-upload>
                                    </div>
                                </template>
                                <span>
                                    In order to upload a different source please cancel current upload first
                                </span>
                            </v-tooltip>
                        </v-card>
                    </v-col>
                    <v-col
                        sm="12"
                        xl="12"
                        lg="12"
                        class="pa-0"
                    >
                        <v-card
                            elevation="0"
                            color="transparent"
                            class="upload-list"
                            style=""
                        >
                            <v-alert
                                v-if="filesUploaded"
                                class="small-upload-alert"
                                outlined
                                type="warning"
                            >
                                Depending on the length of the new video the encoding can take some time. You might need to refresh the page to see the changes.
                            </v-alert>
                            <transition-group
                                name="file-list"
                                tag="div"
                            >
                                <div
                                    v-for="file in filteredFiles"
                                    :key="file.id"
                                >
                                    <div
                                        class="list-control-item"
                                        :class="{'no-errors': !file.error || file.error == 'cancel'}"
                                    >
                                        <v-card
                                            elevation="0"
                                            class="list-mode d-flex"
                                            style="width: 100%"
                                        >
                                            <v-col style="flex: 1 1 0; line-height: normal">
                                                <v-row>
                                                    <v-col
                                                        sm="12"
                                                        md="9"
                                                        lg="9"
                                                        xl="9"
                                                        class="pa-0"
                                                    >
                                                        <div class="file-info-holder pl-5 d-inline-block">
                                                            {{ file.name }} ({{ file.size | filesize }})
                                                            <span
                                                                v-if="$vuetify.breakpoint.mdAndUp"
                                                                class="item-library"
                                                            >in: {{ libraryName }}</span>
                                                        </div>
                                                    </v-col>
                                                    <v-col
                                                        sm="12"
                                                        md="3"
                                                        class="pt-0 pl-0 pb-0 d-flex progress-data"
                                                    >
                                                        <span>{{ Math.ceil(file.progress || 0) }} %</span>
                                                        <v-icon
                                                            v-if="file.success"
                                                            size="18"
                                                            color="success"
                                                        >
                                                            mdi_check
                                                        </v-icon>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        sm="12"
                                                        md="12"
                                                        lg="12"
                                                        xl="12"
                                                        class="progress-bar"
                                                    >
                                                        <v-progress-linear
                                                            :bottom="true"
                                                            class="elevation-0"
                                                            :value="file.progress"
                                                            rounded
                                                            :color="file.success ? 'primary' : (file.error ? (file.error == 'cancel' ? 'warning' : 'error') : 'primary')"
                                                            height="10"
                                                        />
                                                        <v-sheet
                                                            v-if="file.error && file.error != 'cancel'"
                                                            v-safe-html="getErrors(file)"
                                                            color="transparent"
                                                            class="file-errors"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col
                                                style="flex: 0 0 0"
                                                class="px-0 d-flex align-center"
                                            >
                                                <v-btn
                                                    v-if="file.active || file.error || (file.progress == 0 && !file.error)"
                                                    icon
                                                    color="red darken-1 white--text"
                                                    @click.prevent="cancelUpload(file)"
                                                >
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    v-if="file.error && file.error != 'cancel' && $refs.upload.features.html5"
                                                    icon
                                                    color="secondary"
                                                    @click.prevent="retryUpload(file)"
                                                >
                                                    <v-icon>mdi-reload</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-card>
                                    </div>
                                </div>
                            </transition-group>
                        </v-card>
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import VueUploadComponent from 'vue-upload-component';
    import {ChunkUploadHandler} from '../../plugins/chunk-upload/ChunkUploadHandler';
    import {toastError} from '../../services/responseErrors';
    import {getProperUnitSize} from '../../services/metadata';
    import { FileUploadEventBust } from '@/plugins/chunk-upload/ChunkUploadHandler.js';

    export default {
        name: 'Replace',
        props: ['video'],
        components: {
            FileUpload: VueUploadComponent,
        },
        filters: {
            filesize: function (num) {
                if (typeof num !== 'number' || isNaN(num)) {
                    return 0;
                }
                return getProperUnitSize(num);
            }
        },
        data () {
            return {
                files: [],
                fileIsUploading: false,
            };
        },
        computed: {
            ...mapState('libraries', ['libraries']),
            libraryName() {
                const library = this.libraries.find(library => library.id === this.video.library);
                return `${library.company.company_name} / ${library.name}`;
            },
            chunk() {
                // 2MB per chunk
                return {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token'),
                        'Accept': 'application/json',
                    },
                    action: process.env.VUE_APP_VIDEO_API_HOST + `api/replace/${this.video.id}/chunk`,
                    minSize: 1048576,
                    maxActive: 3,
                    maxRetries: 5,
                    handler: ChunkUploadHandler
                };
            },
            filteredFiles() {
                return this.files.filter((file) => {
                    return !file.error || file.error != 'cancel';
                });
            },
            filesUploaded() {
                const finished = this.filteredFiles.filter(file => file.progress === 100);
                return finished.length > 0;
            }
        },
        methods: {
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!/^video\/.*$/i.test(newFile.type)) {
                        var fileExtension = newFile.name.substring(newFile.name.lastIndexOf('.')+1, newFile.name.length);
                        toastError('File: ' + newFile.name + ' with type of ' + fileExtension + ' not accepted!');
                        return prevent();
                    }
                    // do not allow files smaller then the minimum chunk
                    if (newFile.size < 1048576) {
                        toastError('File: ' + newFile.name + ' is not accepted! The minimum filesize is 1MB');
                        return prevent();
                    }
                }
            },
            cancelUpload(file) {
                if (file.chunk && file.chunk.file && file.chunk.chunks) {
                    file.chunk.abort();
                }
                this.$refs.upload.update(file, {error: 'cancel'});
                //decrease uploading counter to make sure uploads dont get stuck
                this.$refs.upload.uploading--;
                this.fileIsUploading = false;
            },
            retryUpload(file) {
                this.$refs.upload.update(file, {active: true, error: '', progress: '0.00'});
            },
            getErrors(file) {
                const s = Object.values(file.response.errors)
                    .reduce((a, b) => Object.values(a).join('<br />') + '<br />' + Object.values(b).join('<br />'));

                if (Array.isArray(s)) {
                    return s.join('<br />');
                }
                return s;
            },
        },
        mounted() {
            this.$store.dispatch('libraries/getLibraries');
            FileUploadEventBust.$on('file-uploading', () => {
                this.fileIsUploading = true;
            });   
            FileUploadEventBust.$on('file-uploaded', () => {
                this.fileIsUploading = false;
            });     
        }
    };
</script>

<style lang="scss">
    .replace-file-upload {
        label {
            cursor: pointer;
        }
        label:hover {
            opacity: 0.1 !important;
            transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        }
    }

    .file-row {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-left: 5px;
        padding-right: 5px;
    }

    .file-list-item {
        display: block;
    }

    .drop-vcard {
        z-index: 1;
        border: 0 none transparent;
        background-color: transparent;
    }

    .progress-data {
        align-items: center;

        span {
            flex: 1 1 0;
            text-align: right;
        }

        .v-icon {
            flex: 0 0 16px;
            width: 16px;
            text-indent: -60px;
            font-weight: bold;
        }
    }

    .selection-content {
        width: 100%;
        text-align: center;
    }

    .drop-selector .v-select__slot label {
        display: block;
        width: 100%;
        text-align: center;
        padding-left: 50px;
    }

    .item-library {
        opacity: .5;
        margin-left: 1rem;
        font-size: .7rem;
    }

    .file-errors {
        padding: 5px 7px;
        color: red !important;
        font-size: .75rem;
        background-color: transparent;
    }

    .upload-list .list-control-item .v-card.list-mode > div {
        height: auto !important;
    }

    #replace-video .upload-list .list-control-item.no-errors .v-card.list-mode > div {
        height: 5rem !important;
    }

    .progress-bar {
        padding: 6px 16px;
    }
    #replace-video .small-upload-alert {
        font-size: .8rem;
        padding: .5rem;
    }

    .ml-m7 {
        margin-left: 7rem;
    }
    .button-minwidth {
        min-width: 132px !important;
    }
    .replace-wrapper{
      max-width: 132px;
    }
</style>
