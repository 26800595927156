<template>
    <v-menu
        v-model="open"
        :close-on-content-click="false"
        :close-on-click="true"
        attach="#videoListSort"
        @cancel="resetTempSort"
    >
        <template v-slot:activator="{ on: menu }">
            <v-tooltip
                bottom
                open-delay="1000"
            >
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        id="videoListSort"
                        icon
                        class="ma-auto text-capitalize list-toolbar-dropdown list-toolbar-dropdown-left"
                        v-on="{...tooltip, ...menu}"
                    >
                        <v-icon
                            v-if="!value.targetId"
                            dark
                        >
                            mdi-sort
                        </v-icon>
                        <v-icon
                            v-if="value.targetId"
                            dark
                            color="primary"
                        >
                            mdi-sort
                        </v-icon>
                    </v-btn>
                </template>
                <span>Sort</span>
            </v-tooltip>
        </template>
        <v-card @click.native.stop="() => {}">
            <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
                <v-list dense>
                    <v-list-item
                        link
                        @click="setTempSortingDirection('asc')"
                    >
                        <v-list-item-icon>
                            <v-icon
                                v-if="isAscending"
                                dense
                            >
                                mdi-check
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                            <v-list-item-title>Ascending</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="setTempSortingDirection('desc')"
                    >
                        <v-list-item-icon>
                            <v-icon
                                v-if="isDescending"
                                dense
                            >
                                mdi-check
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                            <v-list-item-title>Descending</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider v-if="$vuetify.breakpoint.xs" />
                <v-list dense>
                    <v-list-item
                        v-for="header in headers"
                        :key="header.value"
                        link
                        @click="setTempSortingId(header.value)"
                    >
                        <v-list-item-icon>
                            <v-icon
                                v-if="sortedBy(header.value)"
                                dense
                            >
                                mdi-check
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                            <v-list-item-title>{{ header.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>
            <v-divider />
            <v-card-actions>
                <div class="flex-grow-1" />

                <v-btn
                    id="sortCancelAction"
                    small
                    text
                    @click="cancelSort"
                >
                    Cancel
                </v-btn>
                <v-btn
                    id="sortApplyAction"
                    small
                    color="primary"
                    text
                    @click="applySort"
                >
                    Apply
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: 'SortPicker',
        props: ['headers', 'value'],
        data() {
            return {
                sorting: {...this.value.sorting},
                open: false
            };
        },
        watch: {
            open(value, oldValue) {
                if (value && value !== oldValue) {
                    this.resetTempSort();
                }
            }
        },
        computed: {
            isAscending() {
                return this.sorting.direction === 'asc';
            },
            isDescending() {
                return !this.isAscending;
            }
        },
        methods: {
            applySort() {
                this.open = false;
                this.$emit('input', {...this.value, sorting: {...this.sorting}});
            },

            cancelSort() {
                this.open = false;
            },

            setTempSortingId(id) {
                this.sorting.targetId = id;
            },

            setTempSortingDirection(direction) {
                this.sorting.direction = direction;
            },

            resetTempSort() {
                this.sorting.targetId = this.value.sorting.targetId;
                this.sorting.direction = this.value.sorting.direction;
            },

            sortedBy(headerValue) {
                return this.sorting.targetId === headerValue;
            }
        }
    };
</script>

<style scoped>

</style>