<template>
    <stats-card :loading="loading">
        <template v-slot:title>
            Dropoff rate
        </template>
        <template v-slot:content>
            <div class="chart-container">
                <line-chart
                    v-if="chartData"
                    :key="chartUpdate"
                    :chartdata="chartData"
                    :options="options"
                />
            </div>
        </template>
    </stats-card>
</template>

<script>
    import LineChart from '@/components/pages/statistics/charts/line-chart';
    import StatsCard from '@/components/pages/statistics/stats-card';
    import TimeHelper from '@/time-helper';
    import {$http, getStatQueryRequest} from '@/services/http';

    export default {
        name: 'DropoffRate',
        props: ['video', 'dateRange'],
        components: {StatsCard, LineChart},
        data() {
            return {
                loading: true,
                chartData: null,
                chartUpdate: 1,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 10,
                                display: true,
                                maxRotation: 0,
                                minRotation: 0,
                            },
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Play time'
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Views'
                            },
                            ticks: {
                                stepSize: 1,
                                beginAtZero: true
                            }
                        }]
                    },
                }
            };
        },
        methods: {
            getData() {
                let videoId = this.video.id;
                $http.get(getStatQueryRequest('api/videos/drop-off-rate-chart-total-views/', [videoId], this.dateRange)).then(response => {
                    const labels = Object.keys(response.data).map(time => {
                        return TimeHelper.fromMsToHis(parseInt(time));
                    });
                    const viewers = Object.values(response.data);
                    this.chartData = {
                        labels: labels,
                        datasets: [{
                            label: 'Views',
                            borderColor: this.$vuetify.theme.themes.light.primary,
                            backgroundColor: 'transparent',
                            pointBackgroundColor: this.$vuetify.theme.themes.light.primary,
                            data: viewers,
                        }]
                    };
                }).finally(() => {
                    this.loading = false;
                    this.chartUpdate += 1;
                });
            },
        },
        mounted() {
            this.getData();
        },
    };
</script>

<style scoped>

</style>
