const main = {
    namespaced: true,
    state: {
        searchContent: '',
        pagination: {
            itemPerPage: 12,
            currentPage: 1,
            total: 0,
            range: 2
        },
        sorting: {
            column: 'company',
            direction: 'asc'
        },
    },
    mutations: {
        setSearch(state, value) {
            state.searchContent = value;
        },
        setSorting(state, value) {
            state.sorting = value;
        },
        setPagination(state, value)
        {
            for (let key in value) {
                state.pagination[key] = value[key] ;
            }
        }
    }
};

export default main;
