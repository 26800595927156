const undoStackSize = 100;

class UndoStack {
    constructor() {
        this.size = undoStackSize,
            this.items = [];
    }

    push(element) {
        // lose the bottom item if pushing another item (expensive)
        if (this.items.length === undoStackSize) {
            this.items.shift();
        }
        this.items.push(element);
    }

    pop() {
        if (this.hasItems()) {
            return this.items.pop();
        }
    }

    latest() {
        if (this.hasItems()) {
            return this.items[this.items.length - 1];
        }
    }

    hasItems() {
        return this.items.length > 0;
    }
}

export default UndoStack;