<template>
    <v-autocomplete
        v-model="libraryRoles.items"
        label="Libraries"
        :items="selectableLibraries"
        prepend-icon="mdi-bank"
        item-text="name"
        item-value="id"
        return-object
        :search-input.sync="librarySearch"
        :filter="filterObject"
        multiple
        chips
        @input="triggerInput"
    >
        <template v-slot:append-outer>
            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-btn
                        icon
                        color="primary"
                        :disabled="!company"
                        @click.stop="selectLibrariesByCompany"
                        v-on="on"
                    >
                        <v-icon>library_add_check</v-icon>
                    </v-btn>
                </template>
                <span>Select all libraries from the user's company</span>
            </v-tooltip>
        </template>
        <template v-slot:selection="data">
            <v-chip
                close
                @click:close="removeLibrary(data.item)"
            >
                <span class="library-chip-content">
                    <span class="library-company-name">{{ getLibraryCompanyName(data.item) }}</span>
                    <span class="library-name">{{ data.item.name }}</span>
                </span>
            </v-chip>
        </template>
        <template v-slot:item="{item, attrs}">
            <v-list-item-action>
                <v-checkbox v-model="attrs.inputValue" />
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-subtitle>
                    {{ getLibraryCompanyName(item) }}
                </v-list-item-subtitle>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
    import {debounce} from 'lodash';
    import LibraryList from '../../../models/library-list';
    import {$http, getLimitedQuickSearchUri, videoApiUrl} from '../../../services/http';
    import {mapGetters} from 'vuex';

    export default {
        name: 'LibraryRoleEditor',
        props: ['value', 'libraries', 'company', 'exclude'],
        data() {
            return {
                libraryRoles: this.value,
                librarySearch: '',
                selectableLibraries: this.filterResults(this.libraries),
            };
        },
        watch: {
            librarySearch: debounce(function (val) {
                this.getLibraries(val);
            }),
        },
        computed: {
            ...mapGetters('libraries', ['getLibraryCompanyName']),
        },
        methods: {
            filterObject(item, queryText) {
                return (
                    item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
                    item.company.company_name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
                );
            },
            filterResults(libraries) {
                return libraries.filter(library => (this.exclude || []).indexOf(library.id) === -1);
            },
            getLibraries(q) {
                return $http.get(videoApiUrl(getLimitedQuickSearchUri('api/libraries', 10000, q)) + '&sorting[column]=company').then((response) => {
                    this.selectableLibraries = this.filterResults(response.data.data);
                });
            },
            triggerInput() {
                this.$emit('input', this.libraryRoles);
            },
            selectLibrariesByCompany() {
                if (!this.company) {
                    return;
                }

                this.libraryRoles = new LibraryList(this.libraries.filter(library => library.company && library.company_id === this.company.id));
                this.triggerInput();
            },

            removeLibrary(item) {
                this.libraryRoles.remove(item);
                this.triggerInput();
            },
        },
        mounted() {
            this.librarySearch = '';
        }
    };
</script>

<style scoped>

</style>
