<template>
    <div class="border-editor">
        <div class="mr-8">
            <h4 class="mb-8">
                Color
            </h4>
            <color-picker
                ref="colorPicker"
                :value="color"
                @input="colorChanged"
                @validate="colorValidated"
            />
        </div>
        <div class="ml-8">
            <h4 class="mb-3">
                Weight
            </h4>
            <v-text-field
                v-model="width"
                type="number"
                :min="0"
                :max="20"
                hint="px"
                persistent-hint
                @input="widthChange"
            />
            <h4 class="mt-8 mb-3">
                Preview
            </h4>
            <div
                class="mt-4"
                :style="previewStyle"
            />
        </div>
    </div>
</template>

<script>
    import InteractiveBorder from '../../../../models/interactive/format/primitives/InteractiveBorder';
    import ColorPicker from '../../../primitives/color-picker';


    export default {
        name: 'Bordereditor',
        components: {
            ColorPicker
        },
        props: {
            value: {
                type: Object
            },
            minWidth: {
                type: Number,
                default: 0
            },
            maxWidth: {
                type: Number,
                default: 20
            }
        },
        data() {
            return {
                width: this.value.width,
                color: this.value.color,
            };
        },
        computed: {
            previewStyle() {
                return {
                    border: `${this.width}px solid ${this.color}`,
                    height: '100px'
                };
            }
        },
        methods: {
            widthChange(value) {
                if (value > this.maxWidth) {
                    this.width = this.maxWidth;
                }

                if (value < this.minWidth) {
                    this.width = this.minWidth;
                }

                this.emitValues();
            },
            colorChanged(value) {
                this.color = value;
                this.emitValues();
            },
            colorValidated(value) {
                this.$emit('validate', value);
            },
            validate() {
                return this.$refs.colorPicker.validate();
            },
            emitValues() {
                this.$emit('input', new InteractiveBorder({
                    color: this.color,
                    width: this.width || 0
                }));

            }
        }
    };
</script>

<style scoped lang="scss">
    .border-editor {
        display: flex;

        div {
            flex: 1 1 0;
        }
    }
</style>