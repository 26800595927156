import {debounce, cloneDeep} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {$http, videoApiUrl} from '@/services/http';
import PresentationItem from '@/models/presentation/PresentationItem';
import {getFreeStartPoint, getPlayerPresentation, storePresentationExpandAll} from '@/store/presentation/helpers';

const presentationActions = {
    upload: ({commit, state}, payload) => {
        const uuid = uuidv4();
        const name = payload.file.name.replace(/\.[^/.]+$/, '');
        const formData = new FormData();
        const finalStart = getFreeStartPoint(state, payload.start);

        formData.append('file', payload.file);
        formData.append('_id', uuid);
        formData.append('name', name);
        formData.append('start', '' + finalStart);
        return $http.post(videoApiUrl(`api/videos/${payload.videoId}/presentation`), formData)
            .then(() => {
                const reader = new FileReader();
                reader.addEventListener('load', function () {
                    commit('addSlide', {
                        _id: uuid,
                        name: name,
                        start: finalStart,
                        end: finalStart,
                        src: this.result,
                        cdn_src: this.result,
                    });

                    state.player.$emit('updatePresentation', getPlayerPresentation(state.presentation, state.video));
                    commit('setActiveElement', {_id: uuid, start: finalStart});
                });
                reader.readAsDataURL(payload.file);
            });
    },
    getPresentation({commit}, videoId) {
        commit('setLoading', true);
        return $http.get(videoApiUrl(`api/videos/${videoId}`)).then(response => {
            commit('setVideo', response.data);
            return $http.get(videoApiUrl(`api/videos/${videoId}/presentation`)).then(presentationResponse => {
                commit('setPresentation', presentationResponse.data);
            });
        }).finally(() => commit('setLoading', false));
    },
    deleteSlide: ({commit, getters, state}, payload) => {
        return $http.delete(videoApiUrl(`api/videos/${getters.videoId}/presentation/${payload._id}`)).then(() => {
            commit('deleteSlideItem', payload);
            state.player.$emit('updatePresentation', getPlayerPresentation(state.presentation, state.video));
        });
    },
    updatePresentation: debounce(({commit, getters, state}, payload) => {
        $http.put(videoApiUrl(`api/videos/${getters.videoId}/presentation/${payload._id}`), {
            ...payload,
        }).then(() => {
            commit('updateSlide', payload);
            state.player.$emit('updatePresentation', getPlayerPresentation(state.presentation, state.video));
        });
    }, 1000),
    addDuplicate: ({commit, getters, state}, payload) => {
        const finalStart = getFreeStartPoint(state, payload.start);
        const data = {
            ...payload,
            start: finalStart,
            end: finalStart
        };
        return $http.post(videoApiUrl(`api/videos/${getters.videoId}/presentation/duplicate`), data).then(() => {
            commit('addSlide', new PresentationItem(data));
            state.player.$emit('updatePresentation', getPlayerPresentation(state.presentation, state.video));
        });
    },
    reorderSlides: ({commit, getters, state}) => {
        const slides = cloneDeep(state.presentation.items);
        const starts = slides.map(e => e.start);
        const sortedSlides = [...slides].sort((a, b) => {
            return a.name.localeCompare(
                b.name,
                [
                    'en',
                    'nl'
                ],
                {
                    ignorePunctuation: true,
                    numeric: true,
                    sensitivity: 'base',
                    usage: 'sort',
                }
            );
        });

        for (let x in starts) {
            sortedSlides[x].start = starts[x];
        }

        return $http.post(videoApiUrl(`api/videos/${getters.videoId}/presentation/reorder`), {
            'order': sortedSlides.map((e) => {
                return {
                    '_id': e._id,
                    'start': e.start,
                };
            })
        }).then(() => {
            commit('setSlides', sortedSlides);
            state.player.$emit('updatePresentation', getPlayerPresentation(state.presentation, state.video));
        });
    },
    setPresentationView: ({commit, getters, state}, payload) => {
        commit('setPresentationPosition', payload);
        return $http.put(videoApiUrl(`api/videos/${getters.videoId}/presentation/settings`), JSON.stringify({
            position: payload
        }), {
            'headers': {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            state.player.$emit('updatePresentation', getPlayerPresentation(state.presentation, state.video));
        });
    },
    replaceFile: ({commit, getters, state}, payload) => {
        const formData = new FormData();
        formData.append('file', payload.file);
        formData.append('_id', payload._id);
        // $http.put sends empty body, WTF?
        formData.append('_method', 'PUT');
        $http.post(videoApiUrl(`api/videos/${getters.videoId}/presentation/${payload._id}`), formData).then(() => {
            const reader = new FileReader();
            reader.addEventListener('load', function () {
                commit('updateSlide', {
                    _id: payload._id,
                    src: this.result,
                    cdn_src: this.result,
                });
                state.player.$emit('updatePresentation', getPlayerPresentation(state.presentation, state.video));
            });

            reader.readAsDataURL(payload.file);
        });
    },
    toggleExpandAll({state, commit}) {
        commit('setExpandAll', !state.expandAll);
        storePresentationExpandAll(state.expandAll);
    }
};

export default presentationActions;