import BaseArea from './BaseArea';

class InteractiveInputArea extends BaseArea {
    constructor(data) {
        super(data);
        this.type = data.type;
        this.name = data.input.name;
        this.placeholder = data.input.placeholder;
        this.seekOffset = data.input.seekOffset;
    }
}

export default InteractiveInputArea;
