import {skipToTimeIndex} from '@/store/comments/helpers';

const commentsMutations = {
    setLoading: (state, payload) => state.isLoading = payload,
    setVideo: (state, payload) => state.video = payload,
    setPlayer: (state, payload) => state.player = payload,
    setCurrentPlayerTime: (state, value) => state.currentPlayerPosition = value,
    setActiveElement: (state, value) => {
        state.activeElement = value;
        skipToTimeIndex(state, value);
    },
    setActiveElementWithoutSkip: (state, value) => state.activeElement = value,
    setComments: (state, payload) => state.comments = payload,
};

export default commentsMutations;