<template>
    <v-container fluid>
        <v-row
            justify-center
            align-center
            no-gutters
        >
            <v-col cols="12">
                <div class="content">
                    <v-col>
                        <h1 class="ml-4 mr-4 page-header">
                            <span v-if="!user.id">Add</span><span
                                v-if="user.id"
                            >Edit</span>&nbsp;User
                        </h1>
                        <validation-observer
                            ref="obs"
                            v-slot="{ invalid, validated, validate }"
                        >
                            <v-form
                                class="ml-4 mr-4"
                                @submit.prevent="submitUser"
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="Name"
                                            rules="required"
                                        >
                                            <v-text-field
                                                v-model="user.name"
                                                label="Name"
                                                :error-messages="errors"
                                                prepend-icon="mdi-account"
                                                @keyup="validate"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        class="pt-0 pb-0"
                                    >
                                        <v-text-field
                                            v-model="user.phone_number"
                                            label="Telephone / contact number"
                                            prepend-icon="mdi-phone"
                                            type="text"
                                            @keyup="validate"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        class="pt-0 pb-0"
                                    >
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="Email"
                                            rules="required|email"
                                        >
                                            <v-text-field
                                                v-model="user.email"
                                                label="Email"
                                                :error-messages="errors"
                                                prepend-icon="mdi-email"
                                                :disabled="isExistingUser"
                                                @keyup="validate"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        v-if="canEditRoleOf(user)"
                                        cols="12"
                                        md="4"
                                    >
                                        <v-select
                                            v-model="user.role"
                                            label="Role"
                                            :items="roles"
                                            item-text="name"
                                            item-value="slug"
                                            clearable
                                            prepend-icon="mdi-lock"
                                            hide-details
                                            @change="validate"
                                            @input="roleUpdated"
                                        />
                                    </v-col>
                                    <v-col
                                        v-if="canEditUserCompany"
                                        cols="12"
                                        md="4"
                                    >
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="company"
                                            :rules="companyRequired"
                                        >
                                            <v-autocomplete
                                                v-model="user.company"
                                                label="Company"
                                                :items="companies"
                                                prepend-icon="mdi-office-building"
                                                item-text="company_name"
                                                item-value="id"
                                                clearable
                                                return-object
                                                :error-messages="errors"
                                                :search-input.sync="companySearch"
                                                @change="validate"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <template v-if="(isSuperAdmin || isSystemAdmin)">
                                    <v-divider />
                                    <v-row>
                                        <v-col cols="12">
                                            <v-checkbox
                                                v-model="user.platform_upload_everywhere"
                                                label="Can upload to all libraries in Quadia Platform"
                                                @change="platformUploadEverywhereChange"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-divider />
                                <library-roles-editor
                                    v-if="!rolesLoading && user.libraries"
                                    v-model="user.libraries"
                                    :libraries="libraries"
                                    :company="user.company"
                                    @input="onRoleLibraryChange"
                                />
                                <v-divider />
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-left">
                                            <v-btn
                                                type="submit"
                                                color="accent"
                                                class="mr-4"
                                                :disabled="invalid || !validated"
                                            >
                                                <span v-if="!user.id">Add user</span><span
                                                    v-if="user.id"
                                                >Save changes</span>&nbsp;
                                            </v-btn>
                                            <router-link
                                                :to="{name: 'users'}"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    color="error"
                                                    text
                                                >
                                                    Back
                                                </v-btn>
                                            </router-link>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </validation-observer>
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import {debounce} from 'lodash';
    import {$http, getLimitedQuickSearchUri, videoApiUrl} from '../../services/http';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {toastSuccess} from '../../services/responseErrors';
    import RoleLibraries from '../../models/role-libraries';
    import LibraryRolesEditor from '../controls/roles/library-roles-editor';

    export default {
        name: 'AddEditUser',
        components: {
            LibraryRolesEditor,
            ValidationProvider,
            ValidationObserver,
        },
        computed: {
            ...mapGetters('auth', ['canEditRoleOf', 'canEditUserCompany', 'isSuperAdmin', 'isSystemAdmin']),
            ...mapState('roles', {rolesLoading: 'loading'}),
            ...mapGetters('roles', {role: 'getBySlug', roles: 'allowedCompanyLevelRoles'}),
            isExistingUser() {
                return this.user.id ? true : false;
            },
            companyRequired() {
                return ['system-admin', 'super-admin'].indexOf(this.user.role) === -1 ? 'required' : '';
            },
        },
        data() {
            const user = {
                id: '',
                name: '',
                email: '',
                phone_number: '',
                company: null,
                libraries: null,
                platform_upload_everywhere: false,
            };

            if (this.$route.params.id) {
                user.id = this.$route.params.id;
            }

            return {
                user,
                companies: [],
                libraries: [],
                companySearch: '',
                isLoading: true,
            };
        },
        watch: {
            companySearch: debounce(function (val) {
                this.getCompanies(val);
            }),
            'user.company'(newVal, oldVal) {
                if (this.isLoading || oldVal === newVal) {
                    return;
                }

                if (typeof newVal === 'undefined') {
                    this.user.company = null;
                }

                this.user.libraries.clear();
            },
            'user.role'(newVal) {
                if (typeof newVal === 'undefined') {
                    this.user.role = null;
                }
            }
        },
        methods: {
            roleUpdated() {
                //this.user.role = this.roleName(value);
                if (['editor', 'read-only', 'uploader'].indexOf(this.user.role) === -1) {
                    this.user.libraries.clear();
                }
                this.$refs.obs.validate();
            },
            getCompanies(q) {
                if(!this.canEditUserCompany) {
                    return Promise.resolve();
                }
                return $http.get(videoApiUrl(getLimitedQuickSearchUri('api/companies', 1000, q)+'&sorting[column]=company_name')).then((response) => {
                    this.companies = response.data.data;
                });
            },
            getLibraries(q) {
                return $http.get(videoApiUrl(getLimitedQuickSearchUri('api/libraries', 10000, q)) + '&sorting[column]=company').then((response) => {
                    this.libraries = response.data.data;
                });
            },
            getUser() {
                return $http.get(videoApiUrl('api/users/' + this.user.id)).then(response => {
                    this.user = {
                        ...this.user,
                        ...response.data,
                        company: response.data.company,
                        libraries: new RoleLibraries(response.data.libraries, this.role),
                    };
                }).catch(() => {
                    this.$router.push({name: 'users'});
                });
            },
            onRoleLibraryChange() {
                this.$refs.obs.validate();
            },
            platformUploadEverywhereChange() {
                this.$refs.obs.validate();
            },
            submitUser() {
                const method = this.user.id ? $http.put : $http.post;

                const user = {
                    ...this.user,
                    company_id: this.user.company ? this.user.company.id : null,
                    libraries: this.user.libraries.mapToRoles()
                };

                method(videoApiUrl('api/users/' + this.user.id), user).then(() => {
                    toastSuccess(`User ${this.user.id ? 'updated' : 'saved'}`);
                    if (!this.user.id) {
                        this.$router.push('/users');
                    }
                });
            },
        },
        mounted() {
            this.companySearch = '';
            Promise.all([
                this.getCompanies(),
                this.getLibraries(),
                this.$store.dispatch('roles/getRoles'),
            ]).then(() => {
                if (this.user.id) {
                    this.getUser().finally(() => {
                        this.isLoading = false;
                    });
                } else {
                    // auto-select company for new user when company admin is logged in,
                    // as there is no company selector in this case
                    if (this.user.role === 'company-admin') {
                        this.user.company = this.companies[0];
                    }

                    this.user.libraries = new RoleLibraries([], this.role);
                }
            });
        }
    };

</script>

<style scoped>
    .dense .v-input--selection-controls {
        margin-top: 0;
    }

    .library-chip-content {
        display: inline-flex;
        flex-wrap: wrap;
    }

    .library-company-name {
        font-size: .7rem;
        flex: 0 0 100%;
        height: .9rem;
        opacity: .7;
    }

    .library-name {
        flex: 0 0 100%;
        font-size: .8rem;
    }

    .v-list-item__subtitle {
        font-size: .8rem;
    }
</style>
