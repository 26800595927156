<template>
    <tr
        v-if="video"
        class="editable"
    >
        <td>Spoken language(s):</td>
        <td class="pl-2">
            <div class="editable-meta no-flex">
                <metadata-field-editor
                    :key="'languages-' + video.id"
                    v-model="video.metadata.languages"
                    type="tags"
                    name="languages"
                    subtype="tags"
                    :meta="{counter: 5000, readOnly: !canEdit, availableOptions:['Dutch', 'English']}"
                    :open="true"
                    :keep-open="true"
                    @input="videoLanguagesUpdated"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import updateMeta from '../../../../services/metadata';
    import MetadataFieldEditor from '../editor/MetadataFieldEditor';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';

    export default {
        name: 'MetaLanguagesField',
        components: {MetadataFieldEditor},
        props: ['video'],
        computed: {
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            videoLanguagesUpdated(value) {
                updateMeta(this.video.id, {
                    'languages': value
                });
            },
        },
    };
</script>

<style scoped>
    td:first-child {
        width: 8rem !important;
    }

    td:last-child {
        background-color: transparent !important;
    }
</style>
