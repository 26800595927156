import { render, staticRenderFns } from "./interactive-playeraction.vue?vue&type=template&id=b6058808&scoped=true&"
import script from "./interactive-playeraction.vue?vue&type=script&lang=js&"
export * from "./interactive-playeraction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6058808",
  null
  
)

export default component.exports