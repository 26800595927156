<template>
    <div class="mx-1">
        <header-sort-button :color="highlightIfAsc"
                            :column="column"
                            direction="asc"
        />
        <header-sort-button :color="highlightIfDesc"
                            :column="column"
                            direction="desc"
        />
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import HeaderSortButton from '@/components/pages/ftp/header-sort-button';

    export default {
        name: 'HeaderSort',
        components: {HeaderSortButton},
        props: {
            column: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapState('ftp', ['sortedBy']),
            isSortedBy() {
                return this.sortedBy.key === this.column;
            },
            highlightIfDesc() {
                return this.colorBySortDirection('desc');
            },
            highlightIfAsc() {
                return this.colorBySortDirection('asc');
            }
        },
        methods: {
            colorBySortDirection(direction) {
                return this.isSortedBy && this.sortedBy.direction === direction ? 'rgba(0,0,0,0.87)' : '';
            },
        }
    };
</script>

<style scoped>

</style>