import {$http, publishingApiUrl} from './http';

const identifierKey = 'VimeoEntityIdentifier';
const stateKey = 'VimeoAuthorizationState';
const accountNameKey = 'VimeoAccountName';

function cleanupStorage() {
    localStorage.removeItem(identifierKey);
    localStorage.removeItem(stateKey);
    localStorage.removeItem(accountNameKey);
}


export function getVimeoAuthorizationCode(entityId, accountName) {
    $http.get(publishingApiUrl('/accounts/qpd/vimeo/authorization'), {}).then(response => {
        const {oauth2GrantURL, state} = response.data;
        localStorage.setItem(identifierKey, entityId);
        localStorage.setItem(stateKey, state);
        localStorage.setItem(accountNameKey, accountName);
        location.href = oauth2GrantURL;
    });
}

export function getVimeoToken(state, code) {
    return new Promise((resolve, reject) => {
        const storedState = localStorage.getItem(stateKey);
        if (storedState !== null && storedState == state) {
            storeVimeoToken(code).then((entityId) => {
                resolve(entityId);
            });
        } else {
            reject('Invalid Vimeo state');
        }
    });
}

export function storeVimeoToken(code) {
    return new Promise(resolve => {
        const entityId = localStorage.getItem(identifierKey);
        $http.post(publishingApiUrl('/accounts/qpd/vimeo/token'), {
            name: localStorage.getItem(accountNameKey),
            OAuthCode: code,
            meta: {
                companyId: entityId,
                accountType: 'vimeo'
            }
        }).then(() => {
            cleanupStorage();
            resolve(entityId);
        });
    });
}

export function getVimeoAccount(entityId) {
    return new Promise((resolve, reject) => {
        $http.get(publishingApiUrl('/accounts/qpd/token/company/' + entityId)).then(response => {
            const filteredItems = response.data.filter(item => item.meta.accountType === 'vimeo');
            if (filteredItems.length=== 0) {
                reject();
            } else {
                resolve(filteredItems[0]);
            }
        });
    });
}

export function getVimeoCategories() {
    return new Promise(resolve => {
        $http.get(publishingApiUrl('/qpd/categories/vimeo')).then(response => resolve(response.data));
    });
}

export function publishToVimeo(videoDescriptor) {
    return new Promise((resolve, reject) => {
        $http.post(publishingApiUrl('/qpd/publish/vimeo'), videoDescriptor).then(response => resolve(response))
            .catch(e => reject(e));
    });
}
