<template>
    <stats-card class="h13 metric"
                :loading="loading"
    >
        <template v-slot:title>
            Start Ratio
        </template>
        <template v-slot:content>
            {{ ratio }}%
        </template>
    </stats-card>
</template>

<script>
    import StatsCard from './stats-card';
    import { mapState } from 'vuex';

    export default {
        name: 'CardStartRatio',
        components: {
            StatsCard,
        },
        data() {
            return { ratio: 0 };
        },
        computed: {
            ...mapState('statistics-details', [
                'starts',
                'loadingStarts',
                'impressions',
                'loadingImpressions',
            ]),
            loading() {
                return this.loadingStarts || this.loadingImpressions;
            },
        },
        methods: {
            calculateRatio() {
                this.ratio = this.impressions > 0
                    ? Math.round((this.starts / this.impressions) * 100)
                    : 0;
            },
        },
        watch: {
            loading: {
                immediate: true,
                handler(value) {
                    if (!value) {                    
                        this.calculateRatio();
                    }
                }
            },
        },
    };
</script>