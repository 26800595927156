import axios from 'axios';

export const $http = axios.create();

export const playerTemplateApiUrl = function (path) {
    return `${process.env.VUE_APP_PLAYERTEMPLATE_API_HOST}/player-template-management-api${path}`;
};

export const videoApiUrl = function (path) {
    return process.env.VUE_APP_VIDEO_API_HOST + path;
};

export const statsApiUrl = function (path) {
    return process.env.VUE_APP_STATS_API_HOST + path;
};

export const publishingApiUrl = function (path) {
    return process.env.VUE_APP_PUBLISHING_API_HOST + path;
};

export const ftpApiUrl = function (path){
    return process.env.VUE_APP_VIDEO_API_HOST + 'api' + path;
};

export function getLimitedQuickSearchUri(endPoint, pageSize, searchText) {
    const paginationPart = 'pagination[pageSize]=' + (pageSize || 100);
    const quickSearchPart = searchText ? '&filters[quickSearch]=' + encodeURI(searchText) : '';
    return endPoint + '?' + paginationPart + quickSearchPart;
}

export function getStatQueryRequest(endPoint, videos, dates) {

    let queryString = endPoint;

    const queries = [];

    queries.push('videos[]=' + videos.join('&videos[]='));
    if (dates[0] != null) {

        queries.push('from=' + dates[0].toISOString());
    }

    if (dates[1] != null) {
        queries.push('to=' + dates[1].toISOString());
    }

    if (queries.length > 0) {
        queryString = [
            queryString,
            queries.join('&')
        ].join('?');
    }


    return statsApiUrl(queryString);
}