<template>
    <div class="teleport"
         :class="classes"
    >
        <div ref="moved"
             class="teleported"
             :style="style"
        >
            <slot name="default"
                  :top="top"
                  :left="left"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Teleport',
        props: {
            to: {
                type: String,
                required: true
            },
            local: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                left: 0,
                top:0,
            };
        },
        computed: {
            style() {
                if (this.left === 0 && this.top === 0) {
                    return {};
                }

                return {
                    left: this.left + 'px',
                    top: this.top + 'px',
                };
            },
            classes() {
                return {
                    local: this.local
                };
            }
        },
        methods: {
            reposition() {
                const cr = this.$el.getBoundingClientRect();
                const {left, top} = cr;
                let targetLeft = left;
                let targetTop = top;
                let cl = this.$refs.moved.firstElementChild;
                if (cl.getBoundingClientRect().width === 0 || cl.getBoundingClientRect().height === 0) {
                    cl = cl.firstElementChild;
                }
                const s = window.getComputedStyle(cl);
                const w = parseInt(s.width);
                const h = parseInt(s.height);

                const targetRight = left + w;
                const targetBottom = top + h;

                if (targetRight > window.innerWidth) {
                    targetLeft = left - w + 40;

                    if (targetLeft < 0) {
                        targetLeft = 0;
                    }
                }

                if (targetBottom > window.innerHeight) {
                    targetTop = top - h - 40;
                    if (targetTop < 0) {
                        targetTop = 0;
                    }
                }

                this.setPositionValues(targetLeft, targetTop);
            },
            setPositionValues (left, top) {
                this.top = top;
                this.left = left;
            }
        },
        mounted() {
            const target = document.querySelector(this.to);
            target.appendChild(this.$refs.moved);
            this.reposition();
        },
        beforeDestroy() {
            this.$el.appendChild(this.$refs.moved);
        }
    };
</script>

<style scoped>
    .teleport {
        left: 0;
        top: 100%;
        position: absolute;
    }

    .teleport.local {
        top: initial;
    }

    .teleported {
        position: fixed;
        z-index: 99;
    }
</style>