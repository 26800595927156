<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>Project</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-simple-table
                dense
                class="property-sheet"
            >
                <template v-slot:default>
                    <meta-project-number-field
                        v-if="!multipleVideos"
                        :video="video"
                    />
                    <meta-agency-field
                        v-if="!multipleVideos"
                        :video="video"
                    />
                    <meta-internal-notes-field
                        v-if="!multipleVideos && video"
                        :video="video"
                    />
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import MetaInternalNotesField from './fields/meta-internal-notes-field';
    import MetaAgencyField from './fields/meta-agency-field';
    import MetaProjectNumberField from './fields/meta-project-number-field';
    export default {
        name: 'MetadataProject',
        components: {MetaProjectNumberField, MetaAgencyField, MetaInternalNotesField},
        props: ['multipleVideos', 'video']
    };
</script>

<style scoped>

</style>