<template>
    <generic-modal
        title="Copy embed Code"
        class="embedCode"
        :value="open"
        max-width="640"
        @cancel="cancel"
    >
        <template v-slot:default>
            <v-textarea
                ref="code"
                full-width
                outlined
                :value="contents"
                readonly
            />
            <p class="grey--text">
                If the code is broken after you paste and save it, you may need to disable the Smart
                Quotes feature in your OS settings.
            </p>
        </template>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                text
                @click="cancel"
            >
                Close
            </v-btn>
        </template>
    </generic-modal>
</template>

<script>
    import GenericModal from './generic-modal';

    export default {
        name: 'EmbedModal',
        components: {GenericModal},
        props: ['open', 'contents'],
        methods: {
            cancel() {
                this.$emit('cancel');
            },
        }
    };
</script>
<style scoped>

</style>