<template>
    <tr
        v-if="video"
        class="editable"
    >
        <td>Tags</td>
        <td>
            <div class="editable-meta no-flex">
                <metadata-field-editor
                    :key="'tags-' + video.id + video.tags.join('')"
                    v-model="video.tags"
                    type="tags"
                    name="tags"
                    subtype="tags"
                    :meta="{counter: 5000, readOnly: !canEdit, availableOptions: tags}"
                    :open="true"
                    :keep-open="true"
                    @input="videoTagsUpdated"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import updateMeta from '../../../../services/metadata';
    import MetadataFieldEditor from '../editor/MetadataFieldEditor';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';
    import {mapGetters} from 'vuex';

    export default {
        name: 'MetaTagsField',
        components: {MetadataFieldEditor},
        props: ['video'],
        computed: {
            ...mapGetters('tag-store', ['tags']),
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            videoTagsUpdated(value) {
                updateMeta(this.video.id, {
                    tags: value
                });
            }
        },
    };
</script>

<style scoped>

</style>
