<template>
    <v-expansion-panels
        :key="'uploaded-'+selectedVideo"
        v-model="openTabs"
        accordion
        multiple
        class="video-metadata"
    >
        <v-expansion-panel class="elevation-0">
            <v-expansion-panel-header>Basic</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-simple-table
                    dense
                    class="property-sheet external-upload"
                >
                    <template v-slot:default>
                        <tbody>
                            <tr class="editable">
                                <td>Title:</td>
                                <td>
                                    <div class="editable-meta">
                                        <span
                                            v-if="video"
                                            v-safe-html="video.title ? brokenTitle : 'Title'"
                                            class="editable-content"
                                            @dblclick="openEditor()"
                                        />
                                        <metadata-field-editor
                                            v-if="video && canEdit"
                                            :key="'title-' + video.id"
                                            ref="editableTitle"
                                            v-model="video.title"
                                            name="title"
                                            type="inline"
                                            :meta="{counter: 50, pattern: /^([a-zA-Z0-9 \-_\.])+$/gi}"
                                            @input="videoTitleUpdated"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-if="video"
                                class="editable"
                            >
                                <td>Aanvraagnummer:</td>
                                <td>
                                    <div class="editable-meta">
                                        <span
                                            v-if="video"
                                            v-safe-html="video.internalNotes ? video.internalNotes.replace('ticket number', '') : ''"
                                            class="editable-content"
                                            @dblclick="openNotesEditor()"
                                        />
                                        <metadata-field-editor
                                            v-if="video && canEdit"
                                            :key="'in-' + video.id"
                                            ref="editableNotes"
                                            v-model="video.internalNotes"
                                            name="internal notes"
                                            type="inline"
                                            :meta="{counter: 50}"
                                            @input="videoInternalNotesUpdated"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: middle">
                                    Viewing page
                                </td>
                                <td>
                                    <v-btn
                                        small
                                        color="primary"
                                        class="mr-2 copy-button"
                                        @click.stop="copyToClipboard"
                                    >
                                        <v-icon
                                            small
                                            class="mr-2"
                                        >
                                            file_copy
                                        </v-icon>
                                        <span class="mr-1">Copy URL</span>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        small
                                        title="Open in new tab"
                                        :href="viewingUrl"
                                        target="_blank"
                                    >
                                        <v-icon color="primary">
                                            open_in_new
                                        </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="vertical-align: middle"
                                    class="pt-1 pb-1"
                                >
                                    Download
                                </td>
                                <td class="pt-1 pb-1">
                                    <v-btn
                                        small
                                        color="primary"
                                        class="mr-2 copy-button"
                                        @click.stop="copySourceUrl"
                                    >
                                        <v-icon
                                            small
                                            class="mr-2"
                                        >
                                            file_copy
                                        </v-icon>
                                        <span class="mr-1">Copy URL</span>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        small
                                        title="Download"
                                        @click="download"
                                    >
                                        <v-icon color="primary">
                                            mdi-download
                                        </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="elevation-0 external-upload-backup">
            <v-expansion-panel-header>Stuur een kopie van bovenstaande gegevens naar jezelf toe.</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-simple-table
                    dense
                    class="property-sheet external-upload"
                >
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>
                                    <validation-observer
                                        ref="obs"
                                        v-slot="{ invalid, validated, validate }"
                                    >
                                        <v-form @submit.prevent="sendBackupEmail">
                                            <validation-provider
                                                v-slot="{ errors, valid }"
                                                name="Email"
                                                rules="required|email"
                                            >
                                                <v-text-field
                                                    v-model="backupEmailData"
                                                    placeholder="Jouw e-mailadres"
                                                    dense
                                                    outlined
                                                    single-line
                                                    class="backup-email-input"
                                                    :error-messages="errors"
                                                    :hide-details="!validated || valid"
                                                    @keyup="validate"
                                                />
                                            </validation-provider>
                                            <div class="backup-email-submit">
                                                <v-btn
                                                    type="submit"
                                                    color="accent"
                                                    :disabled="invalid"
                                                >
                                                    Send
                                                </v-btn>
                                            </div>
                                        </v-form>
                                    </validation-observer>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel class="elevation-0 external-upload-info">
            <v-expansion-panel-header>Je video is succesvol geüpload!</v-expansion-panel-header>
            <v-expansion-panel-content>
                <p>
                    LET OP! Refresh deze pagina nu niet.
                    Voordat je de pagina afsluit dien je eerst de volgende stappen te doorlopen:
                </p>
                <ul>
                    <li>Vul de titel van je video en het aanvraagnummer in.</li>
                    <li>
                        Klik op “Copy URL” bij viewing page om de link naar de video te kopiëren. Deze link kun je naar
                        de aanvrager van de video sturen en eventueel delen via jouw social media kanalen. Klik op het
                        icoontje daarnaast om de viewing page op een nieuw tab te openen.
                    </li>
                </ul>
                <p>
                    Mocht je de aanvrager ook de mogelijkheid willen geven om de video te downloaden: klik dan op “Copy
                    URL” bij Download.
                </p>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import copy from 'copy-to-clipboard';
    import {throttle} from 'lodash';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {toastSuccess, toastError} from '../../../services/responseErrors';
    import {$http, videoApiUrl} from '../../../services/http';
    import updateMeta from '../../../services/metadata';
    import MetadataFieldEditor from './editor/MetadataFieldEditor';
    import Gate from '../../../services/gate';
    import store from '../../../store/index';

    export default {
        name: 'ExternalUploadMetadata',
        components: {
            MetadataFieldEditor,
            ValidationProvider,
            ValidationObserver
        },
        props: ['selectedVideo'],
        watch: {
            selectedVideo: function () {
                this.getVideo();
            }
        },
        data() {
            return {
                openTabs: [0, 1, 2],
                video: null,
                backupEmailData: ''
            };
        },
        computed: {
            viewingUrl() {
                return location.protocol + '//' + location.host + '/view/' + encodeURI(this.selectedVideo[0].id);
            },
            brokenTitle() {
                return this.video.title ? this.video.title.replace(/_/g, '_<span></span>') : '';
            },
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            openEditor() {
                if(this.canEdit) {
                    this.$refs.editableTitle.openEditor();
                }
            },
            openNotesEditor() {
                if(this.canEdit) {
                    this.$refs.editableNotes.openEditor();
                }
            },
            getVideo() {
                $http.get(videoApiUrl('api/videos/' + this.selectedVideo[0].id)).then((response) => {
                    this.video = response.data;
                });
            },
            copyToClipboard() {
                copy(this.viewingUrl, {format: 'text/plain'});
                toastSuccess('Copied to clipboard!');
            },
            copySourceUrl() {
                copy(videoApiUrl('videos/' + this.video.id + '/download'), {format: 'text/plain'});
                toastSuccess('Copied to clipboard!');
            },
            download() {
                const file = document.createElement('a');
                file.href = videoApiUrl('videos/' + this.video.id + '/download');
                file.setAttribute('download', 'video');
                file.setAttribute('target', '_blank');
                document.body.appendChild(file);
                setTimeout(() => {
                    file.click();
                }, 1000);
            },
            videoTitleUpdated(value) {
                updateMeta(this.video.id, {
                    'name': value
                }).then(() => {
                    this.video.title = value;
                });
            },
            videoInternalNotesUpdated(value) {
                updateMeta(this.video.id, {
                    'internalNotes': value
                }).then(() => {
                    this.video.internalNotes = 'ticket number ' + value;
                });
            },
            sendBackupEmail: throttle(function() {
                if( this.backupEmailData ) {
                    $http.post(videoApiUrl('api/send-backup-data'), {
                        'email': this.backupEmailData,
                        'sourceUrl': this.video.source_url,
                        'viewingUrl': this.viewingUrl,
                    }).then(() => {
                        toastSuccess('Backup email sent.');
                    }).catch(error => {
                        toastError(error.response.data.errors.email);
                    });
                } else {
                    toastError('Please specify an email address.');
                }
            }, 5000)
        },
        mounted() {
            this.getVideo();
        },
        updated() {
            this.getVideo();
        },
    };
</script>

<style scoped>
    .external-upload tbody tr td {
        border: none !important;
    }
    .external-upload-info ul {
        margin-bottom: 16px;
    }
    .backup-email-input {
        width: 65%;
        float: left;
        font-size: 0.75rem;
    }
    .backup-email-submit {
        width: 30%;
        float: left;
        margin-left: 10px;
    }
    .video-metadata .external-upload-backup .property-sheet {
        margin: 0 !important;
    }
    .v-text-field.v-text-field--enclosed .v-text-field__details {
        margin-bottom: 0;
    }
</style>
