<template>
    <v-sheet class="copy-library">
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn
                    small
                    icon
                    color="primary"
                    v-on="on"
                    @click="toggleLibraryList"
                >
                    <v-icon small>
                        content_copy
                    </v-icon>
                </v-btn>
            </template>
            <span>Copy to Library</span>
        </v-tooltip>
        <generic-modal
            v-model="libraryListOpen"
            title="Copy to library"
            @cancel="cancelled"
        >
            <div v-if="!selectedLibrary">
                <v-sheet v-if="!librariesLoading">
                    <p class="primary--text">
                        <span class="primary--text"><v-icon
                            class="mr-2"
                            color="primary"
                        >info</v-icon>The new copy will have it's statistics reset.</span>
                    </p>
                    <p class="comment">
                        Click on the library you'd like to copy the selected video ({{ video.title }}) to:
                    </p>
                    <v-text-field
                        v-if="targetLibraries.length > 5"
                        v-model="searchText"
                        hide-details
                        clearable
                        placeholder="Search libraries"
                    />
                    <div
                        class="library-container"
                        @scroll="listScroll"
                    >
                        <v-list dense>
                            <v-list-item
                                v-for="library in paginatedLibraries"
                                :key="`library-move-copy-${library.id}`"
                                dense
                                @click="selectLibrary(library)"
                            >
                                <v-list-item-content>
                                    <v-list-item-subtitle v-if="library.company">
                                        {{ library.company.company_name }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>{{ library.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-sheet>
                <v-progress-circular
                    v-else
                    indeterminate
                />
            </div>
            <v-sheet v-else>
                <p>This will copy the video to {{ selectedLibrary.company.company_name }}/{{ selectedLibrary.name }}</p>
            </v-sheet>
            <v-progress-linear v-if="actionExecuting" />
            <template v-slot:actions>
                <v-btn
                    v-if="selectedLibrary"
                    text
                    color="primary"
                    @click="doAction"
                >
                    Yes, copy to the selected library
                </v-btn>
                <v-btn
                    v-if="selectedLibrary"
                    text
                    @click="reset"
                >
                    Select a different library
                </v-btn>
                <v-btn
                    text
                    @click="cancel"
                >
                    Cancel
                </v-btn>
            </template>
        </generic-modal>
    </v-sheet>
</template>

<script>
    import {mapState} from 'vuex';
    import GenericModal from '@/components/controls/modals/generic-modal';
    import {$http, videoApiUrl} from '@/services/http';
    import {toastSuccess} from '@/services/responseErrors';

    export default {
        name: 'CopyVideo',
        components: {GenericModal},
        data() {
            return {
                libraryListOpen: false,
                searchText: '',
                actionExecuting: false,
                libraries: [],
                librariesLoading: true,
                currentPage: 1,
                pageSize: 10,
                selectedLibrary: null,
            };
        },
        props: ['video'],
        watch: {
            searchText() {
                this.currentPage = 1;
            },
            libraryListOpen(value) {
                if (!value) {
                    setTimeout(() => {
                        this.selectedLibrary = null;
                    }, 200);
                }
            }
        },
        computed: {
            ...mapState('auth', ['user']),
            targetLibraries()
            {
                return this.libraries.filter(library => {
                    return library.id !== this.video.library;
                });
            },
            searchedLibraries() {
                if (!this.searchText) {
                    return this.targetLibraries;
                }

                const lowerText = this.searchText.toLowerCase();

                return this.targetLibraries.filter(library => library.name.toLowerCase().indexOf(lowerText) > -1 ||
                    library.company && library.company.company_name.toLowerCase().indexOf(lowerText) > -1);
            },
            paginatedLibraries() {
                return this.searchedLibraries.slice(0, this.currentPage * this.pageSize);
            }
        },
        methods: {
            async getLibraries() {
                this.librariesLoading = true;
                const {data} = await $http.get(videoApiUrl('api/libraries/for-upload'));
                this.libraries = data;
                this.librariesLoading = false;
            },
            toggleLibraryList() {
                if (this.libraryListOpen) {
                    this.libraryListOpen = false;
                    return;
                }

                this.libraryListOpen = true;
                this.currentPage = 1;
                this.getLibraries();
            },
            cancelled() {
                this.libraryListOpen = false;
            },
            selectLibrary(library) {
                this.selectedLibrary = library;
            },
            copyToLibrary(library)
            {
                return $http.post(videoApiUrl(`api/videos/${this.video.id}/copy`), {
                    library: library.id,
                    uploadedBy: this.user.name,
                }).then(() => {
                    toastSuccess(`Video is being copied to ${library.company.company_name} / ${library.name}`);
                });
            },
            doAction() {
                this.actionExecuting = true;
                const result = this.copyToLibrary(this.selectedLibrary);

                result.then(() => {
                    this.libraryListOpen = false;
                }).finally(() => {
                    this.actionExecuting = false;
                });
            },
            listScroll(e) {
                if (e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight && this.pageSize * this.currentPage < this.searchedLibraries.length) {
                    ++this.currentPage;
                }
            },
            reset() {
                this.selectedLibrary = null;
            },
            cancel() {
                this.libraryListOpen = false;
            }
        }
    };
</script>

<style scoped lang="scss">
.copy-library {
  position: relative;
  display: inline-block;
}

.library-container {
  max-height: 250px;
  overflow: auto;
}

.comment {
  margin-bottom: 0;
}
</style>
