import OpenVideoAction from '../actions/OpenVideoAction';

class OpenVideoActionFactory {
    make(videoId) {
        return new OpenVideoAction({
            type: 'openVideo',
            videoId: videoId,
            autoPlay: false,
            mute: true,
            startAt: 0
        });
    }
}

export default new OpenVideoActionFactory();