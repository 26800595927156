import InteractiveElementFormat from './InteractiveElementFormat';
import InteractiveBorder from './primitives/InteractiveBorder';

class InteractiveHotspotFormat extends InteractiveElementFormat {
    constructor(data) {
        super(data);
        this.default.border = new InteractiveBorder(data.default.border);
        this.default.background = {
            color: data.default?.background?.color || 'rgba(0, 0, 0, 0)'
        };
        this.hover.border = new InteractiveBorder(data.hover.border);
        this.hover.background = {
            color: data.hover?.background?.color || 'rgba(0, 0, 0, 0)'
        };
    }
}

export default InteractiveHotspotFormat;