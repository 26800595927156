<template>
    <v-btn
        :class="color"
        fab
        elevation="3"
        x-small
        @click.stop="setActiveElement(element)"
    >
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <span
                    class="tooltip-activator"
                    v-on="on"
                >1</span>
            </template>
            <span>{{ element.name }}</span>
        </v-tooltip>
    </v-btn>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'InteractiveExternalScrubPlotSingle',
        props: ['element'],
        methods: {
            ...mapMutations('interactive', ['setActiveElement'])
        },
        computed: {
            ...mapGetters('interactive', ['activeElement']),
            color() {
                return this.element === this.activeElement ? 'primary' : 'white';
            }
        }
    };
</script>

<style scoped>
    .v-btn.white {
        border: 2px solid gray !important;
    }

    .tooltip-activator {
        display:  block;
        width:  100%;
        height:  100%;
        margin-top:  -0.5em;
        padding-top:  0.5em;
        margin-bottom: -0.5em;
        padding-bottom:  0.5em;
    }
</style>
