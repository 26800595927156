
import {getActiveElement, skipToTimeIndex, sortElements} from './helpers';
import InteractiveHotspotArea from '../../models/interactive/areas/InteractiveHotspotArea';
import InteractiveOrigin from '../../models/interactive/format/primitives/InteractiveOrigin';
import InteractiveDimensions from '../../models/interactive/format/primitives/InteractiveDimensions';
import HotspotFormatFactory from '../../models/interactive/factories/hotspot-format-factory';
import BaseArea from '../../models/interactive/areas/BaseArea';
import ImageFormatFactory from '../../models/interactive/factories/image-format-factory';
import InteractiveTextArea from '../../models/interactive/areas/InteractiveTextArea';
import TextFormatFactory from '../../models/interactive/factories/text-format-factory';
import OpenLinkActionFactory from '../../models/interactive/factories/open-link-action-factory';
import OpenVideoActionFactory from '../../models/interactive/factories/open-video-action-factory';
import SkipToActionFactory from '../../models/interactive/factories/skip-to-action-factory';
import BaseAction from '../../models/interactive/actions/BaseAction';
import UndoStack from '../../models/undo/UndoStack';

const interactiveMutations = {
    setPlayer(state, player) {
        state.player = player;
    },
    setElements: (state, elements) => {
        state.elements = elements;
        state.activeIndex = state.elements.findIndex(item => item.active);
        sortElements(state);
    },
    setActiveElement: (state, element) => {
        state.elements.forEach(e => e.active = e._id === element._id);
        state.activeIndex = state.elements.findIndex(item => item.active);
        skipToTimeIndex(state, element.start);
    },
    deactivate(state) {
        state.elements.forEach(e => e.active = false);
        state.activeIndex = null;
    },
    addElement: (state, element) => {
        state.elements.push(element);
        sortElements(state);
    },
    setLoading: (state, value) => {
        state.loading = value;
    },
    setName: (state, name) => {
        const activeElement = getActiveElement(state);
        activeElement.name = name;
    },
    setStart: (state, payload) => {
        const activeElement = getActiveElement(state);
        activeElement.start = payload;
        if (activeElement.startAction === 'pause' || activeElement.end < payload) {
            activeElement.end = payload;
        }
        skipToTimeIndex(state, payload);
        sortElements(state);
    },
    setEnd: (state, payload) => {
        const activeElement = getActiveElement(state);
        activeElement.end = payload;
        if (activeElement.start > activeElement.end) {
            activeElement.start = payload;
            skipToTimeIndex(state, payload);
            sortElements(state);
        }
    },
    setStyleOrigin: (state, { left, top }) => {
        const activeElement = getActiveElement(state);
        activeElement.style.origin = new InteractiveOrigin({ left, top });
    },
    setStyleDimensions: (state, { width, height }) => {
        const activeElement = getActiveElement(state);
        activeElement.style.dimensions = new InteractiveDimensions({ width, height });
    },
    setStartAction: (state, payload) => {
        const activeElement = getActiveElement(state);
        activeElement.startAction = payload;
        if (activeElement.startAction === 'pause') {
            activeElement.end = activeElement.start;
        }
    },
    setHoverBorder: (state, payload) => {
        const activeElement = getActiveElement(state);
        activeElement.style.hover.border = payload;
    },
    setDefaultBorder: (state, payload) => {
        const activeElement = getActiveElement(state);
        activeElement.style.default.border = payload;
    },
    setDefaultBackgroundColor: (state, payload) => {
        const activeElement = getActiveElement(state);
        activeElement.style.default.background.color = payload;
    },
    setHoverBackgroundColor: (state, payload) => {
        const activeElement = getActiveElement(state);
        activeElement.style.hover.background.color = payload;
    },
    setFontFamilies: (state, fontFamily) => {
        const activeElement = getActiveElement(state);
        activeElement.style.default.font.family = fontFamily;
        activeElement.style.hover.font.family = fontFamily;
    },
    setFontSizes: (state, fontSize) => {
        const activeElement = getActiveElement(state);
        activeElement.style.default.font.size = fontSize;
        activeElement.style.hover.font.size = fontSize;
    },
    setDefaultFontColor: (state, color) => {
        const activeElement = getActiveElement(state);
        activeElement.style.default.font.color = color;
    },
    setHoverFontColor: (state, color) => {
        const activeElement = getActiveElement(state);
        activeElement.style.hover.font.color = color;
    },
    setAreaText: (state, text) => {
        const activeElement = getActiveElement(state);
        activeElement.area.text = text;
    },
    setArea: (state, areaType) => {
        const activeElement = getActiveElement(state);
        let area = null;
        let style = null;
        switch (areaType) {
            default:
            case 'hotspot':
                area = new InteractiveHotspotArea({
                    type: areaType,
                    shape: 'rectangle'
                });
                style = HotspotFormatFactory.make(activeElement.style);
                break;
            case 'image':
                area = new BaseArea({
                    type: areaType
                });
                style = ImageFormatFactory.make(activeElement.style);
                break;
            case 'text':
                area = new InteractiveTextArea({
                    type: areaType,
                    text: 'New Text'
                });
                style = TextFormatFactory.make(activeElement.style);
                break;
        }
        activeElement.area = area;
        activeElement.style = style;
    },
    setFormat: (state, format) => {
        const activeElement = getActiveElement(state);
        activeElement.style = format;
    },
    setDefaultBackground: (state, image) => {
        const activeElement = getActiveElement(state);
        activeElement.style.default.background.path = image.path;
        activeElement.style.default.background.url = image.url;
    },
    setHoverBackground: (state, image) => {
        const activeElement = getActiveElement(state);
        activeElement.style.hover.background.path = image.path;
        activeElement.style.hover.background.url = image.url;
    },
    setAction(state, actionType) {
        const activeElement = getActiveElement(state);
        let action = null;
        switch (actionType) {
            default:
            case 'openLink':
            case 'openDownload':
                action = OpenLinkActionFactory.make(actionType);
                break;
            case 'openVideo':
                action = OpenVideoActionFactory.make(state.video.id);
                break;
            case 'skipTo':
            case 'skipToAndPause':
                action = SkipToActionFactory.make(actionType);
                break;
            case 'play':
            case 'pause':
                action = new BaseAction({
                    type: actionType
                });
                break;
        }
        activeElement.action = action;
    },
    setActionTarget: (state, target) => {
        const activeElement = getActiveElement(state);
        activeElement.action.target = target;
    },
    setActionStartAt: (state, startAt) => {
        const activeElement = getActiveElement(state);
        activeElement.action.startAt = startAt;
    },
    setActionSkipToTime: (state, timeIndex) => {
        const activeElement = getActiveElement(state);
        activeElement.action.timeIndex = timeIndex;
    },
    setActionAutoPlay: (state, autoPlay) => {
        const activeElement = getActiveElement(state);
        activeElement.action.autoPlay = autoPlay;
    },
    setActionMute: (state, mute) => {
        const activeElement = getActiveElement(state);
        activeElement.action.mute = mute;
    },
    setActionLink: (state, actionLink) => {
        const activeElement = getActiveElement(state);
        activeElement.action.link = actionLink;
    },
    setActionVideoId: (state, videoId) => {
        const activeElement = getActiveElement(state);
        activeElement.action.videoId = videoId;
    },
    setShape: (state, shape) => {
        const activeElement = getActiveElement(state);
        activeElement.area.shape = shape;
    },
    setVideo: (state, video) => {
        state.video = video;
    },
    popUndo: (state) => {
        state.undoStack.pop();
    },
    pushUndo: (state, undoAction) => {
        state.undoStack.push(undoAction);
    },
    popRedo: (state) => {
        state.redoStack.pop();
    },
    pushRedo: (state, redoAction) => {
        state.redoStack.push(redoAction);
    },
    clearRedo: (state) => {
        state.redoStack = new UndoStack();
    },
    clearUndo: (state) => {
        state.undoStack = new UndoStack();
    },
    setImageShouldPreserveAspectRatio: (state, value) => {
        state.imageShouldPreserveAspectRatio = value;
    },

    setCurrentPlayerTime(state, value) {
        state.currentPlayerPosition = value;
    }
};

export default interactiveMutations;