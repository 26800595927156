<template>
    <div
        class="element-content"
        :style="elementStyle"
        @mouseover="onMouseOver"
        @mouseout="onMouseOut"
    />
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'InteractiveImage',
        data() {
            return {
                mouseOver: false,
            };
        },
        props: ['element'],
        computed: {
            ...mapGetters('interactive', ['activeElement']),
            elementStyle() {
                if (!this.element) {
                    return;
                }

                const styleState = this.mouseOver ? this.element.style.hover : this.element.style.default;

                const url = styleState.background.url || this.element.style.default.background.url;

                const style = {
                    'background-image': `url(${url})`,
                };

                return style;
            },
        },
        methods: {
            onMouseOver() {
                this.mouseOver = true;
            },
            onMouseOut() {
                this.mouseOver = false;
            },
        }
    };
</script>

<style scoped>
    .element-content {
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
    }
</style>