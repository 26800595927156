<template>
    <v-container fluid>
        <v-row justify-center
               align-center
               class="statistics-detailed-page"
               :class="{mobile: isMobile}"
        >
            <v-col>
                <h1 class="page-header ml-6 mr-4 mb-1">
                    Statistics
                </h1>
                <loading-bar v-if="loadingVideo" />

                <v-row v-else
                       :key="statisticsKey"
                       class="px-4"
                       justify="center"
                >
                    <v-col cols="12"
                           class="d-flex justify-space-between"
                           :class="{'flex-column': isMobile}"
                    >
                        <div class="d-flex ml-2 mb-4 video-title-container">
                            <h1 class="video-title font-weight-medium mr-1">
                                {{ title }}
                            </h1>
                            <h1 class="video-title video-id">
                                <span class="separator">|&nbsp;</span>
                                {{ videoId }}
                            </h1>
                        </div>
                        <standalone-stats-date-picker :value="dateRange"
                                                      class="date-picker"
                                                      @input="setDateRange"
                        />
                    </v-col>
                    <v-col cols="12">
                        <div class="stats-cards">
                            <card-impressions class="stat-card" />
                            <card-starts class="stat-card" />
                            <card-start-ratio class="stat-card" />
                            <card-viewed-ratio class="stat-card" />
                            <card-avg-viewing-time class="stat-card" />
                            <card-total-watch-time class="stat-card" />
                            <card-referrers class="stat-card" />
                        </div>
                    </v-col>
                    <v-col cols="12"
                           xl="6"
                           lg="6"
                           md="6"
                           sm="12"
                           class="dropoff-chart-wrapper"
                    >
                        <dropoff-rate
                            :video="video"
                            :date-range="dateRange"
                            class="dropoff-rate-chart"
                        />
                    </v-col>
                    <v-col cols="12"
                           xl="6"
                           lg="6"
                           md="6"
                           sm="12"
                           class="map-chart-wrapper"
                    >
                        <card-countries-geo-chart style="height: 100%" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>    
    import LoadingBar from '@/components/controls/loading-bar.vue';
    import CardReferrers from './card-referrers.vue';
    import CardCountriesGeoChart from './card-countries-geo-chart.vue';
    import CardAvgViewingTime from './card-avg-viewing-time.vue';
    import CardImpressions from './card-impressions.vue';
    import CardTotalWatchTime from './card-total-watch-time.vue';
    import CardStartRatio from './card-start-ratio.vue';
    import CardViewedRatio from './card-viewed-ratio.vue';
    import CardStarts from './card-starts.vue';
    import DropoffRate from '@/components/pages/statistics/dropoff-rate';
    import StandaloneStatsDatePicker from '@/components/pages/statistics/standalone-stats-date-picker';
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'StatisticsDetails',
        components: {
            LoadingBar,
            CardReferrers,
            CardCountriesGeoChart,
            CardAvgViewingTime,
            CardImpressions,
            CardStartRatio,
            CardViewedRatio,
            CardTotalWatchTime,
            CardStarts,
            DropoffRate,
            StandaloneStatsDatePicker
        },
        data() {
            return {
                loadingVideo: true,
            };
        },
        computed: {
            ...mapState('statistics-details', [
                'video',
                'videoId',
                'dateRange',
            ]),
            statisticsKey() {
                return JSON.stringify(this.dateRange);
            },
            title(){
                return this.video?.title || '';
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        methods: {
            ...mapMutations('statistics-details', ['setVideoId', 'setDateRange']),
            ...mapActions('statistics-details', ['getVideo'])
        },
        async mounted() {
            this.setVideoId(this.$route.params.id);
            await this.getVideo();
            this.loadingVideo = false;
        },
    };
</script>

<style lang="scss" scoped>
.video-title{
    font-size: 18px;
}
</style>

<style lang="scss">
.statistics-detailed-page{
    .video-title-container {
      flex: 1 1 0;
      .video-title {
        max-width: 50%;
        word-break: break-all;
        &.video-id {
          max-width: 100%;
        }
      }
    }

    @media screen and (max-width: 900px) {
      .video-title-container.d-flex {
        display: block !important;

        .video-title {
          max-width: 100%;
        }

        .separator {
          display: none;
        }

        .video-title + .video-title {
          border-top: 1px solid #000;
          margin-top: 0.5rem;
          padding-top: 0.5rem;
        }
      }
    }

    .metric-explain {
        line-height: initial;
        font-size: 0.6rem;
        margin-top: -0.6rem;
    }

    .stats-item{
        .v-card__title{
            font-size: 1rem;
        }
        .v-card__text {
            font-size: 1.8rem !important;
        }
    }
    .stats-item.h13.metric .v-card__text{
            line-height: 6rem;
            height: 9rem;
    }
    .stats-item.h13 .v-card__text{
            line-height: 6rem;
            height: 9rem;
    }

    .dropoff-rate-chart{
        height: 100%;

        .v-card__text{
            height: inherit;
            display: flex;
            align-items: center;

            .chart-container{
                margin-bottom: 4rem;
                width: 100%
            }
        }
    }
    .stats-cards{
        display: flex;
        min-width: 100%;
        gap: 11px;
        justify-content: center;
        .stat-card{
            flex: 0 1 calc(14.2857142857% - 10px);
            min-width: calc(14.2857142857% - 10px);
        }
    }

    &.mobile{
        .stats-cards{
            display: flex;
            flex-direction: column;
            min-width: 100%;
            gap: 11px;
            .stat-card{
                min-width: 100%;
            }
        }
    }
}
</style>