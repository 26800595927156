<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-select
                v-model="editor_value"
                :items="options"
                item-text="name"
                item-value="value"
                dense
                flat
                :height="18"
                class="mt-0 meta-field-select"
                @input="onUpdate"
            />
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'Combobox',
        props: ['value', 'open', 'meta'],
        data() {
            return {
                editor_value: this.value,
                editing: false,
            };
        },
        computed: {
            options() {
                return this.meta.options;
            }
        },
        methods: {
            onUpdate() {
                this.$emit('input', this.editor_value);
            },
            editDone() {
                this.$emit('input', this.editor_value);
                this.editCancelled();
            },
        }
    };
</script>

<style lang="scss">
    .v-select.meta-field-select {
        height: 18px !important;
        font-size: .8rem !important;

        .v-input__slot {
            margin-top: 1px !important;
            min-height: 18px !important;
        }

        .v-input__slot:before, .v-input__slot:after {
            display:none !important;
        }

        .v-text-field__details {
            display:none;
        }

        .v-select__selection {
            margin: 0;
        }

        .v-input__append-inner {
            margin-top: 0;
        }

        input {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .v-input__icon {
            height: 18px;
            overflow:hidden;
        }

        .v-icon {height: 18px;}

        &:hover {
            background-color: rgba(0,0,0,.05)
        }
    }
</style>
