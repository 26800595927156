const startActions = [
    {
        value: 'pause',
        name: 'Pause'
    },
    {
        value: 'play',
        name: 'Play'
    },
];

const shapes = [
    {
        value: 'rectangle',
        name: 'Rectangle'
    },
    // not supported in MVP
    // {
    //     value: 'circle',
    //     name: 'Circle'
    // },
    // {
    //     value: 'triangle',
    //     name: 'Triangle'
    // },
];

const actions = [
    {
        value: 'openLink',
        name: 'Open Link'
    },
    {
        value: 'openDownload',
        name: 'Download'
    },
    {
        value: 'openVideo',
        name: 'Open Video'
    },
    {
        value: 'skipTo',
        name: 'Skip To'
    },
    {
        value: 'skipToAndPause',
        name: 'Skip To and Pause'
    },
    {
        value: 'play',
        name: 'Play'
    },
    {
        value: 'pause',
        name: 'Pause'
    },
];

const disabledActions = {
    playeraction: [
        'openLink',
        'openDownload',
        'openVideo',
        'skipToAndPause',
        'play',
    ]
};

const areas = [
    {
        value: 'hotspot',
        name: 'Hot spot'
    },
    {
        value: 'image',
        name: 'Image'
    },
    {
        value: 'text',
        name: 'Text'
    },
    {
        value: 'playeraction',
        name: 'Player Action'
    },
    // not supported in MVP
    // {
    //     value: 'formField',
    //     name: 'Form field'
    // },
];

const positions = [
    {
        value: 'left',
        name: 'Left'
    },
    {
        value: 'top',
        name: 'Top'
    },
    {
        value: 'right',
        name: 'Right'
    },
    {
        value: 'bottom',
        name: 'Bottom'
    },
];

const formats = [
    {
        value: 'hotspot',
        name: 'Colors'
    },
    {
        value: 'image',
        name: 'Image'
    },
    {
        value: 'text',
        name: 'Text'
    }
];

const fontFamilies = [
    {
        value: 'arial, helvetica, sans-serif',
        name: 'Arial, Helvetica, sans-serif'
    },
    {
        value: 'verdana, helvetica, sans-serif',
        name: 'Verdana, Helvetica, sans-serif'
    },
    {
        value: '\'Times New Roman\', times, serif',
        name: 'Times New Roman, Times, serif'
    },
    {
        value: 'Georgia, times, serif',
        name: 'Georgia, Times, serif'
    },
    {
        value: '\'Courier New\', Courier, monospace',
        name: 'Courier New, Courier, monospace'
    },
    {
        value: '\'Lucida Console\', monospace',
        name: 'Lucida Console, monospace'
    },
];

const linkTargets = [
    {
        value: '_blank',
        name: 'New browser tab'
    },
    {
        value: '_top',
        name: 'Same window'
    }
];

const typeFieldRelations = {
    hotspot: [
        'start',
        'name',
        'end',
        'start-action',
        'type',
        'shape',
        'action',
        'border',
        'border-hover',
        'background-color',
        'background-color-hover',
    ],
    text: [
        'start',
        'name',
        'end',
        'start-action',
        'type',
        'action',
        'text-content',
        'font',
        'font-size',
        'font-color',
        'font-color-hover',
    ],
    image: [
        'start',
        'name',
        'end',
        'start-action',
        'type',
        'action',
        'background-image',
        'background-image-hover',
    ],
    playeraction: [
        'start',
        'name',
        'action',
    ]
};

const actionFieldRelations = {
    openLink: [
        'target',
        'link'
    ],
    openDownload: [
        'target',
        'link'
    ],
    openVideo: [
        'video-id',
        'auto-play',
        'mute',
        'start-at',
    ],
    skipTo: [
        'skip-to-time-index',
    ],
    skipToAndPause: [
        'skip-to-time-index',
    ],
    play: [],
    pause: [],
};

/* we use blacklist here as it would be a lot of repetitive code to specify all fields here */
const startActionDisabledFields = {
    pause: [
        'end'
    ]
};

export {
    startActions,
    areas,
    actions,
    disabledActions,
    positions,
    shapes,
    formats,
    fontFamilies,
    linkTargets,
    typeFieldRelations,
    actionFieldRelations,
    startActionDisabledFields,
};