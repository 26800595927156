<template>
    <div class="player-templates">
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-right">
                            <router-link class="add-new"
                                         :to="{name: 'addLibraryPlayerTemplate', params: {'libraryId': libraryId}}"
                                         style="text-decoration: none"
                            >
                                <v-btn color="accent"
                                       block
                                       class="header-button"
                                >
                                    Add player template
                                </v-btn>
                            </router-link>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(template, index) in playerTemplates"
                        :key="index"
                    >
                        <td class="text-no-wrap">
                            {{ template.name }}
                        </td>
                        <td class="text-right">
                            <v-btn icon
                                   class="ma-auto text-capitalize"
                                   @click="editPlayerTemplate(template)"
                            >
                                <v-icon dark
                                        class="edit"
                                >
                                    edit
                                </v-icon>
                            </v-btn>
                            <v-btn v-if="!template.is_default"
                                   icon
                                   class="ma-auto text-capitalize"
                                   @click="deletePlayerTemplate(template)"
                            >
                                <v-icon dark>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <delete-library-player-template v-model="deleteTemplateModal"
                                        :library-id="libraryId"
                                        :template="playerTemplateForDelete"
                                        @deleted="getPlayerTemplates"
        />
    </div>
</template>

<script>
    import {$http, playerTemplateApiUrl} from '@/services/http';
    import DeleteLibraryPlayerTemplate from '@/components/controls/modals/delete-library-player-template.vue';

    export default {
        name: 'LibraryPlayerTemplateList',
        components: {DeleteLibraryPlayerTemplate},
        props: {
            libraryId: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                playerTemplates: [],
                playerTemplateForDelete: {},
                deleteTemplateModal: false,
            };
        },
        methods: {
            editPlayerTemplate(template){
                this.$router.push({name: 'editLibraryPlayerTemplate', params: {libraryId: this.libraryId, templateId: template.id}});
            },
            async getPlayerTemplates() {
                this.playerTemplates = (await $http.get(playerTemplateApiUrl('/libraries/' + this.libraryId + '/player-templates'))).data;
            },
            deletePlayerTemplate(template){
                this.playerTemplateForDelete = template;
                this.deleteTemplateModal = true;
            },
        },
        created() {
            this.getPlayerTemplates();
        }
    };
</script>

<style scoped>
.add-new{
    display: inline-block;
    width: auto;
}
</style>