<template>
    <div v-if="!isLoading">
        <v-container
            fluid
            class="pb-0"
        >
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    xl="9"
                    lg="8"
                    md="7"
                >
                    <presentation-element-container />
                </v-col>
                <v-col
                    v-if="player"
                    cols="12"
                    sm="12"
                    xl="3"
                    lg="4"
                    md="5"
                    class="editor-sidebar"
                    :class="additionalClasses"
                >
                    <v-row class="sidebar-top">
                        <v-col
                            cols="12"
                            class="pt-2"
                        >
                            <h1 class="page-header mb-0">
                                <span v-if="video">{{ video.title }}</span>
                            </h1>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pt-0 d-flex"
                        >
                            <div class="interactive-editor-toolbar">
                                <v-menu>
                                    <template v-slot:activator="{on}">
                                        <v-btn
                                            small
                                            color="primary"
                                            class="mr-2 my-1 toolbar-button"
                                            v-on="on"
                                        >
                                            <v-icon
                                                small
                                                class="mr-1"
                                            >
                                                add
                                            </v-icon>
                                            Add
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item-group color="primary">
                                            <v-list-item @click="addImages">
                                                <v-list-item-title>
                                                    Image(s)
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>
                                <v-menu>
                                    <template v-slot:activator="{on}">
                                        <v-btn
                                            small
                                            class="mr-2 my-1 toolbar-button"
                                            color="primary"
                                            v-on="on"
                                        >
                                            <v-icon
                                                small
                                                class="mr-2"
                                            >
                                                settings
                                            </v-icon>
                                            Settings
                                        </v-btn>
                                    </template>
                                    <v-card elevation="2">
                                        <v-card-text>
                                            <presentation-view-settings
                                                :value="presentation.settings.position"
                                                @input="setPresentationView"
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                                <presentation-editor-expand />
                                <presentation-editor-reorder />
                            </div>
                            <div class="spacer" />
                            <div class="interactive-editor-settings">
                                <v-btn
                                    small
                                    color="primary"
                                    :href="landingPageUrl"
                                    target="_blank"
                                    class="my-1 toolbar-button"
                                >
                                    <v-icon
                                        small
                                        class="mr-2"
                                    >
                                        launch
                                    </v-icon>
                                    View
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="sidebar-content">
                        <v-col
                            cols="12"
                            class="px-lg-0 pt-0"
                        >
                            <presentation-sidebar :key="updateKey" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <input
            v-show="false"
            ref="fileInput"
            :key="uploadKey"
            multiple
            type="file"
            accept="image/*"
            @change="processSelectedImages"
        >
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import PresentationElementContainer from '@/components/controls/presentation/presentation-element-container';
    import PresentationSidebar from '@/components/controls/presentation/presentation-sidebar';
    import PresentationViewSettings from '@/components/controls/presentation/sidebar/presentation-view-settings';
    import PresentationEditorExpand from '@/components/controls/presentation/sidebar/presentation-editor-expand';
    import PresentationEditorReorder from '@/components/controls/presentation/sidebar/presentation-editor-reorder';

    export default {
        name: 'PresentationEditor',
        components: {
            PresentationEditorReorder,
            PresentationEditorExpand, PresentationViewSettings, PresentationSidebar, PresentationElementContainer},
        data() {
            return {
                updateKey: 0,
                uploadKey: 0,
            };
        },
        computed: {
            ...mapState('presentation', [
                'isLoading',
                'player',
                'presentation',
                'currentPlayerPosition',
                'video',
                'expandAll'
            ]),
            ...mapGetters('presentation', ['videoId']),
            additionalClasses() {
                const breakPoint = this.$vuetify.breakpoint.lgAndUp;
                return {
                    'app-sidebar': breakPoint,
                    'mt-n3': breakPoint,
                    'pb-0': breakPoint,
                };
            },
            landingPageUrl() {
                return location.protocol + '//' + location.host + '/view/' + encodeURI(this.video.id);
            }
        },
        methods: {
            ...mapActions('presentation', ['getPresentation', 'setPresentationView', 'upload']),
            addImages() {
                ++this.uploadKey;
                this.$nextTick(() => {
                    this.$refs.fileInput.click();
                });
            },
            processSelectedImages: async function (e) {
                let fileList = Object.values(e.target.files);
                // upload them one by one (many images would be too much to upload at once,
                // it may exceed the maximum size that can be received at once)
                const fileCount = fileList.length;
                const averageInterval = this.video.duration / 1000 / fileCount;
                for (let i = 0; i < fileList.length; i++) {
                    await this.upload({
                        file: fileList[i],
                        videoId: this.videoId,
                        start: Math.round((this.currentPlayerPosition + i * averageInterval) * 10) / 10,
                    }).finally(() => ++this.updateKey);
                }
            }
        },
        mounted() {
            this.getPresentation(this.$route.params.id).catch(e => {
                if (e.response.status && e.respons.status === 404) {
                    this.$router.push('404');
                }
            });
        }
    };
</script>

<style scoped lang="scss">
.app-sidebar {
  background-color: #fff;
  border-left: 1px solid #d9d9d9;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;

  .sidebar-top {
    flex: 0 0 auto;
  }

  .sidebar-content {
    flex: 1 1 0;
    overflow-y: auto;
  }

}

.interactive-editor-toolbar {
  display: flex;
}

.toolbar-button {
  vertical-align: top;
}

</style>