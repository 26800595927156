<template>
    <v-container
        fluid
        class="pt-0"
    >
        <v-row
            justify-center
            align-center
            no-gutters
        >
            <v-col>
                <div class="content">
                    <v-col class="pt-0">
                        <div class="sticky-nav pt-7">
                            <div class="d-flex ml-4 mr-4 header-container">
                                <h1 class="page-header">
                                    Libraries
                                </h1>
                                <v-text-field
                                    height="29"
                                    flat
                                    solo
                                    background-color="grey lighten-1"
                                    hide-details
                                    :append-icon="searchContent ? undefined : 'search'"
                                    label="Quick search"
                                    :value="searchContent"
                                    clearable
                                    class="quick-search"
                                    @input="setSearchContent"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="library-listing-container">
                                <v-row
                                    class="pt-0 pb-0"
                                    style="margin: 0;"
                                >
                                    <div class="col-md-12 no-gutters pt-0 pb-0 sticky-table-header">
                                        <div class="d-flex library-listing-header listing-header-container">
                                            <div
                                                v-for="header in headers"
                                                :key="'list-header-' + header.value"
                                                :class="header.classes"
                                            >
                                                <v-btn
                                                    text
                                                    block
                                                    class="header-button"
                                                    @click.stop="() => {}"
                                                >
                                                    {{ header.text }}
                                                    <template>
                                                        <v-btn
                                                            icon
                                                            class="sort-button ml-2"
                                                            :class="{'is-sorted': isSortedBy(header.value)}"
                                                            small
                                                            :color="isSortedBy(header.value) && sorting.direction === 'asc' ? 'black' : ''"
                                                            @click="applyHeaderSort(header.value, 'asc')"
                                                        >
                                                            <v-icon
                                                                dark
                                                                small
                                                            >
                                                                mdi-arrow-up
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            icon
                                                            small
                                                            class="sort-button ml-2"
                                                            :class="{'is-sorted': isSortedBy(header.value)}"
                                                            :color="isSortedBy(header.value) && sorting.direction === 'desc' ? 'black' : ''"
                                                            @click.stop="applyHeaderSort(header.value, 'desc')"
                                                        >
                                                            <v-icon
                                                                dark
                                                                small
                                                            >
                                                                mdi-arrow-down
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-btn>
                                            </div>
                                            <div class="button-column pt-2 pb-2 pl-4 pr-4">
                                                <router-link
                                                    :to="{name: 'addLibrary'}"
                                                    style="text-decoration: none"
                                                >
                                                    <v-btn
                                                        color="accent"
                                                        block
                                                        class="header-button"
                                                    >
                                                        Add library
                                                    </v-btn>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <v-col
                                        v-for="library in items"
                                        :key="'library-listing-' + library.id"
                                        cols="12"
                                        class="library-listing-item pb-0 pt-0"
                                    >
                                        <v-card class="library-item">
                                            <div class="library-company-column pt-2 pb-2 pl-4 pr-4">
                                                <span> {{ getLibraryCompanyName(library) }}</span>
                                            </div>
                                            <div class="flex-grow-1 library-name-column pt-2 pb-2 pl-4 pr-4">
                                                {{ library.name }}
                                            </div>
                                            <div class="button-column pt-2 pb-2 pl-4 pr-4 text-right">
                                                <v-btn
                                                    icon
                                                    class="ma-auto text-capitalize"
                                                    @click="editLibrary(library.id)"
                                                >
                                                    <v-icon
                                                        dark
                                                        class="edit"
                                                    >
                                                        edit
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    icon
                                                    class="ma-auto text-capitalize"
                                                    @click="deleteLibrary(library.id)"
                                                >
                                                    <v-icon dark>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <no-search-results v-if="noQuickSearchResult" />
                                </v-row>
                                <v-row class="col-md-12 no-gutters pt-0 pb-0">
                                    <x-paginator :value="pagination"
                                                 @input="setPagination"
                                    />
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </div>
            </v-col>
            <delete-library
                v-model="deleteModal"
                :library="libraryForDelete"
                @confirm="confirmDelete"
            />
        </v-row>
    </v-container>
</template>

<script>
    import {debounce} from 'lodash';
    import XPaginator from '../controls/paginator';
    import {$http, videoApiUrl} from '../../services/http';
    import qs from 'qs';
    import DeleteLibrary from '../controls/modals/delete-library';
    import NoSearchResults from '../controls/no-search-result';
    import {toastSuccess} from '../../services/responseErrors';
    import {mapGetters, mapState, mapMutations} from 'vuex';

    export default {
        name: 'Libraries',
        components: {
            DeleteLibrary,
            XPaginator,
            NoSearchResults
        },
        data() {
            return {
                headers: [
                    {
                        text: 'Company',
                        value: 'company',
                        classes: ['library-company-column', 'pa-0', 'pt-2', 'pb-2']
                    },
                    {
                        text: 'Library name',
                        value: 'name',
                        classes: ['flex-grow-1', 'library-name-column', 'pa-0', 'pt-2', 'pb-2']
                    },
                ],
                items: [],
                libraryForDelete: {
                    name: ''
                },
                deleteModal: false,
                noQuickSearchResult: false,
            };
        },
        computed: {
            ...mapGetters('libraries', ['getLibraryCompanyName']),
            ...mapState('library-store', ['searchContent', 'sorting', 'pagination']),
        },
        watch: {
            'pagination.currentPage': {
                handler(value, oldValue) {
                    if (value === oldValue) {
                        return;
                    }
                    this.items = [];
                    this.getPage();
                },
                deep: true,
            }
        },
        methods: {
            ...mapMutations('library-store', ['setPagination', 'setSearch', 'setSorting']),
            isSortedBy(column) {
                return column === this.sorting.column;
            },
            getPage() {
                $http.get(videoApiUrl('api/libraries'), {
                    params: this.createRequest(),
                    paramsSerializer: params => decodeURIComponent(qs.stringify(params))
                }).then(response => {
                    const {data, total} = response.data;
                    this.setPagination({total: total});
                    this.items = [...data];
                    this.$vuetify.goTo(0);

                    this.noQuickSearchResult = false;
                    if( this.items.length < 1 ) {
                        this.noQuickSearchResult = true;
                    }
                });
            },
            createRequest() {
                const request = {
                    sorting: {...this.sorting},
                    pagination: {
                        pageSize: this.pagination.itemPerPage,
                        current: this.pagination.currentPage
                    }
                };

                request.filters = {
                    quickSearch: this.searchContent
                };

                return request;

            },
            editLibrary(libraryID) {
                this.$router.push({name: 'editLibrary', params: {'id': libraryID}});
            },
            deleteLibrary(libraryID) {
                this.libraryForDelete = this.items.filter(item => item.id === libraryID)[0];
                this.deleteModal = true;
            },
            confirmDelete(library) {
                $http.delete(videoApiUrl('api/libraries/' + library.id)).then(() => {
                    toastSuccess('Library deleted.');
                    this.setPagination({ currentPage: 1 });
                    this.getPage();
                });
            },
            applyHeaderSort(column, direction) {
                this.setSorting({
                    direction: direction,
                    column: column
                });
                this.setPagination({ currentPage: 1 });
                this.getPage();
            },
            setSearchContent: debounce(function (value) {
                this.setSearch(value);
                this.resetList();
            }, 300),

            resetList() {
                this.setPagination({ currentPage: 1 });
                this.items = [];
                this.getPage();
            },
        },
        mounted() {
            this.getPage();
        }
    };
</script>

<style scoped>
    .page-header {
        flex: 0 0 310px;
    }

    .quick-search {
        flex: 0 0 200px;
        margin-right: 12px;
    }

    .library-name-column {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 767px) {
        .page-header, .quick-search {
            flex: 1 1 50%;
        }
    }

    @media screen and (max-width: 500px) {
        .header-container {
            flex-wrap: wrap;
            margin-bottom: 10px;
        }

        .library-listing-header {
            flex-wrap: wrap;
        }

        .page-header, .quick-search {
            flex: 0 0 100%;
        }

        .library-item {
            flex-wrap: wrap;
            height: auto !important;
        }
    }

    .sticky-nav {
        overflow: hidden;
        position: sticky;
        position: -webkit-sticky;
        top: 64px;
        z-index: 999;
        background-color: #F5F5F5;
    }

    .sticky-table-header {
        position: sticky;
        top: 136px;
        z-index: 5;
        background-color: #F5F5F5;
    }
</style>
