<template>
    <v-row no-gutters>
        <v-col
            cols="12"
            xl="1"
            lg="2"
            md="2"
            sm="4"
        >
            <v-img
                src="/images/vimeo_logo_blue.png"
                max-width="131"
                class="mr-4 mt-sm-2 mt-0"
            />
        </v-col>
        <v-col
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="4"
        >
            <v-text-field
                v-model="accountName"
                label="Account name"
                :readonly="hasAccount"
            />
        </v-col>
        <v-col
            cols="12"
            xl="1"
            lg="2"
            md="2"
            sm="2"
        >
            <v-btn
                v-if="!account"
                :disabled="!accountName"
                color="primary"
                class="ml-sm-4 mt-sm-3 mt-0"
                @click="grantAccess()"
            >
                Grant Access
            </v-btn>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <v-btn
                        v-if="!!account"
                        text
                        icon
                        class="ml-2 mt-sm-3"
                        color="error"
                        @click="askRemoveAccess()"
                        v-on="on"
                    >
                        <v-icon>clear</v-icon>
                    </v-btn>
                </template>
                <span>Remove</span>
            </v-tooltip>
        </v-col>
        <confirm-modal
            v-model="deleteModal"
            title="Remove Vimeo access token"
            cancel-button="Cancel"
            confirm-button="Remove"
            @confirm="removeAccess"
        >
            Would you like to remove the Vimeo access token?<br>
            This company will no longer be able to publish to Vimeo.
        </confirm-modal>
    </v-row>
</template>

<script>
    import { getVimeoAuthorizationCode } from '@/services/vimeo';
    import { removePublishingAccount } from '@/services/publishing';
    import ConfirmModal from '../modals/confirm-modal';

    export default {
        name: 'VimeoAccount',
        components: {ConfirmModal},
        props: ['company', 'accounts'],
        data() {
            let account = this.getAccount();
            return {
                deleteModal: false,
                accountName: account?.name || '',
                account: account
            };
        },
        computed: {
            hasAccount() {
                return !!this.account;
            }
        },
        methods:{
            getAccount() {
                return this.accounts.find(acc => acc.meta.accountType === 'vimeo');
            },
            resetAccount() {
                this.account = null;
                this.accountName = '';
            },
            grantAccess() {
                getVimeoAuthorizationCode(this.company.id, this.accountName);
            },
            askRemoveAccess() {
                this.deleteModal = true;
            },
            removeAccess() {
                removePublishingAccount(this.account.id).then(() => {
                    this.resetAccount();
                });
            }
        },
    };
</script>

<style scoped>
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).status-button {
        background-color: transparent !important;
        color: #000000 !important;
    }
</style>