<template>
    <generic-modal
        :title="`We were unable to upload ${fileCase}`"
        :value="value"
        @cancel="close"
    >
        <template v-slot:default>
            <v-list>
                <v-list-item
                    v-for="file in files"
                    :key="file.id"
                    three-line
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ file.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <span class="error--text font-italic">{{ getErrorMessage(file) }}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
        <template v-slot:actions>
            <v-btn @click="close">
                Close
            </v-btn>
        </template>
    </generic-modal>
</template>

<script>
    import GenericModal from '@/components/controls/modals/generic-modal';
    import getFileError from '@/plugins/chunk-upload/utils/uploadFileErrors';
    import {mapMutations} from 'vuex';
    export default {
        name: 'UploadFilesWithErrors',
        components: {GenericModal},
        props: {
            files: {
                type: Array,
                default: () => []
            },
            value: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            fileCase() {
                return this.files.length > 1 ? 'some files' : 'a file';
            }
        },
        methods: {
            ...mapMutations('layout', ['clearUploadErrors']),
            close() {
                this.clearUploadErrors();
                this.$emit('input', false);
            },
            getErrorMessage(file) {
                return getFileError(file);
            }
        }
    };
</script>

<style scoped>

</style>