<template>
    <v-btn
        text
        block
        class="header-button"
        @click.stop="() => {}"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        {{ name }}
        <component
            :is="filterComponent"
            v-if="filterComponent && value"
            :column-id="id"
            :value="value.filters"
            @input="headerFilterChange"
        />
        <template v-if="sortable">
            <v-btn
                icon
                small
                class="sort-button"
                :color="descColor"
                :class="sortedClass"
                @click="applyHeaderSort('desc')"
            >
                <v-icon
                    dark
                    small
                >
                    mdi-arrow-up
                </v-icon>
            </v-btn>

            <v-btn
                icon
                small
                class="sort-button"
                :color="ascColor"
                :class="sortedClass"
                @click="applyHeaderSort('asc')"
            >
                <v-icon
                    dark
                    small
                >
                    mdi-arrow-down
                </v-icon>
            </v-btn>
        </template>
    </v-btn>
</template>

<script>
    import ListHeadFilter from './list-head-filter';
    import ListHeadFilterPopup from './list-head-filter-popup';
    import PrivacyFilter from '@/components/controls/filters/media-library/privacy-filter';
    import LibraryFilter from '@/components/controls/filters/media-library/library-filter';

    export default {
        name: 'ListHeadItem',
        components: {ListHeadFilterPopup, ListHeadFilter, PrivacyFilter, LibraryFilter},
        props: ['value', 'name', 'id', 'sortable', 'filterComponent'],
        data() {
            return {
                hover: false
            };
        },
        computed: {
            isSortedBy() {
                return !!this.value && this.value.sorting.targetId === this.id;
            },
            isFilteredBy(){
                return !!this.value && this.value.filters[this.id] && this.value.filters[this.id].length;
            },
            descColor() {
                return this.colorBySortDirection('desc');
            },
            ascColor() {
                return this.colorBySortDirection('asc');
            },
            sortedClass() {
                return {
                    'is-sorted': this.isSortedBy
                };
            }
        },
        methods: {
            applyHeaderSort(direction) {
                this.$emit('input', {...this.value, sorting: {targetId: this.id, direction: direction}});
            },
            headerFilterChange(filters) {
                // the filter component input should always return the complete filters object
                // with the modified header filter in it
                // see media-library-library filter.vue or media-library-privacy-filter.vue for example
                this.$emit('input', {...this.value, filters});
            },
            colorBySortDirection(direction) {
                return this.isSortedBy && this.value.sorting.direction === direction ? 'black' : '';
            }
        }
    };
</script>

<style scoped>
    button.v-icon {
        font-size: 18px !important;
    }
</style>
