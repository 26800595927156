<template>
    <div />
</template>

<script>
    import {getVimeoToken} from '../../../services/vimeo';

    export default {
        name: 'VimeoAuthorize',
        mounted() {
            const {state, code} = this.$route.query;
            getVimeoToken(state, code).then(entityId => {
                this.$router.push({name: 'editCompany', params: {id: entityId}});
            });
        }
    };
</script>

<style scoped>

</style>