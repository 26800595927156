export const hexToRGBA = function (hex) {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    let a = 1;

    if (hex.length > 7) {
        let alpha = parseInt(hex.slice(7, 9), 16);
        a = (alpha * 100 / 255) / 100;
    }

    return `rgba(${r},${g},${b},${a})`;
};

export const rgbaObjectToHex = function(rgbaObject) {
    let result = '#' + rgbaObject.r.toString(16).padStart(2, '0')
        + rgbaObject.g.toString(16).padStart(2, '0')
        + rgbaObject.b.toString(16).padStart(2, '0');
    let alpha = Math.round(rgbaObject.a * 255);
    if (alpha < 255) {
        result = result + alpha.toString(16).padStart(2, '0');
    }

    return result;
};

export const colorToRgbaObject = function(color) {
    let c = color;

    if (!c) {
        c = 'rgba(0,0,0,0)';
    }

    if (!c.includes('rgba') && !c.includes('#')) {
        c = '#' + c;
    }

    if (c.includes('#')) {
        c = hexToRGBA(c);
    }

    const values = c.replace(/r|g|b|a|\(|\)|\s/gi, '').split(',').map(item => parseFloat(item));
    const colorValues = {
        r: 0,
        g: 0,
        b: 0,
        a: 1
    };

    if (values.length >= 1 && !isNaN(values[0])) {
        colorValues.r = values[0];
    }

    if (values.length >= 2 && !isNaN(values[1])) {
        colorValues.g = values[1];
    }

    if (values.length >= 3 && !isNaN(values[2])) {
        colorValues.b = values[2];
    }

    // the parameter may be rgb(1,2,3)
    if (values.length > 3 && !isNaN(values[3])) {
        colorValues.a = values[3];
    }

    return colorValues;
};