<template>
    <div class="d-flex justify-end">
        <v-btn 
            v-if="!deleted"
            color="primary"
            class="mt-8"
            :loading="loading"
            :disabled="actionDisabled"
            @click="deleteFiles"
        >
            <span class="mr-3">Start action</span>
            <v-icon>mdi-send</v-icon>
        </v-btn>
        <div v-else
             class="d-flex align-center"
        >
            <h4 class="mr-3">
                Files deleted
            </h4>
            <v-icon color="primary">
                mdi-check-circle
            </v-icon>
        </div>
        <confirm-modal v-model="deleteModal"
                       cancel-button="No, I've changed my mind"
                       confirm-button="Yes, delete them"
                       title="This will delete the selected files from the FTP folder"
                       @confirm="confirmDelete"
                       @cancel="closeModal"
        >
            This action will permanently delete the selected files from the FTP folder. <br>
            Uploads in progress to libraries will result in incomplete or corrupted videos. <br><br>
            Are you sure?
        </confirm-modal>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    import { $http, ftpApiUrl } from '@/services/http';
    import ConfirmModal from '@/components/controls/modals/confirm-modal';

    export default {
        name:'FtpActionsDelete',
        components: {ConfirmModal},
        data(){
            return {
                deleteModal: false,
                deleted: false,
                loading: false,
            };
        },
        computed:{
            ...mapState('ftp', ['selectedFiles']),
            actionDisabled(){
                return this.selectedFiles.length === 0;
            }
        },
        methods:{
            ...mapActions('ftp', ['getFiles', 'sortFiles']),
            ...mapMutations('ftp', ['setSelectedFiles']),
            deleteFiles() {
                this.deleteModal = true;
            },
            async confirmDelete(){
                this.loading = true;
                await $http.delete(ftpApiUrl('/ftp'),{
                    data: {
                        paths: this.selectedFiles.map(file => file.path)
                    }
                });
                this.deleted = true;
                this.setSelectedFiles([]);
                await this.getFiles();
                this.sortFiles();
                this.loading = false;
            },
            closeModal() {
                this.deleteModal = false;
            }
        },
        watch:{
            selectedFiles(value){
                if(value.length > 0){
                    this.deleted = false;
                }
            }
        }
    };
</script>