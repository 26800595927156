<template>
    <v-row>        
        <v-col cols="12">
            <v-expansion-panels accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header>Whitelist domains</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>
                            Videos of this library will be only available on the specified
                            domains.
                        </p>
                        <v-alert
                            text
                            type="info"
                            elevation="0"
                        >
                            One domain per line. Example:
                            <div>
                                <i>platform.quadia.com</i>
                                <br>
                                <i>quadia.live</i>
                            </div>
                        </v-alert>
                        <v-textarea
                            v-model="input"
                            dense
                            label="Available on domains"
                            prepend-icon="mdi-text"
                            placeholder="
platform.quadia.com
quadia.live
"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'DomainWhitelistEditor',
        props: {
            value: {
                typeof: String,
                required: true,
            },
        },
        data() {
            return {
                input: this.value,
            };
        },
        watch: {
            input(val) {
                this.$emit('input', val);
            },
        },
    };
</script>