import { render, staticRenderFns } from "./privacy-filter.vue?vue&type=template&id=5a48cd16&scoped=true&"
import script from "./privacy-filter.vue?vue&type=script&lang=js&"
export * from "./privacy-filter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a48cd16",
  null
  
)

export default component.exports