<template>
    <div>
        <script
            type="application/javascript"
            :src="playerSrc"
            :data-video-id="videoId"
            :data-id="id"
            data-no-tracking="true"
            data-show-title="false"
            data-disable-interactive="true"
            data-controls="false"
            data-no-custom-css="true"
        />
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';

    export default {
        name: 'CommentPlayer',
        data() {
            return {
                playerSrc: process.env.VUE_APP_PLAYER_SRC,
                id: 'comments-' + this.videoId + '-' + Date.now(),
                playerInterval: null,
            };
        },
        computed: {
            ...mapGetters('comments', ['videoId']),
            ...mapState('comments', ['video']),
            ...mapGetters('auth', ['isLoggedIn']),
        },
        methods: {
            ...mapMutations('comments', ['setPlayer', 'setVideo']),
            registerPlayerIntoStore() {
                this.playerInterval = setInterval(() => {
                    try {
                        let player = window.QuadiaPlayer.players[this.id];
                        player.$on('playerReady', (ready) => {
                            if (ready) {
                                const p = window.QuadiaPlayer.players[this.id];
                                this.setPlayer(p);
                                if (!this.isLoggedIn) {
                                    p.$emit('seek', 0);
                                    setTimeout(() => {
                                        p.get('duration').then(d => {
                                            this.setVideo({
                                                id: this.videoId,
                                                duration: d * 1000,
                                            });
                                            p.get('meta').then(d => {
                                                this.setVideo({
                                                    ...this.video, title: d.title
                                                });
                                            });
                                        });
                                    }, 1000);
                                }
                            }
                        });
                        clearInterval(this.playerInterval);
                    } catch {
                        // It is ok to have no custom config.
                    }
                }, 10);
            },
        },
        mounted() {
            this.registerPlayerIntoStore();
        },
        beforeDestroy() {
            clearInterval(this.playerInterval);
        }
    };
</script>

<style scoped>

</style>