<template>
    <v-sheet class="move-multiple-videos">
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn small
                       color="primary"
                       v-on="on"
                       @click="toggleLibraryList"
                >
                    <v-icon small
                            class="mr-2"
                    >
                        input
                    </v-icon>
                    Move selected videos to another library
                </v-btn>
            </template>
            <span>Move videos to library</span>
        </v-tooltip>
        <v-dialog v-if="isActive"
                  :value="true"
                  title=""
                  persistent
                  width="700"
                  scrollable
        >
            <v-card>
                <v-card-title class="d-flex">
                    Move videos to library
                    <v-spacer />
                    <v-btn fab
                           icon
                           small
                           class="dialog-close-btn mr-1 mt-1"
                           color="primary"
                           @click="cancel"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-subtitle v-if="isOnLibrarySelectionStep">
                    <p class="primary--text">
                        <span class="warning--text">
                            <v-icon class="mr-2"
                                    color="warning"
                            >
                                warning
                            </v-icon>
                            Warning! All the statistics will be kept if you move the video to another library, but are only visible from the new library.
                        </span>
                    </p>
                    <p class="comment">
                        Click on the library you'd like to move the selected videos to:
                    </p>
                    <v-text-field v-if="targetLibraries.length > 5"
                                  v-model="searchText"
                                  hide-details
                                  clearable
                                  placeholder="Search libraries"
                    />
                </v-card-subtitle>
                <v-card-text>
                    <div v-if="isOnLibrarySelectionStep">
                        <v-sheet v-if="!librariesLoading">
                            <v-list dense
                                    class="library-container"
                            >
                                <v-list-item v-for="library in searchedLibraries"
                                             :key="`library-move-copy-${library.id}`"
                                             dense
                                             @click="moveToLibraries(library)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-subtitle v-if="library.company">
                                            {{ library.company.company_name }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>{{ library.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-sheet>
                        <v-progress-linear v-else
                                           indeterminate
                                           color="primary"
                        />
                    </div>
                    <v-sheet v-if="isExecuting">
                        <v-progress-linear color="primary"
                                           :value="currentProgress"
                        />
                        <v-list dense>
                            <v-list-item v-for="(item, index) in movedMessages"
                                         :key="index + item"
                            >
                                <v-list-item-title>
                                    {{ item }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-sheet>
                </v-card-text>
                <v-card-actions v-if="isExecuting"
                                class="px-6 pb-4"
                >
                    <v-spacer />
                    <v-btn :disabled="!buttonEnabled"
                           color="primary"
                           @click="cancel"
                    >
                        Done
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>
<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import {$http, videoApiUrl} from '@/services/http';

    const STEP_NONE = 0;
    const STEP_SELECT_LIBRARY = 1;
    const STEP_EXECUTE = 2;
    export default {
        name: 'MoveMultipleVideos',
        props: {
            libraries: {
                type: Array,
                required: true,

            },
            videos: {
                type: Array,
                required:  true,
            },
        },
        data () {
            return {
                step: STEP_NONE,
                movedMessages: [],
                progress: 0,
                searchText: '',
                buttonEnabled: false,
            };
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('libraries', {librariesLoading: 'loading'}),
            ...mapGetters('libraries', ['allowedLibrariesBy']),
            targetLibraries() {
                const videoLibraries = this.videos.map(v => v.library);
                return this.allowedLibrariesBy(this.user.permissions['copy-to-library'])
                    .filter(library => !videoLibraries.includes(library.id));
            },
            searchedLibraries() {
                if (!this.searchText) {
                    return this.targetLibraries;
                }
                const lowerText = this.searchText.toLowerCase();

                return this.targetLibraries.filter(l => l.name.toLowerCase().indexOf(lowerText) > -1
                    || l.company && l.company.company_name.toLowerCase().indexOf(lowerText) > -1);
            },
            videosCount() {
                return this.videos.length;
            },
            isActive() {
                return this.step > STEP_NONE;
            },
            isOnLibrarySelectionStep()
            {
                return this.step === STEP_SELECT_LIBRARY;
            },
            isExecuting() {
                return this.step === STEP_EXECUTE;
            },
            currentProgress() {
                return Math.round((this.progress / this.videosCount) * 100);
            },
        },
        watch: {
            progress (value) {
                if (value === this.videosCount) {
                    setTimeout(() => {
                        this.buttonEnabled = true;
                    }, 400);
                } 
            }  
        },
        methods: {
            ...mapActions('libraries', ['getLibraries']),
            toggleLibraryList() {
                if (this.step === STEP_SELECT_LIBRARY) {
                    this.step = STEP_NONE;
                    return;
                }

                this.step = STEP_SELECT_LIBRARY;
                this.getLibraries();
            },
            cancel() {
                this.step = STEP_NONE;
            },
            async moveToLibrary(video, library) {
                return $http.post(videoApiUrl(`api/videos/${video.id}/move`), {
                    library: library.id
                }).then(() => {
                    this.movedMessages = [
                        ...this.movedMessages,
                        `Video ${video.title} (${video.id}) moved to ${library.company.company_name}/${library.name}`
                    ];
                    this.$emit('moved', video.id, library.id);
                }).catch(e => {
                    this.movedMessages = [
                        ...this.movedMessages,
                        e.message,
                    ];
                });
            },
            async moveToLibraries(library) {
                this.step = STEP_EXECUTE;
                this.movedMessages = [];
                this.progress = 0;
                this.buttonEnabled = false;
                for (let x in this.videos) {
                    if (this.videos[x].library === library.id) {
                        this.movedMessages = [
                            ...this.movedMessages,
                            `Skipped video ${this.videos[x].id}. Source and target library is the same.`
                        ];
                        this.progress++;
                        continue;
                    }

                    await this.moveToLibrary(this.videos[x], library);
                    this.progress++;
                }
            }
        }
    };
</script>

<style scoped>

</style>