<template>
    <stats-card
        class=" metric geo-chart-card"
        :loading="loading"
    >
        <template v-slot:title>
            Views per country
        </template>
        <template v-slot:content>
            <GChart v-if="!loading"
                    :type="type"
                    :data="chartData"
                    :settings="settings"
                    :options="options"
            />
        </template>
    </stats-card>
</template>

<script>
    import { $http, getStatQueryRequest } from '@/services/http';
    import { GChart } from 'vue-google-charts/legacy';
    import StatsCard from './stats-card';    
    import { mapState } from 'vuex';

    export default {
        name: 'CardCountriesGeoChart',
        components: {
            GChart,
            StatsCard
        },
        data() {
            return {
                loading: true,
                type: 'GeoChart',
                chartData: [['Country', 'Popularity']],
                settings: {
                    packages: ['geochart'],
                },
                options: {
                    colorAxis: {colors: ['#7aa4cb', '#0174dd']}
                }
            };
        },
        computed: {
            ...mapState('statistics-details', [
                'dateRange',
                'videoId',
            ])
        },
        methods: {
            async getCountries() {
                this.loading = true;
                this.countries = [];
                const response = await $http.get(getStatQueryRequest('api/videos/views-by-country/', [this.videoId], this.dateRange));
                if (response.data.length > 0) {
                    response.data.forEach(country => {
                        this.chartData.push([country.key, country.doc_count]);
                    });
                }      
                this.loading = false;
            },
        },
        mounted() {
            this.getCountries();
        },
    };
</script>

<style lang="scss">
.geo-chart-card{
    .v-card__text {
        min-height: 100px;
        height: 100%;
    }
}
</style>