<template>
    <tr
        v-if="video"
        class="editable"
    >
        <td>People in video:</td>
        <td>
            <div class="editable-meta no-flex">
                <metadata-field-editor
                    :key="'people-' + video.id + video.metadata.people.join('')"
                    v-model="video.metadata.people"
                    type="tags"
                    name="people"
                    subtype="tags"
                    :meta="{counter: 5000, readOnly: !canEdit}"
                    :open="true"
                    :keep-open="true"
                    @input="videoPeopleUpdated"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import updateMeta from '../../../../services/metadata';
    import MetadataFieldEditor from '../editor/MetadataFieldEditor';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';

    export default {
        name: 'MetaPeopleInVideoField',
        components: {MetadataFieldEditor},
        props: ['video'],
        computed: {
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            videoPeopleUpdated(value) {
                updateMeta(this.video.id, {
                    'people': value
                });
            },
        },
    };
</script>

<style scoped>

</style>
