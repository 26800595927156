<template>
    <div>
        <v-menu
            close-on-click
            close-on-content-click
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    :class="{primary: hasActiveElement, white:!hasActiveElement}"
                    fab
                    elevation="3"
                    x-small
                    v-on="on"
                >
                    {{ elements.length }}
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item
                    v-for="(element,index) in elements"
                    :key="index"
                    :class="{primary: isActiveElement(element)}"
                    @click.stop="setActiveElement(element)"
                >
                    <v-list-item-title>{{ element.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'InteractiveExternalScrubPlotGroup',
        props: ['elements'],
        computed: {
            ...mapGetters('interactive', ['activeElement']),
            hasActiveElement() {
                let active = false;
                this.elements.forEach(element => {
                    active = active || this.isActiveElement(element);
                });
                return active;
            }
        },
        methods: {
            ...mapMutations('interactive', ['setActiveElement']),
            isActiveElement(element) {
                return element === this.activeElement;
            }
        }
    };
</script>

<style scoped>
    .v-btn.white {
        border: 2px solid gray !important;
    }
</style>
