<template>
    <div
        v-if="isActive"
        class="interactive-item-placeholder"
        :style="style"
        @click="setActiveElement(element)"
    >
        <component
            :is="getComponent(element)"
            :element="element"
        />
    </div>
</template>

<script>
    import InteractiveHotspot from './areas/interactive-hotspot';
    import InteractiveImage from './areas/interactive-image';
    import InteractiveText from './areas/interactive-text';
    import InteractiveInput from './areas/interactive-input';
    import InteractivePlayeraction from './areas/interactive-playeraction';
    import { mapGetters, mapMutations, mapState } from 'vuex';

    export default {
        name: 'InteractiveItemPlaceholder',
        components: {
            InteractiveHotspot,
            InteractiveImage,
            InteractiveText,
            InteractiveInput,
            InteractivePlayeraction,
        },
        props: ['element'],
        computed: {
            ...mapGetters('interactive', ['activeElement', 'elements', 'getComponent', 'isActiveElement']),
            ...mapState('interactive', ['player', 'currentPlayerPosition']),
            isActive() {
                var val = (
                    !this.isActiveElement(this.element) &&
                    this.element.start <= this.currentPlayerPosition &&
                    this.currentPlayerPosition <= this.element.end
                );

                if(val && this.element.startAction === 'pause') {
                    //vue's computed caching solves our single pause requirements
                    this.player.$emit('pause');
                }            

                return val;
            },
            style() {
                if (this.element.area.type === 'playeraction')
                {
                    return {};
                }

                return {
                    left: this.element.style.origin.left + '%',
                    top: this.element.style.origin.top + '%',
                    width: this.element.style.dimensions.width + '%',
                    height: this.element.style.dimensions.height + '%',
                };
            },
        },
        methods: {
            ...mapMutations('interactive', ['setActiveElement'])
        },
    };
</script>

<style scoped lang="scss">
.interactive-item-placeholder {
    position: absolute;
    border: 1px dashed rgba(255, 255, 255, 1);
    cursor: pointer;
}
</style>
