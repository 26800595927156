<template>
    <div v-if="!isLoading">
        <v-container
            fluid
            class="pb-0"
        >
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    xl="9"
                    lg="8"
                    md="7"
                >
                    <interactive-element-container />
                </v-col>
                <v-col
                    v-if="player"
                    cols="12"
                    sm="12"
                    xl="3"
                    lg="4"
                    md="5"
                    class="editor-sidebar"
                    :class="additionalClasses"
                >
                    <v-row class="sidebar-top">
                        <v-col cols="12 pt-2">
                            <h1 class="page-header mb-0">
                                <span v-if="video">{{ video.title }}</span>
                            </h1>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 d-flex"
                        >
                            <div class="interactive-editor-toolbar">
                                <v-menu>
                                    <template v-slot:activator="{on}">
                                        <v-btn
                                            small
                                            color="primary"
                                            v-on="on"
                                        >
                                            <v-icon
                                                small
                                                class="mr-1"
                                            >
                                                add
                                            </v-icon>
                                            Add
                                        </v-btn>
                                        <v-tooltip top>
                                            <template v-slot:activator="{on}">
                                                <v-btn
                                                    small
                                                    icon
                                                    class="ml-2"
                                                    :disabled="!canRedo"
                                                    v-on="on"
                                                    @click="redo"
                                                >
                                                    <v-icon small>
                                                        redo
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Redo</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{on}">
                                                <v-btn
                                                    small
                                                    icon
                                                    class="ml-2"
                                                    :disabled="!canUndo"
                                                    v-on="on"
                                                    @click="undo"
                                                >
                                                    <v-icon small>
                                                        undo
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Undo</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list dense>
                                        <v-list-item-group color="primary">
                                            <v-list-item @click="addHotspot">
                                                <v-list-item-title>
                                                    Hotspot
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="addImage">
                                                <v-list-item-title>
                                                    Image
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="addText">
                                                <v-list-item-title>
                                                    Text
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-divider class="my-2" />
                                            <v-list-item @click="addPlayerAction">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        Player action
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>
                            </div>
                            <div class="spacer" />
                            <div class="interactive-editor-settings">
                                <!--                                <v-btn small disabled class="mr-2">-->
                                <!--                                    <v-icon small class="mr-2">settings</v-icon>-->
                                <!--                                    Settings-->
                                <!--                                </v-btn>-->
                                <v-btn
                                    small
                                    color="primary"
                                    :href="landingPageUrl"
                                    target="_blank"
                                >
                                    <v-icon
                                        small
                                        class="mr-2"
                                    >
                                        launch
                                    </v-icon>
                                    View
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="sidebar-content">
                        <v-col
                            cols="12"
                            class="px-lg-0"
                        >
                            <interactive-sidebar :key="updateKey" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';
    import InteractiveElementContainer from '../controls/interactive/interactive-element-container';
    import InteractiveSidebar from '../controls/interactive/interactive-sidebar';
    import TextFactory from '../../models/interactive/factories/text-factory';
    import ImageFactory from '../../models/interactive/factories/image-factory';
    import HotspotFactory from '../../models/interactive/factories/hotspot-factory';
    import PlayeractionFactory from '../../models/interactive/factories/playeraction-factory';

    export default {
        name: 'InteractiveEditor',
        data() {
            return {
                updateKey: 0
            };
        },
        components: {
            InteractiveSidebar,
            InteractiveElementContainer
        },
        computed: {
            ...mapState('interactive', ['video', 'player']),
            ...mapGetters('interactive', ['isLoading', 'canUndo', 'canRedo']),
            additionalClasses() {
                const breakPoint = this.$vuetify.breakpoint.lgAndUp;
                return {
                    'app-sidebar': breakPoint,
                    'mt-n3': breakPoint,
                    'pb-0': breakPoint,
                };
            },
            landingPageUrl() {
                return location.protocol + '//' + location.host + '/view/' + encodeURI(this.video.id);
            }
        },
        methods: {
            undo() {
                this.$store.dispatch('interactive/undo').then(() => {
                    ++this.updateKey;
                });
            },
            redo() {
                this.$store.dispatch('interactive/redo').then(() => {
                    ++this.updateKey;
                });
            },
            addHotspot() {
                this.addElement(HotspotFactory, 'New Hot Spot');
            },
            addImage() {
                this.addElement(ImageFactory, 'New Image');
            },
            addText() {
                this.addElement(TextFactory, 'New text');
            },
            addPlayerAction() {
                this.addElement(PlayeractionFactory, 'New Player Action');
            },          addElement(factory, name) {
                this.player.get('time').then(start => {
                    this.$store.dispatch('interactive/storeElement', factory.make({name, start}));
                });
            }
        },
        created() {
            this.$store.dispatch('interactive/clearUndoRedo');
            const videoId = this.$route.params.id;
            this.$store.dispatch('interactive/getElements', videoId).catch(e => {
                if (e.response.status && e.response.status === 404) {
                    this.$router.push('404');
                }
            });
        }
    };
</script>
<style scoped lang="scss">
    .app-sidebar {
        background-color: #fff;
        border-left: 1px solid #d9d9d9;
        height: calc(100vh - 64px);
        display: flex;
        flex-direction: column;

        .sidebar-top {
            flex: 0 0 auto;
        }

        .sidebar-content {
            flex: 1 1 0;
            overflow-y: auto;
        }
    }
</style>