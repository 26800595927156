<template>
    <v-container fluid>
        <v-row
            justify-center
            align-center
            no-gutters
        >
            <v-col cols="6">
                <div class="content">
                    <v-col>
                        <h1 class="ml-4 mr-4 page-header">
                            Profile
                        </h1>
                        <validation-observer
                            ref="obs"
                            v-slot="{ invalid, validated, validate }"
                        >
                            <v-form @submit.prevent="submitUser">
                                <v-text-field
                                    v-model="user.email"
                                    label="Email"
                                    prepend-icon="mdi-email"
                                    disabled
                                    @keyup="validate"
                                />

                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Name"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="user.name"
                                        label="Name"
                                        :error-messages="errors"
                                        prepend-icon="mdi-account"
                                        @keyup="validate"
                                    />
                                </validation-provider>
                                <v-text-field
                                    v-model="user.phone_number"
                                    label="Telephone / contact number"
                                    prepend-icon="mdi-phone"
                                    type="text"
                                    @keyup="validate"
                                />


                                <div class="text-right">
                                    <v-btn
                                        type="submit"
                                        color="accent"
                                        :disabled="invalid || !validated"
                                    >
                                        Save changes
                                    </v-btn>
                                </div>
                            </v-form>
                        </validation-observer>
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {$http, videoApiUrl} from '../../services/http';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {toastSuccess} from '../../services/responseErrors';

    export default {
        name: 'Profile',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data() {
            const user = {
                id: '',
                name: '',
                email: '',
                phone_number: '',
            };

            if (this.$route.params.id) {
                user.id = this.$route.params.id;
            }

            return {
                user
            };
        },
        methods: {
            getUser() {
                $http.get(videoApiUrl('api/profile')).then(response => {
                    this.user = {
                        ...this.user,
                        ...response.data,
                    };
                }).catch(() => {
                    this.$router.push({name: 'users'});
                });
            },
            submitUser() {
                $http.put(videoApiUrl('api/profile'), this.user).then(() => {
                    toastSuccess('User profile updated');
                });
            },
        },
        mounted() {
            document.onkeydown = this.checkCapsLock;
            this.getUser();
        }
    };

</script>

<style scoped>
    .dense .v-input--selection-controls {
        margin-top: 0;
    }
</style>
