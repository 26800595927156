import InteractiveElement from '../InteractiveElement';
import {v4 as uuidv4} from 'uuid';

class ImageFactory {
    make({ name, start }) {
        return new InteractiveElement({
            name, //
            _id: uuidv4(),
            start,
            end: start,
            startAction: 'pause', // play | pause
            action: {
                type: 'openLink', // openLink | openDownload | openVideo | skipTo | skipToAndPause | play | pause
                link: 'https://google.com',
                target: '_blank'
            },
            area: {
                type: 'image', // hotspot | image | text,
            },
            style: {
                origin: {
                    left: 25,
                    top: 40,
                },
                dimensions: {
                    width: 50,
                    height: 20
                },
                'default': {
                    background: {
                        path: null, // required when area type is image
                    },
                },
                hover: {
                    background: {
                        path: null, // required when area type is image
                    },
                }
            }
        });
    }
}

export default new ImageFactory();
