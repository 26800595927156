let actionsPermissionsMap = [
  { action: 'index', permissions: [] },
  { action: 'mediaLibrary', permissions: ['list-videos-of-libraries']},
  { action: 'upload', permissions: ['upload-video'] },
  { action: 'users', permissions: ['manage-user-crud'] },
  { action: 'addUser', permissions: ['manage-user-crud'] },
  { action: 'editUser', permissions: ['manage-user-crud'] },
  { action: 'companies', permissions: ['list-companies'] },
  { action: 'addCompany', permissions: ['store-company'] },
  { action: 'editCompany', permissions: ['manage-company'] },
  { action: 'profile', permissions: [] },
  { action: 'libraries', permissions: ['list-libraries'] },
  { action: 'addLibrary', permissions: ['store-library'] },
  { action: 'editLibrary', permissions: ['manage-library'] },
  { action: 'statistics', permissions: ['list-videos-of-libraries'] },
  { action: 'VimeoAuthorize', permissions: ['connect-to-publish-service'] },
  { action: 'manageVideo', permissions: ['manage-videos-of-libraries'] },
  { action: 'usageStatsPage', permissions: ['company-usage-stats']},
  { action: 'publishVideo', permissions: ['publish-video'] },
  { action: 'copyVideo', permissions: ['copy-from-library', 'copy-to-library']},
  { action: 'moveVideo', permissions: ['copy-from-library', 'move-to-library']},
  { action: 'ftp-upload', permissions: ['ftp-folder-access']},
    // TODO: add the proper permission after the feature is connected
  { action: 'interactiveEditor', permissions: []},
];

class Gate {
  allows(user, action, libraryId) {
    if (['super-admin'].indexOf(user.role) === 0) {
      return true;
    }

    let map = actionsPermissionsMap.find(item => item.action === action);
    let requiredPermissions = [];
    if (typeof map === 'object') {
        requiredPermissions = map.permissions;
    }

    if (requiredPermissions.length === 0) {
      return true;
    }

    for (let key in user.permissions) {
      if (requiredPermissions.indexOf(key) === 0) {
        if(typeof libraryId !== 'undefined') {
          if (requiredPermissions[0] === key && user.permissions[key].indexOf(libraryId) === -1) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  allowsPublishing(user, companyId) {
    if (['super-admin'].indexOf(user.role) > -1) {
      return true;
    }

    const permissions = user.permissions['publish-video'];
    if (permissions) {
      return permissions.indexOf(companyId) > -1;
    }

    return false;
  }

  allowsStatisticsOrMediaLibrary(user){
    const permission = user.permissions['list-videos-of-libraries'];
    if (permission) {
        return permission.length > 0;
    }
    return false;
  }

  allowsFtpAccess(user) {
    if (['super-admin'].indexOf(user.role) > -1) {
      return true;
    }

    const permission = user.permissions['ftp-folder-access'];
    return permission.length > 0;
  }
}

export default new Gate();
