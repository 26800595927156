<template>
    <v-card
        elevation="0"
        class="stats-item"
    >
        <v-card-title>
            <slot name="title" />
        </v-card-title>
        <v-divider />
        <v-card-text>
            <slot name="content" />
        </v-card-text>
        <v-progress-linear
            v-if="loading"
            indeterminate
            :color="$vuetify.theme.themes.light.primary"
            style="position: absolute; bottom: 0"
        />
    </v-card>
</template>

<script>
    export default {
        name: 'StatsCard',
        props: ['loading'],
    };
</script>

<style scoped>
.stats-item {
    box-shadow: 0 0 3px #b3b3b3 !important;
}
.stats-item .v-card__title {
    justify-content: center !important;
}

@media (max-width: 1326px) {
  .stats-item .v-card__title {
      font-size: 1rem;
  }
}

@media (max-width: 1100px) {
  .stats-item .v-card__title {
    font-size: 0.8rem;
  }
}

@media (max-width: 959px) {
  .stats-item .v-card__title {
    font-size: 1.25rem;
  }
}


.stats-item .v-card__text {
    text-align: center !important;
    font-size: 4rem;
    line-height: 5rem;
}

.stats-item.smaller .v-card__text {
    font-size: 2.5rem;
}

@media (max-width: 1326px) {
  .stats-item .v-card__title {
    font-size: 1rem;
  }

  .stats-item .v-card__text {
      font-size: 3rem;
  }

  .stats-item.smaller .v-card__text {
    font-size: 2rem;
  }
}

@media (max-width: 1100px) {
  .stats-item .v-card__title {
    font-size: 0.8rem;
  }

  .stats-item .v-card__text {
    font-size: 2rem;
  }

  .stats-item.smaller .v-card__text {
    font-size: 1.8rem;
  }
}

@media (max-width: 959px) {
  .stats-item .v-card__title {
    font-size: 1.25rem;
  }

  .stats-item .v-card__text {
    font-size: 4rem;
  }

  .stats-item.smaller .v-card__text {
    font-size: 2.5rem;
  }
}

</style>
