<template>
    <v-navigation-drawer
        :app="$vuetify.breakpoint.lgAndUp || preventOverlap"
        :absolute="$vuetify.breakpoint.mdAndDown && !preventOverlap"
        clipped
        right
        mobile-break-point="0"
        class="white right-sidebar"
        :width="drawer_width"
    >
        <slot />
    </v-navigation-drawer>
    <!-- END Right navigation -->
</template>

<script>
    export default {
        name: 'RightDrawer',
        props: {
            'preventOverlap': {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                drawer_width: '25%',
                temporary: false
            };
        },
        created() {
            if (this.$vuetify.breakpoint.mdAndDown) {
                this.drawer_width = '35%';
            }
            if (this.$vuetify.breakpoint.smAndDown) {
                this.drawer_width = '40%';
            }
            if (this.$vuetify.breakpoint.xs) {
                this.drawer_width = '80%';
            }
        }
    };
</script>

<style scoped>
    .v-navigation-drawer--absolute {
        box-shadow: 0 0 .5rem rgba(0, 0, 0, .9);
    }
</style>

<style>
    .right-sidebar .v-navigation-drawer__content {
        display: flex;
        flex-direction: column;
    }
</style>
