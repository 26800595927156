<template>
    <div
        ref="container"
        class="element-container"
    >
        <interactive-item-placeholder
            v-for="element in elements"
            :key="element._id"
            :element="element"
            :class="{'hidden': !isVisualElement(element)}"
        />
        <vue-draggable-resizable
            v-if="isActive && isVisualElement(activeElement)"
            :w="pixelWidth"
            :h="pixelHeight"
            :x="pixelLeft"
            :y="pixelTop"
            :parent="true"
            :active="true"
            :prevent-deactivation="true"
            :lock-aspect-ratio="preserveAspectRatio"
            @dragging="onDrag"
            @resizing="onResize"
        >
            <component
                :is="getComponent(activeElement)"
                :element="activeElement"
                @shouldResize="onShouldResize"
            />
        </vue-draggable-resizable>
    </div>
</template>

<script>
    import VueDraggableResizable from 'vue-draggable-resizable';
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
    import InteractiveHotspot from './areas/interactive-hotspot';
    import InteractiveImage from './areas/interactive-image';
    import InteractiveText from './areas/interactive-text';
    import InteractiveInput from './areas/interactive-input';
    import InteractivePlayeraction from './areas/interactive-playeraction';
    import {mapGetters, mapState} from 'vuex';
    import InteractiveItemPlaceholder from './interactive-item-placeholder';
    import { debounce } from 'lodash';

    export default {
        name: 'InteractiveElementVisualEditor',
        data() {
            return {
                parentDimensions: {
                    width: null,
                    height: null
                },
                resizeInterval: null
            };
        },
        mounted() {
            this.updateSizes();
            this.resizeInterval = setInterval(this.updateSizes, 50);
        },
        beforeDestroy() {
            clearInterval(this.resizeInterval);
        },
        components: {
            VueDraggableResizable,
            InteractiveHotspot,
            InteractiveImage,
            InteractiveText,
            InteractiveInput,
            InteractivePlayeraction,
            InteractiveItemPlaceholder
        },
        computed: {
            ...mapGetters('interactive', ['activeElement', 'elements', 'getComponent', 'isVisualElement']),
            ...mapState('interactive', ['imageShouldPreserveAspectRatio', 'player', 'currentPlayerPosition']),
            isActive() {
                var val = (
                    this.sizesSet &&
                    this.activeElement &&
                    this.activeElement.start <= this.currentPlayerPosition &&
                    this.currentPlayerPosition <= this.activeElement.end
                );

                if(val && this.activeElement.startAction === 'pause') {
                    //vue's computed caching solves our single pause requirements
                    this.player.$emit('pause');
                }

                return val;
            },
            preserveAspectRatio() {
                return this.activeElement.area.type === 'image' && this.imageShouldPreserveAspectRatio;
            },
            sizesSet() {
                return this.parentDimensions.width !== null;
            },
            pixelLeft() {
                return this.percentageToPixels(
                    this.parentDimensions.width,
                    this.activeElement.style.origin.left
                );
            },
            pixelTop() {
                return this.percentageToPixels(
                    this.parentDimensions.height,
                    this.activeElement.style.origin.top
                );
            },
            pixelWidth() {
                return this.percentageToPixels(
                    this.parentDimensions.width,
                    this.activeElement.style.dimensions.width
                );
            },
            pixelHeight() {
                return this.percentageToPixels(
                    this.parentDimensions.height,
                    this.activeElement.style.dimensions.height
                );
            }
        },
        methods: {
            updateSizes() {
                this.parentDimensions.width = this.$refs.container.offsetWidth;
                this.parentDimensions.height = this.$refs.container.offsetHeight;
            },
            percentage(partialValue, totalValue) {
                return (100 / totalValue) * partialValue;
            },
            percentageToPixels(percentageValue, totalValue) {
                return (totalValue / 100) * percentageValue;
            },
            onDrag: debounce(function(x, y) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setStyleOrigin',
                    payload: {
                        left: this.percentage(x, this.parentDimensions.width),
                        top: this.percentage(y, this.parentDimensions.height)
                    }
                });
            }, 200),
            onResize: debounce(function(x, y, width, height) {
                this.$store.dispatch(
                    'interactive/executeWithUndoAndSave',{
                        commit: [
                            {
                                name: 'setStyleOrigin',
                                payload: {
                                    left: this.percentage(x, this.parentDimensions.width),
                                    top: this.percentage(y, this.parentDimensions.height)
                                }
                            },
                            {
                                name: 'setStyleDimensions',
                                payload: {
                                    width: this.percentage(width, this.parentDimensions.width),
                                    height: this.percentage(height, this.parentDimensions.height)
                                }
                            }
                        ]
                    }
                );
            }, 200),

            onShouldResize({width, height}) {
                this.onResize(this.pixelLeft, this.pixelTop, width, height);
            }
        }
    };
</script>

<style lang="scss">
.interactive-item-placeholder.hidden {
  display: none !important;
}
.element-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;

    .element-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .vdr {
        border: 0 none transparent;
        box-sizing: border-box;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, .3);
        z-index: 2;
    }

    .handle {
        background-color: rgba(255, 255, 255, 0.7);
        border: 1px solid rgba(0, 0, 0, 0.7);
        border-radius: 50%;
    }

    .handle-tl {
        left: -6px;
        top: -6px;
    }

    .handle-tm {
        top: -6px;
    }

    .handle-tr {
        top: -6px;
        right: -6px;
    }

    .handle-mr {
        right: -6px;
    }

    .handle-ml {
        left: -6px;
    }

    .handle-bl {
        left: -6px;
        bottom: -6px;
    }

    .handle-bm {
        bottom: -6px;
    }

    .handle-br {
        bottom: -6px;
        right: -6px;
    }
}
</style>
