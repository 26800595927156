import {$http, publishingApiUrl} from '@/services/http';

const identifierKey = 'YouTubeEntityIdentifier';
const stateKey = 'YouTubeAuthorizationState';
const accountNameKey = 'YouTubeAccountName';

function cleanupStorage() {
    localStorage.removeItem(identifierKey);
    localStorage.removeItem(stateKey);
    localStorage.removeItem(accountNameKey);
}

export function getYouTubeAuthorizationUrl(companyId, accountName) {
    return $http.get(publishingApiUrl('/accounts/qpd/youtube/authorization'), {}).then(response => {
        const {oauth2GrantURL, state} = response.data;
        localStorage.setItem(identifierKey, companyId);
        localStorage.setItem(stateKey, state);
        localStorage.setItem(accountNameKey, accountName);
        return oauth2GrantURL;
    });
}

export function getYouTubeTokens(state, code) {
    const storedState = localStorage.getItem(stateKey);
    return new Promise((resolve, reject) => {
        if (!!storedState && storedState === state) {
            return $http.get(publishingApiUrl(`/accounts/qpd/youtube/access-token?code=${code}`))
                .then(response => {
                    storeYouTubeTokens(response.data.access_token, response.data.refresh_token)
                        .then(entityId => resolve(entityId));
            });
        } else {
            reject('Invalid YouTube state');
        }
    });
}

export function storeYouTubeTokens(accessToken, refreshToken) {
    const entityId = localStorage.getItem(identifierKey);
    return $http.post(publishingApiUrl('/accounts/qpd/youtube/token'), {
        name: localStorage.getItem(accountNameKey),
        OAuthCode: accessToken,
        refreshToken: refreshToken,
        meta: {
            companyId: entityId,
            accountType: 'youtube'
        }
    }).then(() => {
        cleanupStorage();
        return entityId;
    });
}

export function getYouTubeAccount(entityId) {
    return $http.get(publishingApiUrl(`/accounts/qpd/token/company/${entityId}`))
        .then(response => response.data.filter(item => item.meta.accountType === 'youtube'));
}

export function publishToYouTube(videoDescriptor) {
    return $http.post(publishingApiUrl('/qpd/publish/youtube'), videoDescriptor);
}