<template>
    <v-container fluid>
        <v-row
            justify-center
            align-center
            no-gutters
        >
            <v-col
                cols="12"
                md="12"
            >
                <div class="content">
                    <v-col md="6">
                        <h1 class="ml-4 mr-4 page-header">
                            <span v-if="!playerTemplate.id">Add</span>
                            <span v-if="playerTemplate.id">Edit</span>&nbsp;player template
                            <span v-if="libraryName"> - {{ companyName }} / {{ libraryName }}</span>
                        </h1>
                        <validation-observer
                            ref="obs"
                            v-slot="{ invalid, validated, validate }"
                        >
                            <v-form
                                class="ml-4 mr-4"
                                @submit.prevent="submitPlayerTemplate"
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="playerTemplateName"
                                            rules="required"
                                        >
                                            <v-text-field
                                                v-model="playerTemplate.name"
                                                label="Template name"
                                                :error-messages="errors"
                                                prepend-icon="mdi-bank"
                                                @keyup="validate"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <v-row class="checkboxes">
                                    <v-col cols="12">
                                        <h1 class="page-header">
                                            Player controls
                                        </h1>
                                        <v-row>
                                            <v-col
                                                v-for="(item, index) in playerCheckboxes"
                                                :key="'player-checkbox-'+index"
                                                cols="4"
                                            >
                                                <v-checkbox
                                                    v-model="playerTemplate[item.field]"
                                                    :label="item.label"
                                                    solo
                                                    small
                                                    hide-details
                                                    pa-0
                                                    @change="validate"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="4">
                                                <v-text-field
                                                    :value="playerTemplate.controls_color"
                                                    label="Player icon colour"
                                                    readonly
                                                    @click="openPlayerColorPicker"
                                                >
                                                    <template v-slot:append>
                                                        <div
                                                            :style="{backgroundColor: playerTemplate.controls_color}"
                                                            class="color-preview"
                                                        />
                                                    </template>
                                                </v-text-field>
                                                <generic-modal
                                                    max-width="350px"
                                                    title="Pick a color"
                                                    :value="playerColorPickerModal"
                                                    @cancel="closePlayerColorPicker"
                                                >
                                                    <template v-slot:default>
                                                        <coloreditor
                                                            :value="playerTemplate.controls_color"
                                                            @input="setPlayerColorPickerValue"
                                                        />
                                                    </template>
                                                    <template v-slot:actions>
                                                        <v-spacer />
                                                        <v-btn
                                                            color="error"
                                                            text
                                                            @click="updateControlsColor"
                                                            @mouseup="validate"
                                                        >
                                                            Update
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            @click="closePlayerColorPicker"
                                                        >
                                                            Close
                                                        </v-btn>
                                                    </template>
                                                </generic-modal>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col
                                        v-if="playerTemplate.social_share"
                                        cols="12"
                                    >
                                        <h1 class="page-header">
                                            Share controls
                                        </h1>
                                        <v-row>
                                            <v-col
                                                v-for="(item, index) in shareCheckboxes"
                                                :key="'share-checkbox-'+index"
                                                cols="4"
                                            >
                                                <v-checkbox
                                                    v-model="playerTemplate[item.field]"
                                                    :label="item.label"
                                                    solo
                                                    small
                                                    hide-details
                                                    pa-0
                                                    @change="validate"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-left">
                                            <v-btn
                                                type="submit"
                                                color="accent"
                                                class="mr-4"
                                                :loading="saving"
                                                :disabled="saving || invalid || !validated"
                                            >
                                                <span v-if="!playerTemplate.id">Create template</span>
                                                <span v-if="playerTemplate.id">Update template</span>
                                            </v-btn>
                                            <router-link
                                                v-if="!saving"
                                                :to="{name: 'editLibrary', params: {id: libraryId}}"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    color="error"
                                                    text
                                                >
                                                    Cancel
                                                </v-btn>
                                            </router-link>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </validation-observer>
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {$http, playerTemplateApiUrl, videoApiUrl} from '../../services/http';
    import {toastSuccess} from '../../services/responseErrors';
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import {colorToRgbaObject, rgbaObjectToHex} from '../../color-helper';
    import GenericModal from '@/components/controls/modals/generic-modal';
    import Coloreditor from '@/components/controls/video-metadata/editor/ColorEditor';

    export default {
        name: 'AddEditPlayerTemplate',
        components: {
            Coloreditor,
            GenericModal,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            const playerTemplate = {
                id: '',
                name: '',
            };

            if (this.$route.params.templateId) {
                playerTemplate.id = this.$route.params.templateId;
            }

            return {
                saving: false,
                libraryId: this.$route.params.libraryId,
                libraryName: '',
                companyName: '',
                playerTemplate: playerTemplate,
                pageLoading: false,
                playerColorPickerModal: false,
                playerColorPickerValue: null,
                playerCheckboxes: [
                    {label: 'Mute by default', field: 'mute'},
                    {label: 'Audio only', field: 'audio_only'},
                    {label: 'Repeat', field: 'repeat'},
                    {label: 'Social share', field: 'social_share'},
                    {label: 'Title', field: 'show_title'},
                    {label: 'Autoplay', field: 'autoplay'},
                    {label: 'Description', field: 'show_description'},
                    {label: 'Fullscreen', field: 'fullscreen'},
                    {label: 'Rewind 10 sec', field: 'rewind_10s'},
                    {label: 'Show timecode', field: 'time_info'},
                    {label: 'Show timeline', field: 'scrub_bar'},
                    {label: 'Volume control', field: 'sound_control'},
                    {label: 'Quality', field: 'quality'},
                    {label: 'Use semi-transparent background for subtitles', field: 'text_track_background'},
                ].sort((a, b) => a.label.localeCompare(b.label)),
                shareCheckboxes:[
                    {label: 'Facebook', field: 'share_facebook'},
                    {label: 'Linkedin', field: 'share_linkedin'},
                    {label: 'Email', field: 'share_email'},
                    {label: 'Twitter', field: 'share_twitter'},
                    {label: 'Pinterest', field: 'share_pinterest'},
                    {label: 'Reddit', field: 'share_reddit'},
                    {label: 'Tumblr', field: 'share_tumblr'},
                ].sort((a, b) => a.label.localeCompare(b.label))
            };
        },
        computed: {},
        watch: {},
        methods: {
            getPlayerTemplate() {
                this.pageLoading = true;
                $http
                    .get(playerTemplateApiUrl('/libraries/' + this.libraryId + '/player-templates/' + this.playerTemplate.id))
                    .then(response => {
                        this.playerTemplate = {
                            ...this.playerTemplate,
                            ...response.data,
                        };
                        if(!this.playerTemplate.controls_color){
                            this.playerTemplate.controls_color = '#ffffff';
                        }
                        setTimeout(() => {
                            this.pageLoading = false;
                        }, 250);
                    })
                    .catch(() => {
                        this.$router.push({name: 'editLibrary', params: {id: this.libraryId}});
                        this.pageLoading = false;
                    });
            },
            async submitPlayerTemplate() {
                this.saving = true;
                const method = this.playerTemplate.id ? $http.put : $http.post;
                const playerTemplate = {
                    ...this.playerTemplate,
                };
                await method(playerTemplateApiUrl('/libraries/' + this.libraryId + '/player-templates/' + this.playerTemplate.id), playerTemplate)
                    .then(() => {
                        toastSuccess(`Template ${this.playerTemplate.id ? 'updated' : 'saved'}`);
                        if (!this.playerTemplate.id) {
                            this.$router.push({name: 'editLibrary', params: {id: this.libraryId}});
                        }
                    }).finally(() => {
                        this.saving = false;
                    });
            },
            updateControlsColor(){
                this.playerColorPickerModal = false;
                let rgbaobj = colorToRgbaObject(this.playerColorPickerValue);
                this.playerTemplate.controls_color = rgbaObjectToHex(rgbaobj);
            },
            openPlayerColorPicker(){
                this.playerColorPickerModal = true;
            },
            closePlayerColorPicker(){
                this.playerColorPickerModal = false;
            },
            setPlayerColorPickerValue(value){
                this.playerColorPickerValue = value;
            },
            async getlibrary() {
                this.pageLoading = true;
                let response = await $http.get(videoApiUrl('api/libraries/' + this.libraryId));
                this.libraryName = response.data.name ?? '';
                this.companyName = response.data.company.company_name ?? '';
            }
        },
        async mounted() {
            if (this.playerTemplate.id) {
                this.getPlayerTemplate();
            }
            await this.getlibrary();
        }
    };
</script>

<style scoped lang="scss">
    .checkboxes {
        .v-input {
            margin-top: 0;
        }
    }
    .content{
      .col{
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }

    .color-preview {
      height: 25px;
      width: 25px;
      border-radius: 4px;
      transition: border-radius 200ms ease-in-out;
      border: 0.5px solid lightgray;
    }

    .color-picker-modal{
      width: auto;
    }
</style>
