<template>
    <div />
</template>

<script>
    export default {
        name: 'InteractiveInput'
    };
</script>

<style scoped>

</style>