import LibraryList from './library-list';

class RoleLibraries {
    constructor(roleLibraries, role) {
        this.editorRole = role('editor');
        this.readOnlyRole = role('read-only');
        this.uploaderRole = role('uploader');
        this.editorLibraries = new LibraryList(roleLibraries.filter(item => item.pivot.role === this.editorRole.slug));
        this.readOnlyLibraries = new LibraryList(roleLibraries.filter(item => item.pivot.role === this.readOnlyRole.slug));
        this.uploaderLibraries = new LibraryList(roleLibraries.filter(item => item.pivot.role === this.uploaderRole.slug));
    }

    clear() {
        this.editorLibraries.clear();
        this.readOnlyLibraries.clear();
        this.uploaderLibraries.clear();
    }

    mapToRoles() {
        return [
            ...this.mapToRole(this.editorLibraries, this.editorRole),
            ...this.mapToRole(this.readOnlyLibraries, this.readOnlyRole),
            ...this.mapToRole(this.uploaderLibraries, this.uploaderRole),
        ];
    }

    mapToRole(list, role) {
        return list.map(item => {
            return {
                id: item.id,
                role: role.slug
            };
        });
    }
}

export default RoleLibraries;