<template>
    <v-menu fixed
            nudge-bottom="30"
            content-class="x-menu"
            attach=".above-else"
            max-width="300"
    >
        <list-head-filter
            column-id="libraryId"
            :items="companyFilteredLibraries"
            :select-check="value"
            @filterQuickSearch="setLibraryFilterQuickSearch"
            @filterChanged="filterChanged"
        >
            <template v-slot:filter-top="{listLengthLimit, clearSelected}">
                <v-sheet class="pa-4">
                    <v-select
                        v-if="libraryCompanies.length > 1"
                        v-model="selectedLibraryCompany"
                        :items="libraryCompanies"
                        item-text="company_name"
                        item-value="id"
                        placeholder="Select company"
                        clearable
                        filled
                        dense
                        hide-details
                        class="mb-2"
                        @click.native.stop="() => {}"
                    />
                    <div v-if="libraryColumnFilterItems.length > listLengthLimit">
                        <v-text-field
                            v-model="libraryFilterSearchText"
                            height="29"
                            flat
                            solo
                            background-color="grey lighten-1"
                            hide-details
                            :append-icon="libraryFilterSearchText ? undefined : 'search'"
                            label="Quick search"
                            clearable
                            @click.native.stop="() => {}"
                        />
                        <div
                            v-if="!libraryFilterSearchText"
                            class="mt-4"
                        >
                            Please narrow your search
                        </div>
                        <slot
                            name="filter-top"
                            :clearSelected="clearSelected"
                        />
                    </div>
                </v-sheet>
            </template>
        </list-head-filter>
        <template v-slot:activator="{on}">
            <v-btn 
                small
                class="ml-2"   
                :class="{'library-filter-button': !active}"            
                :color="buttonColor"
                :outlined="active"
                elevation="0"
                v-on="on"
            >
                Library
            </v-btn>
        </template>
    </v-menu>
</template>

<script>
    import {debounce} from 'lodash';
    import ListHeadFilter from '@/components/primitives/list-head-filter';
    import {$http, videoApiUrl} from '@/services/http';
    import qs from 'qs';

    export default {
        name: 'LibraryFilter',
        components: {ListHeadFilter},
        props: {
            value: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                libraryColumnFilterItems: [],
                selectedLibraryCompany: null,
                libraryFilterSearchText: '',
            };
        },
        computed: {
            companyFilteredLibraries: function () {
                const filtered = this.selectedLibraryCompany
                    ? this.libraryColumnFilterItems.filter(item => item.company_id === this.selectedLibraryCompany)
                    : this.libraryColumnFilterItems;
                return this.libraryFilterSearchText
                    ? filtered.filter(item => item.name.toLowerCase().indexOf(this.libraryFilterSearchText.toLocaleLowerCase()) > -1)
                    : filtered;
            },
            libraryCompanies: function () {
                const companies = [];
                this.libraryColumnFilterItems.forEach(item => {
                    const company = {
                        id: item.company_id,
                        company_name: item.subtitle ? item.subtitle : ''
                    };
                    if (!companies.find(companyItem => companyItem.id === company.id)) {
                        companies.push(company);
                    }
                });
                if (companies.length === 1) {
                    this.libraryColumnFilterItems.forEach(item => item.subtitle = '');
                }
                return companies;
            },
            active() {
                return this.value.length > 0 ? true : false;
            },
            buttonColor() {
                return this.active ? 'primary' : '#fff';
            }
        },
        methods: {
            filterChanged(items) {
                this.$emit('input', items.items);
            },
            getLibraries: debounce(function () {
                $http.get(videoApiUrl('api/libraries-i-can-see'), {
                    paramsSerializer: params => {
                        return decodeURIComponent(qs.stringify(params));
                    }
                }).then(res => {
                    this.selectedLibraryCompany = null;
                    this.libraryFilterSearchText = '';
                    this.libraryColumnFilterItems = res.data.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            company_id: item.company_id,
                            subtitle: item.company_name ? item.company_name : null
                        };
                    });
                });
            }, 400),
            setLibraryFilterQuickSearch(search) {
                this.libraryFilterSearchText = search;
            }
        },
        mounted() {
            this.getLibraries();
        }
    };
</script>

<style lang="scss">
.library-filter-button{
    border: 1px solid #ccc;
    border-color: #ccc !important;
    .v-btn__content{
        color: #555 !important;
    }
}
</style>
