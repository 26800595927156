<template>
    <confirm-modal
        v-model="isOpen"
        title="You are leaving the upload page"
        confirm-button="Yes, close"
        cancel-button="Keep me on this page"
        @confirm="confirmLeave"
        @cancel="cancelLeave"
    >
        <p>You are trying to exit this screen. Any unfinished upload(s) will be lost.</p>
        <p>Are you sure?</p>
    </confirm-modal>
</template>

<script>
    import ConfirmModal from '@/components/controls/modals/confirm-modal';
    export default {
        name: 'ConfirmLeaveUpload',
        components: {ConfirmModal},
        data() {
            return {
                isOpen: false,
                resolve: null,
                reject: null
            };
        },
        methods: {
            open() {
                this.isOpen = true;
                return new Promise(resolve => {
                    this.resolve = resolve;
                });
            },
            confirmLeave() {
                this.resolve(true);
                this.isOpen = false;
            },
            cancelLeave() {
                this.resolve = false;
                this.isOpen = false;
            }
        }
    };
</script>

<style scoped>

</style>