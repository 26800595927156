// import {sortBy} from 'lodash';

const commentGetters = {
    videoId: state => state.video.id,
    activeElement: state => state.activeElement,
    elements: state => state.comments.map(c => c.timecode)
                                     .filter((v, index, self) => {
                                        return self.indexOf(v) === index;
                                     })
                                     .sort((a, b) => {
                                        if (a === b) {
                                            return 0;
                                        }

                                        return a > b ? 1 : -1;
                                     }),
    filteredComments: (state) => {
        if (!state.activeElement || parseFloat(state.activeElement) === 0) {
            return state.comments;
        }

        return state.comments.filter(c => c.timecode === state.activeElement);
    }
};

export default commentGetters;