var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify-center":"","align-center":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"content"},[_c('v-col',[_c('h1',{staticClass:"ml-4 mr-4 page-header"},[(!_vm.user.id)?_c('span',[_vm._v("Add")]):_vm._e(),(_vm.user.id)?_c('span',[_vm._v("Edit")]):_vm._e(),_vm._v(" User ")]),_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('v-form',{staticClass:"ml-4 mr-4",on:{"submit":function($event){$event.preventDefault();return _vm.submitUser($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors,"prepend-icon":"mdi-account"},on:{"keyup":validate},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Telephone / contact number","prepend-icon":"mdi-phone","type":"text"},on:{"keyup":validate},model:{value:(_vm.user.phone_number),callback:function ($$v) {_vm.$set(_vm.user, "phone_number", $$v)},expression:"user.phone_number"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"prepend-icon":"mdi-email","disabled":_vm.isExistingUser},on:{"keyup":validate},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1)],1),_c('v-row',[(_vm.canEditRoleOf(_vm.user))?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":"Role","items":_vm.roles,"item-text":"name","item-value":"slug","clearable":"","prepend-icon":"mdi-lock","hide-details":""},on:{"change":validate,"input":_vm.roleUpdated},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1):_vm._e(),(_vm.canEditUserCompany)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"company","rules":_vm.companyRequired},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Company","items":_vm.companies,"prepend-icon":"mdi-office-building","item-text":"company_name","item-value":"id","clearable":"","return-object":"","error-messages":errors,"search-input":_vm.companySearch},on:{"update:searchInput":function($event){_vm.companySearch=$event},"update:search-input":function($event){_vm.companySearch=$event},"change":validate},model:{value:(_vm.user.company),callback:function ($$v) {_vm.$set(_vm.user, "company", $$v)},expression:"user.company"}})]}}],null,true)})],1):_vm._e()],1),((_vm.isSuperAdmin || _vm.isSystemAdmin))?[_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Can upload to all libraries in Quadia Platform"},on:{"change":_vm.platformUploadEverywhereChange},model:{value:(_vm.user.platform_upload_everywhere),callback:function ($$v) {_vm.$set(_vm.user, "platform_upload_everywhere", $$v)},expression:"user.platform_upload_everywhere"}})],1)],1)]:_vm._e(),_c('v-divider'),(!_vm.rolesLoading && _vm.user.libraries)?_c('library-roles-editor',{attrs:{"libraries":_vm.libraries,"company":_vm.user.company},on:{"input":_vm.onRoleLibraryChange},model:{value:(_vm.user.libraries),callback:function ($$v) {_vm.$set(_vm.user, "libraries", $$v)},expression:"user.libraries"}}):_vm._e(),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-left"},[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"accent","disabled":invalid || !validated}},[(!_vm.user.id)?_c('span',[_vm._v("Add user")]):_vm._e(),(_vm.user.id)?_c('span',[_vm._v("Save changes")]):_vm._e()]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{name: 'users'}}},[_c('v-btn',{attrs:{"color":"error","text":""}},[_vm._v(" Back ")])],1)],1)])],1)],2)]}}])})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }