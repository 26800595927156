<template>
    <v-tooltip top>
        <template v-slot:activator="{on}">
            <v-btn v-if="status === 0 && canResolve"
                   x-small
                   ripple
                   rounded
                   width="24"
                   height="16"
                   class="ml-2 pa-0"
                   color="primary"
                   v-on="on"
                   @click="resolveComment"
            >
                <v-icon x-small>
                    check
                </v-icon>
            </v-btn>
            <v-icon v-if="status === 0 && !canResolve"
                    x-small
                    class="ml-2 pl-2 mr-4"
                    color="warning"
                    v-on="on"
            >
                pending
            </v-icon>
            <v-icon v-if="status === 1"
                    x-small
                    class="ml-2 pl-2 mr-4"
                    color="success"
                    v-on="on"
            >
                task_alt
            </v-icon>
        </template>
        <span>{{ currentStatus }}</span>
    </v-tooltip>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import Gate from '@/services/gate';

    export default {
        name: 'Resolve',
        props: {
            status: {
                type: Number,
                default: 0
            },
            commentId: {
                type: Number,
                required: true
            },
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('comments', ['video']),
            ...mapGetters('auth', ['isSuperAdmin', 'isLoggedIn']),
            currentStatus() {
                return this.status === 1 ? 'Comment resolved' : (this.canResolve ? 'Resolve' : 'Comment waiting to be resolved.');
            },
            canResolve() {
                if (!this.isLoggedIn) {
                    return false;
                }
                return this.isSuperAdmin || Gate.allows(this.user, 'manageVideo', this.video.library);
            }
        },
        methods: {
            ...mapActions('comments', ['resolve']),
            resolveComment() {
                console.log(this.commentId);
                this.resolve({
                    videoId: this.video.id,
                    commentId: this.commentId,
                });
            }
        }
    };
</script>

<style scoped>

</style>