<template>
    <div>
        <div v-for="file in files"
             :key="`file-key-${file.path}`"
             class="list-control-item"
             :class="{'selected': isSelected(file)}"
        >
            <v-card class="mb-1 py-1">
                <div class="d-flex">
                    <div class="d-flex align-center justify-center selector-column pr-2">
                        <v-checkbox
                            :input-value="selected"
                            :value="file"
                            @click.stop="toggleFile(file)"
                        />
                    </div>
                    <div class="d-flex file-name-column align-center pr-2"
                         @click.stop="selectFile(file)"
                    >
                        <span class="mt-1">{{ fileName(file.path) }}</span>
                    </div>
                    <div class="d-flex align-center file-date-column pr-2"
                         @click.stop="selectFile(file)"
                    >
                        <span class="mt-1"
                              :class="sizeClass(file.size)"
                        >{{ byteToMb(file.size) }}</span>
                    </div>
                    <div class="d-flex align-center file-date-column pr-2"
                         @click.stop="selectFile(file)"
                    >
                        <span class="mt-1">{{ toDate(file.createdAt) }}</span>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import TimeHelper from '../../../time-helper';
    import {getFileName} from '@/services/ftp-service';

    export default {
        name: 'FtpFileListing',
        computed: {
            ...mapState('ftp', ['files', 'selectedFiles']),
            selected: {
                get() {
                    return this.selectedFiles;
                },
                set(value) {
                    this.setSelectedFiles(value);
                }
            },
        },
        methods: {
            ...mapMutations('ftp', ['setSelectedFiles']),
            toDate(timeStamp) {
                return TimeHelper.formatYmdHi(TimeHelper.fromUnixToClientsTz(timeStamp));
            },
            byteToMb(size) {
                return (size / 1048576).toFixed(2) + ' MB';
            },
            isSelected(file) {
                return this.selected
                    .filter(f => f.path === file.path)
                    .length === 1;
            },
            selectFile(file) {
                this.selected = [file];
            },
            toggleFile(file) {
                if (this.isSelected(file)) {
                    this.selected = this.selected.filter(f => f.path !== file.path);
                } else {
                    this.selected = [...this.selected, file];
                }
            },
            fileName(path) {
                return getFileName(path);
            },
            sizeClass(size) {
                return {
                    'warning--text': size >= process.env.VUE_APP_MAXIMUM_FILE_SIZE,
                };
            }
        },
    };
</script>

<style scoped lang="scss">
span {
  color: #3e3e3e;
}
</style>