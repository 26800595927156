<template>
    <fragment>
        <div class="listing-container">
            <div class="sticky-nav pt-7">
                <v-row class="col-md-12 no-gutters pt-0 pb-0 pl-3 pr-3 d-flex">
                    <div class="title-container">
                        <slot name="title" />
                    </div>
                    <div class="d-flex quick-search-container">
                        <v-text-field
                            height="29"
                            flat
                            solo
                            background-color="grey lighten-1"
                            hide-details
                            :append-icon="searchContent ? undefined : 'search'"
                            label="Quick search"
                            :value="searchContent"
                            clearable
                            @input="setSearchContent"
                        />
                    </div>
                    <div class="text-right download-delete-buttons">
                        <v-tooltip
                            bottom
                            open-delay="1000"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    icon
                                    class="ma-auto text-capitalize"
                                    :disabled="selectedItems.length ===0"
                                    v-on="on"
                                    @click="download"
                                >
                                    <v-icon dark>
                                        mdi-download
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Download</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="modificationAllowed"
                            bottom
                            open-delay="1000"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    icon
                                    class="ma-auto mr-4 text-capitalize"
                                    :disabled="!selectedItems.length"
                                    v-on="on"
                                    @click="deleteVideos"
                                >
                                    <v-icon dark>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Remove</span>
                        </v-tooltip>
                    </div>
                    <div class="text-right view-toggle-container">
                        <v-btn-toggle
                            v-if="canSwitchMode"
                            v-model="gridMode"
                            mandatory
                            rounded
                            class="d-inline-block ml-2"
                            color="primary"
                        >
                            <v-btn
                                height="29"
                                @click="showListMenu"
                            >
                                <v-icon>list</v-icon>
                            </v-btn>
                            <v-btn
                                height="29"
                                @click="showGridMenu"
                            >
                                <v-icon>view_module</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-row>
            </div>
            <v-col class="pb-0 pt-0 sticky-table-header">
                <div
                    class="col-md-12 pa-0 d-flex listing-header listing-header-container"
                    :class="{'d-flex-wrap': $vuetify.breakpoint.smAndDown}"
                >
                    <div class="flex-grow-0 toggle-column pa-2">
                        <v-checkbox
                            v-model="allChecked"
                            height="37"
                            hide-details
                            pa-0
                            solo
                            class="list-toolbar-select-all"
                            small
                            @click.stop="checkAll"
                        />
                    </div>
                    <div
                        class="pa-2"
                        :class="{'flex-grow-1': $vuetify.breakpoint.sm, 'image-column': $vuetify.breakpoint.smAndUp}"
                    >
                        <filter-picker
                            v-if="$vuetify.breakpoint.smAndDown"
                        />
                        <sort-picker
                            v-if="$vuetify.breakpoint.smAndDown"
                            :headers="headers"
                        />
                    </div>
                    <div
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="flex-grow-1 title-column pt-2 pr-0 pl-0 pb-2"
                    >
                        <list-head-item
                            id="title"
                            name="Title"
                            :sortable="true"
                            :value="headerState"
                            @input="headerStateChanged"
                        />
                    </div>
                    <div
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="flex-grow-1 short-text-column pt-2 pr-0 pl-0 pb-2"
                    >
                        <list-head-item
                            id="privacy"
                            class="pl-4 pr-4"
                            name="Status"
                            :sortable="true"
                            filter-component="privacy-filter"
                            :value="headerState"
                            @input="headerStateChanged"
                        />
                    </div>
                    <div
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="flex-grow-1 short-text-column pt-2 pr-0 pl-0 pb-2"
                    >
                        <list-head-item
                            id="publish_date"
                            class="pl-4 pr-4"
                            style="flex: 4"
                            name="Uploaded"
                            :sortable="true"
                            :value="headerState"
                            @input="headerStateChanged"
                        />
                    </div>
                    <div
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="flex-grow-1 short-text-column pt-2 pr-0 pl-0 pb-2"
                    >
                        <list-head-item
                            id="library"
                            class="pl-4 pr-4"
                            style="flex: 4"
                            name="Library"
                            :sortable="false"
                            filter-component="library-filter"
                            :value="headerState"
                            @input="headerStateChanged"
                        />
                    </div>
                </div>
            </v-col>
            <v-row
                v-if="loading"
                class="col-md-12 no-gutters pt-0 pb-0 list-container list-loader"
            >
                <v-progress-linear
                    class=""
                    indeterminate
                />
            </v-row>
            <!-- table content -->
            <v-row class="col-md-12 no-gutters pt-0 pb-0 list-container">
                <no-search-results v-if="noQuickSearchResult" />
                <v-col
                    v-for="item in data.listingData"
                    :key="item.id"
                    cols="12"
                    :class="{'col-xs-12': gridMode, 'col-sm-6':gridMode, 'col-md-4':gridMode, 'col-lg-3':gridMode, 'col-xl-3':gridMode}"
                >
                    <list-control-item
                        :item="item"
                        :selected="item.selected"
                        :class="{'selected': item.selected}"
                        @selection-change="onSelectionChange"
                        @selection-change-list="onSelectionChangeList"
                        @delete-request="onDeleteItemRequest"
                    />
                </v-col>
            </v-row>
            <v-row>
                <div class="col-md-12">
                    <x-paginator :value="pagination"
                                 @input="updatePagination"
                    />
                </div>
            </v-row>
            <!-- end table content -->
        </div>
        <delete-videos
            v-model="deleteModal"
            :videos="itemsForDelete"
            @confirm="deleteItems"
        />
    </fragment>
</template>

<script>
    import {$http, videoApiUrl} from '../../services/http';
    import {debounce} from 'lodash';
    import ListControlItem from '../primitives/list-control-item';
    import SortPicker from './sort-picker';
    import FilterPicker from './filter-picker';
    import XPaginator from './paginator';
    import DeleteVideos from './modals/delete-videos';
    import NoSearchResults from './no-search-result';
    import qs from 'qs';
    import {mapState, mapMutations} from 'vuex';
    import {toastSuccess} from '../../services/responseErrors';
    import Gate from '../../services/gate';
    import store from '../../store/index';
    import downloadVideo from '../../services/download';
    import ListHeadItem from '../primitives/list-head-item';

    export default {
        name: 'ListControl',
        components: {
            ListHeadItem,
            XPaginator,
            ListControlItem,
            SortPicker,
            FilterPicker,
            DeleteVideos,
            NoSearchResults
        },
        data: () => ({
            canSwitchMode: true,
            oldGridMode: 0,
            headers: [
                {text: 'Title', value: 'title'},
                {text: 'Privacy', value: 'privacy'},
                {text: 'Published', value: 'publish_date'}
            ],
            data: {
                listingData: [],
                limit: 5,
                busy: false
            },
            allChecked: false,
            deleteModal: false,
            noQuickSearchResult: false,
            loading: false,
            itemsForDelete: [],
        }),
        computed: {
            ...mapState('layout', ['gridMode']),
            ...mapState('media-library-store', ['searchContent', 'headerState', 'pagination']),
            selectedItems() {
                return this.data.listingData.filter(item => {
                    return item.selected;
                });
            },
            modificationAllowed: function () {
                const allow = this.selectedItems.filter(item => !Gate.allows(store.getters['auth/user'], 'manageVideo', item.library)).length;
                return allow <= 0;
            }
        },
        watch: {
            'pagination.currentPage'(value, oldValue) {
                if (value !== oldValue) {
                    this.getPage();
                }
            }
        },
        methods: {
            ...mapMutations('media-library-store', ['setSearch', 'setHeaderState', 'setPagination']),
            ...mapMutations('layout', ['setGridMode']),
            setSearchContent: debounce(function (value) {
                this.setSearch(value);
                this.resetList();
            }, 300),
            headerStateChanged(value) {
                this.setHeaderState(value);
                this.setPagination({currentPage: 1});
                this.getPage();
                this.$emit('sorted');
            },
            updatePagination(value) {
                this.setPagination(value);
            },
            resetList() {
                this.allChecked = false;
                this.setPagination({currentPage: 1});
                this.data.listingData = [];
                if (this.$route.query.videoId) {
                    this.$router.replace({'query.videoId': null}).catch();
                }
                this.getPage();
            },
            showGridMenu() {
                this.oldGridMode = 1;
                this.setGridMode(1);
            },
            showListMenu() {
                this.oldGridMode = 0;
                this.setGridMode(0);
            },

            onSelectionChange(data) {
                this.allChecked = false;
                this.clearPreviews();
                this.setItemSelection(data.item.id, data.selected);
                setTimeout(this.onResize, 200);
                this.$emit('selection-change', this.selectedItems);
            },

            setItemSelection(id, selected) {
                for (let i = 0; i < this.data.listingData.length; i++) {
                    if (this.data.listingData[i].id === id) {
                        this.data.listingData[i].selected = selected;
                        return;
                    }
                }
            },
            onSelectionChangeList(data) {
                const selfSelected = this.selectedItems.length === 1 && data.selected === false;
                this.allChecked = false;
                for (let i = 0; i < this.data.listingData.length; i++) {
                    this.data.listingData[i].selected = false;
                }
                this.clearPreviews();
                this.setItemSelection(data.item.id, !selfSelected);
                setTimeout(this.onResize, 200);
                this.$emit('selection-change', this.selectedItems);
            },

            clearPreviews() {
                if (window.QuadiaPlayer) {
                    for (let x in window.QuadiaPlayer.players) {
                        if (x.indexOf('preview-player-') > -1) {
                            delete window.QuadiaPlayer.players[x];
                        }
                    }
                }
            },

            checkAll() {
                this.allChecked = !this.allChecked;
                for (let i = 0; i < this.data.listingData.length; i++) {
                    this.data.listingData[i].selected = this.allChecked;
                }
                this.$emit('selection-change', this.selectedItems);
            },

            deleteVideos() {
                this.itemsForDelete = this.selectedItems;
                this.deleteModal = true;
            },


            onDeleteItemRequest(item) {
                this.itemsForDelete = [item];
                this.deleteModal = true;
            },

            onResize() {
                let element = document.querySelector('#list-container');

                if (!element) {
                    return;
                }

                if (element.offsetWidth < 600) {
                    this.oldGridMode = this.gridMode;
                    this.canSwitchMode = false;
                    this.setGridMode(1);
                } else {
                    this.canSwitchMode = true;
                    this.setGridMode(0);
                }
            },
            getPage: debounce(function() {
                this.loading = true;
                $http.get(videoApiUrl('api/videos'), {
                    params: {
                        filters: {
                            'quick-search': this.searchContent,
                            ...this.headerState.filters
                        },
                        sort: {
                            column: this.headerState.sorting.targetId,
                            direction: this.headerState.sorting.direction,
                        },
                        pagination: {
                            pageSize: this.pagination.itemPerPage,
                            current: this.pagination.currentPage
                        }
                    },
                    paramsSerializer: params => {
                        return decodeURIComponent(qs.stringify(params));
                    }
                }).then(res => {
                    const data = res.data.items;
                    for (let x in data) {
                        if (Object.prototype.hasOwnProperty.call(data, x)) {
                            data[x].selected = false;
                        }
                    }

                    this.setPagination({
                        currentPage: res.data.pagination.current,
                        total: res.data.pagination.total,
                    });

                    this.data.listingData = data;
                    this.allChecked = false;
                    this.$vuetify.goTo(0);

                    this.noQuickSearchResult = res.data.items.length === 0 && this.searchContent;
                }).finally(() => this.loading = false);
            }, 400),
            download() {
                this.selectedItems.forEach((item, index) => {
                    downloadVideo(item, index);
                });
            },
            deleteItems(items) {
                let requests = items.map(function (item) {
                    return $http.delete(videoApiUrl(`api/videos/${item.id}`));
                });

                Promise.all(requests).then(() => {
                    toastSuccess('Videos deleted.');
                    this.getPage();
                    this.$emit('selection-change', []);
                });
            },
        },

        mounted() {
            window.removeEventListener('resize', this.onResize);
            window.addEventListener('resize', this.onResize);

            if (this.$route.query.videoId) {
                this.setSearch(this.$route.query.videoId);
            }

            this.onResize();
            this.$store.dispatch('libraries/getLibraries');
            this.getPage();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        }
    };
</script>

<style scoped lang="scss">
    .title-container {
        flex: 0 0 205px;
    }

    .quick-search-container {
        flex: 0 0 200px;
    }

    .view-toggle-container {
        flex: 1 1 0%;
        padding-right: 15px;
        display: none;
    }

    .download-delete-buttons {
        flex: 1 1 0%;
    }

    @media screen and (max-width: 767px) {
        .title-container {
            flex: 1 1 50%;
        }

        .quick-search-container {
            flex: 1 1 50%;
        }
    }

    @media screen and (max-width: 600px) {
        .title-container {
            flex: 0 0 100%;
        }

        .quick-search-container {
            flex: 0 0 100%;
        }

    }

    .list-loader {
        margin-top: -5px;
        margin-bottom: 5px;

    .v-progress-linear {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    }

    .sticky-nav {
        overflow: hidden;
        position: sticky;
        position: -webkit-sticky;
        top: 64px;
        z-index: 999;
        background-color: #F5F5F5;
    }

    .sticky-table-header {
        position: sticky;
        top: 136px;
        z-index: 5;
        background-color: #F5F5F5;
    }
</style>
