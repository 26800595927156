<template>
    <v-expansion-panel class="elevation-0 my-0">
        <v-expansion-panel-header
            class="has-editable-content py-0 px-6"
            @click="toggle"
        >
            <div class="v-data-table v-data-table--dense theme-light property-sheet interactive-metadata-header-table mt-3 ml-n2">
                <table>
                    <tbody>
                        <td class="py-0 pr-4 interactive-start-editor">
                            <time-index-input
                                v-if="isActiveElement(element)"
                                should-confirm
                                :value="element.start"
                                :max="video.duration/1000"
                                autofocus
                                @input="startUpdated"
                                @click.native.stop
                            />
                            <span v-else>{{ presentationStartValue }}</span>
                        </td>
                        <td class="py-0 interactive-name-editor">
                            <div class="d-flex">
                                <div class="title-content">
                                    <input
                                        v-if="isActiveElement(element)"
                                        :key="`element-name-${element.id}`"
                                        type="text"
                                        :value="element.name"
                                        @input="nameUpdated"
                                        @keyup.32.prevent
                                        @click.stop
                                    >
                                    <span v-else>{{ element.name }}</span>
                                </div>
                                <div>
                                    <v-menu class="interactive-item-options">
                                        <template v-slot:activator="{on}">
                                            <v-btn
                                                icon
                                                class="title-options ml-4 mb-3"
                                                x-small
                                                v-on="on"
                                            >
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item-group color="primary">
                                                <v-list-item @click="duplicate">
                                                    <v-list-item-icon class="mr-4">
                                                        <v-icon>content_copy</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>
                                                        Duplicate
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="replaceSlide">
                                                    <v-list-item-icon class="mr-4">
                                                        <v-icon>swap_horizontal_circle</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>
                                                        Replace slide
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="confirmDelete">
                                                    <v-list-item-icon class="mr-4">
                                                        <v-icon>delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>
                                                        Delete
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                        </td>
                    </tbody>
                </table>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content @click="toggle">
            <div
                class="item-content"
                :style="styles"
                @click="toggle"
            />
        </v-expansion-panel-content>
        <confirm-modal
            v-model="deleteModal"
            title="Delete slide"
            cancel-button="Cancel"
            confirm-button="Delete"
            @confirm="doDelete"
        >
            <p>Would you like to delete the selected slide?</p>
            {{ element.name }}
        </confirm-modal>
        <div :key="`replacer-${uploadKey}`">
            <input
                v-show="false"
                ref="replacer"
                type="file"
                accept="image/*"
                @change="fileSelected"
            >
        </div>
    </v-expansion-panel>
</template>

<script>
    import {v4 as uuidv4} from 'uuid';
    import TimeIndexInput from '@/components/controls/video-metadata/editor/TimeIndexInput';
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import TimeHelper from '@/time-helper';
    import ConfirmModal from '@/components/controls/modals/confirm-modal';

    export default {
        name: 'PresentationSidebarItem',
        components: {ConfirmModal, TimeIndexInput},
        props: ['element'],
        data() {
            return {
                deleteModal: false,
                uploadKey: 0,
            };
        },
        computed: {
            ...mapState('presentation', ['video']),
            ...mapGetters('presentation', ['isActiveElement']),
            presentationStartValue() {
                return TimeHelper.fromMsToHisU(this.element.start * 1000);
            },
            styles() {
                return {
                    'background-image': `url(${this.element.cdn_src || this.element.src})`,
                    'background-color': 'rgba(0,0,0,0.3)'
                };
            }
        },
        methods: {
            ...mapMutations('presentation', ['deactivate', 'setActiveElement']),
            ...mapActions('presentation', ['addDuplicate', 'deleteSlide', 'replaceFile', 'updatePresentation']),
            toggle() {
                if (this.isActiveElement(this.element)) {
                    this.deactivate();
                } else {
                    this.setActiveElement(this.element);
                }
            },
            replaceSlide() {
                ++this.uploadKey;
                this.$nextTick(() => {
                    this.$refs.replacer.click();
                });
            },
            duplicate() {
                this.addDuplicate({...this.element, _id: uuidv4(), name: `${this.element.name} - copy`});
            },
            confirmDelete() {
                this.deleteModal = true;
            },
            doDelete() {
                this.deleteSlide(this.element);
            },
            fileSelected(e) {
                this.replaceFile({
                    _id: this.element._id,
                    file: e.target.files[0]
                });
            },
            startUpdated(value) {
                this.updatePresentation({
                    _id: this.element._id,
                    start: value
                });
            },
            nameUpdated(e) {
                this.updatePresentation({
                    _id: this.element._id,
                    name: e.target.value
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.item-content {
  padding-top: 56.25%; /*16:9*/
  height: 0;
}

.v-expansion-panel-content__wrap {
  padding: 0 17px 16px;
}

.has-editable-content {
  padding: 0;

  td {
    font-weight: normal;
    vertical-align: middle;
  }
}

.interactive-metadata .property-sheet td:first-child {
  width: 10rem;
}

.no-height {
  height: auto !important;
}

input[type=text],
input[type=number] {
  border: 1px solid #bbbbbb;
  width: 100%;
  border-radius: 3px;
  padding: 0 3px;
}

.title-content {
  flex: 1 1 auto;
}

.title-options {
  flex: 0 0 auto;
}

.item-content {
  background-position: center center;
  background-size: contain;
}

</style>