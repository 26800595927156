import {get} from 'lodash';
import BaseArea from './BaseArea';

class InteractiveHotspotArea extends BaseArea {
    constructor(data) {
        super(data);
        this.shape = get(data, 'shape', 'rectangle');
    }
}

export default InteractiveHotspotArea;