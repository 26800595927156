<template>
    <v-content class="index-page grey lighten-4">
        <v-app-bar
            class="white-back"
            clipped-right
            app
            color="white"
            light
        >
            <v-row>
                <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="8"
                    sm="10"
                    offset-xl="1"
                    offset-lg="1"
                    offset-md="1"
                    offset-sm="1"
                    class="no-gutters"
                >
                    <v-toolbar-title style="width: 208px">
                        <img
                            src="/images/logo-quadia-horizontal-w145.png"
                            style="max-width: 145px"
                            class="mt-2"
                        >
                    </v-toolbar-title>
                </v-col>
            </v-row>
        </v-app-bar>
        <router-view />
    </v-content>
</template>

<script>
    export default {
        name: 'ExternalUploadLayout'
    };
</script>

<style scoped>
    .no-gutters {
        padding-left: 0;
        padding-right: 0;
    }

    .white-back {
        background-color: #ffffff !important;
    }
</style>
