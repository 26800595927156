<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>GDPR</v-expansion-panel-header>
        <v-expansion-panel-content>
            <quitclaim-files
                :video="video"
                @quitclaimModified="quitclaimModified"
            />
            <v-simple-table
                dense
                class="property-sheet mt-0"
            >
                <template v-slot:default>
                    <meta-people-in-video-field
                        v-if="!multipleVideos"
                        :video="video"
                    />
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import QuitclaimFiles from '../quitclaim-files';
    import MetaPeopleInVideoField from './fields/meta-people-in-video-field';
    export default {
        name: 'MetadataGdpr',
        components: {MetaPeopleInVideoField, QuitclaimFiles},
        props: ['multipleVideos', 'video'],
        methods: {
            quitclaimModified(){
                this.$emit('quitclaimModified');
            }
        }
    };
</script>

<style scoped>

</style>