<template>
    <generic-modal
        :title="`Edit ${name}`"
        :value="open"
        :max-width="maxWidth"
        @cancel="editCancelled"
        @confirm="editDone"
    >
        <template v-slot:default>
            <component
                :is="type"
                ref="editor"
                :key="update"
                v-model="editor_value"
                :meta="meta"
                @validate="onValidate"
            />
            <v-alert
                :value="error"
                small
                text
                type="error"
            >
                {{ errorMessage }}
            </v-alert>
        </template>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                text
                :disabled="error"
                @click="editDone()"
            >
                Update
            </v-btn>
            <v-btn
                text
                @click="editCancelled()"
            >
                Cancel
            </v-btn>
        </template>
    </generic-modal>
</template>

<script>
    import Longtext from './subtypes/Longtext';
    import Bordereditor from './BorderEditor';
    import Coloreditor from './ColorEditor';
    import GenericModal from '../../modals/generic-modal';

    export default {
        name: 'Modal',
        props: ['value', 'open', 'name', 'type', 'meta', 'maxDialogWidth'],
        components: {
            GenericModal,
            Longtext,
            Bordereditor,
            Coloreditor,
        },
        data() {
            return {
                editor_value: this.value,
                error: false,
                errorMessage: '',
                update: 1,
            };
        },
        watch: {
            // resets the value of the nested component if cancelled/opened
            open() {
                this.editor_value = this.value;
                this.update++;
            }
        },
        computed: {
            maxWidth() {
                return this.maxDialogWidth || '720px';
            }
        },
        methods: {
            editDone() {
                if (!this.evaluateValidation(this.$refs.editor.validate())) {
                    return;
                }
                this.$emit('input', this.editor_value);
            },
            evaluateValidation(validationResult) {
                if (validationResult !== true) {
                    this.errorMessage = validationResult.message;
                    this.error = true;
                    return false;
                } else {
                    this.error = false;
                    this.errorMessage = '';
                    return true;
                }
            },
            onValidate(result) {
                this.evaluateValidation(result);
            },
            editCancelled() {
                this.editor_value = this.value;
                this.error = false;
                this.errorMessage = '';
                this.$emit('cancel');
            },
        },
    };
</script>

<style scoped>

</style>