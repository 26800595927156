<template>
    <div class="comments-element-container">
        <div class="editor">
            <div class="visualizer">
                <comment-player
                    v-if="!isLoggedIn || video"
                    class="player-container"
                />
            </div>
            <comments-external-player-controls
                v-if="player && video.duration"
                :key="`pc-${video.duration}`"
                class="mt-4"
            />
            <comments-external-scrub v-if="player && video.duration"
                                     :key="`sc-${video.duration}`"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import CommentPlayer from '@/components/controls/comments/comment-player.vue';
    import CommentsExternalPlayerControls from '@/components/controls/comments/comments-external-player-controls.vue';
    import CommentsExternalScrub from '@/components/controls/comments/scrub/comments-external-scrub.vue';

    export default {
        name: 'CommentsElementContainer',
        components: {
            CommentsExternalScrub,
            CommentsExternalPlayerControls,
            CommentPlayer,
        },
        computed: {
            ...mapGetters('comments', ['activeElement']),
            ...mapState('comments', ['player', 'video']),
            ...mapGetters('auth', ['isLoggedIn']),
            activeKey() {
                const activeId = this.activeElement?._id || -1;
                return `comments-active-element-visual-${activeId}`;
            },
        }
    };
</script>

<style lang="scss">
.comments-element-container {
  width: calc((100vh - (220px)) * (16 / 9));
  max-width: 100%;


  .editor {
    position: relative;
    max-height: calc(100vh - (220px));
    margin-bottom: 1em;

    @media (max-height: 639px) {
      max-height: initial;
    }

    .visualizer {

      background-color: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      position: relative;

      .player-container {
        position: relative;
        display: flex;
      }

      .visual-editor-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

    }


  }

}
</style>