const simpleHash = (input) => {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        const char = input.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString(16);
};

const simpleVerify = (s, hashed) => {
    return simpleHash(s) === hashed;
};

export {
    simpleHash,
    simpleVerify
};