<template>
    <v-simple-table
        dense
        class="property-sheet file-listing-table"
    >
        <tbody>
            <tr>
                <td class="encodings-text">
                    Encodings
                </td>
                <td>
                    <v-menu
                        v-model="menuOpen"
                        :close-on-content-click="false"
                        :close-on-click="true"
                        max-width="280px"
                        bottom
                        left
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                color="primary"
                                class="mr-2 my-1 button-minwidth generate"
                                v-bind="attrs"
                                :disabled="resolutionList.length === 0"
                                v-on="on"
                            >
                                <span class="mr-1">Generate encodings</span>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-toolbar
                                light
                                dense
                                flat
                                class="pr-2"
                            >
                                <v-toolbar-title>Generate</v-toolbar-title>
                                <v-spacer />
                                <v-btn
                                    icon
                                    small
                                    @click="closeMenu"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <label for="select-resolution">Resolutions</label>
                                <v-select
                                    id="select-resolution"
                                    v-model="selectedResolutions"
                                    :items="resolutionList"
                                    placeholder="Select resolution"
                                    filled
                                    dense
                                    hide-details
                                    class="mb-4"
                                    item-text="name"
                                    item-value="height"
                                    clearable
                                    multiple
                                />
                                <label class="encoding-info">Encoding is only available in mp4 format.</label>
                            </v-card-text>
                            <v-divider />
                            <v-card-actions>
                                <v-spacer />

                                <v-btn
                                    text
                                    @click="closeMenu"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    :disabled="!selectedResolutions"
                                    color="primary"
                                    text
                                    @click="requestEncoding"
                                >
                                    Generate & send mail
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <v-list
                        dense
                        flat
                        class="tiny-actions py-0"
                    >
                        <v-list-item-group>
                            <v-list-item
                                v-for="encoding in availableEncodings"
                                :key="encoding.label"
                                class="pa-0 file-name"
                            >
                                <v-list-item-content class="pa-0 mr-1">
                                    <div class="d-flex d-row">
                                        <div class="format-variant">
                                            {{ encoding.label }} / {{ encoding.extension }}
                                        </div>
                                        <div class="format-actions">
                                            <v-tooltip top>
                                                <template v-slot:activator="{on}">
                                                    <v-btn
                                                        icon
                                                        small
                                                        :disabled="!encoding.ready"
                                                        :href="downloadEncodingUrl(encoding)"
                                                        v-on="on"
                                                    >
                                                        <v-icon class="primary--text">
                                                            mdi-download
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Download</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{on}">
                                                    <v-btn
                                                        icon
                                                        small
                                                        :disabled="!encoding.ready"
                                                        v-on="on"
                                                        @click="copyEncodingUrl(encoding)"
                                                    >
                                                        <v-icon class="primary--text file-copy-icon">
                                                            file_copy
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Copy download URL</span>
                                            </v-tooltip>
                                        </div>
                                        <div
                                            v-if="!encoding.ready"
                                            class="progress-text"
                                        >
                                            Generating
                                        </div>
                                        <div
                                            v-if="encoding.ready"
                                            class="progress-text"
                                        >
&nbsp;
                                        </div>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </td>
            </tr>
            <tr>
                <td colspan="1" />
                <td>
                    <v-btn 
                        small
                        color="primary"
                        class="mr-2 my-1 button-minwidth"
                        :disabled="sendMailDisabled"
                        @click="sendMail"
                    >
                        <v-icon small
                                class="mr-2"
                        >
                            mdi-email-send
                        </v-icon>
                        <span class="mr-1">Send mail</span>
                    </v-btn>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
    import copy from 'copy-to-clipboard';
    import {toastSuccess} from '@/services/responseErrors';
    import {$http, videoApiUrl} from '@/services/http';
    import {max} from 'lodash';

    export default {
        name: 'Encodings',
        props: ['video'],
        data() {
            return {
                menuOpen: false,
                resolutions: [
                    {name: '360p', height: 360},
                    {name: '720p', height: 720},
                    {name: '1080p', height: 1080},
                    {name: '4K', height: 2160},
                ],
                selectedResolutions: [],
                availableEncodings: [],
                mailSent: false
            };
        },
        computed: {
            resolutionList(){
                const videoHeight = max([this.video.height, this.resolutions[0].height]);
                const list = this.resolutions.filter((item) => item.height <= videoHeight);
                let usedResolutions = this.availableEncodings.map(item => item.height);
                return list.filter(item => !usedResolutions.includes(item.height));
            },
            availableEncodingsReady(){
                return this.availableEncodings.every(e => e.ready === true) && this.availableEncodings.length > 0;
            },
            sendMailDisabled(){
                if (this.mailSent) {
                    return true;
                }
                return !this.availableEncodings.some(e => e.ready === true);
            }
        },
        methods: {
            closeMenu() {
                this.menuOpen = false;
            },
            copyEncodingUrl(encoding) {                
                copy(this.downloadEncodingUrl(encoding), {format: 'text/plain'});
                toastSuccess('Copied to clipboard!');
            },
            downloadEncodingUrl(encoding) {
                return videoApiUrl('videos/' + this.video.id + '/encodings/' + encoding.height + '/' + encoding.extension);
            },
            listEncodings() {
                $http.get(videoApiUrl(`api/videos/${this.video.id}/encodings`)).then((list) => {
                    this.availableEncodings = list.data;
                });
            },
            requestEncoding() {
                $http.post(videoApiUrl(`api/videos/${this.video.id}/encodings`), {
                    heights: this.selectedResolutions,
                }).then(() => {
                    this.listEncodings();
                });
                this.closeMenu();
            },
            sendMail(){
                $http.post(videoApiUrl(`api/videos/${this.video.id}/encodings/send-mail`))
                    .then(() => {
                        this.mailSent = true;
                        toastSuccess('Email sent!');
                    });
            }
        },
        mounted() {
            this.listEncodings();
            this.$options.encodingPollInterval = setInterval(() => {
                if(!this.availableEncodingsReady){
                    this.listEncodings();
                }
            }, 12000);
        },
        beforeDestroy() {
            clearInterval(this.$options.encodingPollInterval);
        },
    };
</script>

<style lang="scss" scoped>
.v-toolbar__title {
  font-size: 1rem;
}


.encodings-text {
    max-width: 2rem !important;
}

.generate{
    font-size: 10px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
}

.encoding-info{
    font-size: 12px;
    font-style: italic;
}

.file-name {
  width: 100%;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  text-decoration: none;
  color: gray;
}

.v-list-item--active {
  color: inherit !important;
}

.file-copy-icon {
  font-size: 20px !important;
}


.d-row {
  flex-direction: row;
}

.format-variant{
  flex: 0 0 7rem;
  line-height:28px;
}
.format-actions{
  flex: 0 0 auto;
}
.progress-text{
  flex: 0 0 auto;
  line-height: 28px;
}
</style>
