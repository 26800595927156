const main = {
    namespaced: true,
    state: {
        leftDrawer: false,
        gridMode: 0,
        metaOpenTabs: [0],
        uploadErrors: [],
        uploadErrorModal: false,
    },
    mutations: {
        setGridMode(state, value) {
            state.gridMode = value;
        },
        toggleLeft(state) {
            state.leftDrawer = !state.leftDrawer;
        },
        closeLeftDrawer(state) {
            state.leftDrawer = false;
        },
        setLeftDrawer(state, newValue) {
            state.leftDrawer = newValue;
        },
        setMetaOpenTabs(state, newValue) {
            state.metaOpenTabs = newValue;
        },
        addUploadError: (state, value) => {
            if (state.uploadErrors.filter(e => e.id === value.id).length === 0) {
                state.uploadErrors.push(value);
            }
            state.uploadErrorModal = true;
        },
        clearUploadErrors: (state) => state.uploadErrors = [],
        setUploadErrorModal: (state, value) => state.uploadErrorModal = value
    }
};

export default main;
