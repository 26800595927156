<template>
    <v-container
        fluid
        class="pt-0"
    >
        <v-row
            justify-center
            align-center
            no-gutters
        >
            <v-col>
                <div class="content">
                    <v-col class="pt-0">
                        <div class="sticky-nav pt-7">
                            <div class="d-flex ml-4 mr-4 header-container">
                                <h1 class="page-header">
                                    Usage Stats
                                </h1>
                                <v-btn :disabled="disableDownloadButton"
                                       small
                                       class="ml-2 v-btn download-button"
                                       elevation="0"
                                       :outlined="true"
                                       :loading="showCsvProgress"
                                       style=""
                                       :href="csvContent"
                                       :download="csvFileName"
                                >
                                    Download CSV
                                </v-btn>
                            </div>
                        </div>
                        <companies-usage-stats
                            @loaded="usageStatsLoaded"
                        />
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CompaniesUsageStats from '../controls/companies-usage-stats';

    export default {
        name: 'UsageStatsPage',
        components: {CompaniesUsageStats},
        data() {
            return {
                disableDownloadButton: true,
                showCsvProgress: true,
                csvContent: ''
            };
        },
        computed: {
            csvFileName() {
                return 'qp-usage-stats-' + new Date().toLocaleDateString('nl') + '.csv';
            }
        },
        methods: {
            usageStatsLoaded(usageStats) {
                this.showCsvProgress = false;
                this.disableDownloadButton = false;

                let csvContent = 'data:text/csv;charset=utf-8,';
                let header = ['Company Name', 'Users', 'Libraries', 'Videos', 'Used Storage'];
                csvContent += header.join(',') + '\n';
                usageStats.forEach(function(rowObject){
                    let rowArray = Object.values(rowObject);
                    rowArray.shift();
                    rowArray[0] = '"' + rowArray[0] + '"';
                    csvContent += rowArray.join(',') + '\n';
                });
                this.csvContent = csvContent;
            }
        }
    };
</script>

<style scoped>

.download-button {
    background-color: white;
    border-color:rgb(204, 204, 204);
    color: rgb(85, 85, 85)
}

</style>
