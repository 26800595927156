<template>
    <stats-card class="h13 metric"
                :loading="loadingStarts"
    >
        <template v-slot:title>
            Starts
        </template>
        <template v-slot:content>
            {{ starts }}
        </template>
    </stats-card>
</template>

<script>
    import { $http, getStatQueryRequest } from '@/services/http';
    import StatsCard from './stats-card';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'CardStarts',
        components: {
            StatsCard,
        },
        computed: {
            ...mapState('statistics-details', [
                'starts',
                'loadingStarts',
                'dateRange',
                'videoId',
            ]),
        },
        methods: {
            ...mapMutations('statistics-details', ['setStarts', 'setLoadingStarts']),
            async getStarts() {
                this.setLoadingStarts(true);
                const response = await $http.get(
                    getStatQueryRequest('api/videos/views/', [this.videoId], this.dateRange)
                );
                const buckets = response.data.filter((item) => item.key === this.videoId);
                this.setStarts(buckets.length > 0 ? buckets[0].doc_count : 0);
                this.setLoadingStarts(false);
            },
        },
        mounted() {
            this.getStarts();
        },
    };
</script>