import { $http } from './http';
import jwtDecode from 'jwt-decode';
import { RequestRetryService } from './request-retry-service';
import { $refreshTokenService } from './refresh-token-service.js';
import crypto from 'crypto-js';
import router from '@/router.js';
import store from '../store/index';
import {
    toastError, handleBadRequest, handleNotFound,
    handleServerErrors, handleTooEarly, handleTooManyAttempts,
    handleUnauthorized, handleUnprocessable
} from './responseErrors';

export const $auth = {
    loginInProgress: false,
    authCallback() {
        let props = router.resolve({ name: 'AuthCallback' });
        return `${location.protocol}//${location.host}${props.href}`;
    },
    login() {
        if (this.loginInProgress) {
            return;
        }
        this.loginInProgress = true;
        const state = createRandomString(40);
        const verifier = createRandomString(128);
        const challenge = base64Url(crypto.SHA256(verifier));
        window.sessionStorage.setItem('state', state);
        window.sessionStorage.setItem('verifier', verifier);
        window.sessionStorage.setItem('auth_callback_redirect_uri', window.location.href);

        window.location.href = `${process.env.VUE_APP_ACCOUNTS_API_HOST}sso/auth
?client_id=${process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID}
&redirect_uri=${this.authCallback()}
&response_type=code&scope=*
&state=${state}
&code_challenge=${challenge}
&code_challenge_method=S256`;
    },
    async askForToken(code) {
        const url = process.env.VUE_APP_ACCOUNTS_API_HOST + 'sso/token';
        const response = await $http.post(url, {
            grant_type: 'authorization_code',
            client_id: process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID,
            redirect_uri: this.authCallback(),
            code_verifier: window.sessionStorage.getItem('verifier'),
            code,
        });
        window.sessionStorage.removeItem('state');
        window.sessionStorage.removeItem('verifier');

        sessionStorage.setItem('access_token', response.data.access_token);
        sessionStorage.setItem('refresh_token', response.data.refresh_token);

        this.checkAuth();
    },
    loginByAccessToken(token) {
        sessionStorage.removeItem('refresh_token');
        sessionStorage.setItem('access_token', token);
        this.checkAuth();
        return Promise.resolve();
    },
    refresh() {
        const refresh_token = sessionStorage.getItem('refresh_token');
        if (!refresh_token) {
            this.login();
            return Promise.reject();
        }

        if ($refreshTokenService.isInProgress()) {
            return $refreshTokenService.awaitRefresh();
        }

        $refreshTokenService.start();
        const url = process.env.VUE_APP_ACCOUNTS_API_HOST + 'sso/token';
        return $http.post(url, {
            grant_type: 'refresh_token',
            client_id: process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID,
            refresh_token,
        }).then((response) => {
            window.sessionStorage.setItem('access_token', response.data.access_token);
            window.sessionStorage.setItem('refresh_token', response.data.refresh_token);

            this.checkAuth();
        }).catch(() => {
            this.login();
        }).finally(() => {
            $refreshTokenService.finish();
        });
    },
    logout() {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        store.commit('auth/logout');
        store.commit('layout/setMetaOpenTabs', [0]);

        window.location.href = process.env.VUE_APP_ACCOUNTS_API_HOST + 'sso/logout';
    },
    checkAuth() {
        var jwt = window.sessionStorage.getItem('access_token');
        if (jwt) {
            const payload = jwtDecode(jwt);
            store.commit('auth/login', payload);
        } else {
            store.commit('auth/logout');
        }
    },
    getAuthHeader() {
        return 'Bearer ' + window.sessionStorage.getItem('access_token');
    }
};

$http.interceptors.request.use(config => {
    $auth.checkAuth();
    if (store.state.auth.authenticated) {
        config.headers.Authorization = $auth.getAuthHeader();
    }
    return config;
}, error => Promise.reject(error));

$http.interceptors.response.use((response) => {
    RequestRetryService.clearAttempts(response);
    return response;
}, async (error) => {
    switch (error.response.status) {
        case 400:
            handleBadRequest(error);
            break;
        case 401:
            return handle401(error.response);
        case 403:
            handleUnauthorized(error);
            break;
        case 404:
            handleNotFound(error);
            break;
        case 422:
            handleUnprocessable(error);
            break;
        case 425:
            handleTooEarly(error);
            break;
        case 429:
            handleTooManyAttempts(error);
            break;
        case 500:
        default:
            handleServerErrors(error);
            break;
    }

    return Promise.reject(error);
});

$auth.checkAuth();

function handle401(errorResponse) {
    if (errorResponse.config) {
        const data = JSON.parse(errorResponse.config.data || '{}');
        if (data.grant_type === 'refresh_token') {
            $auth.login();
            return Promise.reject();
        }
    }

    return $auth.refresh().then(function () {
        RequestRetryService.incrementNumberOfAttempts(errorResponse);
        if (RequestRetryService.tooManyAttempts(errorResponse)) {
            toastError('You are not allowed to access this feature.');
            return Promise.reject(errorResponse);
        } else {
            return $http.request(errorResponse.config);
        }
    });
}

function base64Url(string) {
    return string.toString(crypto.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

function createRandomString(num) {
    return [...Array(num)].map(() => Math.random().toString(36)[2]).join('');
}
