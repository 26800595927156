<template>
    <tr>
        <td class="pt-1 pb-1 vertical-align-top">
            YouTube
        </td>
        <td
            v-if="loading"
            class="pt-1 pb-1"
        >
            <v-progress-circular
                indeterminate
                size="22"
                color="grey"
            />
        </td>
        <td
            v-if="!loading"
            :key="`youtube-publishing-${publishUpdate}`"
            class="pt-1 pb-1"
        >
            <v-btn
                v-if="canPublish"
                small
                color="primary"
                class="mr-2 mb-1 copy-button button-minwidth"
                @click="publishSetup()"
            >
                <v-icon class="mr-1">
                    publish
                </v-icon>
                <span class="mr-5">Publish</span>
            </v-btn>
            <v-sheet
                v-else
                color="grey--text"
            >
                <ul class="publish-errors">
                    <li v-if="!videoCompanyId || !hasYoutubeAccounts">
                        <v-icon
                            small
                            class="mr-2 mt-n1"
                        >
                            error_outline
                        </v-icon>
                        No account linked.
                    </li>
                </ul>
            </v-sheet>
            <v-sheet v-if="hasPublishingData">
                <div
                    v-for="publishingItem in publishingData"
                    :key="`youtube-publishing-data-${publishingItem.date}`"
                >
                    <v-sheet
                        height="28"
                        style="line-height: 28px;"
                        class="publish-date"
                    >
                        Published:
                        {{ getPublishDate(publishingItem) }}
                        <v-btn
                            v-if="publishingItem.status && publishingItem.status ==='uploaded'"
                            icon
                            text
                            small
                            color="black"
                            :href="getItemHref(publishingItem)"
                            target="_blank"
                        >
                            <v-icon color="primary">
                                open_in_new
                            </v-icon>
                        </v-btn>
                        <v-tooltip
                            v-if="!publishingItem.status || publishingItem.status==='pending'"
                            top
                        >
                            <template v-slot:activator="{on}">
                                <v-icon
                                    small
                                    color="gray"
                                    v-on="on"
                                >
                                    hourglass_empty
                                </v-icon>
                            </template>
                            <span>Upload in progress...</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="!publishingItem.status || publishingItem.status==='failed'"
                            top
                        >
                            <template v-slot:activator="{on}">
                                <v-icon
                                    small
                                    color="error"
                                    v-on="on"
                                >
                                    error
                                </v-icon>
                            </template>
                            <span>Failure reason: {{ publishingItem.failure_reason }}</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="!publishingItem.status || publishingItem.status==='rejected'"
                            top
                        >
                            <template v-slot:activator="{on}">
                                <v-icon
                                    small
                                    color="error"
                                    v-on="on"
                                >
                                    call_missed
                                </v-icon>
                            </template>
                            <span>Rejected reason: {{ publishingItem.rejection_reason }}</span>
                        </v-tooltip>
                    </v-sheet>
                </div>
            </v-sheet>
            <validation-observer
                ref="validateYouTubePublish"
                v-slot="{invalid, validate}"
            >
                <generic-modal
                    v-model="publishModal"
                    title="Publish to YouTube"
                    confirm-button="Publish"
                    canccel-button="Cancel"
                    confirm-color="primary"
                    @confirm="publish()"
                    @cancel="cancel()"
                >
                    <template v-slot:default>
                        <v-row>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Title"
                                    :rules="{required: true, max: 100}"
                                >
                                    <v-text-field
                                        v-model="publishOptions.title"
                                        dense
                                        label="Title"
                                        counter="100"
                                        :error-messages="errors"
                                        @blur="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            Titles longer than 70 characters will be truncated in search results.
                                        </info-tooltip>
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Description"
                                    :rules="{required: true, max: 1000}"
                                >
                                    <v-textarea
                                        v-model="publishOptions.description"
                                        dense
                                        label="Description"
                                        counter="1000"
                                        :error-messages="errors"
                                        @blur="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            Only the first 125 characters of the description will appear as a snippet in
                                            search.
                                        </info-tooltip>
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Account"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="publishOptions.accountId"
                                        dense
                                        :items="youtubeAccounts"
                                        item-text="name"
                                        item-value="id"
                                        label="YouTube account"
                                        :error-messages="errors"
                                        @input="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            YouTube publish supports multiple accounts.
                                            The account is required to select the category as well as for the upload
                                            itself.
                                        </info-tooltip>
                                    </v-select>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="publishOptions.tags"
                                    dense
                                    label="Tags"
                                >
                                    <info-tooltip slot="append-outer">
                                        Tags are limited to 30 characters / tag, separated by comma and may only contain
                                        numbers, letters and spaces.
                                    </info-tooltip>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Category"
                                    rules="required"
                                >
                                    <v-autocomplete
                                        ref="youtubeCategories"
                                        v-model="publishOptions.category"
                                        dense
                                        :items="categories"
                                        item-text="name"
                                        item-value="id"
                                        label="YouTube Category"
                                        :error-messages="errors"
                                        :disabled="categoriesLoading"
                                        @input="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            Categories are predefined by YouTube and are linked to the selected account.
                                        </info-tooltip>
                                    </v-autocomplete>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Privacy"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="publishOptions.privacy"
                                        dense
                                        label="Privacy"
                                        :items="privacyOptions"
                                        :error-messages="errors"
                                        @input="validate"
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:terms>
                        <div class="pb-2 px-4">
                            <a
                                style="color: #666666"
                                href="https://www.youtube.com/t/terms"
                                target="_blank"
                            >YouTube Terms
                                of Service</a><br>
                            <a
                                style="color: #666666"
                                href="https://policies.google.com/privacy"
                                target="_blank"
                            >Google
                                Privacy Policy</a>
                        </div>
                    </template>
                    <template v-slot:actions>
                        <v-progress-circular
                            v-if="isPublishing"
                            indeterminate
                        />
                        <v-btn
                            :disabled="invalid"
                            color="primary"
                            text
                            @click="publish()"
                        >
                            Publish
                        </v-btn>
                        <v-btn
                            text
                            @click="cancel()"
                        >
                            Cancel
                        </v-btn>
                    </template>
                </generic-modal>
            </validation-observer>
        </td>
    </tr>
</template>

<script>
    import moment from 'moment-timezone';
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import GenericModal from '@/components/controls/modals/generic-modal';
    import InfoTooltip from '@/components/primitives/info-tooltip';
    import {$http, publishingApiUrl} from '@/services/http';
    import {getYouTubeAccount, publishToYouTube} from '@/services/youtube';

    export default {
        name: 'PublishYoutube',
        components: {
            InfoTooltip,
            GenericModal,
            ValidationObserver,
            ValidationProvider,
        },
        props: ['video', 'log', 'videoCompanyId'],
        data() {
            return {
                youtubeAccounts: [],
                publishOptions: {
                    accountId: '',
                    title: '',
                    description: '',
                    tags: '',
                    category: '',
                    privacy: '',
                },
                categories: [],
                privacyOptions: [
                    'public',
                    'private',
                    'unlisted',
                ],
                categoriesLoading: false,
                publishingData: this.log,
                loading: false,
                publishUpdate: 0,
                publishModal: false,
                isPublishing: false,
            };
        },
        computed: {
            canPublish() {
                return !!this.videoCompanyId && this.hasYoutubeAccounts;
            },
            hasPublishingData() {
                return this.publishingData.length > 0;
            },
            hasYoutubeAccounts() {
                return this.youtubeAccounts.length > 0;
            },
        },
        watch: {
            'publishOptions.accountId'(value, oldValue) {
                if (!value || value === oldValue) {
                    return;
                }

                this.getCategories(value);
            },
            publishModal(value) {
                this.$emit('popup-status', value);
            }
        },
        methods: {
            getItemHref(publishingItem) {
                return publishingItem.link || '#';
            },
            getPublishDate(publishingItem) {
                return publishingItem.date ? publishingItem.date.split(' ')[0] : 'n/a';
            },
            publishSetup() {
                this.publishModal = true;
                setTimeout(() => {
                    if (this.youtubeAccounts.length === 1) {
                        this.publishOptions.accountId = this.youtubeAccounts[0].id;
                        this.$nextTick(() => {
                            this.$refs.validateYouTubePublish.validate();
                        });

                    }
                }, 100);
            },
            publish() {
                if (this.$refs.validateYouTubePublish.ctx.invalid) {
                    return;
                }

                const publishingData = {
                    account_id: this.publishOptions.accountId,
                    video_title: this.publishOptions.title,
                    video_description: this.publishOptions.description,
                    category: this.publishOptions.category,
                    tags: this.publishOptions.tags.split(','),
                    privacy_status: this.publishOptions.privacy,
                    file_size: this.video.size,
                    duration: this.video.duration,
                    video_url: this.video.source_url,
                    meta: {
                        video_id: this.video.id,
                        platform: 'qpd',
                        external_platform: 'youtube',
                        account_name: this.youtubeAccounts
                            .find(account => account.id === this.publishOptions.accountId).name
                    }
                };

                this.isPublishing = true;
                publishToYouTube(publishingData).then(() => {
                    this.loading = true;

                    const publishingStatus = {
                        uri: '',
                        link: '',
                        accountId: this.publishOptions.accountId,
                        accountName: this.youtubeAccounts.find(item => item.id === this.publishOptions.accountId).name,
                        status: 'pending',
                    };

                    this.publishingData.push({
                        ...publishingStatus,
                        date: moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                    this.publishUpdate++;
                    this.loading = false;
                    this.publishModal = false;
                }).finally(() => this.isPublishing = false);
            },
            cancel() {
                this.publishModal = false;
            },
            prepareModal() {
                this.publishOptions.title = this.video.title;
                this.publishOptions.description = this.video.description;
                this.publishOptions.tags = this.video.tags.join(',');
                this.categories = [];
                this.publishOptions.accountId = '';
            },
            getCategories(forYouTubeAccount) {
                this.categoriesLoading = true;
                $http.get(publishingApiUrl(`/qpd/categories/youtube?id=${forYouTubeAccount}`)).then(response => {
                    this.categories = response.data;
                    this.categoriesLoading = false;
                });
            }
        },
        mounted() {
            this.loading = true;
            this.prepareModal();
            if (!this.videoCompanyId) {
                this.loading = false;
                return;
            }
            return getYouTubeAccount(this.videoCompanyId)
                .then(accounts => this.youtubeAccounts = accounts)
                .finally(() => this.loading = false);
        }
    };
</script>

<style scoped>
    .publish-errors {
        margin-top: 1px;
        list-style: none;
        padding: 0;
    }
</style>