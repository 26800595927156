<template>
    <confirm-modal
        title="Delete videos"
        :value="value"
        confirm-button="Delete"
        cancel-button="Cancel"
        @cancel="cancel"
        @confirm="confirm"
    >
        <p>You are about to permanently delete the following video(s)</p>
        <v-list>
            <v-list-item
                v-for="video in videos"
                :key="'delete-' + video.id"
                dense
            >
                <v-list-item-content>
                    {{ video.title }}
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </confirm-modal>
</template>

<script>
    import ConfirmModal from './confirm-modal';

    export default {
        name: 'DeleteVideos',
        components: {ConfirmModal},
        props: ['value', 'videos'],
        methods: {
            confirm() {
                this.$emit('confirm', this.videos);
                this.$emit('input', false);
            },

            cancel() {
                this.$emit('input', false);
            },
        }
    };
</script>

<style scoped>

</style>
