<template>
    <v-menu
        v-model="open"
        :close-on-content-click="false"
        :close-on-click="true"
        attach="#videoListFilter"
    >
        <template v-slot:activator="{ on: menu }">
            <v-tooltip
                bottom
                open-delay="1000"
            >
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        id="videoListFilter"
                        icon
                        class="ma-auto mr-2 text-capitalize list-toolbar-dropdown list-toolbar-dropdown-left"
                        v-on="{...tooltip, ...menu}"
                    >
                        <v-icon
                            v-if="!open"
                            dark
                        >
                            mdi-filter
                        </v-icon>
                        <v-icon
                            v-if="open"
                            dark
                            color="primary"
                        >
                            mdi-filter
                        </v-icon>
                    </v-btn>
                </template>
                <span>Filter</span>
            </v-tooltip>
        </template>
        <v-card @click.native.stop="() => {}">
            <v-card-text>
                <h3 class="filter-picker-subtitle">
                    Status
                </h3>
                <privacy-filter
                    column-id="privacy"
                    :value="value.filters"
                    flat
                    @input="filterChanged"
                />
                <v-divider />
                <h3 class="filter-picker-subtitle">
                    Library
                </h3>
                <library-filter
                    column-id="library"
                    :value="value.filters"
                    flat
                    @input="filterChanged"
                />
                <slot />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <div class="flex-grow-1" />
                <v-btn
                    small
                    text
                    @click.stop="cancelFilters"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    import LibraryFilter from '@/components/controls/filters/media-library/library-filter';
    import PrivacyFilter from '@/components/controls/filters/media-library/privacy-filter';
    export default {
        name: 'FilterPicker',
        components: {PrivacyFilter, LibraryFilter},
        props: {
            value: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                open: false
            };
        },
        methods: {
            cancelFilters() {
                this.open = false;
            },
            filterChanged(filters) {
                this.$emit('input', {...this.value, filters});
            }
        }
    };
</script>

<style scoped>
.filter-picker-subtitle {
    font-size: 1rem;
    color: black;
    text-align: left;
    margin-top: 0.5rem;
}

.filter-picker-subtitle:first-child {
    margin-top: 0;
}
</style>
