import InteractiveOrigin from '../format/primitives/InteractiveOrigin';
import InteractiveDimensions from '../format/primitives/InteractiveDimensions';
import InteractiveBorder from '../format/primitives/InteractiveBorder';
import InteractiveHotspotFormat from '../format/InteractiveHotspotFormat';

class HotspotFormatFactory {
    make(data) {
        return new InteractiveHotspotFormat({
            origin: new InteractiveOrigin(data.origin),
            dimensions: new InteractiveDimensions(data.dimensions),
            'default': {
                border: new InteractiveBorder({
                    color: 'rgba(255,255,255, 0.7)',
                    width: 1
                }),
                background: {
                    color: 'rgba(255,255,255,0.1)'
                }
            },
            hover: {
                border: new InteractiveBorder({
                    color: 'rgba(255,255,255, 1)',
                    width: 1
                }),
                background: {
                    color: 'rgba(255,255,255,0.5)'
                },
            }
        });
    }
}

export default new HotspotFormatFactory();