import InteractiveElementFormat from './InteractiveElementFormat';

class InteractiveImageFormat extends InteractiveElementFormat {
    constructor(data) {
        super(data);
        this.default.background = {
            path: data.default?.background?.path || null,
            url: data.default?.background?.url || null
        };
        this.hover.background = {
            path: data.hover?.background?.path || null,
            url: data.hover?.background?.url || null
        };
    }
}

export default InteractiveImageFormat;