var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comment-form px-3 pt-4 mx-n3 no-error-color"},[_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('v-form',{ref:"commentForm",on:{"submit":function($event){$event.preventDefault();return _vm.send($event)}}},[(!_vm.isLoggedIn)?_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","dense":"","outlined":"","error-messages":errors,"background-color":"#fff","disabled":_vm.formLoading},on:{"keyup":validate},model:{value:(_vm.commenterUserName),callback:function ($$v) {_vm.commenterUserName=$$v},expression:"commenterUserName"}})]}}],null,true)}):_vm._e(),(!_vm.isLoggedIn)?_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","label":"E-mail address","dense":"","outlined":"","error-messages":errors,"background-color":"#fff","disabled":_vm.formLoading},on:{"keyup":validate},model:{value:(_vm.commenterUserEmail),callback:function ($$v) {_vm.commenterUserEmail=$$v},expression:"commenterUserEmail"}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"grey--text tiny--text mb-2 mt-n1"},[_c('span',{staticClass:"warning--text"},[_c('v-icon',{attrs:{"color":"warning","x-small":""}},[_vm._v(" warning ")]),_vm._v(" Please don't input sensitive information in this form. Chat messages are visible to the public. ")],1),_vm._v(" Shift+Enter adds line breaks. Enter: sends message. ")]),_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb-2",attrs:{"disabled":_vm.formLoading,"error-messages":errors,"auto-grow":"","background-color":"#fff","clearable":"","dense":"","label":"Message","no-resize":"","outlined":"","rows":"2","hide-details":""},on:{"keyup":validate,"keydown":_vm.processKeys},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"submit","icon":"","color":"primary","disabled":_vm.formLoading || !validated || invalid}},on),[_c('v-icon',[_vm._v("send")])],1)]}}],null,true)},[_c('span',[_vm._v("Send")])])]},proxy:true}],null,true),model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }