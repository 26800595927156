<template>
    <v-tooltip
        bottom
        max-width="300"
        nudge-left="150"
    >
        <template v-slot:activator="{on}">
            <v-icon v-on="on">
                info
            </v-icon>
        </template>
        <span>
            <slot />
        </span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'InfoTooltip',
    };
</script>

<style scoped>

</style>