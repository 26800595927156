/**
 * Creates a XHR request
 *
 * @param {Object} options
 * @param {Number} timeout
 */
export const createRequest = (options, timeout) => {
  const xhr = new XMLHttpRequest();
  xhr.open(options.method || 'GET', options.url);
  xhr.timeout = timeout;
  xhr.responseType = 'json';
  if (options.headers) {
    Object.keys(options.headers).forEach(key => {
      xhr.setRequestHeader(key, options.headers[key]);
    });
  }

  return xhr;
};

/**
 * Sends a XHR request with certain form data
 *
 * @param {XMLHttpRequest} xhr
 * @param {Object} data
 */
export const sendFormRequest = (xhr, data) => {
  const body = new FormData();
  for (var name in data) {
    body.append(name, data[name]);
  }
  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        var response;
        try {
          response = JSON.parse(xhr.response);
        } catch (err) {
          response = xhr.response;
        }
        resolve(response);
      } else {
        reject(xhr.response);
      }
    };
    xhr.ontimeout = () => reject('timeout');
    xhr.onerror = () => {
      if (xhr.status === 0 && xhr.readyState === 4) {
        setTimeout(() => reject(xhr.response), 30000);
      }
    };
    xhr.send(body);
  });
};
