import { sortBy } from 'lodash';
import PresentationSettings from '@/models/presentation/PresentationSettings';
import PresentationItem from '@/models/presentation/PresentationItem';

export default class Presentation {
    constructor(data) {
        this.settings = new PresentationSettings(data.settings || {});
        this.items = (data.items || []).map(item => new PresentationItem(item));
        this.items = sortBy(this.items, 'start');
    }
}