<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>Interactive tools</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-btn
                v-if="canEdit"
                class="mr-4"
                small
                color="primary"
                :to="{name: 'interactiveEditor', params: {id: video.id}}"
            >
                Interactive Editor
            </v-btn>
            <v-btn
                v-if="canEdit"
                class="mr-4"
                small
                color="primary"
                :to="{name: 'presentationEditor', params: {id: video.id}}"
            >
                Presentation Editor
            </v-btn>
            <chapters
                class="mt-4"
                :video="video"
                @input="chaptersUpdated"
            >
                <template v-slot:title>
                    Chapters
                </template>
            </chapters>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import Gate from '../../../services/gate';
    import store from '../../../store/index';
    import Chapters from './editor/Chapters';
    export default {
        name: 'MetadataInteractive',
        components: {Chapters},
        props: ['video'],
        computed: {
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            },
        },
        methods: {
            chaptersUpdated() {
                this.$emit('input');
            }
        }
    };
</script>

<style scoped>

</style>
