<template>
    <v-card tile
            elevation="2"
            class="mt-1"
    >
        <v-card-title class="tiny py-1">
            {{ name }}
            <v-spacer />
            <span class="grey--text font-weight-regular">{{ createdAt }}</span>
            <resolve class="ml-2"
                     :status="comment.status"
                     :comment-id="comment.id"
            />
        </v-card-title>
        <v-card-text class="mt-4">
            {{ comment.message }}
        </v-card-text>
    </v-card>
</template>

<script>
    import Resolve from '@/components/controls/comments/sidebar/resolve.vue';

    export default {
        name: 'Comment',
        components: {Resolve},
        props: {
            comment: {
                type: Object,
                required: true
            }
        },
        computed: {
            createdAt() {
                return this.comment.created_at_formatted;
            },
            name() {
                return this.comment.name ?? this.comment.user_name;
            }
        }
    };
</script>

<style scoped>
.tiny {
    font-size: 12px;
    line-height: 1.5em;
    border-bottom: 1px solid #ccc;
}
</style>