<template>
    <div class="presentation-element-container">
        <div class="editor">
            <div class="visualizer">
                <presentation-player
                    v-if="video"
                    class="player-container"
                />
            </div>
            <presentation-external-player-controls
                v-if="player"
                class="mt-4"
            />
            <presentation-external-scrub v-if="player" />
        </div>
    </div>
</template>

<script>
    import PresentationPlayer from '@/components/controls/presentation/presentation-player';
    import PresentationExternalPlayerControls
        from '@/components/controls/presentation/presentation-external-player-controls';
    import PresentationExternalScrub from '@/components/controls/presentation/external-scrub/presentation-external-scrub';
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'PresentationElementContainer',
        components: {
            PresentationExternalScrub,
            PresentationExternalPlayerControls,
            PresentationPlayer
        },
        computed: {
            ...mapGetters('presentation', ['activeElement']),
            ...mapState('presentation', ['player', 'video', 'presentation']),
            activeKey() {
                const activeId = this.activeElement?._id || -1;
                return `presentation-active-element-visual-${activeId}`;
            },
        }
    };
</script>

<style lang="scss">
.presentation-element-container {
  width: calc((100vh - (220px)) * (16 / 9));
  max-width: 100%;


  .editor {
    position: relative;
    max-height: calc(100vh - (220px));
    margin-bottom: 1em;

    @media (max-height: 639px) {
      max-height: initial;
    }

    .visualizer {

      background-color: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      position: relative;

      .player-container {
        position: relative;
        display: flex;
      }

      .visual-editor-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

    }


  }

}
</style>