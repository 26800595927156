<template>
    <v-btn
        :color="color"
        icon
        small
        @click="applyHeaderSort"
    >
        <v-icon small>
            {{ icon }}
        </v-icon>
    </v-btn>
</template>

<script>
    import {mapActions, mapMutations} from 'vuex';

    export default {
        name: 'HeaderSortButton',
        props: {
            column: {
                type: String,
                required: true,
            },
            direction: {
                type: String,
                default: 'asc'
            },
            color: {
                type: String,
                default: '',
            }
        },
        computed: {
            icon() {
                return 'mdi-arrow-' + (this.direction === 'asc' ? 'up' : 'down');
            }
        },
        methods: {
            ...mapMutations('ftp', ['setSortedBy']),
            ...mapActions('ftp', ['sortFiles']),
            applyHeaderSort() {
                this.setSortedBy({direction: this.direction, key: this.column});
                this.sortFiles();
            },
        }
    };
</script>

<style scoped>

</style>