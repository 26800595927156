import {$http, videoApiUrl} from '../services/http';
const urlString = 'api/libraries/{id}/logo';
const viewingPageBackgroundUrlString = 'api/libraries/{id}/viewing-page-background';

export function saveLogo(libraryId, logoData) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('logo', logoData, logoData.name);

        $http.post(videoApiUrl(urlString.replace('{id}', libraryId)), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => resolve(response.data))
            .catch(e => reject(e));
    });
}

export function deleteLogo(libraryId) {
    return new Promise((resolve, reject) => {
        $http.delete(videoApiUrl(urlString.replace('{id}', libraryId)))
            .then(() => resolve())
            .catch(e => reject(e));
    });
}

export function saveViewingPageBackground(libraryId, imageData) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('viewingPageBackground', imageData, imageData.name);
        $http.post(videoApiUrl(viewingPageBackgroundUrlString.replace('{id}', libraryId)), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => resolve(response.data))
            .catch(e => reject(e));
    });
}

export function deleteViewingPageBackground(libraryId) {
    return new Promise((resolve, reject) => {
        $http.delete(videoApiUrl(viewingPageBackgroundUrlString.replace('{id}', libraryId)))
            .then(() => resolve())
            .catch(e => reject(e));
    });
}
