import { $http, ftpApiUrl } from '@/services/http';
import {toastError} from '@/services/responseErrors';

const ftp = {
    namespaced: true,
    state: {
        sortedBy:{
            direction: 'asc',
            key: 'path'
        },
        selectedFolder: '',
        files: [],
        selectedFiles: []
    },
    mutations:{
        setSortedBy: (state, value) => state.sortedBy = value,
        setSelectedFolder: (state, value) => state.selectedFolder = value,
        setFiles: (state, value) => state.files = value,
        setSelectedFiles: (state, value) => state.selectedFiles = value
    },
    actions:{
        async getFiles({ commit, state }) {
            try {
                const {data} = await $http.get(ftpApiUrl(`/ftp?path=${state.selectedFolder}`));
                commit('setFiles', data);
            } catch (e) {
                let message = e.message;
                if (e.response && e.response.status === 404) {
                    message = e.response.data.message;
                }
                toastError(message);
                commit('setFiles', []);
            }
            commit('setSelectedFiles', []);
        },
        sortFiles({commit, state}) {
            let files = new Array(...state.files);
            const direction = state.sortedBy.direction;
            const key = state.sortedBy.key;
            if(direction === 'asc'){
                files.sort((a, b) => a[key] > b[key] ? 1 : -1);
            } else {
                files.sort((a, b) => a[key] < b[key] ? 1 : -1);
            }
            commit('setFiles', files);
        },
        resetStore({commit}) {
          commit('setFiles', []);
          commit('setSelectedFiles', []);
          commit('setSelectedFolder', '');
          commit('setSortedby', {direction: 'asc',key: 'path'});
        }
    },
};

export default ftp;