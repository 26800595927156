<template>
    <fragment>
        <metadata-preview
            v-if="!multipleVideos"
            :key="`preview-${selectedId}-${updateCount}`"
            :video="selectedVideo"
        />
        <v-expansion-panels
            :key="videosHash + ',' + selectedId"
            ref="videoMetadataPanels"
            v-model="openTabs"
            accordion
            class="video-metadata"
        >
            <metadata-general
                v-if="!multipleVideos"
                :videos="videos"
                :video="selectedVideo"
                :multiple-videos="multipleVideos"
            />
            <metadata-thumbnail
                v-if="!multipleVideos && selectedVideo"
                :key="'thumbnail-' + selectedId"
                :video="selectedVideo"
                @input="updateThumbnail"
            />
            <metadata-project
                v-if="!multipleVideos && selectedVideo"
                :key="'project-' + selectedId"
                :video="selectedVideo"
            />
            <metadata-gdpr
                v-if="!multipleVideos && selectedVideo"
                :key="'gdpr-' + selectedId"
                :video="selectedVideo"
                @quitclaimModified="getVideo(true)"
            />
            <metadata-publish
                v-if="selectedVideo && !multipleVideos"
                :videos="videos"
                :video="selectedVideo"
            />
            <metadata-analytics
                v-if="!multipleVideos && selectedVideo"
                :video="selectedVideo"
            />
            <metadata-subtitles
                v-if="!multipleVideos && selectedVideo"
                :key="'subtitles' + selectedId"
                :video="selectedVideo"
            />
            <metadata-interactive
                v-if="!multipleVideos && selectedVideo"
                :key="'interactive' + selectedId"
                :video="selectedVideo"
                @input="updateChapters"
            />
            <metadata-general-multiple v-if="multipleVideos"
                                       :videos="videos"
            />
            <metadata-file
                :videos="videos"
                :video="selectedVideo"
                :multiple-videos="multipleVideos"
            />
        </v-expansion-panels>
    </fragment>
</template>

<script>
    import {VExpansionPanels} from 'vuetify/lib';
    import MetadataThumbnail from './metadata-thumbnail';
    import MetadataGeneral from './metadata-general';
    import MetadataFile from './metadata-file';
    import MetadataPublish from './metadata-publish';
    import MetadataPreview from './metadata-preview';
    import {$http, videoApiUrl} from '../../../services/http';
    import {toast} from '../../../services/responseErrors';
    import {mapState} from 'vuex';
    import MetadataSubtitles from './metadata-subtitles';
    import MetadataProject from './metadata-project';
    import MetadataGdpr from './metadata-gdpr';
    import MetadataAnalytics from './metadata-analytics';
    import MetadataInteractive from './metadata-interactive';
    import MetadataGeneralMultiple from '@/components/controls/video-metadata/metadata-general-multiple.vue';

    export default {
        name: 'VideoMetadata',
        props: ['videos'],
        components: {
            MetadataGeneralMultiple,
            MetadataInteractive,
            MetadataAnalytics,
            MetadataGdpr,
            MetadataProject,
            MetadataSubtitles,
            MetadataPreview,
            VExpansionPanels,
            MetadataThumbnail,
            MetadataGeneral,
            MetadataFile,
            MetadataPublish,
        },
        data() {
            return {
                selectedVideo: null,
                openTabs: [0],
                updateCount: 1,
            };
        },
        computed: {
            videosHash() {
                return this.videos.map((video) => video.id).join(',');
            },
            multipleVideos() {
                return this.videos.length > 1;
            },
            selectedId() {
                return this.selectedVideo ? this.selectedVideo.id : 'empty';
            },
            ...mapState('layout', ['metaOpenTabs']),
        },
        methods: {
            details() {
                toast('Details button clicked');
            },
            getVideo(forceReload) {
                if (!this.selectedVideo || (this.selectedVideo.id != this.videos[0].id) || forceReload) {
                    if (!this.multipleVideos) {
                        $http.get(videoApiUrl('api/videos/' + this.videos[0].id)).then((response) => {
                            this.selectedVideo = {...this.videos[0], ...response.data};
                            this.openTabs = this.metaOpenTabs;
                        });
                    }
                }
            },
            updateThumbnail(thumbnail) {
                this.videos[0].thumbnail = thumbnail;
                this.updateCount++;
            },
            updateChapters() {
                this.updateCount++;
            },
            scrolltop(value){
                this.$nextTick(() => {
                    let el = this.$refs.videoMetadataPanels.$children[value].$el;
                    this.$refs.videoMetadataPanels.$el.scrollTop = el.offsetTop;
                });
            }
        },
        mounted() {
            this.getVideo(false);
        },
        updated() {
            this.$store.commit('layout/setMetaOpenTabs', this.openTabs);
            this.getVideo(false);
        },
        watch: {
            openTabs(value){
                if(value){
                    setTimeout(this.scrolltop,  300, value);
                }
            }
        }
    };
</script>

<style scoped>
    .video-metadata {
        max-height: calc(100% - 248px);
        overflow: auto;
        scroll-behavior: smooth;
    }
</style>
