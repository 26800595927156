<template>
    <stats-card
        class="h13 metric"
        :loading="loadingImpressions"
    >
        <template v-slot:title>
            Impressions
        </template>
        <template v-slot:content>
            {{ impressions }}
        </template>
    </stats-card>
</template>

<script>
    import { $http, getStatQueryRequest } from '@/services/http';
    import StatsCard from './stats-card';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'CardImpressions',
        components: {
            StatsCard,
        },
        computed: {
            ...mapState('statistics-details', [
                'impressions',
                'loadingImpressions',
                'dateRange',
                'videoId',
            ])
        },
        methods: {
            ...mapMutations('statistics-details', [
                'setImpressions',
                'setLoadingImpressions'
            ]),
            async getImpressions() {
                this.setLoadingImpressions(true);
                const response = await $http.get(getStatQueryRequest('api/videos/impressions/', [this.videoId], this.dateRange));
                const buckets = response.data.filter(item => item.key === this.videoId);                
                this.setImpressions(buckets.length > 0 ? buckets[0].doc_count : 0);
                this.setLoadingImpressions(false);
            },
        },
        mounted() {
            this.getImpressions();
        },
    };
</script>