import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';

import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: false,
  },
  // color initializations have been moved to the App.vue created event
  // due to a bug in Vuetify initialization. This bug causes Vuetify to
  // initialize and load the default theme before it could be intercepted
});
