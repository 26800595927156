<template>
    <generic-modal
        :title="title"
        :value="value"
        max-width="640"
        @cancel="cancel"
        @confirm="confirm()"
    >
        <template v-slot:default>
            <slot />
        </template>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                color="error"
                text
                @click="confirm()"
            >
                {{ confirmButton }}
            </v-btn>
            <v-btn
                text
                @click="cancel()"
            >
                {{ cancelButton }}
            </v-btn>
        </template>
    </generic-modal>
</template>

<script>
    import GenericModal from './generic-modal';

    export default {
        name: 'ConfirmModal',
        components: {GenericModal},
        props: ['value', 'title', 'confirmButton', 'cancelButton'],
        methods: {
            confirm() {
                this.$emit('confirm');
                this.$emit('input', false);
            },

            cancel() {
                this.$emit('cancel');
                this.$emit('input', false);
            },
        }
    };
</script>

<style scoped>

</style>
