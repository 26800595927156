<template>
    <div class="preview-video-container">
        <div class="preview-container">
            <v-progress-circular
                indeterminate
                color="primary"
            />
            <preview-player
                v-if="video"
                :id="'preview-player-' + video.id + '-' + Date.now()"
                :video="video"
                hide-control-elements="rewind,timestamp,share,quality,fullscreen,subtitle,playlist,chapters"
                :mute="true"
            />
        </div>
        <v-dialog
            v-model="dialog"
            content-class="overflow-visible video-modal-width"
            style="display:none"
            @input="v => v || modalClose()"
        >
            <template v-slot:activator="{ on }">
                <div class="text-center pt-1">
                    <v-btn
                        color="primary"
                        small
                        dark
                        class="mb-2"
                        v-on="on"
                        @click="modalOpen"
                    >
                        <v-icon left>
                            remove_red_eye
                        </v-icon>
                        Preview video
                    </v-btn>
                    <slot />
                </div>
            </template>

            <v-card class="modal-outline-style">
                <div class="modal-interior">
                    <v-btn
                        text
                        icon
                        class="close-modal"
                        @click="modalClose"
                    >
                        <v-icon>highlight_off</v-icon>
                    </v-btn>
                    <preview-player
                        v-if="video"
                        :id="'modal-preview-player-' + Date.now()"
                        :data-video-id="video.id"
                        :video="video"
                        socialshare="email,facebook,twitter,link"
                    />
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import PreviewPlayer from './preview-player';
    export default {
        name: 'VideoPreview',
        components: {
            PreviewPlayer
        },
        props: ['video'],
        data() {
            return {
                dialog: false,
            };
        },
        computed: {
            videoId() {
                return this.video ? this.video.id : 'null';
            }
        },
        methods: {
            modalOpen() {
                var smallPreviewId = document.querySelector('[id^="preview-player-' + this.video.id + '"]').id;
                window.QuadiaPlayer.players[smallPreviewId].$emit('stop');
            },
            modalClose() {
                var modalPreviewId = document.querySelector('[id^="modal-preview-player-"]').id;
                window.QuadiaPlayer.players[modalPreviewId].$emit('stop');
                this.dialog = false;
            }
        }
    };
</script>

<style scoped>
    .preview-video-container {
        width: 100%;
    }

    .preview-button {
        display: block;
        margin-left: 15px;
        margin-top: -15px;
        margin-bottom: 10px;
    }

    .close-modal {
        position: absolute;
        right: -15px;
        top: -15px;
        background-color: white;
        z-index: 999;
    }

    .overflow-visible {
        overflow: visible !important;
    }

    .preview-container {
        position: relative;
        min-height: 205px;
    }

    .preview-container .v-progress-circular {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -16px;
        margin-top: -20px;
        z-index: -1;
    }

</style>

<style>
    .modal-interior iframe {
        display: block !important;
    }

    .preview-video-container .v-expansion-panel-content__wrap{
       padding: 0;
    }

    .video-metadata .v-expansion-panel-content__wrap{
        padding: 0 17px 16px;
    }
    .video-modal-width {
        width: 1000px !important;
    }
    .modal-outline-style {
        background-color: black !important;
        height: calc(1000px * 9/16);
    }
    @media screen and (max-width: 1024px){
        .video-modal-width {
            width: 750px !important;
        }
        .modal-outline-style {
            height: calc(750px * 9/16);
        }
    }

    @media screen and (max-width: 768px) {
        .video-modal-width {
            width: 480px !important;
        }
        .modal-outline-style {
            height: calc(480px * 9/16);
        }
    }
</style>
