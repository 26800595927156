import {videoApiUrl} from './http';

const downloadVideo = function(item, index) {
    if (!index) {
        index = 1;
    }

    const file = document.createElement('a');
    file.href = videoApiUrl('videos/' + item.id + '/download');
    file.setAttribute('download', 'video');
    document.body.appendChild(file);
    setTimeout(() => {
        file.click();
    }, 100 + index * 1000);
};

export default downloadVideo;