<template>
    <div />
</template>

<script>
    export default {
        name: 'InteractivePlayeraction'
    };
</script>

<style scoped>

</style>