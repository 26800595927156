import { render, staticRenderFns } from "./comments-external-scrub.vue?vue&type=template&id=7a2b7968&scoped=true&"
import script from "./comments-external-scrub.vue?vue&type=script&lang=js&"
export * from "./comments-external-scrub.vue?vue&type=script&lang=js&"
import style0 from "./comments-external-scrub.vue?vue&type=style&index=0&id=7a2b7968&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a2b7968",
  null
  
)

export default component.exports