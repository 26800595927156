import { $http } from './http';
import { toast } from './responseErrors';

let oldVersion = null;
let shouldReload = false;

export function fetchNewVersion() {
    fetch('/version.json')
        .then(response => response.json())
        .then(deployment => {
            if (oldVersion !== null && deployment.version != oldVersion) {
                shouldReload = true;
                // eslint-disable-next-line no-console
                console.log('[APP] Update found, application should reload on next navigation');
            }
            oldVersion = deployment.version;
            // eslint-disable-next-line no-console
            console.log('[APP] Update check complete.');
        });
}

function refresh() {
    toast('We have updated the platform for you. The page will reload now.', 'info');
    $http.get('/index.html', {
        headers: {
            'Pragma': 'no-cache'
        }
    }).then(() => {
        // eslint-disable-next-line no-console
        console.log('[APP] Reloading...');
        window.location.reload();
    });
}

export function refreshWhenDeprecated() {
    if (shouldReload) {
        refresh();
    }
}