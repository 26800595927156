<template>
    <v-menu v-model="visible"
            nudge-bottom="30"
            :close-on-content-click="false"
    >
        <stats-date-picker :value="value"
                           inline
                           @input="dateRangeUpdated"
        />
        <template v-slot:activator="{on}">
            <v-btn
                small
                class="ml-2 date-picker-button"
                color="#fff"
                elevation="0"
                v-on="on"
            >
                {{ formattedDates }}
                <v-icon class="ml-3"
                        small
                >
                    mdi-calendar-blank
                </v-icon>
            </v-btn>
        </template>
    </v-menu>
</template>

<script>
    import moment from 'moment-timezone';
    import StatsDatePicker from '@/components/pages/statistics/stats-date-picker';

    export default {
        name: 'StandaloneStatsDatePicker',
        components: {StatsDatePicker },
        props: {
            value: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                visible: false,
            };
        },
        computed: {
            formattedDates() {
                return moment(this.value[0]).format('YYYY-MM-DD') + ' - ' + moment(this.value[1]).format('YYYY-MM-DD');
            }
        },
        methods: {
            dateRangeUpdated(value) {
                this.visible = false;
                this.$emit('input', value);
            },
        },
    };
</script>

<style lang="scss">
.date-picker-button{
    border: 1px solid #ccc;
    border-color: #ccc !important;
    .v-btn__content{
        color: #555 !important;
    }
}
</style>