var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"pt-1 pb-1 vertical-align-top"},[_vm._v(" Vimeo ")]),(_vm.loading)?_c('td',{staticClass:"pt-1 pb-1"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"22","color":"grey"}})],1):_vm._e(),(!_vm.loading)?_c('td',{key:("vimeo-publishing-" + _vm.publishUpdate),staticClass:"pt-1 pb-1"},[(_vm.canPublish)?_c('v-btn',{staticClass:"mr-2 mb-1 copy-button button-minwidth",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.publishSetup()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" publish ")]),_c('span',{staticClass:"mr-5"},[_vm._v("Publish")])],1):_c('v-sheet',{attrs:{"color":"grey--text"}},[(!_vm.canPublish)?_c('div',[_c('ul',{staticClass:"publish-errors"},[(!_vm.videoCompanyId || !_vm.vimeoAccount)?_c('li',[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"small":""}},[_vm._v(" error_outline ")]),_vm._v(" No account linked. ")],1):_vm._e(),(!_vm.video.size)?_c('li',[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"small":""}},[_vm._v(" error_outline ")]),_vm._v(" No file size information available. ")],1):_vm._e()])]):_vm._e()]),(_vm.hasPublishingData)?_c('v-sheet',[_c('span',[_c('v-sheet',{staticClass:"publish-date",staticStyle:{"line-height":"28px"},attrs:{"height":"28","elevation":"0"}},[_vm._v("Published: "+_vm._s(_vm.publishDate)+" "),_c('v-btn',{attrs:{"icon":"","text":"","small":"","color":"black","href":_vm.vimeoUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("open_in_new")])],1)],1)],1)]):_vm._e(),_c('validation-observer',{ref:"validatePublish",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('generic-modal',{attrs:{"title":"Publish to Vimeo","confirm-button":"Publish","cancel-button":"Cancel","confirm-color":"primary"},on:{"confirm":function($event){return _vm.publish()},"cancel":function($event){return _vm.cancel()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Title","rules":{ required:true, max:100}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Title","counter":"100","error-messages":errors},on:{"blur":validate},model:{value:(_vm.publishOptions.title),callback:function ($$v) {_vm.$set(_vm.publishOptions, "title", $$v)},expression:"publishOptions.title"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Titles longer than 70 characters will be truncated in search results. ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":{max: 1000}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"dense":"","label":"Description","counter":"1000","error-messages":errors},on:{"blur":validate},model:{value:(_vm.publishOptions.description),callback:function ($$v) {_vm.$set(_vm.publishOptions, "description", $$v)},expression:"publishOptions.description"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Only the first 125 characters of the description will appear as a snippet in search. ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":"Tags"},model:{value:(_vm.publishOptions.tags),callback:function ($$v) {_vm.$set(_vm.publishOptions, "tags", $$v)},expression:"publishOptions.tags"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Tags are limited to 30 characters / tag, separated by comma and may only contain numbers, letters and spaces. ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"categories",attrs:{"dense":"","label":"Category","items":_vm.categories,"item-text":"name","item-value":"value","error-messages":errors,"filter":_vm.customFilter},on:{"input":validate},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.parentName && _vm.categoriesFiltered())?_c('span',{staticClass:"mr-1 cat"},[_vm._v(_vm._s(item.parentName)+":")]):_vm._e(),_vm._v(_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.publishOptions.category),callback:function ($$v) {_vm.$set(_vm.publishOptions, "category", $$v)},expression:"publishOptions.category"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Categories are predefined by Vimeo."),_c('br'),_c('strong',[_vm._v("Your selected category may not be applied by Vimeo:")]),_c('br'),_vm._v(" Vimeo takes this selection as a suggestion, and "),_c('u',[_vm._v("makes the final determination")]),_vm._v(" about whether the video belongs in these categories. ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Privacy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","label":"Privacy","items":_vm.privacyOptions,"error-messages":errors},on:{"input":validate},model:{value:(_vm.publishOptions.privacy),callback:function ($$v) {_vm.$set(_vm.publishOptions, "privacy", $$v)},expression:"publishOptions.privacy"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_c('i',[_vm._v("Unlisted")]),_vm._v(" and "),_c('i',[_vm._v("disabled")]),_vm._v(" "),_c('u',[_vm._v("cannot be used with "),_c('b',[_vm._v("Vimeo Basic")]),_vm._v(" accounts")]),_vm._v(", only with "),_c('strong',[_vm._v("Vimeo Plus, Vimeo Pro")]),_vm._v(" and "),_c('strong',[_vm._v("Vimeo Business")])])],1)]}}],null,true)})],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),(_vm.isPublishing)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary","text":""},on:{"click":function($event){return _vm.publish()}}},[_vm._v(" Publish ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")])]},proxy:true}],null,true),model:{value:(_vm.publishModal),callback:function ($$v) {_vm.publishModal=$$v},expression:"publishModal"}})]}}],null,false,190097199)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }