<template>
    <div
        :key="`activeIndex-${currentPlayerPosition}`"
        class="interactive-metadata comments-editor-data"
    >
        <div v-for="(comment) in filteredComments"
             :key="`filteredComment-${comment.id}-${comment.created_at}-${comment.status}`"
        >
            <comment :comment="comment" />
        </div>
    </div>
</template>

<script>

    import {mapGetters, mapState} from 'vuex';
    import Comment from './sidebar/comment';

    export default {
        name: 'CommentsSidebar',
        components: {
            Comment
        },
        computed: {
            ...mapState('comments', ['currentPlayerPosition']),
            ...mapGetters('comments', ['filteredComments']),
        }
    };
</script>