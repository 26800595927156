<template>
    <script
        type="application/javascript"
        :src="playerSrc"
        :data-video-id="video.id"
        :data-hide-control-elements="hideControlElements"
        :data-id="id"
        :data-mute="mute"
        :data-token="authToken"
        data-no-tracking="true"
        data-show-title="false"
        data-audio-only="false"
        data-socialshare="[]"
        data-repeat="false"
        data-show-description="false"
        data-autoplay="false"
        data-allow-fullscreen="false"
        data-no-custom-css="true"
    />
</template>

<script>
    export default {
        name: 'PreviewPlayer',
        props: {
            video: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            id: {
                type: String,
                default: '' + Date.now()
            },
            hideControlElements: {
                type: String,
                default: ''
            },
            mute: {
                type: Boolean,
                default: false
            },
            socialshare: {
                type: String,
                default: ''
            }
        },
        data: function() {
            return {
                playerSrc: process.env.VUE_APP_PLAYER_SRC,
                authToken: sessionStorage.getItem('access_token')
            };
        }
    };
</script>

<style scoped>

</style>
