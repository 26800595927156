import UndoStack from '../models/undo/UndoStack';
import interactiveGetters from './interactive/getters';
import interactiveMutations from './interactive/mutations';
import interactiveActions from './interactive/actions';

const interactive = {
    namespaced: true,
    state: {
        video: null,
        elements: [],
        loading: false,
        player: null,
        activeIndex: null,
        undoStack: new UndoStack(),
        redoStack: new UndoStack(),
        preventUndoEvents: false,
        imageShouldPreserveAspectRatio: true,
        currentPlayerPosition: 0,
    },
    getters: interactiveGetters,
    mutations: interactiveMutations,
    actions: interactiveActions,
};

export default interactive;
