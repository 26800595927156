<template>
    <confirm-modal
        v-model="isOpen"
        title="Would you like to reorder the slides?"
        confirm-button="Yes, reorder"
        cancel-button="No, I've changed my mind"
        @confirm="confirm"
        @cancel="cancel"
    >
        <p>The slides will be reordered by their name according to the following criteria:</p>
        <ul>
            <li>Language-specific comparison</li>
            <li>Numbers (digits) in the name</li>
            <li>Punctuation is ignored</li>
            <li>Not case sensitive</li>
        </ul>
        <br />
        <p>The slides will be moved around on the existing time indexes.</p>
        <p>This action cannot be undone. Would you like to continue?</p>
    </confirm-modal>
</template>

<script>
    import ConfirmModal from '@/components/controls/modals/confirm-modal';

    export default {
        name: 'ConfirmPresentationReorder',
        components: {ConfirmModal},
        props: {
            uploadItem: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                isOpen: false,
                resolve: null,
            };
        },
        computed: {
            uploadItemName() {
                return this.uploadItem?.name || '';
            }
        },
        methods: {
            open() {
                this.isOpen = true;
                return new Promise(resolve => {
                    this.resolve = resolve;
                });
            },
            confirm() {
                this.resolve(true);
                this.isOpen = false;
            },
            cancel() {
                this.resolve(false);
                this.isOpen = false;
            }
        }
    };
</script>

<style scoped>

</style>