<template>
    <v-card class="mt-4 pt-2"
            elevation="0"
    >
        <h1 class="ml-4 mr-4 page-header">
            Landing page settings
        </h1>
        <v-card-text class="pt-0">
            <v-row v-if="library.id">
                <v-col cols="12"
                       md="6"
                >
                    <h5 class="mb-2">
                        Logo
                    </h5>
                    <logo-upload v-if="!logoLoading"
                                 :key="`l-${logoLoading}`"
                                 :library-id="library.id"
                                 :has-own-logo="library.hasOwnLogo"
                                 :value="library.logoUrl"
                                 @input="updateLogo"
                    />
                    <v-progress-circular v-else
                                         class="mb-4"
                                         indeterminate
                                         size="36"
                                         color="primary"
                    />
                </v-col>
                <v-col cols="12"
                       md="6"
                >
                    <h5 class="mb-2">
                        Background image
                    </h5>
                    <background-upload v-if="!viewingPageBackgroundLoading"
                                       :key="`vpbg-${viewingPageBackgroundLoading}`"
                                       :library-id="library.id"
                                       :value="library.viewingPageBackgroundUrl"
                                       @input="updateViewingPageBackgroundImage"
                    />
                    <v-progress-circular v-else
                                         class="mb-4"
                                         indeterminate
                                         size="36"
                                         color="primary"
                    />
                </v-col>
                <v-col cols="12">
                    <v-divider light
                               class="mb-4"
                    />
                    <v-textarea v-model="library.footer_text"
                                dense
                                label="Footer text"
                                prepend-icon="mdi-text"
                    />
                </v-col>
                <v-col class="pb-0"
                       cols="12"
                >
                    <v-btn color="primary"
                           :loading="isUpdating"
                           :disabled="isUpdating"
                           @click="saveLandingPageSettings"
                    >
                        Save changes
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    Landing page settings will be available after you save this library first.
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import BackgroundUpload from '@/components/pages/library/BackgroundUpload.vue';
    import LogoUpload from '@/components/pages/library/LogoUpload.vue';
    import {$http, videoApiUrl} from '@/services/http';
    import {saveLogo, saveViewingPageBackground} from '@/services/library';
    import LibraryMixin from '@/components/pages/library/LibraryMixin';

    export default {
        name: 'LibraryLandingPageSettings',
        mixins: [
            LibraryMixin,
        ],
        components: {LogoUpload, BackgroundUpload},
        props: {
            value: {
                type: Object,
                default: () => {},
            }
        },
        data() {
            return {
                library: this.value,
                logoLoading: false,
                selectedLogo: null,
                selectedViewingPageBackground: null,
                viewingPageBackgroundLoading: false,
                saving: false,
            };
        },
        computed: {
            isUpdating() {
                return this.saving || this.logoLoading || this.viewingPageBackgroundLoading;
            }
        },
        methods: {
            async saveLandingPageSettings() {
                try {
                    this.saving = true;
                    // replaced promise.all approach as it wasn't working properly with auth refresh token
                    try {
                        await $http.put(videoApiUrl('api/libraries/' + this.library.id), this.mapLibrary());
                    } catch {
                        //
                    }

                    if (this.selectedLogo) {
                        this.logoLoading = true;
                        try {
                            const data = await saveLogo(this.library.id, this.selectedLogo);
                            if (data.logo) {
                                this.library.logoUrl = data.logo;
                                this.library.hasOwnLogo = true;
                            }
                        } catch {
                            //
                        }
                    }

                    if (this.selectedViewingPageBackground) {
                        this.viewingPageBackgroundLoading = true;
                        try {
                            const data = await saveViewingPageBackground(this.library.id, this.selectedViewingPageBackground);
                            if (data.viewingPageBackground) {
                                this.library.viewingPageBackgroundUrl = data.viewingPageBackground;
                            }
                        } catch {
                            //
                        }
                    }
                } finally {
                    this.saving = false;
                    this.logoLoading = false;
                    this.viewingPageBackgroundLoading = false;
                }
            },
            updateLogo(logo) {
                if (logo === this.library.logoUrl) {
                    this.selectedLogo = null;
                    return;
                }

                this.selectedLogo = logo;

                if (!logo) {
                    this.library.logoUrl = null;
                }
            },
            updateViewingPageBackgroundImage(image) {
                if (image === this.library.viewingPageBackgroundUrl) {
                    this.selectedViewingPageBackground = null;
                    return;
                }

                this.selectedViewingPageBackground = image;

                if (!image) {
                    this.library.viewingPageBackgroundUrl = null;
                }
            }
        }
    };
</script>

<style scoped>

</style>