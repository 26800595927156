import Presentation from '@/models/presentation/Presentation';
import presentationActions from '@/store/presentation/actions';
import presentationMutations from '@/store/presentation/mutations';
import presentationGetters from '@/store/presentation/getters';
import {getPresentationExpandAll} from '@/store/presentation/helpers';

const presentation = {
    namespaced: true,
    state: {
        video: null,
        presentation: new Presentation({}),
        isLoading: false,
        player: null,
        activeIndex: null,
        currentPlayerPosition: 0,
        expandAll: getPresentationExpandAll(),
    },
    getters: presentationGetters,
    mutations: presentationMutations,
    actions: presentationActions
};

export default presentation;