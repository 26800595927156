import { $http, videoApiUrl } from '@/services/http';

const start = new Date();
const end = new Date();
start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);

const main = {
    namespaced: true,
    state: {
        videoId: null,
        video: null,
        dateRange: [start, end],
        starts: 0,
        loadingStarts: true,
        impressions: 0,
        loadingImpressions: true,
        avgWatchTime: 0,
        loadingAvgWatchTime: true,
        totalWatchTime: '00:00:00',
        referrers: [],
    },
    mutations: {
        setVideoId(state, value) {
            state.videoId = value;
        },
        setVideo(state, value) {
            state.video = value;
        },
        setTotalWatchTime(state, value) {
            state.totalWatchTime = value;
        },
        setReferrers(state, value) {
            state.referrers = value;
        },
        setDateRange(state, value) {
            state.dateRange = value;
        },
        setStarts(state, value) {
            state.starts = value;
        },
        setLoadingStarts(state, value) {
            state.loadingStarts = value;
        },
        setImpressions(state, value) {
            state.impressions = value;
        },
        setLoadingImpressions(state, value) {
            state.loadingImpressions = value;
        },
        setAvgWatchTime(state, value) {
            state.avgWatchTime = value;
        },
        setLoadingAvgWatchTime(state, value) {
            state.loadingAvgWatchTime = value;
        },
    },
    actions: {
        async getVideo({state, commit}) {            
            const response = await $http.get(
                videoApiUrl(`api/videos/${state.videoId}`)
            );
            commit('setVideo', response.data);
        },
    }
};

export default main;
