<template>
    <tr class="editable">
        <td>Description:</td>
        <td v-if="video">
            <div class="editable-meta">
                <div
                    class="editable-content description-content description-item-content"
                    :class="{'has-overflow': descriptionHasOverflow}"
                    @dblclick="openEditor()"
                >
                    {{ video
                        ? video.description : "" }}
                </div>
                <metadata-field-editor
                    v-if="canEdit"
                    :key="'desc-' + video.id"
                    ref="editableDescription"
                    v-model="video.description"
                    type="modal"
                    name="description"
                    subtype="longtext"
                    :meta="{counter: 1000}"
                    @input="videoDescriptionUpdated"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import updateMeta from '../../../../services/metadata';
    import MetadataFieldEditor from '../editor/MetadataFieldEditor';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';

    export default {
        name: 'MetaDescriptionField',
        components: {MetadataFieldEditor},
        props: ['video'],
        computed: {
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        data() {
            return {
                descriptionHasOverflow: false,
            };
        },
        methods: {
            videoDescriptionUpdated(value) {
                updateMeta(this.video.id, {
                    'description': value
                })
                    .then(() => this.updateElements());
            },
            updateElements() {
                setTimeout(() => {
                    this.descriptionHasOverflow = this.elementHasOverflow('.description-item-content');
                }, 100);
            },
            elementHasOverflow(selector) {
                const element = document.querySelector(selector);
                if (!element) {
                    return false;
                }

                return (element.scrollHeight > element.offsetHeight);
            },
            openEditor() {
                if(this.canEdit) {
                    this.$refs.editableDescription.openEditor();
                }
            }
        },
        mounted() {
            this.updateElements();
        },
        updated() {
            this.updateElements();
        }
    };
</script>

<style scoped>
    .editable-content.description-content {
        max-height: 150px;
        overflow: hidden;
        position: relative;
    }

    .editable-content.description-content.has-overflow:after {
        position: absolute;
        width: 100%;
        background-image: linear-gradient(transparent, #FFFFFF);
        bottom: 0;
        left: 0;
        height: 50px;
        line-height: 70px;
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
        color: rgba(0 0 0 .2);
        content: ""
    }

    tr:hover .editable-content.description-content.has-overflow:after {
        background-image: linear-gradient(transparent, #EEEEEE);
    }
</style>
