<template>
    <generic-modal
        :title="title"
        :value="value"
        max-width="550"
        @cancel="cancel"
    >
        <v-divider />
        <v-simple-table dense>
            <template v-slot:default>
                <tr v-if="!editMode">
                    <td>Select file to upload</td>
                    <td>
                        <v-btn
                            color="primary"
                            small
                            @click="openFileDialog"
                        >
                            Select file
                        </v-btn>
                    </td>
                </tr>
                <tr v-if="editMode">
                    <td>Document</td>
                    <td>
                        <v-btn
                            color="primary"
                            small
                            :href="quitclaim.url"
                        >
                            Download
                        </v-btn>
                    </td>
                </tr>
                <tr v-if="!editMode && quitclaim.selectedFileName">
                    <td>Selected file:</td>
                    <td>{{ quitclaim.selectedFileName }}</td>
                </tr>
                <tr v-if="isFileSelected || editMode">
                    <td>Person name</td>
                    <td>
                        <v-text-field
                            v-model="quitclaim.personName"
                            class="adjustable-height-v-text-field"
                            dense
                            outlined
                            single-line
                            height="34px"
                            :hide-details="true"
                        />
                    </td>
                </tr>
                <tr v-if="isFileSelected || editMode">
                    <td>
                        End date of copyright
                    </td>
                    <td>
                        <date-picker
                            v-model="quitclaim.copyrightEndAt"
                            style="width: 100%;"
                            type="date"
                            :show-second="false"
                            value-type="timestamp"
                            format="YYYY-MM-DD"
                        />
                    </td>
                </tr>
            </template>
        </v-simple-table>

        <input
            v-show="false"
            ref="fileInput"
            type="file"
            accept=".png, .jpg, .jpeg, .docx, .zip, .doc, .pdf"
            @change="fileSelected"
        >
        <div class="actions">
            <v-spacer />
            <v-btn
                v-if="editMode && !readOnly"
                :disabled="!quitclaim.personName || !quitclaim.copyrightEndAt"
                color="error"
                text
                @click="updateQuitclaim"
            >
                Update
            </v-btn>
            <v-btn
                v-if="!editMode && !readOnly"
                :disabled="!isFileSelected || !quitclaim.personName || !quitclaim.copyrightEndAt"
                color="error"
                text
                @click="uploadSelectedFile"
            >
                Add
            </v-btn>
            <v-btn
                text
                @click="cancel()"
            >
                Cancel
            </v-btn>
        </div>
    </generic-modal>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import GenericModal from './generic-modal';

    export default {
        name: 'QuitclaimModal',
        components: {
            GenericModal,
            DatePicker,
        },
        props: ['value', 'quitclaim', 'readOnly'],
        computed: {
            title() {
                return this.quitclaim.id ? 'Quitclaim | ' + this.quitclaim.selectedFileName : 'Add quitclaim';
            },
            editMode() {
                return this.quitclaim.id ? true : false;
            }
        },
        data() {
            return {
                isFileSelected: false,
            };
        },
        methods: {
            cancel() {
                this.$emit('input', false);
            },
            fileSelected() {
                this.isFileSelected = true;
                this.quitclaim.selectedFileName = this.$refs.fileInput.files[0].name;
            },
            openFileDialog() {
                this.$refs.fileInput.click();
            },
            uploadSelectedFile() {
                let formData = new FormData();
                formData.append('file', this.$refs.fileInput.files[0]);
                formData.append('person_name', this.quitclaim.personName);
                formData.append('copyright_end_at', this.quitclaim.copyrightEndAt);
                this.$emit('upload', formData);
            },
            updateQuitclaim() {
                this.$emit('update', this.quitclaim);
            },
        },
    };
</script>

<style scoped>
    table td {
        padding-left: 0;
        padding-top: 0.5em;
    }

    table td:nth-of-type(2) {
        text-align: left;
    }

    .actions {
        text-align: right;
        margin-top: 1.5em;
    }
</style>
