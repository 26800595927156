import {$http} from '@/services/http';

/**
 * Response timeout for a request (includes connection)
 * @type {number}
 */
const DEFAULT_RESPONSE_TIMEOUT  = 60000;

/**
 * Time between two attempts (give time for temporary network hiccups)
 * @type {number}
 */
const DEFAULT_REQUEST_INTERVAL  = 30000;

/**
 * Interval base for throttled endpoints (will be increased at the next power of 2 after each attempt)
 * @type {number}
 */
const DEFAULT_THROTTLE_INTERVAL_BASE = 65000;

/**
 * Maximum number of retries
 * @type {number}
 */
const DEFAULT_MAX_RETRIES = 5;

/**
 * Used for waiting specified amount of time
 * @param interval
 * @returns {Promise<unknown>}
 */
const sleep = async (interval) => {
    return new Promise(resolve => {
        setTimeout(resolve, interval);
    });
};

/**
 * A regular Axios post for reuse, with specifiable timeout
 * @param url
 * @param data
 * @param timeout
 * @returns {AxiosPromise<any>}
 */
export const postRequest = async (url, data, timeout) => {
    return $http({
        method: 'POST',
        url,
        data,
        timeout
    });
};

/**
 * Auto-retrying Axios request with configurable retry parameters
 * @param url
 * @param data
 * @param responseTimeout
 * @param maxRetries
 * @param waitBetweenRetries
 * @param throttleInterval
 * @returns {Promise<unknown>}
 */
export const postRequestWithRetries = async (
    url,
    data,
    responseTimeout = DEFAULT_RESPONSE_TIMEOUT,
    maxRetries = DEFAULT_MAX_RETRIES,
    waitBetweenRetries = DEFAULT_REQUEST_INTERVAL, // for timeouts and failed requests
    throttleInterval = DEFAULT_THROTTLE_INTERVAL_BASE, // for 429 error status
) => {
    let retries = maxRetries;
    let shouldRetry = true;
    let error = null;
    let result = null;

    return new Promise( (resolve, reject) => {
        while (shouldRetry) {
            error = null;
            let waitBetween = waitBetweenRetries;
            try {
                sleep(retries === maxRetries ? 50 : waitBetween);
                result = postRequest(url, data, responseTimeout);
                if (result) {
                    return resolve(result);
                }
            } catch (e) {
                error = e;
                if (e.response) {
                    switch (e.response.status) {
                        case 429:
                            waitBetween = Math.pow(2, maxRetries - retries) * throttleInterval; // throttled request, it's useless to send requests too often
                            break;
                        default:
                            retries = 0; // don't retry on any other error (those need to be fixed)
                            break;
                    }
                }
            }

            shouldRetry = (-- retries > -1);
        }

        return reject(error || 'network');
    });
};
