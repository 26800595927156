import OpenLinkAction from '../actions/OpenLinkAction';

class OpenLinkActionFactory {
    make(type) {
        return new OpenLinkAction({
            type: type,
            link: 'https://www.quadia.com',
            target: '_blank'
        });
    }
}

export default new OpenLinkActionFactory();