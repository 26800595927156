<template>
    <v-expansion-panel class="elevation-0 my-0">
        <v-expansion-panel-header
            class="has-editable-content py-0 px-6"
            @click="toggle"
        >
            <div class="v-data-table v-data-table--dense theme-light property-sheet interactive-metadata-header-table mt-3 ml-n2">
                <table>
                    <tbody>
                        <td class="py-0 pr-4 interactive-start-editor">
                            <time-index-input
                                v-if="isActiveElement(element) && elementCanHaveField(element, 'start')"
                                should-confirm
                                :value="element.start"
                                :max="video.duration / 1000"
                                autofocus
                                @input="interactiveStartUpdated"
                                @click.native.stop
                            />
                            <span v-else>{{ inactiveStartValue }}</span>
                        </td>
                        <td class="py-0 interactive-name-editor">
                            <div class="d-flex">
                                <div class="title-content">
                                    <input
                                        v-if="isActiveElement(element) && elementCanHaveField(element, 'name')"
                                        :key="'element-name-' + element._id"
                                        type="text"
                                        :value="element.name"
                                        @input="interactiveElementNameUpdated"
                                        @keyup.32.prevent
                                        @click.stop
                                    >
                                    <span v-else>{{ element.name }}</span>
                                </div>
                                <div>
                                    <v-menu class="interactive-item-options">
                                        <template v-slot:activator="{on}">
                                            <v-btn
                                                icon
                                                class="title-options ml-4 mb-3"
                                                x-small
                                                v-on="on"
                                            >
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item-group color="primary">
                                                <v-list-item @click="duplicate">
                                                    <v-list-item-icon class="mr-4">
                                                        <v-icon>content_copy</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Duplicate</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="confirmDelete">
                                                    <v-list-item-icon class="mr-4">
                                                        <v-icon>delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                        </td>
                    </tbody>
                </table>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content @click="toggle">
            <v-simple-table
                dense
                class="property-sheet mt-0 ml-n2"
            >
                <template v-slot:default>
                    <tbody>
                        <tr
                            v-if="elementCanHaveField(element, 'end')"
                            class="interactive-end-editor"
                        >
                            <td>Until:</td>
                            <td>
                                <div class="editable-meta">
                                    <time-index-input
                                        :value="element.end"
                                        :max="video.duration / 1000"
                                        @input="interactiveEndUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'start-action')"
                            class="interactive-start-action-editor"
                        >
                            <td>Start action:</td>
                            <td>
                                <div class="editable-meta">
                                    <metadata-field-editor
                                        :key="'start-action-' + element._id + '-' + element.startAction"
                                        :value="element.startAction"
                                        type="combobox"
                                        name="start-action"
                                        :meta="{options: options.startActions}"
                                        :open="true"
                                        :keep-open="true"
                                        @input="interactiveStartActionUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'type')"
                            class="interactive-area-type-editor"
                        >
                            <td>Type:</td>
                            <td>
                                <div class="editable-meta">
                                    <metadata-field-editor
                                        :key="'area-type-' + element._id + '-' + element.area.type"
                                        :value="element.area.type"
                                        type="combobox"
                                        name="area-type"
                                        :meta="{options: options.areas}"
                                        :open="true"
                                        :keep-open="true"
                                        @input="interactiveAreaTypeUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'shape')"
                            class="interactive-shape-editor"
                        >
                            <td>Shape:</td>
                            <td>
                                <div class="editable-meta">
                                    <metadata-field-editor
                                        :key="'shape-' + element._id + '-' + element.area.shape"
                                        :value="element.area.shape"
                                        type="combobox"
                                        name="shape"
                                        :meta="{options: options.shapes}"
                                        :open="true"
                                        :keep-open="true"
                                        @input="interactiveShapeUpdated"
                                    />
                                </div>
                            </td>
                        </tr>

                        <!-- Actions -->
                        <tr
                            v-if="elementCanHaveField(element, 'action')"
                            class="interactive-action-type-editor"
                        >
                            <td class="pt-4">
                                Action:
                            </td>
                            <td class="pt-4">
                                <div class="editable-meta">
                                    <metadata-field-editor
                                        :key="'shape-' + element._id + '-' + element.action.type"
                                        :value="element.action.type"
                                        type="combobox"
                                        name="action"
                                        :meta="{options: options.actions.filter(a => {
                                            return elementCanHaveAction(element, a.value)
                                        })}"
                                        :open="true"
                                        :keep-open="true"
                                        @input="interactiveActionTypeUpdated"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr
                            v-if="elementCanHaveActionField(element, 'link')"
                            class="interactive-action-link-editor"
                        >
                            <td>Link:</td>
                            <td>
                                <input
                                    type="text"
                                    :value="element.action.link"
                                    @input="interactiveActionLinkUpdated"
                                >
                                <v-sheet
                                    v-if="errors.has('action.link')"
                                    v-safe-html="errors.first('action.link')"
                                    class="error--text floating-error pt-1 pb-1 pr-2 pl-2"
                                    elevation="10"
                                />
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveActionField(element, 'target')"
                            class="interactive-action-target-editor"
                        >
                            <td>Target:</td>
                            <td>
                                <metadata-field-editor
                                    :key="'shape-' + element._id + '-' + element.action.target"
                                    :value="element.action.target"
                                    type="combobox"
                                    name="action"
                                    :meta="{options: options.linkTargets}"
                                    :open="true"
                                    :keep-open="true"
                                    @input="interactiveActionTargetUpdated"
                                />
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveActionField(element, 'video-id')"
                            class="interactive-action-video-id-editor"
                        >
                            <td>Video ID:</td>
                            <td>
                                <input
                                    type="text"
                                    :value="element.action.videoId"
                                    @input="interactiveActionVideoIdUpdated"
                                >
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveActionField(element, 'auto-play')"
                            class="interactive-action-auto-play-editor"
                        >
                            <td>Auto play:</td>
                            <td>
                                <v-switch
                                    hide-details
                                    :false-value="false"
                                    :true-value="true"
                                    class="pa-0 ma-0"
                                    :input-value="element.action.autoPlay"
                                    @change="interactiveActionAutoplayUpdated"
                                />
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveActionField(element, 'mute')"
                            class="interactive-action-mute-editor"
                        >
                            <td>Mute:</td>
                            <td>
                                <v-switch
                                    hide-details
                                    :false-value="false"
                                    :true-value="true"
                                    class="pa-0 ma-0"
                                    :input-value="element.action.mute"
                                    @change="interactiveActionMuteUpdated"
                                />
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveActionField(element, 'start-at')"
                            class="interactive-action-start-at-editor"
                        >
                            <td>Start at:</td>
                            <td>
                                <div class="editable-meta">
                                    <time-index-input
                                        :value="element.action.startAt"
                                        @input="interactiveActionStartAtUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveActionField(element, 'skip-to-time-index')"
                            class="interactive-action-skip-to-time-index-editor"
                        >
                            <td>Skip to:</td>
                            <td>
                                <div class="editable-meta">
                                    <time-index-input
                                        :value="element.action.timeIndex"
                                        :max="video.duration / 1000"
                                        @input="interactiveActionSkipToTimeUpdated"
                                    />
                                </div>
                            </td>
                        </tr>

                        <!-- end of actions -->

                        <!-- formatting -->
                        <tr>
                            <td
                                v-if="elementCanHaveAnyFormattingField(element)"
                                class="pt-4"
                            >
                                Formatting:
                            </td>
                            <td class="pt-4">
                                {{ elementFormatName(element.area.type) }}
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'border')"
                            class="interactive-default-border-editor"
                        >
                            <td>Border:</td>
                            <td>
                                <div class="editable-meta">
                                    <span class="editable-content">
                                        <border-visualizer :value="element.style.default.border" />
                                    </span>
                                    <metadata-field-editor
                                        :key="'border-default-' + element._id"
                                        :value="element.style.default.border"
                                        type="modal"
                                        subtype="bordereditor"
                                        name="border"
                                        @input="defaultBorderChanged"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'border-hover')"
                            class="interactive-hover-border-editor"
                        >
                            <td>Border hover:</td>
                            <td>
                                <div class="editable-meta">
                                    <span class="editable-content">
                                        <border-visualizer :value="element.style.hover.border" />
                                    </span>
                                    <metadata-field-editor
                                        :key="'border-hover-' + element._id"
                                        :value="element.style.hover.border"
                                        type="modal"
                                        subtype="bordereditor"
                                        name="border hover"
                                        @input="hoverBorderChanged"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'background-color')"
                            class="interactive-default-background-color-editor"
                        >
                            <td>Background color:</td>
                            <td>
                                <div class="editable-meta">
                                    <span class="editable-content">
                                        <color-visualizer :value="element.style.default.background.color" />
                                    </span>
                                    <metadata-field-editor
                                        :key="'background-color-default-' + element._id"
                                        :value="element.style.default.background.color"
                                        type="modal"
                                        subtype="coloreditor"
                                        name="background color"
                                        :max-dialog-width="'350px'"
                                        @input="defaultBackgroundColorUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'background-color-hover')"
                            class="interactive-hover-background-color-editor"
                        >
                            <td>Background hover color:</td>
                            <td>
                                <div class="editable-meta">
                                    <span class="editable-content">
                                        <color-visualizer :value="element.style.hover.background.color" />
                                    </span>
                                    <metadata-field-editor
                                        :key="'background-color-hover-' + element._id"
                                        :value="element.style.hover.background.color"
                                        type="modal"
                                        subtype="coloreditor"
                                        name="hover background color"
                                        :max-dialog-width="'350px'"
                                        @input="hoverBackgroundColorUpdated"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr
                            v-if="elementCanHaveField(element, 'text-content')"
                            class="interactive-area-text-content-editor"
                        >
                            <td class>
                                Content:
                            </td>
                            <td>
                                <div class="editable-meta">
                                    <div
                                        :key="element.area.text"
                                        class="editable-content area-text-content description-content mb-2"
                                        @dblclick="$refs.areaTextEditor.openEditor()"
                                    >
                                        {{ element.area.text || '' }}
                                    </div>
                                    <metadata-field-editor
                                        :key="'area-text-' + element._id + element.area.text"
                                        ref="areaTextEditor"
                                        :value="element.area.text"
                                        type="modal"
                                        subtype="longtext"
                                        name="area text"
                                        :meta="{counter:500}"
                                        @input="interactiveAreaTextUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'font')"
                            class="interactive-font-editor"
                        >
                            <td>Font family:</td>
                            <td>
                                <div class="editable-meta">
                                    <metadata-field-editor
                                        :key="'format-font-' + element._id"
                                        :value="element.style.default.font.family"
                                        type="combobox"
                                        name="format-font"
                                        :meta="{options: options.fontFamilies}"
                                        :open="true"
                                        :keep-open="true"
                                        @input="interactiveFontFamilyUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'font-size')"
                            class="interactive-font-size-editor"
                        >
                            <td>Size:</td>
                            <td>
                                <div class="editable-meta">
                                    <input
                                        :key="'format-font-size-' + element._id"
                                        :value="element.style.default.font.size"
                                        type="number"
                                        :min="8"
                                        name="format-font-size"
                                        class="mr-1"
                                        :style="{width: '3em'}"
                                        @input="interactiveFontSizeUpdated"
                                    >px
                                </div>
                            </td>
                        </tr>

                        <tr
                            v-if="elementCanHaveField(element, 'font-color')"
                            class="interactive-default-font-color-editor"
                        >
                            <td>Font color:</td>
                            <td>
                                <div class="editable-meta">
                                    <span class="editable-content">
                                        <color-visualizer :value="element.style.default.font.color" />
                                    </span>
                                    <metadata-field-editor
                                        :key="'background-color-hover-' + element._id"
                                        :value="element.style.default.font.color"
                                        type="modal"
                                        subtype="coloreditor"
                                        name="font color"
                                        :max-dialog-width="'350px'"
                                        @input="interactiveFontColorUpdated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'font-color-hover')"
                            class="interactive-hover-font-color-editor"
                        >
                            <td>Font hover color:</td>
                            <td>
                                <div class="editable-meta">
                                    <span class="editable-content">
                                        <color-visualizer :value="element.style.hover.font.color" />
                                    </span>
                                    <metadata-field-editor
                                        :key="'background-color-hover-' + element._id"
                                        :value="element.style.hover.font.color"
                                        type="modal"
                                        subtype="coloreditor"
                                        name="font hover color"
                                        :max-dialog-width="'350px'"
                                        @input="interactiveFontColorHoverUpdated"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr
                            v-if="elementCanHaveField(element, 'background-image')"
                            class="interactive-default-background-image-editor"
                        >
                            <td class="no-height">
                                Image:
                            </td>
                            <td class="no-height">
                                <metadata-field-editor
                                    :key="'background-image-' + element._id"
                                    :value="element.style.default.background.path"
                                    type="imagepicker"
                                    name="image"
                                    :open="true"
                                    :keep-open="true"
                                    @input="interactiveDefaultBackgroundImageUpdated"
                                />
                                <img
                                    v-if="defaultBackgroundUrl(element)"
                                    class="file-selector-image"
                                    :src="defaultBackgroundUrl(element)"
                                    alt="uploaded file"
                                >
                            </td>
                        </tr>
                        <tr
                            v-if="elementCanHaveField(element, 'background-image-hover')"
                            class="interactive-hover-background-image-editor"
                        >
                            <td class="no-height">
                                Image hover:
                            </td>
                            <td class="no-height">
                                <metadata-field-editor
                                    :key="'background-image-hover-' + element._id"
                                    :value="element.style.hover.background.path"
                                    type="imagepicker"
                                    name="image-hover"
                                    :open="true"
                                    :keep-open="true"
                                    @input="interactivHoverBackgroundImageUpdated"
                                />
                                <img
                                    v-if="hoverBackgroundUrl(element)"
                                    class="file-selector-image"
                                    :src="hoverBackgroundUrl(element)"
                                    alt="uploaded file"
                                >
                            </td>
                        </tr>
                    <!-- end of formatting -->
                    </tbody>
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
        <confirm-modal
            v-model="deleteModal"
            title="Remove element?"
            confirm-button="Remove"
            cancel-button="Cancel"
            @confirm="deleteConfirmed"
        >
            <p>Would you like to remove the following element?</p>
            <p>{{ element.name }}</p>
        </confirm-modal>
    </v-expansion-panel>
</template>

<script>
    import {uploadInteractiveImage} from '../../../../store/interactive/helpers.js';
    import MetadataFieldEditor from '../../video-metadata/editor/MetadataFieldEditor';
    import TimeIndexInput from '../../video-metadata/editor/TimeIndexInput';
    import {mapGetters, mapState} from 'vuex';
    import TimeHelper from '../../../../time-helper';
    import BorderVisualizer from './visualizers/BorderVisualizer';
    import ColorVisualizer from './visualizers/ColorVisualizer';
    import ConfirmModal from '../../modals/confirm-modal';
    import { debounce } from 'lodash';

    export default {
        name: 'InteractiveSidebarItem',
        components: {ConfirmModal, ColorVisualizer, BorderVisualizer, MetadataFieldEditor, TimeIndexInput},
        props: ['element'],
        data() {
            return {
                deleteModal: false
            };
        },
        computed: {
            ...mapState('interactive', ['video']),
            ...mapGetters('interactive', [
                'options',
                'elementFormatName',
                'elementCanHaveField',
                'elementCanHaveActionField',
                'elementCanHaveAction',
                'elementCanHaveAnyFormattingField',
                'defaultBackgroundUrl',
                'hoverBackgroundUrl',
                'activeElement',
                'isActiveElement'
            ]),
            inactiveStartValue() {
                return TimeHelper.fromMsToHisU(this.element.start * 1000);
            }
        },
        methods: {
            toggle() {
                if(this.isActiveElement(this.element)) {
                    this.$store.commit('interactive/deactivate');
                } else {
                    this.$store.commit('interactive/setActiveElement', this.element);
                }
            },
            interactiveStartActionUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setStartAction',
                    payload: value
                });
            },
            interactiveAreaTypeUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setArea',
                    payload: value
                });
            },
            interactiveShapeUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setShape',
                    payload: value
                });
            },
            interactiveStartUpdated: debounce(function(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setStart',
                    payload: value
                });
            }, 400),
            interactiveEndUpdated: debounce(function(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setEnd',
                    payload: value
                });
            }, 400),
            hoverBorderChanged(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setHoverBorder',
                    payload: value
                });
            },
            defaultBorderChanged(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setDefaultBorder',
                    payload: value
                });
            },
            defaultBackgroundColorUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setDefaultBackgroundColor',
                    payload: value
                });
            },
            hoverBackgroundColorUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setHoverBackgroundColor',
                    payload: value
                });
            },
            interactiveFontFamilyUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setFontFamilies',
                    payload: value
                });
            },
            interactiveFontSizeUpdated(e) {
                let value = parseFloat(e.target.value);
                if (isNaN(value) || value < 8) {
                    value = 8;
                }

                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setFontSizes',
                    payload: value
                });
            },
            interactiveFontColorUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setDefaultFontColor',
                    payload: value
                });
            },
            interactiveFontColorHoverUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setHoverFontColor',
                    payload: value
                });
            },
            async interactiveDefaultBackgroundImageUpdated(value) {
                let image = await uploadInteractiveImage(this.video.id, value);
                this.$store.dispatch('interactive/updateDefaultBackgroundImage', image);
            },
            async interactivHoverBackgroundImageUpdated(value) {
                let image = await uploadInteractiveImage(this.video.id, value);
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setHoverBackground',
                    payload: image
                });
            },
            interactiveAreaTextUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setAreaText',
                    payload: value
                });
            },
            interactiveActionTypeUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setAction',
                    payload: value
                });
            },
            interactiveActionLinkUpdated: debounce(function(e) {
                this.errors.remove('action.link');
                this.$store.commit('interactive/setActionLink', e.target.value);

                if(!e.target.value && ['openLink', 'openDownload'].includes(this.element.action.type)){
                    this.errors.add({field: 'action.link', msg: 'The Link field must have a value!'});
                    return;
                }

                this.$validator.verify(e.target.value, 'url').then((res) => {
                    if(res.valid){
                        this.$store.dispatch('interactive/executeSingleCommit', {
                            name: 'setActionLink',
                            payload: e.target.value
                        });
                    }else{
                        this.errors.add({field: 'action.link', msg: 'The Link must be a valid URL!'});
                    }
                });
            }, 500),
            interactiveActionTargetUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setActionTarget',
                    payload: value
                });
            },
            interactiveActionVideoIdUpdated(e) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setActionVideoId',
                    payload: e.target.value
                });
            },
            interactiveActionAutoplayUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setActionAutoPlay',
                    payload: value
                });
            },
            interactiveActionMuteUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setActionMute',
                    payload: value
                });
            },
            interactiveActionStartAtUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setActionStartAt',
                    payload: value
                });
            },
            interactiveActionSkipToTimeUpdated(value) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setActionSkipToTime',
                    payload: value
                });
            },
            interactiveElementNameUpdated(e) {
                this.$store.dispatch('interactive/executeSingleCommit', {
                    name: 'setName',
                    payload: e.target.value
                });
            },
            confirmDelete() {
                this.deleteModal = true;
            },
            deleteConfirmed() {
                this.$store.dispatch('interactive/removeElement', this.element);
            },
            duplicate() {
                this.$store.dispatch('interactive/duplicateElement', this.element);
            }
        },
    };
</script>

<style scoped lang="scss">
    .v-expansion-panel-content__wrap {
        padding: 0 17px 16px;
    }

    .has-editable-content {
        padding: 0;

        td {
            font-weight: normal;
            vertical-align: middle;
        }
    }

    .v-dialog {
        width: auto;
    }

    .interactive-metadata .property-sheet td:first-child {
        width: 10rem;
    }

    .no-height {
        height: auto !important;
    }

    input[type=text],
    input[type=number] {
        border: 1px solid #bbbbbb;
        width: 100%;
        border-radius: 3px;
        padding: 0 3px;
    }

    .title-content {
        flex: 1 1 auto;
    }

    .title-options {
        flex: 0 0 auto;
    }

    .file-selector-image {
        max-width: 100%;
    }

    .editable-content.description-content {
      max-height: 150px;
      overflow: hidden;
      position: relative;
    }

    .editable-content.description-content.has-overflow:after {
      position: absolute;
      width: 100%;
      background-image: linear-gradient(transparent, #FFFFFF);
      bottom: 0;
      left: 0;
      height: 50px;
      line-height: 70px;
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
      color: rgba(0, 0, 0, .2);
      content: ""
    }

    tr:hover .editable-content.description-content.has-overflow:after {
      background-image: linear-gradient(transparent, #EEEEEE);
    }
</style>

<style scoped lang="scss">
    .interactive-metadata-header-table {
        .v-data-table__wrapper {
            overflow-y: visible !important;
        }
    }

    .floating-error {
      top: auto !important;
    }
</style>
