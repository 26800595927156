<template>
    <v-container
        fluid
        class="pt-0"
    >
        <v-row
            justify-center
            align-center
            no-gutters
        >
            <v-col>
                <div class="content">
                    <v-col class="pt-0">
                        <div class="sticky-nav pt-7">
                            <div class="d-flex header-container ml-4 mr-4">
                                <h1 class="page-header">
                                    Users
                                </h1>
                                <v-text-field
                                    height="29"
                                    flat
                                    solo
                                    background-color="grey lighten-1"
                                    hide-details
                                    :append-icon="searchContent ? undefined : 'search'"
                                    label="Quick search"
                                    :value="searchContent"
                                    clearable
                                    class="quick-search"
                                    @input="setSearchContent"
                                />
                            </div>
                        </div>
                        <div class="user-listing-container">
                            <v-row
                                class="pt-0 pb-0"
                                style="margin: 0;"
                            >
                                <div class="col-md-12 no-gutters pt-0 pb-0 sticky-table-header">
                                    <div class="d-flex user-listing-header listing-header-container">
                                        <div class="image-column pt-2 pr-4 pb-2" />
                                        <div
                                            v-for="header in getHeaders"
                                            :key="'list-header-' + header.value"
                                            :class="header.classes"
                                        >
                                            <v-btn
                                                :disabled="header.value === 'role'"
                                                text
                                                block
                                                class="header-button"
                                                :class="{'disabled-button': header.value === 'role'}"
                                                @click.stop="() => {}"
                                            >
                                                {{ header.text }}
                                                <component
                                                    :is="header.filterComponent"
                                                    v-if="header.canFilter"
                                                    :value="filters"
                                                    :column-id="header.value"
                                                    @input="setFilters"
                                                />

                                                <template v-if="header.sortable">
                                                    <v-btn
                                                        icon
                                                        class="sort-button ml-2"
                                                        :class="{'is-sorted': sorting.column === header.value}"
                                                        small
                                                        :color="isSortedBy(header.value) && sorting.direction === 'asc' ? 'black' : ''"
                                                        @click="applyHeaderSort(header.value, 'asc')"
                                                    >
                                                        <v-icon
                                                            dark
                                                            small
                                                        >
                                                            mdi-arrow-up
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        icon
                                                        small
                                                        class="sort-button ml-2"
                                                        :class="{'is-sorted': isSortedBy(header.value)}"
                                                        :color="isSortedBy(header.value) && sorting.direction === 'desc' ? 'black' : ''"
                                                        @click.stop="applyHeaderSort(header.value, 'desc')"
                                                    >
                                                        <v-icon
                                                            dark
                                                            small
                                                        >
                                                            mdi-arrow-down
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-btn>
                                        </div>
                                        <div class="button-column pt-2 pb-2 pl-4 pr-4">
                                            <router-link
                                                :to="{name: 'addUser'}"
                                                style="text-decoration: none"
                                            >
                                                <v-btn
                                                    color="accent"
                                                    block
                                                >
                                                    Add user
                                                </v-btn>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <v-col
                                    v-for="user in items"
                                    :key="user.id + user.name"
                                    cols="12"
                                    class="user-listing-item pb-0 pt-0"
                                >
                                    <v-card>
                                        <div class="image-column pr-4">
                                            <img
                                                class="listing-image"
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                                :style="{'background-image': 'url(' + (user.picture ? user.picture : '/images/profile-placeholder.png') + ')'}"
                                            >
                                        </div>
                                        <div class="flex-grow-1 name-column pa-0 pt-2 pb-2">
                                            <div class="px-4">
                                                {{ user.name }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="showCompanyColumn"
                                            class="flex-grow-1 company-column pa-0  pt-2 pb-2"
                                        >
                                            <div class="px-4">
                                                {{ getCompany(user) }}
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 role-column pa-0  pt-2 pb-2">
                                            <div class="px-4">
                                                {{ user.theRole }}
                                            </div>
                                        </div>
                                        <div class="button-column pt-2 pb-2 pl-4 pr-4 text-right">
                                            <v-btn
                                                v-if="user.canEdit"
                                                icon
                                                class="ma-auto text-capitalize"
                                                @click="editUser(user.id)"
                                            >
                                                <v-icon
                                                    dark
                                                    class="edit"
                                                >
                                                    edit
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-if="user.canDelete"
                                                icon
                                                class="ma-auto text-capitalize"
                                                @click="deleteUser(user.id)"
                                            >
                                                <v-icon dark>
                                                    mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-card>
                                </v-col>
                                <no-search-results v-if="noQuickSearchResult" />
                            </v-row>
                            <v-row class="col-md-12 no-gutters pt-0 pb-0">
                                <x-paginator :value="pagination"
                                             @input="setPagination"
                                />
                            </v-row>
                        </div>
                    </v-col>
                </div>
            </v-col>
        </v-row>
        <delete-user
            v-model="deleteModal"
            :user="userForDelete"
            @confirm="confirmDelete"
        />
    </v-container>
</template>

<script>
    import {debounce} from 'lodash';
    import XPaginator from '../controls/paginator';
    import {$http, videoApiUrl} from '../../services/http';
    import qs from 'qs';
    import DeleteUser from '../controls/modals/delete-user';
    import NoSearchResults from '../controls/no-search-result';
    import {toastSuccess} from '../../services/responseErrors';
    import {mapGetters, mapState, mapMutations} from 'vuex';
    import CompanyFilter from '@/components/controls/filters/users/company-filter';

    export default {
        name: 'Users',
        components: {DeleteUser, XPaginator, NoSearchResults, CompanyFilter},
        data() {
            return {
                headers: [
                    {
                        text: 'Name',
                        value: 'name',
                        classes: ['flex-grow-1', 'name-column', 'pa-0', 'pt-2', 'pb-2'],
                        sortable: true,
                    },
                    {
                        text: 'Company',
                        value: 'company',
                        classes: ['flex-grow-1', 'company-column', 'pa-0', 'pt-2', 'pb-2'],
                        canFilter: true,
                        filterComponent: 'company-filter'
                    },
                    {
                        text: 'Role',
                        value: 'role',
                        classes: ['flex-grow-1', 'role-column', 'pa-0', 'pt-2', 'pb-2']
                    },
                ],
                items: [],
                userForDelete: {
                    id: '',
                },
                deleteModal: false,
                noQuickSearchResult: false,
                loading: false
            };
        },
        computed: {
            ...mapGetters('auth', ['isSuperAdmin', 'isSystemAdmin']),
            ...mapState('user-store', ['searchContent', 'sorting', 'filters', 'pagination']),
            getHeaders() {
                return this.headers.filter(item => {
                    return !(!this.showCompanyColumn && item.value === 'company');
                });
            },
            showCompanyColumn() {
                return this.isSuperAdmin || this.isSystemAdmin;
            }
        },
        watch: {
            filters() {
                this.setPagination({ currentPage: 1 });
                this.getPage();
            },
            'pagination.currentPage'(value, oldValue) {
                if (value === oldValue) {
                    return;
                }
                this.items = [];
                this.getPage();
            }
        },
        methods: {
            ...mapMutations('user-store', ['setPagination', 'setSearch', 'setSorting', 'setFilters']),
            isSortedBy(column) {
                return column === this.sorting.column;
            },
            getPage() {
                $http.get(videoApiUrl('api/users'), {
                    params: {
                        sorting: {...this.sorting},
                        filters: {
                            quickSearch: encodeURIComponent(this.searchContent),
                            company_id: this.filters.company
                        },
                        pagination: {
                            pageSize: this.pagination.itemPerPage,
                            current: this.pagination.currentPage
                        }
                    },
                    paramsSerializer: params => decodeURIComponent(qs.stringify(params))
                }).then(response => {
                    const {data, total} = response.data;
                    this.setPagination({ total: total });
                    this.items = [...data];
                    this.noQuickSearchResult = this.items.length < 1;
                });
            },
            editUser(userID) {
                this.$router.push({name: 'editUser', params: {'id': userID}});
            },
            deleteUser(userID) {
                this.userForDelete = this.items.filter(item => item.id === userID)[0];
                this.deleteModal = true;
            },
            confirmDelete(user) {
                $http.delete(videoApiUrl('api/users/' + user.id)).then(() => {
                    toastSuccess('User deleted.');
                    this.setPagination({ currentPage: 1 });
                    this.getPage();
                });
            },
            applyHeaderSort(column, direction) {
                this.setSorting({
                    direction: direction,
                    column: column
                });
                this.setPagination({ currentPage: 1 });
                this.getPage();
            },
            setSearchContent: debounce(function (value) {
                this.setSearch(value);
                this.resetList();
            }, 300),
            resetList() {
                this.setPagination({ currentPage: 1 });
                this.items = [];
                this.getPage();
            },
            getCompany(user) {
                return user.company_name || 'N/A';
            }
        },
        mounted() {
            this.getPage();
        }
    };
</script>

<style scoped lang="scss">
.page-header {
    flex: 0 0 310px;
}

.quick-search {
    flex: 0 0 200px;
    margin-right: 12px;
}

.sticky-nav {
    overflow: hidden;
    position: sticky;
    position: -webkit-sticky;
    top: 64px;
    z-index: 999;
    background-color: #F5F5F5;
}

.sticky-table-header {
    position: sticky;
    top: 136px;
    z-index: 5;
    background-color: #F5F5F5;
}



</style>

<style lang="scss">
.disabled-button {
    span{
        color: black !important;
    }
}
</style>