import {$http, videoApiUrl} from '../services/http';
import Role from '../models/role.js';

const roles = {
    namespaced: true,
    state: {
        roles: {
            allowedCompanyLevelRoles: [],
            libraryLevelRoles: []
        },
        loading: false,
    },
    mutations: {
        setRoles(state, roles) {
            state.roles = roles;
        },
        setLoading(state, value) {
            state.loading = value;
        }
    },
    getters: {
        allowedCompanyLevelRoles: state => state.roles.allowedCompanyLevelRoles,
        libraryLevelRoles: state => state.roles.libraryLevelRoles,
        all: state => [...state.roles.allowedCompanyLevelRoles, ...state.roles.libraryLevelRoles],
        getBySlug: (state, getters) => slug => getters.all.find(role => role.slug === slug)
            || new Role({
                name: '',
                slug: '',
                company_level: false,
                library_level: false
        }),
    },
    actions: {
        getRoles({commit}) {
            commit('setLoading', true);
            return $http.get(videoApiUrl('api/roles'))
                .then(response => {
                    commit('setRoles', {
                        allowedCompanyLevelRoles: response.data.allowedCompanyLevelRoles.map(role => new Role(role)),
                        libraryLevelRoles: response.data.libraryLevelRoles.map(role => new Role(role))
                    });
                })
                .finally(() => commit('setLoading', false));
        }
    }
};

export default roles;