<template>
    <v-expansion-panels
        :key="`activeIndex-${activeIndex}`"
        class="interactive-metadata presentation-editor-data"
        flat
        tile
        readonly
        multiple
        :value="currentOpen"
    >
        <presentation-sidebar-item
            v-for="(element) in elements"
            :key="`presentation-sidebar-item-${element._id}`"
            :element="element"
            :class="{'active-slide': activeElement && activeElement._id === element._id}"
        />
    </v-expansion-panels>
</template>

<script>
    import PresentationSidebarItem from '@/components/controls/presentation/sidebar/presentation-sidebar-item';
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'PresentationSidebar',
        components: {PresentationSidebarItem},
        computed: {
            ...mapState('presentation', ['activeIndex', 'expandAll']),
            ...mapGetters('presentation', ['elements', 'openElements', 'activeElement']),
            currentOpen() {
                return this.expandAll ? this.openElements : [this.activeIndex];
            }
        }
    };
</script>

<style scoped>
    .active-slide.elevation-0 {
        box-shadow: inset 0 0 0px 2px #2977be !important;
    }
</style>

<style>
    .presentation-editor-data .v-expansion-panel-header__icon {
        display: none;
    }
</style>