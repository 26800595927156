<template>
    <div>
        <v-row class="no-gutters">
            <v-col cols="2">
                <h1 class="page-header mt-4">
                    {{ role('editor').name }} for
                </h1>
            </v-col>
            <v-col cols="10">
                <library-role-editor
                    :key="updateCounterEditor"
                    v-model="roleLibraries.editorLibraries"
                    :libraries="libraries"
                    :company="company"
                    :exclude="excludeEditorLibraries"
                    @input="triggerInputEditor"
                />
            </v-col>
        </v-row>
        <v-row class="no-gutters">
            <v-col cols="2">
                <h1 class="page-header mt-4">
                    {{ role('read-only').name }} for
                </h1>
            </v-col>
            <v-col cols="10">
                <library-role-editor
                    :key="updateCounterReadOnly"
                    v-model="roleLibraries.readOnlyLibraries"
                    :libraries="libraries"
                    :company="company"
                    :exclude="excludeReadOnlyLibraries"
                    @input="triggerInputReadOnly"
                />
            </v-col>
        </v-row>
        <v-row class="no-gutters">
            <v-col cols="2">
                <h1 class="page-header mt-4">
                    {{ role('uploader').name }} for
                </h1>
            </v-col>
            <v-col cols="10">
                <library-role-editor
                    :key="updateCounterUploader"
                    v-model="roleLibraries.uploaderLibraries"
                    :libraries="libraries"
                    :company="company"
                    :exclude="excludeUploaderLibraries"
                    @input="triggerInputUploader"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import LibraryRoleEditor from './library-role-editor';

    export default {
        name: 'LibraryRolesEditor',
        components: {LibraryRoleEditor},
        props: ['value', 'libraries', 'company'],
        data() {
            return {
                roleLibraries: this.value,
                updateCounterEditor: 0,
                updateCounterReadOnly: 0,
                updateCounterUploader: 0,
            };
        },
        computed: {
            ...mapGetters('roles', {role: 'getBySlug'}),
            excludeEditorLibraries() {
                return this.roleLibraries.readOnlyLibraries.map(item => item.id).concat(this.roleLibraries.uploaderLibraries.map(item => item.id));
            },
            excludeReadOnlyLibraries() {
                return this.roleLibraries.editorLibraries.map(item => item.id).concat(this.roleLibraries.uploaderLibraries.map(item => item.id));
            },
            excludeUploaderLibraries() {
                return this.roleLibraries.readOnlyLibraries.map(item => item.id).concat(this.roleLibraries.editorLibraries.map(item => item.id));
            }
        },
        methods: {
            triggerInputEditor() {
                ++this.updateCounterReadOnly;
                ++this.updateCounterUploader;
                this.emitInput();
            },
            triggerInputReadOnly() {
                ++this.updateCounterEditor;
                ++this.updateCounterUploader;
                this.emitInput();
            },
            triggerInputUploader() {
                ++this.updateCounterEditor;
                ++this.updateCounterReadOnly;
                this.emitInput();
            },
            emitInput() {
                this.$emit('input', this.roleLibraries);
            }
        },
        mounted() {
            ++this.updateCounterEditor;
            ++this.updateCounterReadOnly;
            ++this.updateCounterUploader;
        }
    };
</script>

<style scoped>

</style>
