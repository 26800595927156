<template>
    <div class="color-picker">
        <v-color-picker
            hide-mode-switch
            flat
            :value="color"
            @input="colorSelected"
        />
        <v-text-field
            ref="colorEditor"
            v-model="hexValue"
            label="Hex value"
            hint="Shorthand values (#fff or #ffff) are not supported."
            persistent-hint
            @input="hexEdited"
        />
    </div>
</template>

<script>
    import {colorToRgbaObject, rgbaObjectToHex} from '../../color-helper';

    export default {
        name: 'ColorPicker',
        props: ['value'],
        data() {
            return {
                color: colorToRgbaObject(this.value),
                hexValue: '',
                hexEditing: false,

            };
        },
        computed: {
            colorAsHex() {
                return rgbaObjectToHex(this.color);
            }
        },
        methods: {
            validate() {
                let result = true;
                if (!this.hexValue.startsWith('#') || (this.hexValue.length !== 7 && this.hexValue.length !== 9)) {
                    result = {
                        message: 'The hex value is invalid. It must have 6 or 8 digits (0 - F) and must start with a # character'
                    };
                }
                this.$emit('validate', result);
                return result;
            },
            hexEdited(value) {
                // had to introduce this as the Vue color picker is triggering its input event whenever its model
                // changes (even if the prop changes)
                this.hexEditing = true;
                this.colorSelected(colorToRgbaObject(value));
                this.validate();
                this.$nextTick(() => {
                    this.hexEditing = false;
                });
            },
            colorSelected(value) {
                this.color = value;
                this.$emit('input', `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`);

                if (!this.hexEditing) {
                    this.hexValue = this.colorAsHex;
                    this.validate();
                }

            },
        },
        created() {
            this.$nextTick(() => {
                this.hexValue = this.colorAsHex;
            });

        }
    };
</script>

<style scoped>

</style>