<template>
    <div>
        <v-btn
            small
            color="primary"
            class="mr-2 copy-button button-minwidth"
            @click.stop="copyToClipboard"
        >
            <v-icon
                small
                class="mr-2"
            >
                file_copy
            </v-icon>Copy code
        </v-btn>
        <v-btn
            icon
            small
            title="Open Modal"
            color="primary"
            @click.stop="openModal"
        >
            <v-icon>remove_red_eye</v-icon>
        </v-btn>
        <embed-modal
            :open="editing"
            :contents="embedCode"
            @cancel="closeModal"
        />
    </div>
</template>

<script>
    import copy from 'copy-to-clipboard';
    import EmbedModal from './modals/embed-modal';
    import {toastSuccess} from '../../services/responseErrors';

    export default {
        name: 'EmbedCode',
        props: ['video'],
        data() {
            return {
                editing: false
            };
        },
        components: {
            EmbedModal
        },
        computed: {
            embedCode() {
                return `<script src="${process.env.VUE_APP_PLAYER_SRC}" data-video-id="${this.video.id}"></` + 'script>';
            }
        },
        methods: {
            copyToClipboard: function () {
                copy(this.embedCode, {format: 'text/plain'});
                toastSuccess('Copied to clipboard!');
            },
            openModal() {
                this.editing = true;
            },
            closeModal() {
                this.editing = false;
            }
        }
    };
</script>

<style scoped>
    .embed-code {
        word-break: break-all;
        background-color: #EEEEEE;
        padding: 0.5rem !important;
        border-radius: 0.4rem;
        cursor: copy;
    }
    .copy-button.button-minwidth {
        min-width: 132px;
    }
</style>
