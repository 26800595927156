import { render, staticRenderFns } from "./companies-usage-stats.vue?vue&type=template&id=51b815aa&scoped=true&"
import script from "./companies-usage-stats.vue?vue&type=script&lang=js&"
export * from "./companies-usage-stats.vue?vue&type=script&lang=js&"
import style0 from "./companies-usage-stats.vue?vue&type=style&index=0&id=51b815aa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51b815aa",
  null
  
)

export default component.exports