<template>
    <div :class="{'filter-popup-container': !flat}">
        <v-btn
            v-if="!flat"
            ref="button"
            icon
            small
            class="filter-button"
            :class="{
                ...buttonClass,
                'is-open': open
            }"
            @click.stop="openPopup"
        >
            <v-icon
                dark
                small
            >
                mdi-filter-variant
            </v-icon>
        </v-btn>

        <v-card
            v-if="open || flat"
            id="popupId"
            :class="{'filter-popup': !flat}"
            :style="popupStyle"
            :elevation="elevation"
        >
            <v-toolbar
                v-if="!flat"
                light
                dense
                flat
                class="pr-2"
            >
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer />
                <v-btn
                    icon
                    small
                    @click.stop="closeMenu"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <slot />
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'ListHeadFilterPopup',
        props: ['buttonClass', 'flat'],
        data: () => ({
            top: 0,
            left: '',
            minWidth: 235,
            open: false,
        }),
        computed: {
            popupStyle() {
                if (this.flat) {
                    return {};
                }
                return {
                    top: this.top + 'px',
                    left: this.left + 'px',
                    minWidth: this.minWidth + 'px'
                };
            },
            elevation() {
                return this.flat ? 0 : 4;
            }
        },
        methods: {
            closeMenu() {                
                this.open = false;
            },
            checkClickLocation(event){
                const element = document.getElementById('popupId');
                if (!element) {
                    return;
                }
                const isClickInside = element.contains(event.target);
                if(!isClickInside) {
                    this.closeMenu();
                }
            },
            openPopup() {
                const popup = this.$refs.button.$el;
                const popupPos = popup.getBoundingClientRect();
                this.top = popupPos.height;
                this.left = '';
                const r = document.querySelector('.sticky-table-header').getBoundingClientRect();
                if(popupPos.left + 220 > r.right) {
                    this.left = r.right - popupPos.left - 180;
                }
                this.open = true;
            }
        },
        mounted(){
            document.body.addEventListener('click', this.checkClickLocation, true);
        },
        beforeDestroy(){
            document.body.removeEventListener('click',  this.checkClickLocation);
        }
    };
</script>

<style scoped>
    .v-toolbar__title {
        font-size: 1rem;
    }

    .v-menu__content {
        position: fixed !important;

    }

    .filter-popup-container {
        height: 28px;
    }

    .filter-popup {
        font-weight: 400;
        position: absolute;
        z-index: 5;
    }
</style>
