<template>
    <v-simple-table
        dense
        class="property-sheet visibility-editor"
    >
        <template v-slot:default>
            <tbody>
                <tr>
                    <td>Status</td>
                    <td>
                        <v-row no-gutters>
                            <v-col cols="8">
                                <v-select
                                    v-if="editing"
                                    v-model="visibilityType"
                                    :items="privacy_select_items"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    flat
                                    @input="onUpdate"
                                />
                                <div v-else>
                                    {{ visibilityTypeText }}
                                </div>
                            </v-col>
                            <v-col
                                v-if="allowEdit"
                                cols="4"
                                class="text-right"
                            >
                                <v-btn
                                    v-if="editing"
                                    class="mr-2"
                                    x-small
                                    icon
                                    :disabled="error"
                                    color="primary"
                                    @click="editDone"
                                >
                                    <v-icon>done</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="editing"
                                    x-small
                                    icon
                                    color="error"
                                    @click="editCancelled"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="!editing"
                                    class="mr-2"
                                    x-small
                                    icon
                                    color="primary"
                                    @click="onEditStart"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
                <tr v-if="visibilityType == 'scheduled'">
                    <td>From</td>
                    <td>
                        <date-picker
                            v-if="editing"
                            v-model="dateFrom"
                            style="width: 100%;"
                            type="datetime"
                            :show-second="false"
                            placeholder="Select date"
                            value-type="timestamp"
                            format="YYYY-MM-DD HH:mm"
                            @input="onUpdate"
                        />
                        <div v-else>
                            {{ video.visibility.from | toLocalTimestamp | formatTimestamp }}
                        </div>
                        <v-sheet
                            v-if="error && errorMessageDateFrom"
                            v-safe-html="errorMessageDateFrom"
                            class="error--text floating-error pt-1 pb-1 pr-2 pl-2"
                            elevation="10"
                        />
                    </td>
                </tr>
                <tr v-if="visibilityType == 'scheduled'">
                    <td>Until</td>
                    <td>
                        <date-picker
                            v-if="editing"
                            v-model="dateUntil"
                            style="width: 100%;"
                            type="datetime"
                            :show-second="false"
                            placeholder="Select date"
                            value-type="timestamp"
                            format="YYYY-MM-DD HH:mm"
                            @input="onUpdate"
                        />
                        <div v-else>
                            {{ video.visibility.until | toLocalTimestamp | formatTimestamp }}
                        </div>
                        <v-sheet
                            v-if="error && errorMessageDateUntil"
                            v-safe-html="errorMessageDateUntil"
                            class="error--text floating-error pt-1 pb-1 pr-2 pl-2"
                            elevation="10"
                        />
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import moment from 'moment-timezone';
    import updateMeta from '../../../../services/metadata';
    import VisibilityHelper from '../../../../visibility-helper';
    import TimeHelper from '../../../../time-helper';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';

    export default {
        name: 'VisibilityEditor',
        props: ['video'],
        components: {
            DatePicker
        },
        computed: {
            visibilityTypeText() {
                return VisibilityHelper.getOption(this.video.visibility.type);
            },
            allowEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        data() {
            return {
                error: false,
                errorMessageDateFrom: '',
                errorMessageDateUntil: '',
                editing: false,
                privacy_select_items: VisibilityHelper.getOptions(),
                dateFrom: null,
                dateUntil: null,
                visibilityType: this.video.visibility.type
            };
        },
        methods: {

            resetErrors() {
                this.error = false;
                this.errorMessageDateFrom = '';
                this.errorMessageDateUntil = '';
            },
            onUpdate() {
                this.resetErrors();
                this.error = this.validate();
            },
            validate() {
                if (this.visibilityType == 'scheduled' && !this.dateFrom && !this.dateUntil) {
                    return true;
                }
                if (this.visibilityType == 'scheduled') {
                    if (this.dateFrom && this.dateFrom < moment()) {
                        this.errorMessageDateFrom = 'The selected date cannot be in the past.';
                        return true;
                    }
                    if (this.dateUntil && this.dateUntil < moment()) {
                        this.errorMessageDateUntil = 'The selected date cannot be in the past.';
                        return true;
                    }
                    if (this.dateFrom && this.dateUntil && this.dateFrom >= this.dateUntil) {
                        this.errorMessageDateUntil = 'The selected date must be after ' + moment(this.dateFrom).format('YYYY-MM-DD HH:mm');
                        return true;
                    }
                }
                return false;
            },
            onEditStart() {
                this.editing = true;
                this.error = this.validate();
            },
            editDone() {
                this.resetErrors();
                updateMeta(this.video.id, {
                    visibility: {
                        type: this.visibilityType,
                        from: this.dateFrom ? moment.unix(this.dateFrom).utc().unix() / 1000 : null,
                        until: this.dateUntil ? moment.unix(this.dateUntil).utc().unix() / 1000 : null
                    }
                }).then(() => {
                    this.video.visibility.type = this.visibilityType;
                    this.video.visibility.from = this.dateFrom ? moment.unix(this.dateFrom).utc().unix() / 1000 : null;
                    this.video.visibility.until = this.dateUntil ? moment.unix(this.dateUntil).utc().unix() / 1000 : null;

                    this.$emit('onVisibilityUpdated');
                    this.editing = false;
                });

            },
            editCancelled() {
                this.editing = false;
                this.resetErrors();

            },
        },
        filters: {
            formatTimestamp: function (value) {
                return value ? moment(value).format('YYYY-MM-DD HH:mm') : '';
            },
            toLocalTimestamp(value) {
                return value ? TimeHelper.fromUnixToClientsTz(value).unix() * 1000 : null;
            }
        },
        mounted() {
            this.dateFrom = this.$options.filters.toLocalTimestamp(this.video.visibility.from);
            this.dateUntil = this.$options.filters.toLocalTimestamp(this.video.visibility.until);
        }
    };
</script>

<style lang="scss">
    .visibility-editor {
        .v-select {
            margin-top: -5px !important;

            .v-select__selections {
                .v-select__selection {
                    font-size: 0.8rem;
                    line-height: 16px;
                }
            }
        }

        .mx-input {
            height: 25px !important;
            font-size: 12px !important;
        }

        .floating-error {
            top: auto !important;
        }

        .v-text-field__details {
            display: none;
        }
    }
</style>
