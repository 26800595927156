<template>
    <div>
        <v-container
            id="external-upload-page"
            fluid
            fill-height
        >
            <v-row
                justify-center
                align-center
            >
                <v-col
                    ref="dragDrop"
                    class="drop-area darken-1 mx-3"
                    :class="{'drag-active': dragActive}"
                >
                    <div class="content pl-3 pr-3">
                        <v-col
                            sm="12"
                            class="drag-and-drop draggable text-center pa-0"
                        >
                            <v-card
                                elevation="0"
                                color="transparent"
                                class="drop-vcard"
                            >
                                <v-icon
                                    color="primary"
                                    class="upload-icon"
                                >
                                    mdi-cloud-upload
                                </v-icon>
                                <br>
                                <h1 class="drop-caption darkgray--text">
                                    Drag & drop files or folder
                                    <small class="d-block font-italic grey--text">Maximum file size: 10 GB</small>
                                </h1>
                                <file-upload
                                    ref="upload"
                                    v-model="files"
                                    :thread="3"
                                    accept="video/mov,video/quicktime,video/mp4,video/x-m4v,video/*"
                                    :multiple="true"
                                    :chunk-enabled="true"
                                    :chunk="chunk"
                                    drop="#external-upload-page .drop-area"
                                    :drop-directory="true"
                                    class="py-2 px-5 draggable"
                                    @input-filter="inputFilter"
                                    @input-file="onFileInput"
                                    @input="$refs.upload.active = true"
                                >
                                    <v-btn
                                        color="primary"
                                        class="my-5 py-0"
                                    >
                                        Or choose files
                                    </v-btn>
                                </file-upload>
                            </v-card>
                        </v-col>
                        <v-col
                            sm="12"
                            xl="8"
                            offset-xl="2"
                            lg="10"
                            offset-lg="1"
                            class="pa-0"
                        >
                            <v-card
                                elevation="0"
                                color="transparent"
                                class="upload-list"
                                style=""
                            >
                                <transition-group
                                    name="file-list"
                                    tag="div"
                                >
                                    <v-row
                                        v-for="(file, index) in filteredFiles"
                                        :key="file.id"
                                        class="file-row pl-8 pr-8"
                                    >
                                        <v-row
                                            class="mr-4 ml-4 list-control-item"
                                            :class="{'no-errors': !file.error || file.error == 'cancel', 'selected': file.selected, 'disabled': file.progress !== 100}"
                                            @click.stop="onSelectionChange(file)"
                                        >
                                            <v-card
                                                elevation="0"
                                                class="list-mode d-flex pl-2 pr-2 pt-0 pb-1"
                                                style="width: 100%"
                                            >
                                                <div class="toggle-column flex-grow-0 pa-2">
                                                    <v-checkbox
                                                        :input-value="file.selected"
                                                        @click.stop="onSelectionChange(file)"
                                                    />
                                                </div>
                                                <v-col
                                                    style="flex: 0 0 0"
                                                    class="pr-0 pl-0"
                                                >
                                                    <v-icon
                                                        color="darkGray"
                                                        class="video-icon"
                                                        size="48"
                                                    >
                                                        mdi-file-video
                                                    </v-icon>
                                                </v-col>
                                                <v-col style="flex: 1 1 0; line-height: normal">
                                                    <v-row>
                                                        <v-col
                                                            sm="12"
                                                            md="9"
                                                            class="pa-0"
                                                        >
                                                            <div class="file-info-holder pl-5 d-inline-block">
                                                                {{ file.name }} ({{ file.size | filesize }})
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            sm="12"
                                                            md="3"
                                                            class="pt-0 pl-0 pb-0 pr-8 d-flex progress-data"
                                                        >
                                                            <span>{{ Math.ceil(file.progress || 0) }} %</span>
                                                            <v-icon
                                                                v-if="file.success"
                                                                size="18"
                                                                color="success"
                                                            >
                                                                mdi_check
                                                            </v-icon>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col
                                                            sm="12"
                                                            md="12"
                                                            lg="12"
                                                            xl="12"
                                                        >
                                                            <v-progress-linear
                                                                :bottom="true"
                                                                class="elevation-0"
                                                                :value="file.progress"
                                                                rounded
                                                                :color="file.success ? 'primary' : (file.error ? (file.error == 'cancel' ? 'warning' : 'error') : 'primary')"
                                                                height="10"
                                                            />
                                                            <v-sheet
                                                                v-if="file.error && file.error != 'cancel'"
                                                                v-safe-html="getErrors(file)"
                                                                color="transparent"
                                                                class="file-errors"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col
                                                    style="flex: 0 0 0"
                                                    class="text-right pt-2 pr-0 pl-0"
                                                >
                                                    <v-btn
                                                        v-if="file.active || file.error || (file.progress == 0 && !file.error)"
                                                        icon
                                                        color="red darken-1 white--text"
                                                        @click.prevent="cancelUpload(file)"
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="file.error && file.error != 'cancel' && $refs.upload.features.html5"
                                                        icon
                                                        color="secondary"
                                                        @click.prevent="retryUpload(file)"
                                                    >
                                                        <v-icon>mdi-reload</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-show="file.success"
                                                        icon
                                                        color="error"
                                                        :disabled="file.active"
                                                        @click="removeFile(index, file)"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-card>
                                        </v-row>
                                    </v-row>
                                </transition-group>
                            </v-card>
                        </v-col>
                    </div>
                </v-col>
            </v-row>

            <delete-videos
                v-model="deleteModal"
                :videos="deleteVideos"
                @confirm="confirmDelete"
            />
        </v-container>
        <right-drawer v-if="hasSelectedVideo">
            <extermal-upload-metadata :selected-video="selectedVideo" />
        </right-drawer>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import VueUploadComponent from 'vue-upload-component';
    import {ChunkUploadHandler} from '../../plugins/chunk-upload/ChunkUploadHandler';
    import DeleteVideos from '../controls/modals/delete-videos';
    import {$http, videoApiUrl} from '../../services/http';
    import {toastError, toastSuccess} from '../../services/responseErrors';
    import {getProperUnitSize} from '../../services/metadata';
    import RightDrawer from '../controls/right-drawer';
    import ExtermalUploadMetadata from '../controls/video-metadata/external-upload-metadata';
    import { $auth } from '../../services/auth';
    import store from '../../store';

    var url = window.location.toString();
    var regex = /.*[&?]token=([a-zA-Z0-9-_.]+).*/i;
    var hasToken = regex.test(url);

    if (hasToken) {
        var token = url.replace(regex, '$1');
        $auth.loginByAccessToken(token).then(() => {
            store.dispatch('libraries/getLibraries');
        });
    }

    export default {
        name: 'ExternalUpload',
        components: {
            FileUpload: VueUploadComponent,
            DeleteVideos,
            RightDrawer,
            ExtermalUploadMetadata
        },
        filters: {
            filesize: function (num) {
                if (typeof num !== 'number' || isNaN(num)) {
                    return 0;
                }
                return getProperUnitSize(num);
            }
        },
        data: function () {
            return {
                files: [],
                dragActive: false,
                feedback: '',
                snackBarColor: 'error',
                snackbar: false,
                deleteVideos: [],
                deleteModal: false,
                library: null,
                selectedVideo: [],
                selected: false
            };
        },
        computed: {
            ...mapState('libraries', ['libraries']),
            ...mapState('auth', ['user']),
            hasSelectedVideo() {
                return this.selectedVideo.length > 0;
            },
            extraData() {
                return {
                    library: this.library.id
                };
            },
            chunk() {
                // needs to reload the access token as when CHUNK_DEFAULT_OPTIONS is defined,
                // local storage may not be filled/available
                return {
                    headers: {
                        'Authorization': 'Bearer ' + this.getAccessToken(),
                        'Accept': 'application/json',
                    },
                    action: process.env.VUE_APP_VIDEO_API_HOST + 'api/upload/chunk',
                    minSize: 1048576,
                    maxActive: 3,
                    maxRetries: 5,
                    handler: ChunkUploadHandler
                };

            },
            filteredFiles() {
                return this.files.filter((file) => {
                    return !file.error || file.error != 'cancel';
                });
            }
        },
        watch: {
            $route (to) {
                if (to.name == 'upload') {
                    this.library = null;
                    this.$store.dispatch('libraries/getLibraries');
                }
            }
        },
        beforeRouteLeave (to, from, next) {
            if (this.confirmLeave()){
                next();
            } else {
                // Cancel navigation
                next(false);
            }
        },
        created() {
            window.addEventListener('beforeunload', this.onBeforeUnload);
        },
        methods: {
            onSelectionChange(file) {
                this.selectedVideo = [
                    {
                        id: file.objectId
                    }
                ];
                this.filteredFiles.forEach(item => item.selected = false);
                file.selected = true;
            },
            onFileInput(newFile) {
                if(newFile && newFile.success) {
                    this.selectedVideo = [
                        {
                            id: newFile.objectId
                        }
                    ];
                    this.filteredFiles.forEach(item => item.selected = false);
                    newFile.selected = true;
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!this.library) {
                        if (this.libraries.length  >= 1) {
                            this.setLibrary(this.libraries[0]);
                        } else {
                            toastError('Please make sure that you are assigned to a library.');
                            return prevent();
                        }
                    }

                    if (!/^video\/.*$/i.test(newFile.type)) {
                        var fileExtension = newFile.name.substring(newFile.name.lastIndexOf('.')+1, newFile.name.length);
                        toastError('File: ' + newFile.name + ' with type of ' + fileExtension + ' not accepted!');
                        return prevent();
                    }
                    // do not allow files smaller then the minimum chunk
                    if (newFile.size < 1048576) {
                        toastError('File: ' + newFile.name + ' is not accepted! The minimum filesize is 1MB');
                        return prevent();
                    }
                    // prevent uploading very large files
                    if (newFile.size > process.env.VUE_APP_MAXIMUM_FILE_SIZE) {
                        toastError('File: ' + newFile.name + ' is too large. The maximum file size that can be uploaded is 10 GB.');
                        return prevent();
                    }

                    // inputFilter is called both both at start and finish (which is odd)
                    // - this check makes sure that the library selected for a file already uploading is not changed
                    // - allows selecting a different library for a subsequent upload
                    if (!newFile.data.library) {
                        newFile.data.library = this.extraData.library;
                    }

                    newFile.data.uploaded_by = this.user.name;
                }
            },
            removeFile(index, file) {
                this.deleteVideos = [
                    {
                        id: file.objectId,
                        title: file.name,
                        uploadFileObject: file
                    }
                ];

                this.deleteModal = true;
            },
            confirmDelete(items) {
                this.deleteItems(items).then(items => {
                    for (let i in items) {
                        this.$refs.upload.remove(items[i].uploadFileObject);
                    }
                });
            },
            deleteItems(items) {
                return new Promise((resolve, reject) => {
                    let requests = items.map(function (item) {
                        return new Promise((resolve, reject) => {
                            $http.delete(videoApiUrl(`api/videos/${item.id}`)).then(() => {
                                resolve();
                            }).catch((e) => reject(e));
                        });
                    });

                    Promise.all(requests).then(() => {
                        toastSuccess('Videos deleted');
                        resolve(items);
                    }).catch(e => {
                        reject(e);
                    });
                });
            },
            cancelUpload(file) {
                if (file.chunk && file.chunk.file && file.chunk.chunks) {
                    file.chunk.abort();
                }
                this.$refs.upload.update(file, {error: 'cancel'});
                //decrease uploading counter to make sure uploads dont get stuck
                this.$refs.upload.uploading--;
            },
            retryUpload(file) {
                this.$refs.upload.update(file, {active: true, error: '', progress: '0.00'});
            },
            onDragLeave(e) {
                if (e.target.className.indexOf('drop-area') !== -1 &&
                    e.fromElement.parentNode.className.indexOf('drop-area') === -1) {
                    this.dragActive = false;
                }
            },
            onDrop() {
                this.dragActive = false;
            },
            onDragEnter(e) {
                if (e.target.className !== 'row') {
                    this.dragActive = true;
                }
            },
            onBodyDropOrDragOver(e) {
                e.preventDefault();
                e.stopPropagation();
                if (event.type === 'drop') {
                    this.dragActive = false;
                    var validTarget = false;
                    e.path.forEach((node) => {
                        if (node.id == 'external-upload-page') {
                            validTarget = true;
                        }
                    });
                    if (!validTarget && this.$route.name === 'upload') {
                        toastError('Please drop your file into the target box.');
                    }
                }
                return false;
            },
            setLibrary(library) {
                this.library = library;
            },
            getErrors(file) {
                const s = Object.values(file.response.errors)
                    .reduce((a, b) => Object.values(a).join('<br />') + '<br />' + Object.values(b).join('<br />'));

                if (Array.isArray(s)) {
                    return s.join('<br />');
                }
                return s;
                // return "";
            },
            getAccessToken() {
                let access_token = sessionStorage.getItem('access_token');
                if (access_token) {
                    return access_token;
                }
                if(!this.$route.query.token) {
                    toastError('Invalid token.');
                    return '';
                }
                return this.$route.query.token;
            },
            onBeforeUnload(e) {
                if (!this.confirmLeave()) {
                    // Cancel the event
                    e.preventDefault();
                    // Chrome requires returnValue to be set
                    e.returnValue = '';
                }
            },

            confirmLeave() {
                return window.confirm('Do you really want to leave? you have unsaved changes!');
            },
        },
        mounted() {
            this.library = null;
            if (store.state.auth.authenticated) {
                store.dispatch('libraries/getLibraries');
            }
            this.$refs.dragDrop.parentNode.addEventListener('dragleave', this.onDragLeave, false);
            this.$refs.dragDrop.addEventListener('drop', this.onDrop);
            this.$refs.dragDrop.addEventListener('dragenter', this.onDragEnter, false);
            document.addEventListener('drop', this.onBodyDropOrDragOver, false);
            document.addEventListener('dragover', this.onBodyDropOrDragOver, false);
        },
        beforeDestroy() {
            this.$refs.dragDrop.parentNode.removeEventListener('dragleave', this.onDragLeave);
            this.$refs.dragDrop.removeEventListener('dragenter', this.onDragEnter);
            this.$refs.dragDrop.removeEventListener('drop', this.onDrop);
            document.removeEventListener('drop', this.onBodyDropOrDragOver);
            document.addEventListener('dragover', this.onBodyDropOrDragOver, false);
            window.removeEventListener('beforeunload', this.onBeforeUnload);
        },
    };
</script>

<style lang="scss">
    #external-upload-page {
        .drop-area {
            &.drag-active {
                border: 4px solid #2977be !important;
                background-color: lighten(#2977be, 50%) !important;
                padding: 8px;
            }
            .drop-caption {
                font-weight: normal;
                font-size: 1.2rem;
            }
        }
        .disabled {
            pointer-events:none;
        }
    }

    .drag-and-drop {
        .upload-icon {
            font-size: 80px;
        }
        .file-uploads {
            label {
                cursor: pointer;
            }
        }
    }

    .file-row {
        .video-icon {
            font-size: 80px;
        }
    }

    .file-list-item {
        display: block;
    }

    .file-list-enter-active, .file-list-leave-active {
        transition: all 1s;
    }

    .file-list-enter {
        opacity: 0;
        transform: translateX(30px);
    }

    .file-list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .drop-vcard {
        z-index: 1;
        border: 0 none transparent;
        background-color: transparent;
    }

    .progress-data {
        align-content: flex-end;

        span {
            flex: 1 1 0;
            text-align: right;
        }

        .v-icon {
            flex: 0 0 16px;
            width: 16px;
            text-indent: -60px;
            font-weight: bold;
        }
    }

    .library-sheet-content {
        text-align: center;
        margin-left: 25px;
    }

    .library-company-name {
        opacity: .7;
        margin-right: .5rem
    }

    .library-company-name:after {
        content: "/";
        margin-left: .5rem;
    }

    .selection-content {
        width: 100%;
        text-align: center;
    }

    .drop-selector .v-select__slot label {
        display: block;
        width: 100%;
        text-align: center;
        padding-left: 50px;
    }

    .item-library {
        opacity: .5;
        margin-left: 1rem;
        font-size: .7rem;
    }

    .file-errors {
        padding: 5px 7px;
        color: red !important;
        font-size: .75rem;
        background-color: transparent;
    }

    .upload-list .list-control-item .v-card.list-mode > div {
        height: auto !important;
    }

    .upload-list .list-control-item.no-errors .v-card.list-mode > div {
        height: 4rem !important;
    }
</style>
