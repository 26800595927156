import SkipToAction from '../actions/SkipToAction';

class SkipToActionFactory {
    make(actionType) {
        const fromData = {
            type: actionType,
            timeIndex: 0,
        };

        return new SkipToAction(fromData);
    }
}

export default new SkipToActionFactory();