var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"pt-1 pb-1 vertical-align-top"},[_vm._v(" YouTube ")]),(_vm.loading)?_c('td',{staticClass:"pt-1 pb-1"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"22","color":"grey"}})],1):_vm._e(),(!_vm.loading)?_c('td',{key:("youtube-publishing-" + _vm.publishUpdate),staticClass:"pt-1 pb-1"},[(_vm.canPublish)?_c('v-btn',{staticClass:"mr-2 mb-1 copy-button button-minwidth",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.publishSetup()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" publish ")]),_c('span',{staticClass:"mr-5"},[_vm._v("Publish")])],1):_c('v-sheet',{attrs:{"color":"grey--text"}},[_c('ul',{staticClass:"publish-errors"},[(!_vm.videoCompanyId || !_vm.hasYoutubeAccounts)?_c('li',[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"small":""}},[_vm._v(" error_outline ")]),_vm._v(" No account linked. ")],1):_vm._e()])]),(_vm.hasPublishingData)?_c('v-sheet',_vm._l((_vm.publishingData),function(publishingItem){return _c('div',{key:("youtube-publishing-data-" + (publishingItem.date))},[_c('v-sheet',{staticClass:"publish-date",staticStyle:{"line-height":"28px"},attrs:{"height":"28"}},[_vm._v(" Published: "+_vm._s(_vm.getPublishDate(publishingItem))+" "),(publishingItem.status && publishingItem.status ==='uploaded')?_c('v-btn',{attrs:{"icon":"","text":"","small":"","color":"black","href":_vm.getItemHref(publishingItem),"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" open_in_new ")])],1):_vm._e(),(!publishingItem.status || publishingItem.status==='pending')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"gray"}},on),[_vm._v(" hourglass_empty ")])]}}],null,true)},[_c('span',[_vm._v("Upload in progress...")])]):_vm._e(),(!publishingItem.status || publishingItem.status==='failed')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"error"}},on),[_vm._v(" error ")])]}}],null,true)},[_c('span',[_vm._v("Failure reason: "+_vm._s(publishingItem.failure_reason))])]):_vm._e(),(!publishingItem.status || publishingItem.status==='rejected')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"error"}},on),[_vm._v(" call_missed ")])]}}],null,true)},[_c('span',[_vm._v("Rejected reason: "+_vm._s(publishingItem.rejection_reason))])]):_vm._e()],1)],1)}),0):_vm._e(),_c('validation-observer',{ref:"validateYouTubePublish",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('generic-modal',{attrs:{"title":"Publish to YouTube","confirm-button":"Publish","canccel-button":"Cancel","confirm-color":"primary"},on:{"confirm":function($event){return _vm.publish()},"cancel":function($event){return _vm.cancel()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Title","rules":{required: true, max: 100}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Title","counter":"100","error-messages":errors},on:{"blur":validate},model:{value:(_vm.publishOptions.title),callback:function ($$v) {_vm.$set(_vm.publishOptions, "title", $$v)},expression:"publishOptions.title"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Titles longer than 70 characters will be truncated in search results. ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":{required: true, max: 1000}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"dense":"","label":"Description","counter":"1000","error-messages":errors},on:{"blur":validate},model:{value:(_vm.publishOptions.description),callback:function ($$v) {_vm.$set(_vm.publishOptions, "description", $$v)},expression:"publishOptions.description"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Only the first 125 characters of the description will appear as a snippet in search. ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Account","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","items":_vm.youtubeAccounts,"item-text":"name","item-value":"id","label":"YouTube account","error-messages":errors},on:{"input":validate},model:{value:(_vm.publishOptions.accountId),callback:function ($$v) {_vm.$set(_vm.publishOptions, "accountId", $$v)},expression:"publishOptions.accountId"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" YouTube publish supports multiple accounts. The account is required to select the category as well as for the upload itself. ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":"Tags"},model:{value:(_vm.publishOptions.tags),callback:function ($$v) {_vm.$set(_vm.publishOptions, "tags", $$v)},expression:"publishOptions.tags"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Tags are limited to 30 characters / tag, separated by comma and may only contain numbers, letters and spaces. ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"youtubeCategories",attrs:{"dense":"","items":_vm.categories,"item-text":"name","item-value":"id","label":"YouTube Category","error-messages":errors,"disabled":_vm.categoriesLoading},on:{"input":validate},model:{value:(_vm.publishOptions.category),callback:function ($$v) {_vm.$set(_vm.publishOptions, "category", $$v)},expression:"publishOptions.category"}},[_c('info-tooltip',{attrs:{"slot":"append-outer"},slot:"append-outer"},[_vm._v(" Categories are predefined by YouTube and are linked to the selected account. ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Privacy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","label":"Privacy","items":_vm.privacyOptions,"error-messages":errors},on:{"input":validate},model:{value:(_vm.publishOptions.privacy),callback:function ($$v) {_vm.$set(_vm.publishOptions, "privacy", $$v)},expression:"publishOptions.privacy"}})]}}],null,true)})],1)],1)]},proxy:true},{key:"terms",fn:function(){return [_c('div',{staticClass:"pb-2 px-4"},[_c('a',{staticStyle:{"color":"#666666"},attrs:{"href":"https://www.youtube.com/t/terms","target":"_blank"}},[_vm._v("YouTube Terms of Service")]),_c('br'),_c('a',{staticStyle:{"color":"#666666"},attrs:{"href":"https://policies.google.com/privacy","target":"_blank"}},[_vm._v("Google Privacy Policy")])])]},proxy:true},{key:"actions",fn:function(){return [(_vm.isPublishing)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary","text":""},on:{"click":function($event){return _vm.publish()}}},[_vm._v(" Publish ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")])]},proxy:true}],null,true),model:{value:(_vm.publishModal),callback:function ($$v) {_vm.publishModal=$$v},expression:"publishModal"}})]}}],null,false,3925217232)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }