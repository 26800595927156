<template>
    <!-- Left navigation -->
    <v-navigation-drawer
        :value="$vuetify.breakpoint.mdAndDown && drawer || $vuetify.breakpoint.lgAndUp && !drawer || drawer"
        :expand-on-hover="$vuetify.breakpoint.lgAndUp && drawer"
        :mobile-break-point="$vuetify.breakpoint.thresholds.md"
        mini-variant-width="55"
        clipped
        :absolute="$vuetify.breakpoint.mdAndDown"
        :app="$vuetify.breakpoint.lgAndUp"
        class="left-navigation-color"
        @input="hideNav"
    >
        <v-list>
            <template v-for="item in items">
                <v-row
                    v-if="item.heading"
                    :key="item.heading"
                    align="center"
                >
                    <v-col cols="12">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                </v-row>
                <v-divider
                    v-else-if="item.divider"
                    :key="item.divider"
                    class="my-1"
                />
                <v-list-group
                    v-else-if="item.children"
                    :key="item.text"
                    v-model="item.model"
                    :prepend-icon="item.icon"
                    :append-icon="item.model ? item.appendicon : item['appendicon-alt']"
                    :class="{...item.classes, 'active': isCurrentOrParent(item)}"
                >
                    <template v-slot:activator>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </template>
                    <!-- child items -->
                    <v-list-item
                        v-for="(child, i) in item.children"
                        :key="i"
                        link
                        :to="child.route"
                    >
                        <v-list-item-icon v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ child.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- END child items -->
                </v-list-group>
                <v-list-item
                    v-else
                    :key="item.text"
                    link
                    :to="item.route"
                    :class="{...item.classes, 'active': isCurrentOrParent(item)}"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title @click="click(item)">
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
    <!-- END Left navigation -->
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import Gate from '../../services/gate';

    export default {
        name: 'LeftDrawer',
        data() {
            return {
                activeItems: {
                    mediaLibrary: 'mediaLibrary',
                    users: 'admin',
                    addUser: 'admin',
                    editUser: 'admin',
                    editCompany: 'admin',
                    addCompany: 'admin',
                    companies: 'admin',
                    profile: 'admin',
                    usageStatsPage: 'admin'
                }
            };
        },
        computed: {
            ...mapGetters('auth', []),
            ...mapState('layout', {
                drawer: 'leftDrawer'
            }),
            items() {
                let companyMenu = {
                    icon: 'business',
                    text: 'Companies',
                    route: { name: 'companies' }
                };

                if (this.$store.getters['auth/user'].role === 'company-admin') {
                    companyMenu = {
                        icon: 'business',
                        text: 'Company',
                        route: {
                            name: 'editCompany',
                            params: { id: this.$store.getters['auth/user'].company }
                        }
                    };
                }

                let usageStatsMenu = null;
                if (['super-admin', 'system-admin'].includes(this.$store.getters['auth/user'].role)) {
                    usageStatsMenu = { icon: 'bar_chart', text: 'Usage Stats', route: { name: 'usageStatsPage' } };
                }

                return [
                    {
                        id: 'mediaLibrary',
                        icon: 'video_library',
                        text: 'Media library',
                        route: { name: 'mediaLibrary' }
                    },
                    {
                        id: 'statistics',
                        icon: 'bar_chart',
                        text: 'Statistics',
                        route: {name: 'statistics'}
                    },
                    {
                        id: 'admin',
                        icon: 'settings_applications',
                        'icon-alt': 'settings_applications',
                        text: 'Admin',
                        children: [
                            { icon: 'person', text: 'Profile', route: { name: 'profile' } },
                            companyMenu,
                            {
                                icon: 'mdi-bank',
                                text: 'Libraries',
                                route: { name: 'libraries' }
                            },
                            { icon: 'people', text: 'Users', route: { name: 'users' } },
                            usageStatsMenu
                        ].filter(item =>
                            item && Gate.allows(this.$store.getters['auth/user'], item.route.name)
                        ),
                        route: {}
                    },
                    {
                        id: 'ftp',
                        icon: 'mdi-folder-upload',
                        text: 'FTP',
                        route: {name: 'ftp-upload'}
                    },
                    {
                        icon: 'mdi-cloud-upload',
                        text: 'Upload',
                        route: {
                            name: 'upload'
                        },
                        classes: { 'hidden-md-and-up': true }
                    }
                ].filter(item => {
                    if (item.route.name === 'ftp-upload') {
                        return Gate.allowsFtpAccess(this.$store.getters['auth/user']);
                    } else if ( item.route.name === 'statistics' || item.route.name === 'mediaLibrary') {
                        return Gate.allowsStatisticsOrMediaLibrary(this.$store.getters['auth/user']);

                    } else {
                        return Gate.allows(this.$store.getters['auth/user'], item.route.name ?? '');
                    }
                });
            }
        },
        methods: {
            click(item) {
                if (item.route) {
                    this.$router.push(item.route);
                }
            },
            hideNav(value) {
                this.$store.commit('layout/setLeftDrawer', value);
            },
            isCurrentOrParent(item) {
                return (
                    item.id === this.activeItems[this.$route.name] ||
                    (item.route && item.route.name === this.$route.name)
                );
            }
        },
        mounted() {
            this.$store.commit('layout/closeLeftDrawer');
        }
    };
</script>

<style scoped>
.v-navigation-drawer--absolute,
.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.9);
}

.v-navigation-drawer--close {
  box-shadow: 0 0 0 transparent;
}

.v-navigation-drawer--mini-variant .v-list-item > *:not(:first-child) {
  display: initial;
}
</style>
