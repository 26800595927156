import InteractiveElementFormat from './InteractiveElementFormat';
import InteractiveFont from './primitives/InteractiveFont';

class InteractiveTextFormat extends InteractiveElementFormat {
    constructor(data) {
        super(data);
        this.default.font = new InteractiveFont(data.default.font);
        this.hover.font = new InteractiveFont(data.hover.font);
    }
}

export default InteractiveTextFormat;