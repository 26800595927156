<template>
    <v-container fluid
                 class="px-8"
    >
        <v-row>
            <div class="page-title-container">
                <h1 class="page-header ml-8 mt-4 mb-0">
                    Ftp
                </h1>
            </div>
        </v-row>
        <v-row justify-center>
            <v-col cols="12"
                   lg="8"
                   md="7"
                   sm="12"
            >
                <ftp-folders />
            </v-col>
            <v-col cols="12"
                   lg="4"
                   md="5"
                   sm="12"
            >
                <ftp-actions />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import FtpFolders from './ftp-folders';
    import FtpActions from './ftp-actions';
    import { mapActions,  } from 'vuex';
    export default {
        name: 'FTP',
        components: { FtpFolders, FtpActions},
        methods:{
            ...mapActions('ftp',['resetStore'])
        },
        beforeDestroy(){
            this.resetStore();
        }
    };
</script>

<style scoped lang="scss">

</style>