import {sortBy} from 'lodash';
import {skipToTimeIndex} from '@/store/interactive/helpers';
import Presentation from '@/models/presentation/Presentation';
import PresentationItem from '@/models/presentation/PresentationItem';

const presentationMutations = {
    setLoading: (state, payload) => state.isLoading = payload,
    setVideo: (state, payload) => state.video = payload,
    setPresentation: (state, payload) => state.presentation = new Presentation(payload),
    setPresentationPosition: (state, payload) => state.presentation.settings.position = payload,
    setActiveElement: (state, element) => {
        state.presentation.items.forEach(e => e.active = e._id === element._id);
        state.activeIndex = state.presentation.items.findIndex(item => item.active);
        skipToTimeIndex(state, element.start);
    },
    addSlide: (state, payload) => {
        state.presentation.items.push(new PresentationItem(payload));
        state.presentation.items = sortBy(state.presentation.items, 'start');
        state.activeIndex = state.presentation.items.findIndex(item => item.active);
    },
    updateSlide: (state, payload) => {
        state.presentation.items.forEach(item => {
            if (item._id === payload._id) {
                if (!!payload.start && payload.start >= 0) {
                    item.start = payload.start;
                }

                if (payload.name) {
                    item.name = payload.name;
                }

                if (payload.src) {
                    item.src = payload.src;
                }

                if (payload.cdn_src) {
                    item.cdn_src = payload.cdn_src;
                }
            }
        });
        state.presentation.items = sortBy(state.presentation.items, 'start');
        state.activeIndex = state.presentation.items.findIndex(item => item.active);
    },
    deleteSlideItem: (state, payload) => {
        state.presentation.items = state.presentation.items.filter(item => item._id !== payload._id);
        state.activeIndex = state.presentation.items.findIndex(item => item.active);
    },
    deactivate: (state) => {
        state.presentation.items.forEach(e => e.active = false);
        state.activeIndex = null;
    },
    setPlayer: (state, payload) => state.player = payload,
    setCurrentPlayerTime: (state, value) => state.currentPlayerPosition = value,
    setExpandAll: (state, payload) => state.expandAll = payload,
    setSlides: (state, payload) => state.presentation = {...state.presentation, items: payload},
};

export default presentationMutations;