<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>Source File</v-expansion-panel-header>
        <v-expansion-panel-content>
            <template v-slot:default>
                <v-simple-table
                    dense
                    class="property-sheet mb-1"
                >
                    <tbody>
                        <tr>
                            <td>Duration:</td>
                            <td>{{ durations }}</td>
                        </tr>
                        <tr v-if="!multipleVideos">
                            <td>Resolution:</td>
                            <td>{{ video.width }} x {{ video.height }}</td>
                        </tr>
                        <tr v-if="!multipleVideos">
                            <td>File Type:</td>
                            <td>
                                {{ video['mime-type'] }}
                            </td>
                        </tr>
                        <tr>
                            <td>Size:</td>
                            <td>{{ videoSizes }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <replace
                    v-if="showReplaceVideo"
                    :video="video"
                />

                <span class="download-text">Download</span>
                <v-simple-table
                    dense
                    class="property-sheet mb-1"
                >
                    <tbody>
                        <tr>
                            <td class="source-text">
                                Source
                            </td>
                            <td>
                                <v-btn
                                    small
                                    color="primary"
                                    class="mr-2 my-1 copy-button button-minwidth"
                                    @click.stop="copySourceUrl"
                                >
                                    <v-icon
                                        small
                                        class="mr-2"
                                    >
                                        file_copy
                                    </v-icon>
                                    <span class="mr-1">Copy URL</span>
                                </v-btn>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn
                                            icon
                                            small
                                            class="my-1"
                                            v-on="on"
                                            @click="download"
                                        >
                                            <v-icon color="primary">
                                                mdi-download
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Download source file</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <encodings :key="encodingsKey"
                           :video="video"
                />
            </template>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import TimeHelper from '../../../time-helper';
    import {getProperUnitSize} from '../../../services/metadata';
    import Replace from '../replace';
    import Gate from '../../../services/gate';
    import store from '../../../store/index';
    import copy from 'copy-to-clipboard';
    import {videoApiUrl} from '../../../services/http';
    import {toastSuccess} from '../../../services/responseErrors';
    import Encodings from '@/components/controls/encodings';
    import { FileUploadEventBust } from '@/plugins/chunk-upload/ChunkUploadHandler.js';    

    export default {
        name: 'MetadataFile',
        props: ['videos', 'multipleVideos', 'video'],
        components: {
            Replace,
            Encodings,
        },
        data(){
            return {
                encodingsKey: 0,
            };
        },
        computed: {
            videoSizes () {
                let size = 0;
                this.videos.forEach((video) => size += video.size);
                return getProperUnitSize(size);
            },
            showReplaceVideo() {
                return !this.multipleVideos && Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            },
            durations () {
                let duration = 0;
                this.videos.forEach(video => duration += video.duration);
                return TimeHelper.fromMsToHis(duration);
            },
        },
        methods: {
            copySourceUrl() {
                copy(videoApiUrl('videos/' + this.video.id + '/download'), {format: 'text/plain'});
                toastSuccess('Copied to clipboard!');
            },
            download() {
                const file = document.createElement('a');
                file.href = videoApiUrl('videos/' + this.video.id + '/download');
                file.setAttribute('download', 'video');
                document.body.appendChild(file);
                setTimeout(() => {
                    file.click();
                }, 1000);
            },
        },
        mounted() {
            FileUploadEventBust.$on('file-uploaded', () => {
                this.encodingsKey++;
            });
        }
    };
</script>

<style scoped>
    .button-minwidth {
        min-width: 132px;
    }
    .download-text {
        font-weight: 500;
        color: #676767;
    }
    .source-text {
        min-width: 7rem;
    }
</style>
