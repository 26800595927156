<template>
    <div class="d-flex flex-column">
        <ftp-actions-library-select v-model="library" />
        <v-checkbox v-model="emailWhenUploadsCompleted"
                    label="Email me when uploads are completed"
                    hide-details
                    class="mt-0"
        />
        <h4 class="mt-4 mb-2">
            and encode in
        </h4>
        <div class="px-4">
            <v-checkbox v-model="encodeVersions"
                        label="360p"
                        :value="360"
                        hide-details
                        class="mt-0"
            />
            <v-checkbox v-model="encodeVersions"
                        label="720p"
                        :value="720"
                        hide-details
                        class="mt-0"
            />
            <v-checkbox v-model="encodeVersions"
                        label="1080p"
                        :value="1080"
                        hide-details
                        class="mt-0"
            />
        </div>
        <v-checkbox v-model="emailDownloadUrls"
                    label="Email me download URLs when encoding completed"
                    hide-details
        />
        <v-checkbox v-model="deleteFromFtp"
                    label="Remove files from FTP after encoding is completed"
                    hide-details
                    class="mt-0"
        />
        <v-btn v-if="!uploadStarted"
               color="primary"
               class="ml-auto mt-10"
               :loading="loading"
               :disabled="actionDisabled"
               @click="upload"
        >
            <span class="mr-3">Start action</span>
            <v-icon>mdi-send</v-icon>
        </v-btn>
        <div v-else
             class="d-flex align-center ml-auto mt-10"
        >
            <h4 class="mr-3">
                Upload started
            </h4>
            <v-icon color="primary">
                mdi-check-circle
            </v-icon>
        </div>
        <div v-if="filesWithErrorWhileUploading.length > 0 && !loading"
             class="mt-10"
        >
            <h4 class="mb-2">
                There was an error with the following files:
            </h4>
            <ul class="mb-2">
                <li v-for="file in filesWithErrorWhileUploading"
                    :key="`error-${file}`"
                >
                    {{ file }}
                </li>
            </ul>
            <h4>please try again.</h4>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { $http, ftpApiUrl } from '../../../services/http';
    import FtpActionsLibrarySelect from './ftp-actions-library-select.vue';
    export default {
        name:'FtpActionsUpload',
        components:{FtpActionsLibrarySelect},
        data(){
            return {
                library: {},
                uploadStarted: false,
                emailWhenUploadsCompleted: false,
                emailDownloadUrls: false,
                deleteFromFtp: false,
                encodeVersions: [],
                loading: false,
                filesWithErrorWhileUploading: []
            };
        },
        computed:{
            ...mapState('ftp', ['selectedFiles']),
            actionDisabled(){
                return Object.keys(this.library).length === 0 || this.selectedFiles.length === 0;
            }
        },
        methods:{
            async upload(){
                this.loading = true;
                this.filesWithErrorWhileUploading = [];
                for (const file in this.selectedFiles){
                    const data = {
                        path: this.selectedFiles[file].path,
                        library: this.library.id,
                        'email_after_upload': this.emailWhenUploadsCompleted,
                        'email_download_urls': this.emailDownloadUrls,
                        'delete_from_ftp' : this.deleteFromFtp,
                        'encoding_versions': this.encodeVersions
                    };
                    await $http.post(ftpApiUrl('/ftp/upload'), data)
                        .catch(() => {
                            this.filesWithErrorWhileUploading.push(this.selectedFiles[file].path);
                        });
                }
                this.loading = false;
                if(this.filesWithErrorWhileUploading.length === 0){
                    this.uploadStarted = true;
                }
            },
        },
        watch:{
            library(){
                this.uploadStarted = false;
                this.filesWithErrorWhileUploading = [];
            },
            selectedFiles(){
                this.uploadStarted = false;
            }
        },
    };
</script>

<style lang="scss" scoped>
h4{
    color: #000000DE
}
</style>