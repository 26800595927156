<template>
    <stats-card class="h13 metric smaller"
                :loading="loading"
    >
        <template v-slot:title>
            Total watch time
        </template>
        <template v-slot:content>
            {{ totalWatchTime }}
            <div class="metric-explain">
                (hours minutes and seconds)
            </div>
        </template>
    </stats-card>
</template>

<script>
    import { $http, getStatQueryRequest } from '@/services/http';
    import StatsCard from './stats-card';
    import TimeHelper from '@/time-helper';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'CardTotalWatchTime',
        components: {
            StatsCard,
        },
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapState('statistics-details', [
                'dateRange',
                'videoId',
                'totalWatchTime',
            ]),
        },
        methods: {
            ...mapMutations('statistics-details', ['setTotalWatchTime']),
            async getTotalWatchTime() {
                this.loading = true;
                const response = await $http.get(
                    getStatQueryRequest(
                        'api/videos/total-watch-time/',
                        [this.videoId],
                        this.dateRange
                    )
                );
                const currentVideoData = response.data.find(
                    (bucket) => bucket.key === this.videoId
                );
                this.setTotalWatchTime(
                    currentVideoData
                        ? TimeHelper.fromMsToHis(currentVideoData.total_time)
                        : '00:00:00'
                );
                this.loading = false;
            },
        },
        mounted() {
            this.getTotalWatchTime();
        },
    };
</script>