<template>
    <tr>
        <td class="pt-1 pb-1 vertical-align-top">
            Vimeo
        </td>
        <td
            v-if="loading"
            class="pt-1 pb-1"
        >
            <v-progress-circular
                indeterminate
                size="22"
                color="grey"
            />
        </td>
        <td
            v-if="!loading"
            :key="`vimeo-publishing-${publishUpdate}`"
            class="pt-1 pb-1"
        >
            <v-btn
                v-if="canPublish"
                small
                color="primary"
                class="mr-2 mb-1 copy-button button-minwidth"
                @click="publishSetup()"
            >
                <v-icon class="mr-1">
                    publish
                </v-icon>
                <span class="mr-5">Publish</span>
            </v-btn>
            <v-sheet
                v-else
                color="grey--text"
            >
                <div v-if="!canPublish">
                    <ul class="publish-errors">
                        <li v-if="!videoCompanyId || !vimeoAccount">
                            <v-icon
                                small
                                class="mr-2 mt-n1"
                            >
                                error_outline
                            </v-icon>
                            No account linked.
                        </li>
                        <li v-if="!video.size">
                            <v-icon
                                small
                                class="mr-2 mt-n1"
                            >
                                error_outline
                            </v-icon>
                            No file size information available.
                        </li>
                    </ul>
                </div>
            </v-sheet>
            <v-sheet v-if="hasPublishingData">
                <span>
                    <v-sheet
                        height="28"
                        style="line-height: 28px;"
                        elevation="0"
                        class="publish-date"
                    >Published: {{ publishDate }}
                        <v-btn
                            icon
                            text
                            small
                            color="black"
                            :href="vimeoUrl"
                            target="_blank"
                        >
                            <v-icon color="primary">open_in_new</v-icon>
                        </v-btn>
                    </v-sheet>
                </span>
            </v-sheet>
            <validation-observer
                ref="validatePublish"
                v-slot="{invalid, validate}"
            >
                <generic-modal
                    v-model="publishModal"
                    title="Publish to Vimeo"
                    confirm-button="Publish"
                    cancel-button="Cancel"
                    confirm-color="primary"
                    @confirm="publish()"
                    @cancel="cancel()"
                >
                    <template v-slot:default>
                        <v-row>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Title"
                                    :rules="{ required:true, max:100}"
                                >
                                    <v-text-field
                                        v-model="publishOptions.title"
                                        dense
                                        label="Title"
                                        counter="100"
                                        :error-messages="errors"
                                        @blur="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            Titles longer than 70 characters will be truncated in search results.
                                        </info-tooltip>
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Description"
                                    :rules="{max: 1000}"
                                >
                                    <v-textarea
                                        v-model="publishOptions.description"
                                        dense
                                        label="Description"
                                        counter="1000"
                                        :error-messages="errors"
                                        @blur="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            Only the first 125 characters of the description will appear as a snippet in
                                            search.
                                        </info-tooltip>
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="publishOptions.tags"
                                    dense
                                    label="Tags"
                                >
                                    <info-tooltip slot="append-outer">
                                        Tags are limited to 30 characters / tag, separated by comma and may only contain
                                        numbers, letters and spaces.
                                    </info-tooltip>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Category"
                                    rules="required"
                                >
                                    <v-autocomplete
                                        ref="categories"
                                        v-model="publishOptions.category"
                                        dense
                                        label="Category"
                                        :items="categories"
                                        item-text="name"
                                        item-value="value"
                                        :error-messages="errors"
                                        :filter="customFilter"
                                        @input="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            Categories are predefined by Vimeo.<br>
                                            <strong>Your selected category may not be applied by Vimeo:</strong><br>
                                            Vimeo takes this selection as a suggestion, and <u>makes the final
                                                determination</u> about whether the video belongs in these categories.
                                        </info-tooltip>
                                        <template v-slot:item="{item}">
                                            <span
                                                v-if="item.parentName && categoriesFiltered()"
                                                class="mr-1 cat"
                                            >{{ item.parentName }}:</span>{{ item.name }}
                                        </template>
                                    </v-autocomplete>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{errors}"
                                    name="Privacy"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="publishOptions.privacy"
                                        dense
                                        label="Privacy"
                                        :items="privacyOptions"
                                        :error-messages="errors"
                                        @input="validate"
                                    >
                                        <info-tooltip slot="append-outer">
                                            <i>Unlisted</i> and <i>disabled</i>&nbsp;<u>cannot be used with <b>Vimeo
                                                Basic</b> accounts</u>, only with <strong>Vimeo Plus, Vimeo Pro</strong> and
                                            <strong>Vimeo Business</strong>
                                        </info-tooltip>
                                    </v-select>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:actions>
                        <v-spacer />
                        <v-progress-circular
                            v-if="isPublishing"
                            indeterminate
                        />
                        <v-btn
                            :disabled="invalid"
                            color="primary"
                            text
                            @click="publish()"
                        >
                            Publish
                        </v-btn>
                        <v-btn
                            text
                            @click="cancel()"
                        >
                            Cancel
                        </v-btn>
                    </template>
                </generic-modal>
            </validation-observer>
        </td>
    </tr>
</template>

<script>

    import moment from 'moment-timezone';
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import {getVimeoAccount, getVimeoCategories, publishToVimeo} from '../../../services/vimeo';
    import GenericModal from '../modals/generic-modal';
    import InfoTooltip from '../../primitives/info-tooltip';

    export default {
        name: 'PublishVimeo',
        components: {
            InfoTooltip,
            GenericModal,
            ValidationProvider,
            ValidationObserver
        },
        props: ['video', 'log', 'videoCompanyId'],
        data() {
            return {
                vimeoAccount: null,
                publishOptions: {
                    title: '',
                    description: '',
                    tags: '',
                    category: '',
                    privacy: '',
                },
                publishingStatus: this.log ,
                categories: [],
                privacyOptions: [
                    'anybody',
                    'contacts',
                    'disable',
                    'nobody',
                    'password',
                    'unlisted',
                    'users'
                ],
                publishModal: false,
                loading: false,
                publishUpdate: 0,
                isPublishing: false,
            };
        },
        watch:{
            publishModal(value) {
                this.$emit('popup-status', value);
            }
        },
        computed: {
            publishDate() {
                const last = this.publishingStatus.length - 1;
                return this.hasPublishingData ? this.publishingStatus[last].date.split(' ')[0] : 'n/a';
            },
            vimeoUrl() {
                const last = this.publishingStatus.length - 1;
                return this.hasPublishingData ? this.publishingStatus[last].link : '#';
            },
            canPublish() {
                if (!this.videoCompanyId) {
                    return false;
                }

                if (!this.vimeoAccount) {
                    return false;
                }

                if (!this.video.size || this.video.size == 0) {
                    return false;
                }

                return true;
            },
            hasPublishingData() {
                return this.publishingStatus.length > 0;
            },

        },
        methods: {
            categoriesFiltered() {
                if (!this.$refs.categories) {
                    return false;
                }
                return this.$refs.categories.filteredItems.length < this.categories.length;
            },
            publishSetup() {
                this.publishModal = true;
                setTimeout(() => {
                    this.$refs.validatePublish.validate();
                }, 1000);
            },
            customFilter(item, itemText) {
                return ((item.parentName || '') + ':' + item.name).toLowerCase().indexOf(itemText.toLowerCase()) > -1 && !item.header;
            },
            publish() {
                // prevent submit if invalid (modal confirm is independent of the submit button state)
                if (this.$refs.validatePublish.ctx.invalid) {
                    return;
                }

                const publishingData = {
                    account_id: this.vimeoAccount.id,
                    video_title: this.publishOptions.title,
                    video_description: this.publishOptions.description,
                    file_size: this.video.size,
                    category: this.publishOptions.category,
                    tags: this.publishOptions.tags.split(',').map(item => {
                        return {text: item};
                    }),
                    privacy_status: this.publishOptions.privacy,
                    mime_type: this.video['mime-type'],
                    duration: this.video.duration,
                    video_url: this.video.source_url,
                    meta: {
                        video_id: this.video.id,
                        platform: 'qpd',
                        external_platform: 'vimeo',
                        account_name: this.vimeoAccount.name,
                    }
                };

                // shows circular progress on the publishing modal
                this.isPublishing = true;
                publishToVimeo(publishingData).then(response => {
                    const publishingStatus = {
                        uri: response.data.uri,
                        link: response.data.link,
                        accountId: this.vimeoAccount.id,
                        accountName: this.vimeoAccount.name,
                    };

                    this.loading = true;

                    this.publishingStatus.push({
                        ...publishingStatus,
                        date: moment().format('YYYY-MM-DD HH:mm:ss')
                    });

                    this.publishUpdate++;
                    this.loading = false;
                    this.publishModal = false;
                })
                    .finally(() => {
                        this.isPublishing = false;
                    });
            },

            prepareModal() {
                this.publishOptions.title = this.video.title;
                this.publishOptions.description = this.video.description;
                this.publishOptions.tags = this.video.tags.join(',');
                getVimeoCategories().then(categories => {
                    this.categories = this.collapseCategories(categories);
                });
            },
            cancel() {
                this.publishModal = false;
            },
            collapseCategories(categories) {
                const result = [];

                categories.forEach(category => {
                    const header = {
                        header: category.name,
                        name: category.name + ' '
                    };

                    result.push(header);
                    const catItem = {
                        id: category.id,
                        name: category.name,
                        value: category.name,
                        parentName: null,
                    };
                    result.push(catItem);

                    if (category.subcategories !== null) {
                        category.subcategories.forEach(subcategory => {
                            result.push({
                                id: subcategory.id,
                                name: subcategory.name,
                                value: `${category.name}|${subcategory.name}`,
                                parentName: category.name,
                            });
                        });
                    }
                });

                return result;
            },

        },
        mounted() {
            this.loading = true;
            this.prepareModal();
            this.vimeoAccount = null;
            if (!this.videoCompanyId) {
                this.loading = false;
                return;
            }
            getVimeoAccount(this.videoCompanyId).then(account => {
                this.vimeoAccount = {
                    id: account.id,
                    name: account.name
                };
            }).finally(() => this.loading = false);
        }
    };
</script>

<style scoped lang="scss">
    .col {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    span.grey--text {
        font-size: .8em;
    }

    .copy-button.button-minwidth {
        min-width: 132px;
    }

    .publish-errors {
        margin-top: 1px;
        list-style: none;
        padding: 0;
    }

    .vertical-align-top {
        vertical-align: top;
    }


</style>
<style lang="scss">
    .v-autocomplete__content {
        .v-list--dense {
            .v-subheader {
                font-size: 0.85rem;
                font-weight: 500;
                color: black;
            }
        }
    }

    .cat {
        color: black;
        font-weight: 500;
    }
</style>
