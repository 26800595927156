<template>
    <confirm-modal
        title="Delete user"
        :value="value"
        max-width="640"
        confirm-button="Delete"
        cancel-button="Cancel"
        @cancel="cancel"
        @confirm="confirm"
    >
        <p>The selected user will be deleted. Would you like to confirm?</p>
        <v-list>
            <v-list-item dense>
                <v-list-item-content>
                    {{ user.name }}
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </confirm-modal>
</template>

<script>
    import ConfirmModal from './confirm-modal';

    export default {
        name: 'DeleteUser',
        components: {ConfirmModal},
        props: ['value', 'user'],
        methods: {
            confirm() {
                this.$emit('confirm', this.user);
                this.$emit('input', false);
            },

            cancel() {
                this.$emit('input', false);
            },
        }
    };
</script>

<style scoped>

</style>
