var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.pageLoading)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify-center":"","align-center":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"content"},[_c('v-col',{attrs:{"md":"6"}},[_c('h1',{staticClass:"ml-4 mr-4 page-header"},[(!_vm.library.id)?_c('span',[_vm._v("Add")]):_vm._e(),(_vm.library.id)?_c('span',[_vm._v("Edit")]):_vm._e(),_vm._v(" library ")]),_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('v-form',{staticClass:"ml-4 mr-4",on:{"submit":function($event){$event.preventDefault();return _vm.submitlibrary($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"LibraryName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Library name","error-messages":errors,"prepend-icon":"mdi-bank"},on:{"keyup":validate},model:{value:(_vm.library.name),callback:function ($$v) {_vm.$set(_vm.library, "name", $$v)},expression:"library.name"}})]}}],null,true)})],1),(_vm.showCompany)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Company","prepend-icon":"mdi-office-building","items":_vm.companies,"loading":_vm.companiesLoading,"item-text":"company_name","item-value":"id","search-input":_vm.search,"error-messages":errors},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":validate,"change":validate},model:{value:(_vm.library.company),callback:function ($$v) {_vm.$set(_vm.library, "company", $$v)},expression:"library.company"}})]}}],null,true)})],1):_vm._e()],1),_c('domain-whitelist-editor',{on:{"input":validate},model:{value:(_vm.library.whitelisted_domains),callback:function ($$v) {_vm.$set(_vm.library, "whitelisted_domains", $$v)},expression:"library.whitelisted_domains"}}),_c('webhooks-listing',{attrs:{"value":_vm.library.webhooks}}),_c('default-tags-listing',{attrs:{"value":_vm.library.defaultTags}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-left"},[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"accent","disabled":invalid || !validated}},[(!_vm.library.id)?_c('span',[_vm._v("Add library")]):_vm._e(),(_vm.library.id)?_c('span',[_vm._v("Save changes")]):_vm._e()]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{name: 'libraries'}}},[_c('v-btn',{attrs:{"color":"error","text":""}},[_vm._v(" Cancel ")])],1)],1)])],1)],1)]}}],null,false,2593942017)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('library-landing-page-settings',{attrs:{"value":_vm.library}})],1),_c('v-divider'),(_vm.library.id)?_c('v-col',{attrs:{"md":"12"}},[_c('assigned-library-users',{attrs:{"library-id":_vm.library.id}})],1):_vm._e(),(_vm.library.id)?_c('v-col',{staticClass:"player-templates-wrapper",attrs:{"md":"12"}},[_c('h1',{staticClass:"ml-4 mr-4 page-header"},[_vm._v(" Player templates ")]),_c('library-player-template-list',{attrs:{"library-id":_vm.library.id}})],1):_vm._e()],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }