<template>
    <div class="logo-upload">
        <div v-if="!loading">
            <div v-if="!hasSelectedLogo">
                <img v-if="hasOriginal"
                     class="logo-holder mb-4"
                     :src="originalValue"
                >
                <div v-else
                     class="logo-holder mb-4"
                >
                    Please select a logo
                </div>
            </div>
            <div v-else>
                <img class="logo-holder mb-4"
                     :src="selectedLogoDisplay"
                >
                <div class="mb-4">
                    Please click Save changes to upload the logo
                </div>
            </div>
        </div>
        <div v-else>
            <v-progress-circular class="mb-4"
                                 indeterminate
                                 size="36"
                                 color="primary"
            />
        </div>
        <input v-if="uploadReady"
               ref="logoInput"
               type="file"
               accept=".jpg, .jpeg, .png, image/jpeg, image/png"
               label="Select logo"
               class="file-input"
               @input="onSelectFile"
        >
        <v-btn class="mr-4"
               color="primary"
               small
               outlined
               @click="onSelectLogoClick"
        >
            <span v-if="hasOriginal">Replace Logo</span>
            <span v-else>Select Logo</span>
        </v-btn>
        <v-btn v-if="hasSelectedLogo"
               class="mr-4"
               small
               outlined
               @click="resetLogo"
        >
            Reset
            Logo
        </v-btn>
        <v-btn v-if="hasOriginal"
               color="secondary"
               small
               outlined
               @click="removeLogo"
        >
            Remove Logo
        </v-btn>
        <confirm-modal v-model="confirmRemove"
                       title="Remove logo?"
                       confirm-button="Remove Logo"
                       cancel-button="Cancel"
                       @confirm="onConfirmRemove"
        >
            This will remove the logo for this library and the preview page will show the default company logo afterwards. Would you like to do this?
        </confirm-modal>
    </div>
</template>

<script>
    import {deleteLogo} from '@/services/library';
    import ConfirmModal from '@/components/controls/modals/confirm-modal.vue';

    export default {
        name: 'LogoUpload',
        components: {ConfirmModal},
        props: {
            value: {
                type: String,
                default: () => null,
            },
            libraryId: {
                type: Number,
                required: true,
            },
            hasOwnLogo: {
                type: Boolean,
                default: () => false,
            }
        },
        data() {
            return {
                loading: false,
                internalValue: null,
                originalValue: this.value,
                selectedLogoDisplay: null,
                uploadReady: true,
                confirmRemove: false,
            };
        },
        computed: {
            hasOriginal() {
                return !!this.originalValue
                    && typeof this.originalValue === 'string'
                    && this.hasOwnLogo;
            },
            hasSelectedLogo() {
                return !!this.internalValue;
            }
        },
        methods: {
            onSelectFile() {
                const input = this.$refs.logoInput;
                const files = input.files;
                if (files && files[0]) {
                    const reader = new FileReader();
                    reader.onload = e => this.selectedLogoDisplay = e.target.result;
                    reader.readAsDataURL(files[0]);
                    this.internalValue = files[0];
                    this.$emit('input', this.internalValue);
                }
            },
            onSelectLogoClick() {
                this.$refs.logoInput.click();
            },
            resetLogo() {
                this.uploadReady = false;
                this.selectedLogoDisplay = null;
                this.internalValue = null;
                this.uploadReady = true;
                this.$emit('input', this.originalValue);
            },
            removeLogo() {
                this.confirmRemove = true;
            },
            async onConfirmRemove() {
                this.loading = true;
                this.originalValue = null;
                this.resetLogo();
                await deleteLogo(this.libraryId);
                this.loading = false;
                this.$emit('input', null);
            }
        }
    };
</script>

<style scoped>
.file-input {
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
}

.logo-holder {
    max-height: 200px;
    max-width: 200px;
    display: block;
}
</style>