<template>
    <div
        class="metadata-editor"
        :class="cssClasses"
    >
        <v-btn
            v-show="!isOpen"
            icon
            color="primary"
            class="edit-button mb-2"
            x-small
            @click="openEditor"
        >
            <v-icon>edit</v-icon>
        </v-btn>
        <v-component
            :is="editor_type"
            v-show="isOpen"
            v-model="field_value"
            :name="name"
            :open="isOpen"
            :type="subtype"
            :meta="meta"
            :required="required"
            :max-dialog-width="maxDialogWidth"
            @cancel="cancelEditing"
            @input="save"
        />
    </div>
</template>

<script>
    /**
     * This meta field editor is an extensible polymorphic editor:
     * Variants:
     *  - inline: The inline variant supports only a text field
     *  - modal: May support a wide variety of metadata editors, including complex ones
     *    Implemented subtypes:
     *    - Textarea: Currently this is the only one implemented, but more can be created in the future
     */

    import Inline from './Inline';
    import Modal from './Modal';
    import Tags from './Tags';
    import Combobox from './Combobox';
    import Imagepicker from './ImagePicker';

    export default {
        name: 'MetadataFieldEditor',
        props: ['value', 'type', 'subtype', 'name', 'meta', 'open', 'keepOpen', 'required', 'maxDialogWidth'],
        components: {
            Inline,
            Modal,
            Tags,
            Combobox,
            Imagepicker,
        },
        data() {
            return {
                editor_type: this.type,
                field_value: this.value,
                editing: this.open || false
            };
        },
        computed: {
            cssClasses() {
                const items = {
                    'editing': this.isOpen,
                };

                items[this.type] = true;

                return items;
            },
            isOpen() {
                if(this.type === 'inline'){
                    return true;
                }
                return this.editing;
            }
        },
        methods: {
            save() {
                if(!this.keepOpen) {
                    this.editing = false;
                }
                this.$emit('input', this.field_value);
            },
            openEditor() {
                this.editing = true;
            },
            cancelEditing() {
                this.editing = false;
            }
        },
    };
</script>

<style scoped>

</style>
