<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>Subtitles & Transcripts</v-expansion-panel-header>
        <v-expansion-panel-content class="subtitles">
            <v-simple-table
                dense
                class="property-sheet mt-0"
            >
                <template v-slot:default>
                    <tbody>
                        <meta-languages-field
                            v-if="!multipleVideos"
                            :video="video"
                        />
                        <tr>
                            <td colspan="2">
                                <subtitles
                                    :key="updateKey"
                                    :video="video"
                                >
                                    <template v-slot:title>
                                        Subtitles
                                    </template>
                                </subtitles>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <transcripts
                                    :key="updateKey"
                                    :video="video"
                                >
                                    <template v-slot:title>
                                        Transcripts
                                    </template>
                                </transcripts>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import Subtitles from './editor/Subtitles';
    import MetaLanguagesField from './fields/meta-languages-field';
    import Transcripts from '@/components/controls/video-metadata/editor/Transcripts';

    export default {
        name: 'MetadataSubtitles',
        components: {Transcripts, MetaLanguagesField, Subtitles},
        data() {
            return {
                updateKey: 0,
            };
        },
        props: ['video', 'multipleVideos'],
    };
</script>

<style scoped>

</style>

<style>
.subtitles .v-expansion-panel-content__wrap {
    padding-right: 14px !important;
}
</style>
