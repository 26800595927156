import BaseAction from './BaseAction';

class OpenVideoAction extends BaseAction{
    constructor(data) {
        super(data);
        this.videoId = data.videoId;
        this.autoPlay = data.autoPlay;
        this.mute = data.mute;
        this.startAt = data.startAt;
    }
}

export default OpenVideoAction;