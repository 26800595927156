<template>
    <v-sheet class="editor-inline">
        <div class="editor-field mr-2">
            <input
                ref="field"
                type="text"
                class="field-control"
                solo
                dense
                :value="editor_value"
                @input="input"
            >
            <v-sheet
                v-if="error"
                v-safe-html="errorMessage"
                class="error--text floating-error pt-1 pb-1 pr-2 pl-2"
                elevation="10"
            />
        </div>
    </v-sheet>
</template>

<script>
    import {debounce} from 'lodash';

    export default {
        name: 'Inline',
        props: ['value', 'open', 'meta', 'name', 'required'],
        data() {
            return {
                editor_value: null,
                error: false,
                errorMessage: ''
            };
        },
        watch: {
            open(val) {
                if (val) {
                    const field = this.$refs.field;
                    field.focus();
                    field.setSelectionRange(0, field.value.length);
                }
            },
            value: {
                handler(val) {
                    this.editor_value = val;
                },
                immediate: true
            }
        },
        methods: {
            input: debounce(function (e) {
                this.editor_value = e.target.value;
                if (this.validate() === true) {
                    this.error = false;
                    this.errorMessage = false;
                    this.$emit('input', this.editor_value);
                }
            }, 400),
            validate() {
                let result = true;
                this.error = false;
                this.errorMessage = '';
                if (this.editor_value.length === 0 && this.required) {
                    this.error = true;
                    this.errorMessage = 'The <strong>' + this.name + '</strong> is required.';
                    return {
                        message: this.errorMessage
                    };

                }
                if (this.editor_value.length > this.meta.counter) {
                    this.error = true;
                    this.errorMessage = 'The <strong>' + this.name + '</strong> cannot be longer than <strong>' + this.meta.counter + '</strong> characters';
                    return {
                        message: this.errorMessage
                    };
                }
                if (this.meta.minLength && this.editor_value.length < this.meta.minLength) {
                    this.error = true;
                    this.errorMessage = 'The <strong>' + this.name + '</strong> cannot be shorter than <strong>' + this.meta.minLength + '</strong> characters';
                    return {
                        message: this.errorMessage
                    };
                }

                const re = new RegExp(this.meta.pattern);
                if (this.editor_value.length > 0 &&  !re.test(this.editor_value)) {
                    this.error = true;
                    this.errorMessage = 'The <strong>' + this.name + '</strong> can contain only the following characters: <strong> a-zA-Z0-9-_ </strong> and <strong>space</strong>.';
                    return {
                        message: this.errorMessage
                    };
                }

                return result;
            }
        }
    };
</script>

<style scoped>

</style>
