<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>Thumbnail</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-img
                v-if="!thumbnailProcessing"
                :src="video.thumbnail"
                aspect-ratio="1"
                class="grey lighten-2 video-thumbnail"
                max-width="315px"
                max-height="177px"
                alt="Thumbnail"
            />
            <div
                v-if="thumbnailProcessing"
                class="thumbnail-instead grey lighten-2 video-thumbnail"
            >
                <v-progress-circular indeterminate />
            </div>
            <div
                v-if="allowEdit"
                class="button-container d-flex"
            >
                <v-btn
                    small
                    color="primary"
                    class="mr-2"
                    :disabled="thumbnailProcessing || !videoStarted"
                    @click="useCurrentFrame"
                >
                    Use current frame
                </v-btn>
                <v-btn
                    small
                    normal
                    color="primary"
                    :disabled="thumbnailProcessing"
                    @click="openDialog"
                >
                    Upload file
                </v-btn>
            </div>
            <input
                v-show="false"
                ref="fileInput"
                type="file"
                accept="image/*"
                @change="uploadSelected"
            >
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import {$http, videoApiUrl} from '../../../services/http';
    import {toastError} from '../../../services/responseErrors';
    import Gate from '../../../services/gate';
    import store from '../../../store/index';

    export default {
        name: 'MetadataThumbnail',
        props: ['video'],
        data() {
            return {
                thumbnailProcessing: false,
                videoStarted: false
            };
        },
        computed: {
            allowEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            useCurrentFrame() {
                this.thumbnailProcessing = true;
                var smallPreviewId = document.querySelector('[id^="preview-player-' + this.video.id + '"]').id;
                window.QuadiaPlayer.players[smallPreviewId].get('time')
                    .then(time => {
                        $http.post(videoApiUrl(`api/videos/${this.video.id}/thumbnails/capture-frame`), {time})
                            .then((response) => {
                                this.video.thumbnail = response.data.thumbnail;
                                this.$emit('input', response.data.thumbnail);
                            })
                            .finally(() => this.thumbnailProcessing = false);
                    }).catch(() => {
                        this.thumbnailProcessing = false;
                    });
            },
            openDialog() {
                this.$refs.fileInput.click();
            },
            uploadSelected() {
                var formData = new FormData();
                formData.append('file', this.$refs.fileInput.files[0]);
                this._upload(formData);
            },
            _upload(formData) {
                this.thumbnailProcessing = true;
                $http.post(videoApiUrl(`api/videos/${this.video.id}/thumbnails`), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.video.thumbnail = res.data.thumbnail;
                    this.$emit('input', res.data.thumbnail);
                    this.thumbnailProcessing = false;
                });
            },
            playbackStarted() {
                this.videoStarted = true;
            },
            playbackTimeChanged(time) {
                var smallPreviewId = document.querySelector('[id^="preview-player-' + this.video.id + '"]').id;
                window.QuadiaPlayer.players[smallPreviewId].get('duration')
                    .then((duration) => {
                        if (Math.floor(duration) <= parseInt(time)) {
                            this.videoStarted = false;
                        }
                    });
            },
            getPreviewPlayer() {
                return new Promise((resolve, reject) => {
                    let count = 0;
                    let t = setInterval(() => {
                        try {
                            if (count > 50) {
                                clearTimeout(t);
                                return reject('timeout');
                            } else {
                                const qp = window.QuadiaPlayer;
                                if (qp) {
                                    const previewPlayerNode = document.querySelector('[id^="preview-player-' + this.video.id + '"]');
                                    if(previewPlayerNode){
                                        const smallPreviewId = previewPlayerNode.id;
                                        const previewPlayer = qp.players[smallPreviewId];
                                        if (previewPlayer) {
                                            resolve(previewPlayer);
                                        } else {
                                            count++;
                                        }
                                    } else {
                                        count++;
                                    }
                                } else {
                                    count++;
                                }
                            }
                        } catch (e) {
                            reject(e);
                        }
                    }, 100);
                });
            },
            setPlayerListeners(){
                this.getPreviewPlayer().then(previewPlayer => {
                    previewPlayer.$on('play', this.playbackStarted);
                    previewPlayer.$on('seek', this.playbackStarted);
                    previewPlayer.$on('firstFrame', this.playbackStarted);
                    previewPlayer.$on('timeChange', this.playbackTimeChanged);
                }).catch(e => {
                    toastError(e.message);
                });
            }
        },
        mounted() {
            this.videoStarted = false;
            this.videoFinished = false;

            this.setPlayerListeners();
        },
        updated(){
            this.setPlayerListeners();
        }
    };
</script>
<style scoped>
    .video-thumbnail {
        display: block;
        margin: 1rem auto;
    }

    .button-container {
        justify-content: center;
    }

    .thumbnail-instead {
        width: 315px;
        height: 177px;
        display: flex;
        align-content: space-around;
        align-items: center;
        overflow: hidden;
    }

    .thumbnail-instead > div {
        flex: 0 0 299px;
    }
</style>
