<template>
    <v-tooltip bottom>
        <template v-slot:activator="{on}">
            <v-checkbox
                :input-value="expandAll"
                hide-details
                label="Expand all"
                class="editor-expando py-0 my-1 mr-2"
                @change="toggleExpandAll"
                v-on="on"
            />
        </template>
        <span>Expands all slides, not just the active one.</span>
    </v-tooltip>
</template>

<script>
    import {mapActions, mapState} from 'vuex';

    export default {
        name: 'PresentationEditorExpand',
        computed: {
            ...mapState('presentation', ['expandAll']),
        },
        methods: {
            ...mapActions('presentation', ['toggleExpandAll']),
        }
    };
</script>

<style lang="scss">
.editor-expando {
  display: inline-flex;
  .v-label {
    font-size: 12px !important;
    font-weight: bold;
  }
}
</style>