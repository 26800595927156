<template>
    <v-dialog
        ref="dialog"
        :value="value"
        :max-width="internalMaxWidth"
        scrollable
        @click:outside="cancel"
        @keydown="onKeyDown"
    >
        <v-card>
            <v-btn
                fab
                icon
                small
                absolute
                class="dialog-close-btn mr-1 mt-1"
                color="primary"
                @click="cancel()"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-card-title>
                {{ title }}
            </v-card-title>
            <v-card-text ref="content">
                <slot />
            </v-card-text>
            <v-card-actions>
                <v-spacer><slot name="terms" /></v-spacer>
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'GenericModal',
        props: ['value', 'title', 'maxWidth'],
        data() {
            return {
                internalMaxWidth: this.maxWidth || 640
            };
        },
        watch: {
            value(newValue) {
                if (newValue === true) {
                    setTimeout(() => {
                        // focuses the dialog content to listen for keydown events (closes/triggers default confirm action)
                        this.$refs.dialog.show();
                    }, 200);
                }
            }
        },
        methods: {
            confirm() {
                this.$emit('confirm');
            },

            cancel() {
                this.$emit('cancel');
            },
            onKeyDown(e) {
                if (e.keyCode === 27) {
                    this.cancel();
                    return;
                }

                if (e.keyCode === 13) {
                    this.confirm();
                    return;
                }
            }
        }
    };
</script>

<style scoped>
    .dialog-close-btn {
        right: 0
    }
</style>
