<template>
    <fragment>
        <tr v-if="libraryCompanyInfo.hasCompanyName">
            <td>Company</td>
            <td>
                <a :href="resolveCompanyLink"
                   target="_blank"
                >
                    {{ libraryCompanyInfo.companyName }}
                </a>
            </td>
        </tr>
        <tr>
            <td>Library: </td>
            <td>
                <v-sheet>
                    <span v-if="libraryCompanyInfo.hasAnything">
                        <a :href="resolveLibraryLink"
                           target="_blank"
                        >
                            {{ libraryCompanyInfo.libraryName }}
                        </a>
                    </span>
                </v-sheet>
                <slot></slot>
            </td>
        </tr>
    </fragment>
</template>
<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'LibraryLink',
        props: {
            video: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapState('libraries', ['libraries']),
            ...mapGetters('libraries', ['getLibraryCompanyName']),
            resolveLibraryLink() {
                return this.$router.resolve({
                    name: 'editLibrary',
                    params: {
                        id: this.libraryCompanyInfo.libraryId
                    }
                }).href;
            },
            resolveCompanyLink() {
                console.log(this.$router.resolve({
                    name: 'editCompany',
                    params: {
                        id: this.libraryCompanyInfo.companyId
                    }
                }));
                return this.$router.resolve({
                    name: 'editCompany',
                    params: {
                        id: this.libraryCompanyInfo.companyId
                    }
                }).href;
            },
            libraryCompanyInfo() {
                if (!this.video) {
                    return {
                        hasAnything: false,
                        hasLibraryName: false,
                        hasCompanyName: false,
                        companyName: '',
                        companyId: '',
                        libraryName: '',
                        libraryId: '',
                    };
                }

                let oldCompany = null;
                let hasMoreCompanies = false;

                this.libraries.forEach(library => {
                    if (!library.company) {
                        return;
                    }

                    if (oldCompany !== library.company.company_name) {
                        if (oldCompany != null) {
                            hasMoreCompanies = true;
                        }
                        oldCompany = library.company.company_name;
                    }
                });

                const library = this.libraries.find(library => library.id === this.video.library);

                if (library) {
                    console.log(library);
                    return {
                        hasAnything: true,
                        hasCompanyName: hasMoreCompanies,
                        hasLibraryName: true,
                        libraryName: library.name,
                        libraryId: library.id,
                        companyName: hasMoreCompanies ? this.getLibraryCompanyName(library) : null,
                        companyId: hasMoreCompanies ? library.company_id : null,
                    };
                }

                return {
                    hasAnything: false,
                };
            },
        },
    };
</script>

<style scoped>

</style>