<template>
    <stats-card class="no-content-padding h13"
                :loading="loading"
    >
        <template v-slot:title>
            Starts per referrer
        </template>
        <template v-slot:content>
            <v-list>
                <div v-for="referrer in referrers"
                     :key="referrer.key"
                >
                    <v-list-item @click="copyToClipboard(referrer)">
                        <v-list-item-content>
                            <v-list-item-title>
                                <div class="title-flex">
                                    <div class="title-flex-item flex-key"
                                         :title="referrer.key"
                                    >
                                        {{ referrer.key }}
                                    </div>
                                    <div class="title-flex-item flex-number ml-2">
                                        <span class="number-color">{{ referrer.doc_count }}</span>
                                    </div>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list>
        </template>
    </stats-card>
</template>

<script>
    import { $http, getStatQueryRequest } from '@/services/http';
    import StatsCard from './stats-card';
    import copy from 'copy-to-clipboard';
    import { toastSuccess } from '@/services/responseErrors';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'CardReferrers',
        components: {
            StatsCard,
        },
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapState('statistics-details', [
                'dateRange',
                'videoId',
                'referrers',
            ])
        },
        methods: {
            ...mapMutations('statistics-details', [
                'setReferrers'
            ]),
            copyToClipboard(item) {
                copy(item.key, { format: 'text/plain' });
                toastSuccess('Referrer URL copied to clipboard!');
            },
            async getReferrerStarts() {
                this.loading = true;
                const response = await $http.get(
                    getStatQueryRequest(
                        'api/videos/starts-per-referrer/',
                        [this.videoId],
                        this.dateRange
                    )
                );
                const currentBucket = response.data.find(bucket => bucket.key === this.videoId);
                this.setReferrers(currentBucket?.referrer_aggr?.buckets || []);
                this.loading = false;
            },
        },
        mounted() {
            this.getReferrerStarts();
        },
    };
</script>