import InteractiveElement from '../InteractiveElement';
import {v4 as uuidv4} from 'uuid';

class TextFactory {
    make({ name, start }) {
        return new InteractiveElement({
            name, //
            _id: uuidv4(),
            start,
            end: start,
            startAction: 'pause', // play | pause
            action: {
                type: 'openLink', // openLink | openDownload | openVideo | skipTo | skipToAndPause | play | pause
                link: 'https://google.com',
                target: '_blank'
            },
            area: {
                type: 'text', // hotspot | image | text,
                text: 'A content text comes here',
            },
            style: {
                origin: {
                    left: 25,
                    top: 40,
                },
                dimensions: {
                    width: 50,
                    height: 20
                },
                'default': {
                    font: { // required when area type is text
                        family: 'arial, helvetica, sans-serif',
                        size: 24,
                        color: '#FFFF00AA',
                    },
                },
                hover: {
                    font: { // required when area type is text
                        family: 'arial, helvetica, sans-serif',
                        size: 24,
                        color: 'rgba(255,0,0, 0.5)',
                    },
                }
            }
        });
    }
}

export default new TextFactory();
