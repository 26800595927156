<template>
    <v-card
        elevation="0"
        class="stats-date-picker"
        :class="{
            'd-flex': inline,
            'd-flex-row': inline,
        }"
    >
        <v-list :class="{ 'minimum-width': inline }">
            <v-list-item-group
                v-model="stringifiedValue"
                :mandatory="mandatory"
                :color="$vuetify.theme.themes.light.primary"
            >
                <v-list-item v-for="item in list"
                             :key="item.text"
                             :value="item.value"
                >
                    <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <date-picker
            :value="daterange"
            style="width: 100%"
            range
            placeholder="Select date"
            :disabled-date="disabledBeforeAndAfter"
            :inline="inline"
            :clearable="false"
            @input="update"
        />
    </v-card>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: 'StatsDatePicker',
        components: { DatePicker },
        props: {
            value: {
                type: Array,
                required: true,
            },
            minimumDate: {
                default() {
                    return new Date('2020-01-01T00:00:00');
                },
            },
            inline: {
                type: Boolean,
                default: () => false,
            },
        },
        data() {
            return {
                daterange: this.normalize(this.value),
                list: [
                    {
                        text: 'Today',
                        value: this.stringify((this.today())),
                    },
                    {
                        text: 'Past week',
                        value: this.stringify(this.pastWeek()),
                    },
                    {
                        text: 'Past Month',
                        value: this.stringify(this.pastMonth()),
                    },
                    {
                        text: 'Past Year',
                        value: this.stringify(this.pastYear()),
                    },
                ],
            };
        },
        computed: {
            stringifiedValue: {
                get() {
                    return this.stringify(this.daterange);
                },
                set(x) {
                    const parsed = JSON.parse(x);
                    this.daterange = [new Date(parsed[0]), new Date(parsed[1])];
                },
            },
            mandatory() {   
                for(var i in this.list) {
                    if(this.stringifiedValue === this.list[i].value) {
                        return true;
                    }
                }
                return false;
            },            
        },
        methods: {
            stringify(x) {
                return JSON.stringify(x);
            },
            normalize(x) {
                x[0].setHours(0, 0, 0, 0);
                x[1].setHours(23, 59, 59, 999);
                return x;
            },
            disabledBeforeAndAfter(date) {
                if (!this.minimumDate || typeof this.minimumDate.valueOf !== 'function') {
                    return false;
                }

                const lastAvailable = new Date();
                lastAvailable.setHours(23, 59, 59, 999);

                return (
                    date.valueOf() < this.minimumDate.valueOf() ||
                    date.valueOf() > lastAvailable.valueOf()
                );
            },
            update(value) {
                this.daterange = this.normalize(value);
            },
            today() {
                return this.normalize([new Date(), new Date()]);
            },
            pastWeek() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
                return this.normalize([start, end]);
            },
            pastMonth() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 31 * 24 * 3600 * 1000);
                return this.normalize([start, end]);
            },
            pastYear() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 365 * 24 * 3600 * 1000);
                return this.normalize([start, end]);
            },
        },
        watch: {
            daterange(value) {
                this.$emit('input', value);
            },
        }
    };
</script>

<style scoped>
.stats-date-picker {
  box-shadow: 0 0 3px #b3b3b3 !important;
}

.minimum-width {
  min-width: 200px;
}
</style>
