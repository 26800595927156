import {$http, videoApiUrl} from './http';

const magnitudeOrders = [
    'Bytes',
    'kB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB'
];

export default function updateMeta(videoId, meta) {
    return new Promise((resolve, reject) => {
        $http.put(videoApiUrl('api/videos/' + videoId), meta)
            .then(() => resolve())
            .catch((e) => reject(e));
    });
}

export function getProperUnitSize(size) {
    let result = parseInt(size);
    let order = 0;

    while (result > 1000) {
        result = result / 1024;
        order++;
    }

    return `${result.toFixed(2)} ${magnitudeOrders[order]}`;
}