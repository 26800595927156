import commentActions from '@/store/comments/actions';
import commentsMutations from '@/store/comments/mutations';
import commentGetters from '@/store/comments/getters';

const comments = {
    namespaced: true,
    state() {
        return {
            video: null,
            player: null,
            activeElement: null,
            currentPlayerPosition: 0,
            comments: [],
        };
    },
    actions: commentActions,
    mutations: commentsMutations,
    getters: commentGetters,
};

export default comments;