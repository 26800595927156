export default class PresentationItem {
    constructor(data) {
        this._id = data._id,
        this.name = data.name || '';
        this.start = data.start || 0;
        this.end = data.end || 0;
        this.src = data.src || '';
        this.cdn_src = data.cdn_src || '';
        this.active = false;
    }
}
