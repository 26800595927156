import InteractiveElement from '../InteractiveElement';
import { v4 as uuidv4 } from 'uuid';

class HotspotFactory {
    make({ name, start }) {
        return new InteractiveElement({
            name, //
            _id: uuidv4(),
            start,// 0 <= start < duration
            end: start,// start <= end
            startAction: 'pause', // play | pause
            action: {
                type: 'openLink', // openLink | openDownload | openVideo | skipTo | skipToAndPause | play | pause
                link: 'https://google.com',//required when type IN (openLink, openDownload)
                target: '_blank'//required when type IN (openLink, openDownload)
            },
            area: {
                type: 'hotspot', // hotspot | image | text,
                shape: 'rectangle', // rectangle - has meaning for hotspot only, required for hotspot
            },
            style: {
                origin: {//required for each type
                    left: 25,
                    top: 40,
                },
                dimensions: {//required for each type
                    width: 50,
                    height: 20
                },
                'default': {
                    border: {//required when area.type is hotspot
                        width: 1,
                        color: 'rgba(255,255,255, 1)',
                    },
                    background: {
                        color: 'rgba(0, 0, 0, 0.0)',//required when area.type is hotspot
                    },
                },
                hover: {
                    border: {//required when area.type is hotspot
                        width: 1,
                        color: '#ff0000',
                    },
                    background: {
                        color: 'rgba(0, 0, 0, 0.0)',//required when area.type is hotspot
                    },
                }
            }
        });
    }
}

export default new HotspotFactory();
