<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-left">
                        Email
                    </th>
                    <th class="text-left">
                        Role
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in assignedUsers"
                    :key="index"
                >
                    <td class="text-no-wrap">
                        {{ user.user_name }}
                    </td>
                    <td class="text-no-wrap">
                        {{ user.user_email }}
                    </td>
                    <td class="text-no-wrap">
                        {{ user.role_name }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
    import {$http, videoApiUrl} from '@/services/http';

    export default {
        name: 'AssignedLibraryUsers',
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            libraryId: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                assignedUsers: [],
            };
        },
        methods: {
            getAssignedUsers() {
                $http
                    .get(videoApiUrl('api/libraries/' + this.libraryId + '/assigned-users'))
                    .then(data => {
                        this.assignedUsers = data.data;
                    });
            },
        },
        created() {
            this.getAssignedUsers();
        }
    };
</script>

<style scoped>

</style>