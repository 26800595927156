<template>
    <list-head-filter-popup
        :button-class="{
            'is-filtered': isFilteredBy
        }"
        :flat="flat"
    >
        <list-head-filter
            :column-id="columnId"
            :items="companyFilteredLibraries"
            :select-check="value"
            :class="{'pt-4': flat}"
            @filterQuickSearch="setLibraryFilterQuickSearch"
            @filterChanged="filterChanged"
        >
            <template v-slot:filter-top="{listLengthLimit, clearSelected}">
                <v-select
                    v-if="libraryCompanies.length > 1"
                    v-model="selectedLibraryCompany"
                    :items="libraryCompanies"
                    item-text="company_name"
                    item-value="id"
                    placeholder="Select company"
                    clearable
                    filled
                    dense
                    hide-details
                    class="mb-2"
                    attach
                />
                <div v-if="libraryColumnFilterItems.length > listLengthLimit">
                    <v-text-field
                        v-model="libraryFilterSearchText"
                        height="29"
                        flat
                        solo
                        background-color="grey lighten-1"
                        hide-details
                        :append-icon="libraryFilterSearchText ? undefined : 'search'"
                        label="Quick search"
                        clearable
                    />
                    <div
                        v-if="!libraryFilterSearchText"
                        class="mt-4"
                    >
                        Please narrow your search
                    </div>
                    <slot
                        name="filter-top"
                        :clearSelected="clearSelected"
                    />
                </div>
            </template>
        </list-head-filter>
    </list-head-filter-popup>
</template>

<script>
    import {debounce} from 'lodash';
    import ListHeadFilterPopup from '@/components/primitives/list-head-filter-popup';
    import ListHeadFilter from '@/components/primitives/list-head-filter';
    import {$http, videoApiUrl} from '@/services/http';
    import qs from 'qs';

    export default {
        name: 'LibraryFilter',
        components: {ListHeadFilter, ListHeadFilterPopup},
        props: {
            value: {
                type: Object,
                default: () => {
                }
            },
            columnId: {
                type: String,
                default: ''
            },
            flat: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                libraryColumnFilterItems: [],
                selectedLibraryCompany: null,
                libraryFilterSearchText: '',
            };
        },
        computed: {
            isFilteredBy() {
                return this.value[this.columnId] && this.value[this.columnId].length;
            },
            companyFilteredLibraries: function () {
                const filtered = this.selectedLibraryCompany
                    ? this.libraryColumnFilterItems.filter(item => item.company_id === this.selectedLibraryCompany)
                    : this.libraryColumnFilterItems;
                return this.libraryFilterSearchText
                    ? filtered.filter(item => item.name.toLowerCase().indexOf(this.libraryFilterSearchText.toLocaleLowerCase()) > -1)
                    : filtered;
            },
            libraryCompanies: function () {
                const companies = [];
                this.libraryColumnFilterItems.forEach(item => {
                    const company = {
                        id: item.company_id,
                        company_name: item.subtitle ? item.subtitle : ''
                    };
                    if (!companies.find(companyItem => companyItem.id === company.id)) {
                        companies.push(company);
                    }
                });
                if (companies.length === 1) {
                    this.libraryColumnFilterItems.forEach(item => item.subtitle = '');
                }
                return companies;
            }
        },
        methods: {
            filterChanged(items) {
                this.$emit('input', {...this.value, [items.column]: items.items});
            },
            getLibraries: debounce(function () {
                $http.get(videoApiUrl('api/libraries-i-can-see'), {
                    paramsSerializer: params => {
                        return decodeURIComponent(qs.stringify(params));
                    }
                }).then(res => {
                    this.selectedLibraryCompany = null;
                    this.libraryFilterSearchText = '';
                    this.libraryColumnFilterItems = res.data.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            company_id: item.company_id,
                            subtitle: item.company_name ? item.company_name : null
                        };
                    });
                });
            }, 400),
            setLibraryFilterQuickSearch(search) {
                this.libraryFilterSearchText = search;
            }
        },
        mounted() {
            this.getLibraries();
        }
    };
</script>