import get from 'lodash/get';
import OpenLinkAction from './actions/OpenLinkAction';
import OpenVideoAction from './actions/OpenVideoAction';
import InteractiveTextArea from './areas/InteractiveTextArea';
import BaseArea from './areas/BaseArea';
import InteractiveInputArea from './areas/InteractiveInputArea';
import BaseAction from './actions/BaseAction';
import SkipToAction from './actions/SkipToAction';
import InteractiveHotspotFormat from './format/InteractiveHotspotFormat';
import InteractiveImageFormat from './format/InteractiveImageFormat';
import InteractiveTextFormat from './format/InteractiveTextFormat';
import InteractiveHotspotArea from './areas/InteractiveHotspotArea';

const createFormat = function(elementType, data) {
    switch (elementType) {
        default:
        case 'hotspot':
            return new InteractiveHotspotFormat(data);
        case 'image':
            return new InteractiveImageFormat(data);
        case 'text':
            return new InteractiveTextFormat(data);
    }
};

class InteractiveElement {
    constructor(data, active) {
        this.active = active || false;
        this.name = get(data, 'name', 'New Hotspot');
        this._id = get(data, '_id', Date.now()),
        this.start = data.start;
        this.end = data.end;
        this.startAction = get(data, 'startAction', 'pause'); // play | pause

        this.style = data.style ? createFormat(data.area.type, data.style) : null;

        switch (data.action.type) {
            case 'play':
            case 'pause':
                this.action = new BaseAction(data.action);
                break;
            case 'openLink':
            case 'openDownload':
                this.action = new OpenLinkAction(data.action);
                break;
            case 'openVideo':
                this.action = new OpenVideoAction(data.action);
                break;
            case 'skipTo':
            case 'skipToAndPause':
                this.action = new SkipToAction(data.action);
                break;
            default:
                this.action = null;
                break;
        }

        if (data.area && data.area.type) {
            switch (data.area.type) {
                case 'text':
                    this.area = new InteractiveTextArea(data.area);
                    break;
                case 'hotspot':
                    this.area = new InteractiveHotspotArea(data.area);
                    break;
                case 'image':
                case 'playeraction':
                    this.area = new BaseArea(data.area);
                    break;
                case 'input':
                    this.area = new InteractiveInputArea(data.area);
                    break;
            }
        }
    }
}

export default InteractiveElement;