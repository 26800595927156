<template>
    <span>
        <span class="visualizer-item color-sample mr-2">
            <span
                class="color-sample-inner"
                :style="colorSampleStyle"
            />
        </span>
        <span class="visualizer-item color-text mr-4">{{ color }}</span>
        <span class="visualizer-item color-opacity">{{ opacity }}%</span>
    </span>
</template>

<script>
    import {colorToRgbaObject, rgbaObjectToHex} from '../../../../../color-helper';

    export default {
        name: 'ColorVisualizer',
        props: ['value'],
        computed: {
            color() {
                return rgbaObjectToHex(colorToRgbaObject(this.value));
            },
            opacity() {
                return Math.round(colorToRgbaObject(this.value).a * 100);
            },
            colorSampleStyle() {
                return {
                    backgroundColor: this.value
                };
            }
        }
    };
</script>

<style scoped lang="scss">
    .color-sample {
        position: relative;
        display: inline-block;
        width: 1em;
        height: 1em;
        box-shadow: 0 0 1px rgba(0,0,0,1);
        vertical-align: middle;
        background-image: /* tint image */ linear-gradient(to right, rgba(192, 192, 192, 0.75), rgba(192, 192, 192, 0.75)), /* checkered effect */ linear-gradient(to right, black 50%, white 50%), linear-gradient(to bottom, black 50%, white 50%);
        background-blend-mode: normal, difference, normal;
        background-size: 1em;

        .color-sample-inner {
            position: absolute;
            display: inline-block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .color-text {
        display: inline-block;
        width: 6em;
    }

    .color-opacity {
        display: inline-block;
        width: 3em;
        text-align: right;
    }
</style>