import { render, staticRenderFns } from "./usage-stats.vue?vue&type=template&id=990f3bde&scoped=true&"
import script from "./usage-stats.vue?vue&type=script&lang=js&"
export * from "./usage-stats.vue?vue&type=script&lang=js&"
import style0 from "./usage-stats.vue?vue&type=style&index=0&id=990f3bde&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "990f3bde",
  null
  
)

export default component.exports