<template>
    <v-expansion-panel v-if="libraries.length > 1 || canUploadEverywhere"
                       class="elevation-0"
    >
        <v-expansion-panel-header>
            General
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <move-multiple-videos :libraries="libraries"
                                  :videos="videos"
                                  @moved="updateLibrary"
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import MoveMultipleVideos from '@/components/primitives/move-multiple-videos.vue';

    export default {
        name: 'MetadataGeneralMultiple',
        props: {
            videos: {
                type: Array,
                required: true,
            }
        },
        components: {MoveMultipleVideos},
        computed: {
            ...mapState('libraries', ['libraries']),
            ...mapGetters('auth', ['canUploadEverywhere']),
        },
        methods: {
            ...mapActions('libraries', ['getLibraries']),
            updateLibrary(videoId, libraryId) {
                for (let video in this.videos) {
                    if (this.videos[video].id === videoId) {
                        this.videos[video].library = libraryId;
                    }
                }
            }
        },
        mounted() {
            this.getLibraries();
        }
    };
</script>

<style scoped>

</style>