<template>
    <stats-card class="h13 metric"
                :loading="loadingAvgWatchTime"
    >
        <template v-slot:title>
            Viewed Ratio
        </template>
        <template v-slot:content>
            {{ viewedRatio }}%
        </template>
    </stats-card>
</template>

<script>
    import StatsCard from './stats-card';
    import { mapState } from 'vuex';

    export default {
        name: 'CardStarts',
        components: {
            StatsCard,
        },
        computed: {
            ...mapState('statistics-details', [
                'avgWatchTime',
                'loadingAvgWatchTime',
                'video',
            ]),
            viewedRatio() {
                const avgWatchTime = this.avgWatchTime / 1000;
                const duration = Math.floor(this.video.duration / 1000);
                return duration > 0 ? Math.round((avgWatchTime / duration) * 100) : 0;
            },
        },
    };
</script>