<template>
    <stats-card
        class="h13 metric smaller"
        :loading="loadingAvgWatchTime"
    >
        <template v-slot:title>
            Avg viewing time
        </template>
        <template v-slot:content>
            {{ avgWatchTimeFormatted }}
            <div class="metric-explain">
                (hours minutes and seconds)
            </div>
        </template>
    </stats-card>
</template>

<script>
    import { $http, getStatQueryRequest } from '@/services/http';
    import StatsCard from './stats-card';
    import TimeHelper from '@/time-helper';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'CardAvgViewingTime',
        components: {
            StatsCard,
        },
        computed: {
            ...mapState('statistics-details', [
                'avgWatchTime',
                'loadingAvgWatchTime',
                'dateRange',
                'videoId',
            ]),
            avgWatchTimeFormatted() {
                if (!this.avgWatchTime) {
                    return '00:00:00';
                }
                return TimeHelper.fromMsToHis(this.avgWatchTime);
            }
        },
        methods: {
            ...mapMutations('statistics-details', [
                'setAvgWatchTime',
                'setLoadingAvgWatchTime'
            ]),
            async getAverageWatchTime() {
                this.setLoadingAvgWatchTime(true);
                const response = await $http.get(getStatQueryRequest('api/videos/average-view-time/', [this.videoId], this.dateRange));
                const item = response.data.filter(item => item.key === this.videoId);
                this.setAvgWatchTime(item.length ? item[0].time : 0);
                this.setLoadingAvgWatchTime(false);
            }
        },
        mounted() {
            this.getAverageWatchTime();
        },
    };
</script>