<template>
    <fragment v-if="video">
        <metadata-preview
            :key="`upload-preview-${video.id}-${updateCount}`"
            :video="video"
        />
        <v-expansion-panels
            :key="'uploaded-'+video.id"
            ref="uploadMetadataPanels"
            v-model="openTabs"
            accordion
            class="video-metadata video-upload-expansion-panels"
        >
            <v-expansion-panel class="elevation-0">
                <v-expansion-panel-header>Basic</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table
                        dense
                        class="property-sheet"
                    >
                        <template v-slot:default>
                            <tbody>
                                <meta-title-field
                                    :video="video"
                                    :multiple-videos="false"
                                    @input="updateTitle"
                                />
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="elevation-0">
                <v-expansion-panel-header>General</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table
                        dense
                        class="property-sheet"
                    >
                        <template v-slot:default>
                            <tbody>
                                <meta-description-field
                                    v-if="video"
                                    :video="video"
                                />
                                <meta-internal-notes-field
                                    v-if="video"
                                    :video="video"
                                />
                                <meta-tags-field
                                    v-if="video"
                                    :video="video"
                                />
                                <meta-project-number-field :video="video" />
                                <meta-agency-field :video="video" />
                                <meta-people-in-video-field :video="video" />
                                <meta-languages-field :video="video" />
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <metadata-thumbnail
                v-if="video"
                :key="'thumbnail-' + video.id"
                :video="video"
                @input="updateThumbnail"
            />
            <v-expansion-panel class="elevation-0">
                <v-expansion-panel-header>Publish & share</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <visibility-editor :video="video" />
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="elevation-0">
                <v-expansion-panel-header>Privacy</v-expansion-panel-header>
                <v-expansion-panel-content class="privacy">
                    <quitclaim-files :video="video" />
                </v-expansion-panel-content>
            </v-expansion-panel>
            <metadata-subtitles
                v-if="video"
                :key="'subtitles' + video.id"
                :video="video"
            />
        </v-expansion-panels>
    </fragment>
</template>

<script>
    import {$http, videoApiUrl} from '../../../services/http';
    import MetadataPreview from './metadata-preview';
    import MetaTitleField from './fields/meta-title-field';
    import MetaDescriptionField from './fields/meta-description-field';
    import MetaInternalNotesField from './fields/meta-internal-notes-field';
    import MetaTagsField from './fields/meta-tags-field';
    import MetaProjectNumberField from './fields/meta-project-number-field';
    import MetaAgencyField from './fields/meta-agency-field';
    import MetaPeopleInVideoField from './fields/meta-people-in-video-field';
    import MetaLanguagesField from './fields/meta-languages-field';
    import QuitclaimFiles from '../quitclaim-files';
    import MetadataThumbnail from './metadata-thumbnail';
    import MetadataSubtitles from './metadata-subtitles';
    import VisibilityEditor from './editor/VisibilityEditor';

    export default {
        name: 'UploadMetadata',
        components: {
            VisibilityEditor,
            MetadataSubtitles,
            MetadataThumbnail,
            QuitclaimFiles,
            MetaLanguagesField,
            MetaPeopleInVideoField,
            MetaAgencyField,
            MetaProjectNumberField,
            MetaTagsField,
            MetaInternalNotesField,
            MetaDescriptionField,
            MetaTitleField,
            MetadataPreview,
        },
        props: ['selectedVideo'],
        data() {
            return {
                openTabs: [0, 1],
                video: null,
                updateCount: 1,
            };
        },
        computed: {},
        methods: {
            getVideo() {
                $http.get(videoApiUrl('api/videos/' + this.selectedVideo.objectId)).then((response) => {
                    this.video = response.data;
                });
            },
            updateTitle(value){
                this.selectedVideo.name = value;
            },
            updateThumbnail(thumbnail) {
                this.video.thumbnail = thumbnail;
                this.updateCount += 1;
            },
            scrolltop(value){
                this.$nextTick(() => {
                    let el = this.$refs.uploadMetadataPanels.$children[value].$el;
                    this.$refs.uploadMetadataPanels.$el.scrollTop = el.offsetTop;
                });
            }
        },
        mounted() {
            this.getVideo();
        },
        watch: {
            openTabs(value){
                if(value){
                    setTimeout(this.scrolltop,  300, value);
                }
            },
            selectedVideo: function () {
                this.getVideo();
            }
        }
    };
</script>

<style scoped>
    .video-metadata {
        max-height: calc(100% - 248px);
        overflow: auto;
        scroll-behavior: smooth;
    }
</style>

<style lang="scss">
  .video-upload-expansion-panels {
      .v-data-table__wrapper {
        overflow: visible !important;
      }
  }
</style>
