<template>
    <div
        ref="scrub"
        class="scrub"
        @mouseover="mouseOver"
        @mousemove="mouseMove"
        @mouseout="mouseOut"
        @mousedown="markMouseDown"
    >
        <v-card
            v-if="scrubHoverTime"
            elevation="2"
            class="scrubHoverTime"
            :style="scrubHoverStyles"
        >
            <v-card-text class="py-1 text-center">
                {{ hoverIndex }}
            </v-card-text>
        </v-card>
        <div
            class="scrub-time-index"
            :style="timeIndexStyle"
        />

        <component
            :is="item.component"
            v-for="(item, index) in elementGroups"
            :key="index"
            :elements="item.elements"
            :element="item.elements[0]"
            class="item"
            :style="{left: offsetPx(item)}"
        />
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';
    import TimeHelper from '../../../../time-helper';
    import CommentsExternalScrubPlotSingle from './comments-external-scrub-plot-single';
    import CommentsExternalScrubPlotGroup from './comments-external-scrub-plot-group';
    import {debounce} from 'lodash';

    export default {
        name: 'CommentsExternalScrub',
        components: {
            CommentsExternalScrubPlotSingle,
            CommentsExternalScrubPlotGroup,
        },
        data() {
            return {
                plotWidthInPx: 32,
                scrubWidthInPx: null,
                resizeInterval: null, //stupid workaround because of unreliable window.resize
                currentTime: 0,
                isMouseDown: false,
                scrubHoverTime: false,
                hoverIndex: 0,
                scrubHoverStyles: {
                    left: 0,
                },
            };
        },
        computed: {
            ...mapState('comments', ['video', 'player']),
            ...mapGetters('comments', ['elements']),
            duration() {
                return this.video.duration / 1000;
            },
            thresholdInSeconds() {
                return this.duration / (this.scrubWidthInPx / this.plotWidthInPx);
            },
            elementGroups() {
                if (!this.elements || !this.elements.length) {
                    return [];
                }

                let groups = [
                    {
                        component: 'comments-external-scrub-plot-single',
                        elements: [this.elements[0]],
                    },
                ];
                for (let i = 1; i < this.elements.length; i++) {
                    let lastGroup = groups[groups.length - 1];
                    let firstElementOfLastGroup = groups[groups.length - 1].elements[0];
                    let distance = this.elements[i] - firstElementOfLastGroup;
                    if (distance > this.thresholdInSeconds) {
                        groups.push({
                            component: 'comments-external-scrub-plot-single',
                            elements: [this.elements[i]],
                        });
                    } else {
                        lastGroup.component = 'comments-external-scrub-plot-group';
                        lastGroup.elements.push(this.elements[i]);
                    }
                }
                return groups;
            },
            timeIndexStyle() {
                return { width: (this.currentTime * 100) / this.duration + '%' };
            },
        },
        methods: {
            ...mapMutations('comments', ['setActiveElementWithoutSkip']),
            updateScrubWidthInPx() {
                this.scrubWidthInPx = this.$refs.scrub.offsetWidth - this.plotWidthInPx;
            },
            offsetPx(item) {
                const percent = item.elements[0] / this.duration;
                return this.scrubWidthInPx * percent + 'px';
            },
            getTime(e) {
                return (e.offsetX / this.$refs.scrub.offsetWidth) * this.duration;
            },
            seekAndPause(e) {
                this.player.$emit('seek', this.getTime(e));
                this.player.$emit('pause');
            },
            markMouseDown(e) {
                this.seekAndPause(e);
                this.isMouseDown = true;
                document.addEventListener('mousemove', this.seekWhileMoving);
            },
            seekWhileMoving(e) {
                if (this.isMouseDown) {
                    let time = (e.offsetX / this.$refs.scrub.offsetWidth) * this.duration;
                    this.player.$emit('seek', time);
                }
            },
            markMouseUp() {
                this.isMouseDown = false;
                document.removeEventListener('mousemove', this.seekWhileMoving);
            },
            hoverTime(e) {
                let time = TimeHelper.fromMsToHisU(this.getTime(e) * 1000);
                return time;
            },
            mouseOver() {
                this.scrubHoverTime = true;
            },
            mouseOut() {
                this.scrubHoverTime = false;
            },
            mouseMove(event) {
                this.scrubHoverStyles.left = event.offsetX + 'px';
                this.hoverIndex = this.hoverTime(event);
            },
            refreshCommentList() {
                this.setActiveElementWithoutSkip(this.currentTime);
            }
        },
        created() {
            this.refreshCommentList = debounce(this.refreshCommentList, 400);
        },
        mounted() {
            this.updateScrubWidthInPx();
            this.resizeInterval = setInterval(this.updateScrubWidthInPx, 50);
            this.player.$on('timeChange', (time) => {
                this.currentTime = time;
                this.refreshCommentList(time);
            });
            document.addEventListener('mouseup', this.markMouseUp);
        },
        beforeDestroy() {
            clearInterval(this.resizeInterval);
            document.removeEventListener('mouseup', this.markMouseUp);
        },
    };
</script>

<style scoped lang="scss">
.scrub {
  position: relative;
  background-color: #bbbbbb;
  border-radius: 0.15rem;
  cursor: pointer;
  overflow: visible;
  height: 0.3rem;
  margin-top: 2rem;

  .scrub-time-index {
    height: 100%;
    background-color: #2977be;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.item {
  position: absolute;
  margin-top: -0.8rem;
}
.scrubHoverTime {
  position: absolute;
  bottom: 2rem;
  width: 7rem;
  margin-left: -3.5rem;
  opacity: 0.9;
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: -0.75rem;
    left: calc(50% - 0.75rem);
    border-top: 0.75rem solid #ffffff;
    border-left: 0.75rem solid transparent;
    border-right: 0.75rem solid transparent;
  }
}
</style>
