<template>
    <v-sheet class="move-video">
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn
                    small
                    icon
                    color="primary"
                    v-on="on"
                    @click="toggleLibraryList('move')"
                >
                    <v-icon small>
                        input
                    </v-icon>
                </v-btn>
            </template>
            <span>Move to Library</span>
        </v-tooltip>
        <generic-modal
            v-model="libraryListOpen"
            title="Move to library"
            @cancel="cancelled"
        >
            <v-sheet v-if="!librariesLoading">
                <p class="primary--text">
                    <span class="warning--text"><v-icon
                        class="mr-2"
                        color="warning"
                    >warning</v-icon>Warning! All the statistics will be kept if you move the video to another library, but are only visible from the new library.</span>
                </p>
                <p class="comment">
                    Click on the library you'd like to move the selected video ({{ video.title }}) to:
                </p>
                <v-text-field
                    v-if="targetLibraries.length > 5"
                    v-model="searchText"
                    hide-details
                    clearable
                    placeholder="Search libraries"
                />
                <v-list
                    dense
                    class="library-container"
                >
                    <v-list-item
                        v-for="library in searchedLibraries"
                        :key="`library-move-copy-${library.id}`"
                        dense
                        @click="doAction(library)"
                    >
                        <v-list-item-content>
                            <v-list-item-subtitle v-if="library.company">
                                {{ library.company.company_name }}
                            </v-list-item-subtitle>
                            <v-list-item-title>{{ library.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-progress-linear v-if="actionExecuting" />
            </v-sheet>
            <v-progress-circular
                v-else
                indeterminate
            />
        </generic-modal>
    </v-sheet>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import GenericModal from '@/components/controls/modals/generic-modal';
    import {$http, videoApiUrl} from '@/services/http';
    import {toastSuccess} from '@/services/responseErrors';

    export default {
        name: 'MoveVideo',
        components: {GenericModal},
        data() {
            return {
                libraryListOpen: false,
                libraryListMode: 'copy',
                searchText: '',
                actionExecuting: false,
            };
        },
        props: ['video'],
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('libraries', {librariesLoading: 'loading'}),
            ...mapGetters('libraries', ['allowedLibrariesBy']),
            targetLibraries()
            {
                return this.allowedLibrariesBy(this.user.permissions['copy-to-library'])
                    .filter(library => {
                        return library.id !== this.video.library;
                    });
            },
            searchedLibraries() {
                if (!this.searchText) {
                    return this.targetLibraries;
                }

                const lowerText = this.searchText.toLowerCase();

                return this.targetLibraries.filter(library => library.name.toLowerCase().indexOf(lowerText) > -1 ||
                    library.company && library.company.company_name.toLowerCase().indexOf(lowerText) > -1);
            },
        },
        methods: {
            ...mapActions('libraries', ['getLibraries']),
            toggleLibraryList() {
                if (this.libraryListOpen) {
                    this.libraryListOpen = false;
                    return;
                }

                this.libraryListOpen = true;
                this.getLibraries();
            },
            cancelled() {
                this.libraryListOpen = false;
            },
            moveToLibrary(library)
            {
                return $http.post(videoApiUrl(`api/videos/${this.video.id}/move`), {
                    library: library.id
                }).then(() => {
                    this.video.library = library.id;
                    toastSuccess(`Video moved to&nbsp;<strong>${library.name}.</strong>`);
                    this.$emit('moved', library.id);
                });
            },
            doAction(library) {
                this.actionExecuting = true;
                const result = this.moveToLibrary(library);

                result.then(() => {
                    this.libraryListOpen = false;
                }).finally(() => {
                    this.actionExecuting = false;
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.move-video {
  position: relative;
  display: inline-block;
}

.library-container {
  max-height: 250px;
  overflow: auto;
}

.comment {
  margin-bottom: 0;
}
</style>
