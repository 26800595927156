<template>
    <v-expansion-panels
        :key="'activeIndex-' + activeIndex"
        class="interactive-metadata"
        flat
        tile
        readonly
        :value="activeIndex"
    >
        <interactive-sidebar-item
            v-for="element in elements"
            :key="'interactive-sidebar-item' + element._id"
            :element="element"
        />
    </v-expansion-panels>
</template>

<script>
    import InteractiveSidebarItem from './sidebar/interactive-sidebar-item';
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'InteractiveSidebar',
        components: {InteractiveSidebarItem},
        computed: {
            ...mapState('interactive', ['activeIndex']),
            ...mapGetters('interactive', ['elements'])
        }
    };
</script>

<style scoped>

</style>
