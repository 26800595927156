<template>
    <v-card class="pa-1"
            style="position: sticky; top: 80px"
    >
        <v-card-title class="font-weight-regular">
            Select action
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-row>
                <v-col cols="12"
                       lg="6"
                       md="6"
                       sm="12"
                       class="d-flex align-center justify-center"
                >
                    <v-select v-model="action"
                              :items="availableActions"
                              outlined
                              dense
                              hide-details
                              label="Actions"
                    >
                        <template v-slot:item="{item}">
                            <span>{{ titleCase(item) }}</span>
                        </template>
                        <template v-slot:selection="{item}">
                            <span>{{ titleCase(item) }}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                    class="d-flex align-center justify-center"
                >
                    <h4>{{ numberOfSelectedFiles }}</h4>
                </v-col>
            </v-row>
            <v-alert v-if="hasBigFile"
                     color="warning"
                     outlined
            >
                Files larger than {{ maxFileSize }} cannot be uploaded through FTP. Please reduce the file size and reload this list.
            </v-alert>
            <ftp-actions-upload v-if="action === 'upload'" />
            <ftp-actions-delete v-if="action === 'delete'" />
        </v-card-text>
    </v-card>
</template>

<script>
    import FtpActionsUpload from './ftp-actions-upload';
    import FtpActionsDelete from './ftp-actions-delete';
    import { mapState } from 'vuex';
    export default {
        name: 'FtpActions',
        components: {
            FtpActionsUpload,
            FtpActionsDelete
        },
        data(){
            return {
                action: 'upload',
                actions: ['upload', 'delete']
            };
        },
        computed:{
            ...mapState('ftp', ['selectedFiles']),
            numberOfSelectedFiles(){
                return `${this.selectedFiles.length} selected files${this.action === 'upload' ? ' to' : '.'}`;
            },
            hasBigFile() {
                return this.selectedFiles.filter(f => {
                    return f.size >= process.env.VUE_APP_MAXIMUM_FILE_SIZE;
                }).length > 0;
            },
            availableActions() {
                return this.hasBigFile ? this.actions.filter(a => a !== 'upload') : this.actions;
            },
            maxFileSize() {
                return process.env.VUE_APP_MAXIMUM_FILE_SIZE / 1024 / 1024 + ' MB';
            }
        },
        watch: {
            selectedFiles() {
                if (this.hasBigFile) {
                    this.action = this.availableActions[0];
                } else {
                    this.action = 'upload';
                }
            }
        },
        methods: {
            titleCase(s) {
                return s.charAt(0).toUpperCase() + s.slice(1);
            }
        }
    };
</script>

<style lang="scss" scoped>
h4{
    color: #000000DE
}
</style>