class LibraryList {
    constructor(libraries) {
        this.items = libraries || [];
    }

    add (library) {
        this.items.push(library);
    }

    remove(library) {
        const index = this.items.map(libraryItem => libraryItem.id).indexOf(library.id);
        if (index > -1) {
            this.items.splice(index, 1);
        }
    }

    clear() {
        this.items = [];
    }

    map(callback) {
        return this.items.map(callback);
    }
}

export default LibraryList;