<template>
    <generic-modal
        title="Delete player template"
        :value="value"
        max-width="640"
        @cancel="cancel"
    >
        <template v-slot:default>
            <p>The selected template will be deleted. Would you like to confirm?</p>
            <v-list>
                <v-list-item dense>
                    <v-list-item-content>
                        {{ template.name }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                :loading="deleting"
                color="error"
                text
                @click="confirm"
            >
                Confirm
            </v-btn>
            <v-btn
                v-if="!deleting"
                text
                @click="cancel"
            >
                cancel
            </v-btn>
        </template>
    </generic-modal>
</template>

<script>
    import GenericModal from './generic-modal';
    import { toastSuccess } from '@/services/responseErrors';
    import { $http, playerTemplateApiUrl } from '@/services/http';

    export default {
        name: 'DeleteLibraryPlayerTemplate',
        components: { GenericModal },
        props: ['value', 'libraryId', 'template'],
        data() {
            return {
                deleting: false,
            };
        },
        methods: {
            async confirm() {
                this.deleting = true;
                const url = playerTemplateApiUrl(
                    `/libraries/${this.libraryId}/player-templates/${this.template.id}`
                );
                await $http.delete(url);
                toastSuccess('Library player template deleted.');
                this.$emit('deleted');
                this.deleting = false;
                this.$emit('input', false);
            },
            cancel() {
                this.$emit('input', false);
            },
        },
    };
</script>