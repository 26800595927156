import {$http, videoApiUrl} from '../services/http';

const libraries = {
    namespaced:true,
    state: {
        libraries: [],
        loading: false,
        error: false,
        errorContent: null
    },
    mutations: {
        replaceLibraries(state, libraries) {
            state.libraries = libraries;
            state.loading = false;
        },
        setError(state, payload) {
            state.errorContent = payload;
            state.error = payload ? true : false;
        },
        setLoading(state, status){
            state.loading = status;
        }
    },
    getters: {
        getLibraryCompanyName: () => library => {
            if (!library) {
                return '';
            }

            return library.company ? library.company.company_name : '';
        },
        allowedLibrariesBy: (state) => permission => {
            return state.libraries.filter(library => permission.indexOf(library.id) > -1);
        }
    },
    actions: {
        getLibraries({commit}) {
            commit('setLoading', true);
            $http.get(videoApiUrl('api/libraries/my-libraries'))
                .then(response => {
                    commit('replaceLibraries', response.data);
                });
        }
    }
};

export default libraries;