<template>
    <div class="px-1 py-2 mb-1 listing-header-container">
        <div class="d-flex">
            <div class="selector-column">
                &nbsp;
            </div>
            <div class="d-flex file-name-column">
                <button class="header-button">
                    Filename
                </button>
                <header-sort column="path" />
            </div>
            <div class="d-flex file-size-column">
                <button class="header-button">
                    Size (mb)
                </button>
                <header-sort column="size" />
            </div>
            <div class="d-flex align-center file-date-column">
                <button class="header-button">
                    Date added
                </button>
                <header-sort column="createdAt" />
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderSort from '@/components/pages/ftp/header-sort';
    export default {
        name: 'ListingHeader',
        components: {HeaderSort},
    };
</script>

<style>
.header-button {
    color: rgba(0,0,0,0.87);
}
</style>
