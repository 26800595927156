<template>
    <div>
        <v-btn
            small
            color="primary"
            class="mr-2 copy-button button-minwidth"
            @click.stop="copyToClipboard"
        >
            <v-icon small
                    class="mr-2"
            >
                file_copy
            </v-icon>
            <span class="mr-1">Copy URL</span>
        </v-btn>
        <v-btn
            icon
            small
            title="Open in new tab"
            :href="url"
            target="_blank"
        >
            <v-icon color="primary">
                open_in_new
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
    import copy from 'copy-to-clipboard';
    import { toastSuccess } from '@/services/responseErrors';
    export default {
        name: 'UrlPresenter',
        props: {
            url: {
                type: String,
                required: true
            }
        },
        methods: {
            copyToClipboard() {
                copy(this.url, {format: 'text/plain'});
                toastSuccess('Copied to clipboard!');
            }
        }
    };
</script>

<style scoped>
.copy-button.button-minwidth {
    min-width: 132px;
}
</style>