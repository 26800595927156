const PRESENTATION_EDITOR_SETTINGS_KEY = 'PresentationSettings';

function getPlayerPresentation(presentation, video) {
    return {
        type: 'qpd',
        duration: video.duration,
        presentation: JSON.parse(JSON.stringify(presentation)),
    };
}

function getFreeStartPoint(state, start) {
    // prevent adding element to the same spot
    let finalStart = start;

    state.presentation.items.forEach(item => {
        if (item.start === finalStart) {
            finalStart = item.start + 0.2;
        }
    });

    if (finalStart > state.video.duration / 1000) {
        finalStart = state.video.duration / 1000 - 0.4;
    }

    return finalStart;
}

function getPresentationSettings() {
    const settings = localStorage.getItem(PRESENTATION_EDITOR_SETTINGS_KEY);

    return settings ? JSON.parse(settings) : {};
}

function getPresentationExpandAll() {
    const parsedSettings = getPresentationSettings();

    if (typeof parsedSettings.expandAll === 'undefined') {
        return true;
    }

    return parsedSettings.expandAll;
}


function storePresentationExpandAll(expandAll) {
    const settings = getPresentationSettings();
    settings.expandAll = expandAll;
    localStorage.setItem(PRESENTATION_EDITOR_SETTINGS_KEY, JSON.stringify(settings));
}

export {
    getPlayerPresentation,
    getFreeStartPoint,
    getPresentationExpandAll,
    storePresentationExpandAll,
};