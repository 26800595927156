<template>
    <v-container fluid>
        <v-row
            justify-center
            align-center
            no-gutters
        >
            <v-col>
                <div class="content">
                    <v-col>
                        <v-card
                            class="pt-2"
                            elevation="0"
                        >
                            <h1 class="ml-4 mr-4 page-header">
                                <span v-if="editMode">Edit</span>
                                <span v-else>Add</span>
                                &nbsp;Company
                            </h1>
                            <validation-observer
                                ref="obs"
                                v-slot="{ invalid, validated, validate }"
                            >
                                <v-form
                                    class="ml-4 mr-4"
                                    @submit.prevent="submitCompany"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                        >
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="CompanyName"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    v-model="company.company_name"
                                                    label="Company name"
                                                    :error-messages="errors"
                                                    prepend-icon="mdi-office-building"
                                                    @keyup="validate"
                                                />
                                            </validation-provider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="pt-0 pb-0"
                                        >
                                            <v-text-field
                                                v-model="company.contact_person"
                                                dense
                                                label="Main contact person"
                                                prepend-icon="mdi-account"
                                                required
                                                @keyup="validate"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="pt-0 pb-0"
                                        >
                                            <v-text-field
                                                v-model="company.phone_number"
                                                dense
                                                label="Telephone / contact number"
                                                prepend-icon="mdi-phone"
                                                type="text"
                                                @keyup="validate"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="pt-0 pb-0"
                                        >
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Email"
                                                rules="required|email"
                                            >
                                                <v-text-field
                                                    v-model="company.email_address"
                                                    dense
                                                    label="Email"
                                                    prepend-icon="mdi-email"
                                                    :error-messages="errors"
                                                    @keyup="validate"
                                                />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <div class="text-left">
                                                <v-btn
                                                    type="submit"
                                                    color="accent"
                                                    class="mr-4"
                                                    :disabled="invalid || !validated"
                                                >
                                                    <span v-if="editMode">Save changes</span>
                                                    <span v-else>Add Company</span>&nbsp;
                                                </v-btn>
                                                <router-link
                                                    :to="{name: 'companies'}"
                                                    style="text-decoration: none"
                                                >
                                                    <v-btn
                                                        color="error"
                                                        text
                                                    >
                                                        Back
                                                    </v-btn>
                                                </router-link>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                        </v-card>
                        <v-card
                            class="mt-4 pt-2"
                            elevation="0"
                        >
                            <h1 class="ml-4 mr-4 page-header">
                                Landing page settings
                            </h1>
                            <v-card-text class="pt-0">
                                <v-row v-if="company.id">
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <div v-if="!logoLoading">
                                            <div v-if="!selectedLogo">
                                                <img
                                                    v-if="company.hasOwnLogo"
                                                    class="logo-holder mb-4"
                                                    style="height:36px"
                                                    :src="company.logoUrl"
                                                >
                                                <div
                                                    v-else
                                                    class="logo-holder mb-4"
                                                >
                                                    Please select a logo
                                                </div>
                                            </div>
                                            <img
                                                v-else
                                                class="logo-holder mb-4"
                                                style="height:36px"
                                                :src="selectedLogoDisplay"
                                            >
                                            <div
                                                v-if="selectedLogo"
                                                class="mb-4"
                                            >
                                                Please click Save changes to upload the logo
                                            </div>
                                        </div>
                                        <div v-else>
                                            <v-progress-circular
                                                class="mb-4"
                                                indeterminate
                                                size="36"
                                                color="primary"
                                            />
                                        </div>
                                        <input
                                            v-if="uploadReady"
                                            ref="logoInput"
                                            type="file"
                                            accept=".jpg, .jpeg, .png, image/jpeg, image/png"
                                            label="Select logo"
                                            class="file-input"
                                            @input="onSelectFile"
                                        >
                                        <v-btn
                                            class="mr-4"
                                            color="primary"
                                            small
                                            outlined
                                            @click="onSelectLogoClick"
                                        >
                                            <span v-if="company.hasOwnLogo">Replace Logo</span>
                                            <span v-else>Select Logo</span>
                                        </v-btn>
                                        <v-btn
                                            v-if="selectedLogo"
                                            class="mr-4"
                                            small
                                            outlined
                                            @click="resetLogo"
                                        >
                                            Reset
                                            Logo
                                        </v-btn>
                                        <v-btn
                                            v-if="company.hasOwnLogo"
                                            color="secondary"
                                            small
                                            outlined
                                            @click="removeLogo"
                                        >
                                            Remove Logo
                                        </v-btn>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-textarea
                                            v-model="company.footer_text"
                                            dense
                                            label="Footer text"
                                            prepend-icon="mdi-text"
                                        />
                                    </v-col>
                                    <v-col
                                        class="pb-0"
                                        cols="12"
                                    >
                                        <v-btn
                                            color="primary"
                                            @click="saveLandingPageSettings"
                                        >
                                            Save changes
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col>
                                        Landing page settings will be available after you save this company first.
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card
                            v-if="enableSocialMediaIntegration"
                            class="mt-4 pt-2"
                            elevation="0"
                        >
                            <h1 class="ml-4 mr-4 page-header">
                                Social media integration
                            </h1>
                            <v-card-text>
                                <vimeo-account
                                    :company="company"
                                    :accounts="publishingAccounts"
                                />
                                <youtube-accounts
                                    :company="company"
                                    :accounts="publishingAccounts"
                                />
                            </v-card-text>
                        </v-card>
                        <v-card
                            v-if="enableUsageStats"
                            class="mt-4 pt-2"
                            elevation="0"
                        >
                            <h1 class="ml-4 mr-4 page-header">
                                Usage stats
                            </h1>
                            <v-card-text>
                                <companies-usage-stats
                                    v-if="company.id"
                                    :company-id="company.id"
                                />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </div>
            </v-col>
        </v-row>
        <confirm-modal
            v-model="confirmRemove"
            title="Remove logo?"
            confirm-button="Remove Logo"
            cancel-button="Cancel"
            @confirm="onConfirmRemove"
        >
            This will remove the logo for this company and the preview page will show the default Quadia logo
            afterwards. Would you like to do this?
        </confirm-modal>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {$http, videoApiUrl} from '../../services/http';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {toastSuccess} from '../../services/responseErrors';
    import VimeoAccount from '../controls/publishing/vimeo-account';
    import ConfirmModal from '../controls/modals/confirm-modal';
    import {deleteLogo, saveLogo} from '../../services/company';
    import CompaniesUsageStats from '../controls/companies-usage-stats';
    import store from '../../store';
    import Gate from '../../services/gate';
    import YoutubeAccounts from '@/components/controls/publishing/youtube-accounts';
    import {getPublishingAccounts} from '@/services/publishing';

    export default {
        name: 'AddEditCompany',
        components: {
            YoutubeAccounts,
            CompaniesUsageStats,
            ValidationProvider,
            ValidationObserver,
            VimeoAccount,
            ConfirmModal,
        },
        data() {
            return {
                company: {
                    id: this.$route.params.id || '',
                    company_name: '',
                    contact_person: '',
                    phone_number: '',
                    email_address: '',
                    footer_text: '',
                    logoUrl: '',
                },
                selectedLogoDisplay: null,
                selectedLogo: null,
                uploadReady: true,
                confirmRemove: false,
                logoLoading: false,
                loadingPublishingAccounts: false,
                publishingAccounts: [],
            };
        },
        computed: {
            ...mapGetters('auth', ['user']),
            editMode() {
                return !!this.company.id;
            },
            enableSocialMediaIntegration() {
                return process.env.VUE_APP_ENABLE_SOCIAL_MEDIA_INTEGRATION
                    && this.editMode
                    && Gate.allows(this.user, 'VimeoAuthorize')
                    && !this.loadingPublishingAccounts;
            },
            enableUsageStats() {
                return Gate.allows(store.getters['auth/user'], 'usageStatsPage');
            },
        },
        methods: {
            getCompany() {
                $http.get(videoApiUrl('api/companies/' + this.company.id)).then(response => {
                    this.company = response.data;
                }).catch(() => {
                    this.$router.push({name: 'companies'});
                });
            },

            mapCompany() {
                return {
                    id: this.company.id,
                    company_name: this.company.company_name,
                    contact_person: this.company.contact_person,
                    phone_number: this.company.phone_number,
                    email_address: this.company.email_address,
                    footer_text: this.company.footer_text,
                };
            },

            submitCompany() {
                const method = this.company.id ? $http.put : $http.post;

                method(videoApiUrl('api/companies/' + this.company.id), this.mapCompany()).then(() => {
                    toastSuccess(`Company ${this.editMode ? 'updated' : 'saved'}`);
                    if (!this.editMode) {
                        this.$router.push('/companies');
                    }
                });
            },

            onSelectFile() {
                const input = this.$refs.logoInput;
                const files = input.files;
                if (files && files[0]) {
                    const reader = new FileReader();
                    reader.onload = e =>
                        this.selectedLogoDisplay = e.target.result;

                    reader.readAsDataURL(files[0]);

                    this.selectedLogo = files[0];
                }
            },

            resetLogo() {
                this.selectedLogo = null;
                this.selectedLogoDisplay = null;
                this.uploadReady = false;
                this.$nextTick(() => {
                    this.uploadReady = true;
                });
            },

            removeLogo() {
                this.confirmRemove = true;
            },

            onSelectLogoClick() {
                this.$refs.logoInput.click();
            },

            onConfirmRemove() {
                this.logoLoading = true;
                this.resetLogo();
                deleteLogo(this.company.id)
                    .then(() => {
                        this.company.hasOwnLogo = false;
                        this.company.logo = '';
                        this.company.logoUrl = '';
                    })
                    .finally(() => this.logoLoading = false);
            },

            saveLandingPageSettings() {
                this.logoLoading = true;
                const promises = [
                    $http.put(videoApiUrl('api/companies/' + this.company.id), this.mapCompany()),
                ];

                if (this.selectedLogo) {
                    promises.push(saveLogo(this.company.id, this.$refs.logoInput.files[0]));
                }

                Promise.all(promises)
                    .then(values => {
                        values.forEach((value) => {
                            if (value.logo) {
                                this.company.logoUrl = value.logo;
                                this.company.hasOwnLogo = true;
                                this.resetLogo();
                            }
                        });
                    })
                    .finally(() => this.logoLoading = false);
            }
        },
        mounted() {
            if (this.editMode) {
                this.getCompany();
                this.loadingPublishingAccounts = true;
                getPublishingAccounts(this.company.id)
                    .then(accounts => this.publishingAccounts = accounts)
                    .finally(() => this.loadingPublishingAccounts = false);
            }
        }
    };
</script>
<style scoped>
    .file-input {
        visibility: hidden;
        width: 1px;
        height: 1px;
        position: absolute;
    }

    .logo-holder {
        height: 36px;
        display: block;
    }
</style>
