<template>
    <v-textarea
        ref="editor"
        v-model="dataValue"
        :counter="meta.counter"
        rows="15"
        @input="onUpdate"
    />
</template>

<script>
    export default {
        name: 'Longtext',
        props: ['value', 'meta'],
        data() {
            return {
                dataValue: this.value
            };
        },
        methods: {
            onUpdate() {
                this.validate();
                this.$emit('input', this.dataValue);
            },
            validate() {
                const length = this.meta.counter || 100000;
                let result = true;
                if (this.dataValue.length > length) {
                    result = {
                        message: 'The text entered is too long. It should have at most ' + this.meta.counter + ' characters.'
                    };
                }

                this.$emit('validate', result);
                return result;
            }
        },
        mounted() {
            this.$refs.editor.focus();
        }
    };
</script>

<style scoped>

</style>