import {$http, publishingApiUrl} from '@/services/http';

export function getPublishingAccounts(entityId) {
    return $http.get(publishingApiUrl(`/accounts/qpd/token/company/${entityId}`)).then(response => {
        return response.data;
    });
}

export function removePublishingAccount(accountId) {
    return new Promise(resolve => {
        $http.delete(publishingApiUrl(`/accounts/qpd/token/${accountId}`)).then(() => {
            resolve();
        });
    });
}

export function getPublishingInfo(videoId) {
    return $http.get(publishingApiUrl(`/qpd/publish/${videoId}`)).then(response => {
        return {
            vimeo: response.data.filter(item => item.external_platform === 'vimeo'),
            youtube: response.data.filter(item => item.external_platform === 'youtube')
        };
    });
}