import InteractiveDimensions from './primitives/InteractiveDimensions';
import InteractiveOrigin from './primitives/InteractiveOrigin';

class InteractiveElementFormat {
    constructor(data) {
        this.dimensions = new InteractiveDimensions(data.dimensions);
        this.origin = new InteractiveOrigin(data.origin);
        this.default = {};
        this.hover = {};
    }
}

export default InteractiveElementFormat;