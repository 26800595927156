<template>
    <span>
        <span class="visualizer-item mr-4">
            <color-visualizer :value="value.color" />
        </span>
        <span class="visualizer-item width-element mr-4">
            {{ value.width }}px
        </span>
    </span>
</template>

<script>
    import ColorVisualizer from './ColorVisualizer';
    export default {
        name: 'BorderVisualizer',
        components: {ColorVisualizer},
        props: ['value'],
    };
</script>

<style scoped>
    .width-element {
        display: inline-block;
        width: 3em;
        text-align: right;
    }
</style>