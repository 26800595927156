<template>
    <div class="interactive-element-container">
        <div class="editor">
            <interactive-editor-player class="player-container" />
            <interactive-element-visual-editor
                v-if="player"
                :key="activeKey"
                class="visual-editor-container"
            />
        </div>
        <interactive-external-player-controls v-if="player" />
        <interactive-external-scrub v-if="player" />
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import InteractiveExternalScrub from './external-scrub/interactive-external-scrub';
    import InteractiveEditorPlayer from './interactive-editor-player';
    import InteractiveElementVisualEditor from './interactive-element-visual-editor';
    import InteractiveExternalPlayerControls from './interactive-external-player-controls';

    export default {
        name: 'InteractiveElementContainer',
        components: {
            InteractiveExternalPlayerControls,
            InteractiveElementVisualEditor,
            InteractiveEditorPlayer,
            InteractiveExternalScrub
        },
        computed: {
            ...mapGetters('interactive', ['activeElement']),
            ...mapState('interactive', ['player']),
            activeKey() {
                const activeId = this.activeElement?._id || -1;
                return `interactive-element-visual-editor-${activeId}`;
            }
        }
    };
</script>

<style lang="scss">
.interactive-element-container {
    width: calc((100vh - (220px)) * (16 / 9));
    max-width:100%;

    .editor {
        position: relative;
        max-height: calc(100vh - (220px));
        margin-bottom: 1em;

        .player-container {
            position: relative;
            display: flex;
        }
        .visual-editor-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
</style>