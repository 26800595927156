<template>
    <tr
        v-if="video"
        class="editable"
    >
        <td>Project number:</td>
        <td>
            <div class="editable-meta">
                <span
                    v-safe-html="video.metadata.projectNumber"
                    class="editable-content"
                    @dblclick="openEditor()"
                />
                <metadata-field-editor
                    v-if="canEdit"
                    :key="'projectNumber-' + video.id"
                    ref="editableProjectNumber"
                    v-model="video.metadata.projectNumber"
                    name="projectNumber"
                    type="inline"
                    :meta="{counter: 50, pattern: /^([a-zA-Z0-9 \-_\.])+$/gi}"
                    @input="videoProjectNumberUpdated"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import updateMeta from '../../../../services/metadata';
    import MetadataFieldEditor from '../editor/MetadataFieldEditor';
    import Gate from '../../../../services/gate';
    import store from '../../../../store/index';

    export default {
        name: 'MetaProjectNumberField',
        components: {MetadataFieldEditor},
        props: ['video'],
        computed: {
            canEdit() {
                return Gate.allows(store.getters['auth/user'], 'manageVideo', this.video.library);
            }
        },
        methods: {
            videoProjectNumberUpdated(value) {
                updateMeta(this.video.id, {
                    'projectNumber': value
                });
            },
            openEditor() {
                if(this.canEdit) {
                    this.$refs.editableProjectNumber.openEditor();
                }
            }
        },
    };
</script>

<style scoped>

</style>
