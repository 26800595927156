export default {
    getOptions() {
        return [
            {
                id: 'public',
                name: 'Public',
            },
            {
                id: 'private',
                name: 'Private',
            },
            {
                id: 'scheduled',
                name: 'Scheduled',
            },
        ];
    },
    getOption(item_key){
        return this.getOptions().find(item => {
            return item.id === item_key;
        }).name;
    }

};
