<template>
    <v-row>
        <v-col>
            <v-expansion-panels accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Sync webhooks
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-data-table :headers="headers"
                                      :items="value"
                                      no-data-text="There are no synchronization webhooks."
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'WebhooksListing',
        props: {
            value: {
                type: Array,
                required: true,
            }
        },
        data() {
            return {
                headers: [
                    {
                        'text': 'URL',
                        'sortable': false,
                        'align': 'start',
                        'value': 'url',
                    },
                    {
                        'text': 'API Key',
                        'sortable': false,
                        'align': 'start',
                        'value': 'apiKey',
                    }
                ]
            };
        },
    };
</script>

<style scoped>

</style>