<template>
    <div>
        <script
            type="application/javascript"
            :src="playerSrc"
            :data-video-id="videoId"
            :data-id="id"
            :data-token="authToken"
            data-no-tracking="true"
            data-show-title="false"
            data-disable-interactive="true"
            data-disable-presentation="true"
            data-controls="false"
            data-no-custom-css="true"
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'InteractiveEditorPlayer',
        computed: {
            ...mapGetters('interactive', ['videoId'])
        },
        data() {
            return {
                playerSrc: process.env.VUE_APP_PLAYER_SRC,
                authToken: sessionStorage.getItem('access_token'),
                id: 'interactive-' + this.videoId + '-' + Date.now(),
                playerInterval: null
            };
        },
        methods: {
            registerPlayerIntoStore() {
                this.playerInterval = setInterval(() => {
                    try {
                        let player = window.QuadiaPlayer.players[this.id];
                        player.$on('playerReady', (ready) => {
                            if (ready) {
                                const p = window.QuadiaPlayer.players[this.id];
                                this.$store.commit('interactive/setPlayer', p);
                            }
                        });
                        clearInterval(this.playerInterval);
                    } catch {
                    // It is ok to have no custom config.
                    }
                }, 10);
            }
        },
        mounted() {
            this.registerPlayerIntoStore();
        },
        beforeDestroy() {
            clearInterval(this.playerInterval);
        }
    };
</script>