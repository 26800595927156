<template>
    <div
        class="numeric-input"
        :class="{'error-state': error}"
    >
        <input
            ref="autofocus"
            class="segment"
            type="text"
            maxlength="2"
            placeholder="00"
            :value="hours"
            :disabled="readOnly"
            @input="setHours"
            @focus="select"
            @keydown="confirmEdit"
        >
        :
        <input
            class="segment"
            type="text"
            maxlength="2"
            placeholder="00"
            :value="minutes"
            :disabled="readOnly"
            @input="setMinutes"
            @focus="select"
            @keydown="confirmEdit"
        >
        :
        <input
            class="segment"
            type="text"
            maxlength="2"
            placeholder="00"
            :value="seconds"
            :disabled="readOnly"
            @input="setSeconds"
            @focus="select"
            @keydown="confirmEdit"
        >
        .
        <input
            class="segment decimals"
            type="text"
            maxlength="1"
            placeholder="0"
            :value="millies"
            :disabled="readOnly"
            @input="setMilliseconds"
            @focus="select"
            @keydown="confirmEdit"
        >
        <v-tooltip v-if="shouldConfirm"
                   top
        >
            <template v-slot:activator="{on}">
                <v-btn x-small
                       color="primary"
                       tile
                       fab
                       class="ml-2 pa-0 mt-n1"
                       width="16"
                       height="16"
                       :disabled="error || loading"
                       :loading="loading"
                       v-on="on"
                       @click="emitValueIfCorrect"
                >
                    <v-icon x-small>
                        check
                    </v-icon>
                </v-btn>
            </template>
            <span>Click this button to save your change.</span>
        </v-tooltip>
        <v-tooltip
            :value="error"
            bottom
            color="#f0f0f0"
            eleveion="4"
            class="error-tooltip"
        >
            <template v-slot:activator="{on}">
                <span v-on="on" />
            </template>
            <span
                v-safe-html="errorMessage"
                class="error--text"
            />
        </v-tooltip>
    </div>
</template>

<script>
    import TimeHelper from '@/time-helper';

    const Hx = 3600;
    const Mx = 60;
    const Sx = 1;
    const Ux = 0.1;

    export default {
        name: 'TimeIndexInput',
        props: {
            value: {
                type: Number,
            },
            max: {
                type: Number,
                default: null,
            },
            autofocus: {
                type: Boolean,
                default: false,
            },
            readOnly: {
                type: Boolean
            },
            shouldConfirm: {
                type: Boolean,
                default: () => false,
            }
        },
        data() {
            return {
                localValue: 0,
                error: false,
                loading: false,
            };
        },
        computed: {
            hours() {
                return Math.floor(this.localValue / Hx);
            },
            minutes() {
                return Math.floor((this.localValue - this.hours * Hx) / Mx);
            },
            seconds() {
                return Math.floor(
                    (this.localValue - this.hours * Hx - this.minutes * Mx) / Sx
                );
            },
            millies() {
                return Math.floor((this.localValue * 10) % 10);
            },
            errorMessage() {
                const maximum = this.max
                    ? TimeHelper.fromMsToHisU(this.max * 1000)
                    : 'unlimited';
                return `Value is out of allowed range <br />(0 - ${maximum}).`;
            },
        },
        methods: {
            setHours(e) {
                var sum = this.minutes * Mx + this.seconds * Sx + this.millies * Ux;
                this.setVal(e, 100, Hx, sum);
            },
            setMinutes(e) {
                var sum = this.hours * Hx + this.seconds * Sx + this.millies * Ux;
                this.setVal(e, 60, Mx, sum);
            },
            setSeconds(e) {
                var sum = this.hours * Hx + this.minutes * Mx + this.millies * Ux;
                this.setVal(e, 60, Sx, sum);
            },
            setMilliseconds(e) {
                var sum = this.hours * Hx + this.minutes * Mx + this.seconds * Sx;
                this.setVal(e, 10, Ux, sum);
                this.select(e);
            },
            setVal(e, segmentMax, unit, sum) {
                var val = parseInt(e.target.value) || 0;
                val = 0 <= val && val < segmentMax ? val : 0;
                sum += val * unit;

                if (this.max !== null && sum > this.max) {
                    this.error = true;
                    this.select(e);
                } else {
                    this.error = false;
                    if (!this.shouldConfirm) {
                        this.emitValue(sum);
                    }
                }

                this.localValue = sum;

                if (!this.error && val > 5) {
                    var nextInput = e.target.nextElementSibling;
                    if (nextInput) {
                        nextInput.focus();
                    }
                }
                this.$forceUpdate();
            },
            emitValue(value) {
                this.$emit('input', value);
            },
            emitValueIfCorrect()
            {
                if (!this.error) {
                    this.loading = true;
                    this.emitValue(this.localValue);
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                }
            },
            confirmEdit(x) {
                if (!this.shouldConfirm) {
                    return;
                }
                if (x.keyCode === 13 || x.which === 13) {
                    this.emitValueIfCorrect();
                }
            },
            select(e) {
                e.target.select();
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.localValue = val;
                },
            },
        },
        mounted() {
            if (this.autofocus) {
                this.$refs.autofocus.focus();
            }
        },
    };
</script>

<style lang="scss" scoped>
.error-tooltip {
  background-color: white;
}
.numeric-input {
    bottom: -1px;
    padding: 0 2px;
    display: inline;
    white-space: nowrap;
    border: 1px solid #bbbbbb;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 3px;
    position: relative;

    &.error-state {
        border: 1px solid #cc1100;
    }

    .floating-error {
        position: absolute;
        white-space: initial;
        left: 0;
        min-width: 200px;
        max-width: 250px;
        z-index: 1;
    }
}
.segment {
    width: 1.5em;
    text-align: right;

    &.decimals {
        width: 0.9em;
    }
}
</style>
