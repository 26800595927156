<template>
    <v-card class="pa-1">
        <v-card-title class="font-weight-regular">
            Select files and folders
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-row class="mb-5">
                <v-col cols="12">
                    <v-select v-model="folder"
                              :items="ftpFolderAccess"
                              outlined
                              dense
                              hide-details
                              label="Folder"
                              placeholder="Please select a folder"
                    >
                        <template v-slot:append-outer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn icon
                                           small
                                           :disabled="!folder"
                                           v-on="on"
                                           @click="folder = folder"
                                    >
                                        <v-icon>
                                            mdi-refresh
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh file list</span>
                            </v-tooltip>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <div class="ftp-file-list">
                <listing-header />
                <v-progress-linear v-if="loading"
                                   indeterminate
                />
                <ftp-file-listing :class="{'mb-9': folder && loading}" />
                <h4 v-if="!folder"
                    class="mt-5 pl-1"
                >
                    Please select a folder.
                </h4>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import ListingHeader from './listing-header';
    import FtpFileListing from './ftp-file-listing';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    export default {
        name: 'FtpFolders',
        components: {ListingHeader, FtpFileListing},
        data(){
            return{
                loading: false,
            };
        },
        computed:{
            ...mapState('ftp', ['selectedFolder']),
            ...mapGetters('auth', ['ftpFolderAccess']),
            folder:{
                get(){
                    return this.selectedFolder;
                },
                async set(value){
                    this.setFiles([]);
                    this.loading = true;
                    await this.setSelectedFolder(value);
                    this.setSelectedFiles([]);
                    await this.getFiles();
                    this.loading = false;
                }
            }
        },
        methods:{
            ...mapMutations('ftp', ['setSelectedFolder', 'setSelectedFiles', 'setFiles']),
            ...mapActions('ftp', ['getFiles'])
        }
    };
</script>

<style lang="scss">
.ftp-file-list {
  .selector-column {
    flex: 0 0 80px;
  }
  .file-name-column {
    flex: 1 1 0;
    white-space: normal !important;
    overflow-wrap: break-word;
    word-break: break-all;
  }
  .file-size-column {
    flex: 0 0 150px;
  }
  .file-date-column {
    flex: 0 0 150px;
  }
  .icon-column {
    flex: 0 0 50px;
  }
}
</style>